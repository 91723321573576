import React from "react";
import { Grid } from "@material-ui/core";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { RouteNames, RouteParams } from "../../../constants/routeNames";
import NavTabs from "../../../components/NavTabs";
import PastOrders from "../../../components/UserProfile/PastOrders";
import OrderDetails from "../../../components/OrderDetails";
import FutureOrders from "../../../components/UserProfile/FutureOrders";
import tabs from "./tabs";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const WasherOrders: React.FC<Props> = () => {
  useTitle(`Washerly Orders`);
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/:type/:${RouteParams.ORDER_ID}`}>
        <OrderDetails />
      </Route>
      <Grid container spacing={2}>
        <NavTabs tabs={tabs}>
          <Switch>
            <Route exact path={`${match.path}/${RouteNames.NON_FUTURE_WASH}`}>
              <PastOrders />
            </Route>
            <Route exact path={`${match.path}/${RouteNames.FUTURE_WASH}`}>
              <FutureOrders />
            </Route>
            <Redirect to={`${match.path}/${RouteNames.NON_FUTURE_WASH}`} />
          </Switch>
        </NavTabs>
      </Grid>
    </Switch>
  );
};

export default WasherOrders;
