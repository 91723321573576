import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  createStyles,
  DialogActions,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(5),
      height: "auto",
      maxHeight: "80%",
      minHeight: 370,
    },
    closeButton: {
      padding: 0,
      color: theme.palette.error.dark,
    },
    titleContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    buttonLabel: {
      fontSize: 10,
      color: theme.palette.text.secondary,
    },
  })
);

interface PopupProps {
  title?: string;
  subtitle?: string;
  open: boolean;
  handleClose: () => void;
  simpleSubmit?: string;
}

const Popup: React.FC<PopupProps> = ({
  title,
  open,
  handleClose,
  children,
  simpleSubmit,
  subtitle,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      aria-labelledby="form-dialog-title"
    >
      <Box className={classes.titleContainer}>
        <Box>
          <Typography variant="h2" component="h2" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {subtitle}
          </Typography>
        </Box>

        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
      {simpleSubmit && (
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            autoFocus
            classes={{
              label: classes.buttonLabel,
            }}
          >
            {simpleSubmit}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Popup;
