import React, { SVGProps } from "react";

type Props = SVGProps<any>;

const CouponIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.901"
      height="41.9"
      viewBox="0 0 41.901 41.9"
      {...props}
    >
      <g id="coupons" transform="translate(0 -0.001)">
        <path
          id="Path_723"
          data-name="Path 723"
          d="M41.534,10.174l-4.992-4.91a1.227,1.227,0,0,0-.861-.352H26.394L21.843.361a1.228,1.228,0,0,0-1.736,0L15.556,4.911H6.22a1.228,1.228,0,0,0-.861.352L.367,10.174A1.228,1.228,0,0,0,0,11.049V40.674A1.228,1.228,0,0,0,1.228,41.9H40.673A1.228,1.228,0,0,0,41.9,40.674V11.049a1.228,1.228,0,0,0-.367-.875ZM35.178,7.367l4.267,4.2v3.29a6.234,6.234,0,0,0-4.867,4.789H25.13l7.129-7.129a1.228,1.228,0,0,0,0-1.736l-3.41-3.41Zm-14.2-4.4,8.68,8.68-8,8H7.322a6.005,6.005,0,0,0-.9-2.128L20.975,2.965ZM6.722,7.367H13.1L4.687,15.781a6.2,6.2,0,0,0-2.232-.927v-3.29Zm32.723,32.08H2.455V17.4A3.75,3.75,0,0,1,4.992,20.87,1.228,1.228,0,0,0,6.22,22.1H35.681a1.228,1.228,0,0,0,1.228-1.228A3.75,3.75,0,0,1,39.445,17.4Z"
          transform="translate(0 0)"
          fill="currentColor"
        />
        <path
          id="Path_724"
          data-name="Path 724"
          d="M199.236,88.684a1.228,1.228,0,0,0,1.736,1.736l3.472-3.472a1.228,1.228,0,1,0-1.736-1.736Z"
          transform="translate(-182.601 -77.907)"
          fill="currentColor"
        />
        <path
          id="Path_725"
          data-name="Path 725"
          d="M221.364,134.507a1.228,1.228,0,0,0,1.736,0l5.133-5.133a1.228,1.228,0,1,0-1.736-1.736l-5.133,5.133A1.228,1.228,0,0,0,221.364,134.507Z"
          transform="translate(-202.918 -116.861)"
          fill="currentColor"
        />
        <circle
          id="Ellipse_160"
          data-name="Ellipse 160"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(17 26.902)"
          fill="currentColor"
        />
        <circle
          id="Ellipse_161"
          data-name="Ellipse 161"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(22 31.902)"
          fill="currentColor"
        />
        <path
          id="Path_726"
          data-name="Path 726"
          d="M218.005,332.358a1.228,1.228,0,0,0-1.736,0l-4.91,4.91A1.228,1.228,0,0,0,213.1,339l4.91-4.91A1.228,1.228,0,0,0,218.005,332.358Z"
          transform="translate(-193.732 -304.827)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default CouponIcon;
