import React from "react";
import WashersWithRequestsList from "./WashersWithRequestsList";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { RouteParams } from "../../../constants/routeNames";
import EquipmentRequestsForWasher from "./EquipmentRequestsForWasher";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const EquipmentsRouter: React.FC<Props> = () => {
  useTitle(`Washerly Admin Equipments`);
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <WashersWithRequestsList />
      </Route>
      <Route
        path={`${path}/:${RouteParams.USER_ID}/`}
        component={EquipmentRequestsForWasher}
      />
    </Switch>
  );
};

export default EquipmentsRouter;
