import React, { SVGProps } from "react";

type Props = SVGProps<any>;

const VerificationSentIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="81"
      viewBox="0 0 81 81"
      {...props}
    >
      <path
        id="add-contact"
        d="M77.836,22.94A3.164,3.164,0,0,0,74.672,26.1v42.24a6.338,6.338,0,0,1-4.03,5.895,7.861,7.861,0,0,0-.093-2.69A30.743,30.743,0,0,0,51.508,49.228a16.454,16.454,0,1,0-21.8-.082,30.749,30.749,0,0,0-19.254,22.4,7.861,7.861,0,0,0-.093,2.69,6.336,6.336,0,0,1-4.03-5.894V12.657a6.335,6.335,0,0,1,6.328-6.328H45.879a3.164,3.164,0,1,0,0-6.328H12.656A12.67,12.67,0,0,0,0,12.657V68.344A12.67,12.67,0,0,0,12.656,81H68.344A12.671,12.671,0,0,0,81,68.344V26.1A3.164,3.164,0,0,0,77.836,22.94ZM40.652,26.75A10.125,10.125,0,1,1,30.527,36.875,10.136,10.136,0,0,1,40.652,26.75Zm-23.7,47.361a1.489,1.489,0,0,1-.306-1.266,24.374,24.374,0,0,1,47.71,0,1.515,1.515,0,0,1-1.493,1.827H18.138A1.5,1.5,0,0,1,16.951,74.111ZM51.869,14.261a3.164,3.164,0,0,1,4.474-4.475l7.206,7.2a.405.405,0,0,0,.546-.018L75.254,1.326A3.164,3.164,0,1,1,80.405,5L69.168,20.755q-.053.073-.109.145a6.752,6.752,0,0,1-4.8,2.5q-.222.015-.444.015a6.749,6.749,0,0,1-4.644-1.856l-.056-.054Zm0,0"
        transform="translate(0 0)"
        fill="#707070"
      />
    </svg>
  );
};

export default VerificationSentIcon;
