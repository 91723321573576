import React, { useEffect } from "react";
import TitledPaper from "../../../../shared/TitledPaper";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import StarIcon from "../../../../shared/icons/StarIcon";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import { parseRating } from "../../../../helpers/utils/utils";
import useActions from "../../../../hooks/useActions";
import { ReviewsActions } from "../../../../store/actions/reviews.actions";

interface OwnProps {}

type Props = OwnProps;

const MyRating: React.FC<Props> = () => {
  const { authorizedUser } = useSelector((state: AppState) => state.auth);
  const reviewsActions = useActions<typeof ReviewsActions>(ReviewsActions);
  const reviewsState = useSelector((state: AppState) => state.reviews);
  useEffect(() => {
    reviewsActions.getReviewsForActiveUser({ size: 1, page: 0 });
  }, [reviewsActions]);
  if (!authorizedUser) {
    return null;
  }
  const rating = !authorizedUser.rating
    ? "-.-"
    : parseRating(authorizedUser.rating);
  return (
    <Grid item xs={12}>
      <TitledPaper
        title={"My Rating"}
        subtitle={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
      >
        <Box
          mt={3}
          pb={4}
          display="flex"
          alignItems="center"
          color="secondary.main"
        >
          <StarIcon size="large" />
          <Box ml={2}>
            <Typography variant="subtitle1" color="textPrimary">
              Rating:
            </Typography>
          </Box>
          <Box ml={1.5}>
            <Typography variant="h2" color="secondary">
              {rating}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          pt={4}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexDirection="column"
        >
          <Typography variant="h4" color="primary" gutterBottom>
            {reviewsState.reviewsForActiveUser.total}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            Total Review
          </Typography>
        </Box>
      </TitledPaper>
    </Grid>
  );
};

export default MyRating;
