import { createStyles, makeStyles, Theme } from "@material-ui/core";
import colors from "../../../../../constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(1),
    },
    rowContainer: {
      paddingLeft: theme.spacing(4),
      boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.03)",
    },
    divider: {
      marginRight: theme.spacing(2.5),
      height: theme.spacing(3),
    },
    textContainer: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: theme.palette.primary.main,
    },
    inProgressContainer: {
      width: "100%",
      minHeight: theme.spacing(7),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    arrowIcon: {
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "transparent",
        color: colors.green,
      },
    },
  })
);
