import { RouteNames } from "../../constants/routeNames";
import { UserRoutes } from "../../models/router.models";
import WasherTutorials from "../../pages/washer/WasherTutorials";
import WasherExam from "../../pages/washer/WasherExam";

const pendingVerifiedWasherRoutes: UserRoutes = {
  routes: [
    {
      path: `/${RouteNames.TUTORIALS}`,
      component: WasherTutorials,
      exact: true,
    },
    {
      path: `/${RouteNames.START_THE_EXAM}`,
      component: WasherExam,
      exact: true,
    },
  ],
  defaultRoute: `/${RouteNames.TUTORIALS}`,
};

export default pendingVerifiedWasherRoutes;
