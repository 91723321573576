import { axiosInstance } from "./base.api";
import { AxiosRequestConfig } from "axios";
import {
  EarningsGraphForCompanyAdminResponse,
  EarningsListForCompanyAdminResponse,
} from "../models/responses/earnings.model";
import MockAdapter from "axios-mock-adapter";
import { ResponseStatus } from "../models/responses/result.model";
import { promoterEarnings } from "../mock/requests/promoterEarnings";

export const earningsApi = {
  getEarningsGraphDataForCompanyAdmin(params: {}) {
    const config = {
      params: {
        ...params,
      },
    };
    return axiosInstance.get<EarningsGraphForCompanyAdminResponse>(
      `/private/earnings/filter`,
      config
    );
  },

  getEarningsHistory(params: {}) {
    const config = {
      params: {
        ...params,
      },
    };
    const mock = new MockAdapter(axiosInstance, { delayResponse: 500 });

    mock
      .onGet("/private/earnings/list", { params: { size: 5, page: 0 } })
      .reply(200, {
        result: ResponseStatus.SUCCESS,
        data: promoterEarnings,
      });
    const req = axiosInstance.get<EarningsListForCompanyAdminResponse>(
      `/private/earnings/list`,
      config
    );
    mock.restore();
    return req;
  },

  getEarningsPDF(params: {}) {
    const config: AxiosRequestConfig = {
      responseType: "arraybuffer",
      params: {
        ...params,
      },
    };

    return axiosInstance.get(`/private/earnings/pdf`, config);
  },
};
