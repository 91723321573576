import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import CouponCode from "../../../../components/CouponCode";
import texts from "../../../../constants/texts";

interface OwnProps {}

type Props = OwnProps;

const CompanyCoupon: React.FC<Props> = (props) => {
  const coupon = useSelector(
    (state: AppState) => state.company.activeCompany?.activeCoupon
  );
  if (!coupon) {
    return null;
  }

  return (
    <CouponCode
      coupon={coupon}
      title={texts.roles.companyAdmin.dashboard.couponTitle}
      subtitle={texts.roles.companyAdmin.dashboard.couponSubtitle}
    />
  );
};

export default CompanyCoupon;
