import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import { Sessions } from "../../models/responses/session.model";
import { GetWasherSessionsParams } from "../../models/requests/Sessions";

export enum SessionActionTypes {
  GET_USER_SESSIONS = "[Session] Get user sessions",
  GET_USER_SESSIONS_SUCCESS = "[Session] Get user sessions success",
  GET_USER_SESSIONS_FAILURE = "[Session] Get user sessions failure",
}

export const SessionActions = {
  getUserSessions: (payload: GetWasherSessionsParams) =>
    createAction(SessionActionTypes.GET_USER_SESSIONS, payload),
  getUserSessionsSuccess: (payload: Sessions) =>
    createAction(SessionActionTypes.GET_USER_SESSIONS_SUCCESS, payload),
  getUserSessionsFailure: (payload: string) =>
    createAction(SessionActionTypes.GET_USER_SESSIONS_FAILURE, payload),
};

export type SessionActions = ActionsUnion<typeof SessionActions>;

export type getMySessionsHistoryType = ActionsOfType<
  SessionActions,
  SessionActionTypes.GET_USER_SESSIONS
>;
