import React from "react";
import EditUserData from "../EditUserData/EditUserData";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import Loading from "../../shared/Loading";

interface OwnProps {}

type Props = OwnProps;

const EditUserProfile: React.FC<Props> = () => {
  const { activeUser } = useSelector((state: AppState) => state.users);

  if (!activeUser) {
    return <Loading />;
  }

  return <EditUserData user={activeUser} />;
};

export default EditUserProfile;
