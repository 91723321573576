import styled from "styled-components";
import { unitSize } from "../constants/sizes";
import colors from "../constants/colors";

export default styled.div`
  && {
    height: ${unitSize * 2.5}px;
    position: relative;
    ::after {
      position: absolute;
      display: block;
      content: "";
      height: 6px;
      width: 6px;
      top: -2px;
      right: -2px;
      border: 2px solid ${colors.white};
      border-radius: 50%;
      background-color: ${(props) => props.color};
    }
  }
`;
