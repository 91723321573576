import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import {
  EarningsGraphData,
  EarningsListForCompanyAdmin,
} from "../../models/responses/earnings.model";
import {
  GetEarningsParams,
  GetEarningsPDFParams,
} from "../../models/requests/Earnings";

export enum EarningsActionTypes {
  GET_EARNINGS_GRAPH_DATA_FOR_COMPANY_ADMIN = "[Earnings] Get earnings graph data for company admin",
  GET_EARNINGS_GRAPH_DATA_FOR_COMPANY_ADMIN_SUCCESS = "[Earnings] Get earnings graph data for company admin success",
  GET_EARNINGS_GRAPH_DATA_FOR_COMPANY_ADMIN_FAILURE = "[Earnings] Get earnings graph data for company admin failure",
  GET_EARNINGS_GRAPH_DATA_PARAMS = "[Earnings] SET earnings graph params",

  GET_EARNINGS_HISTORY = "[Earnings] Get earnings history data",
  GET_EARNINGS_HISTORY_SUCCESS = "[Earnings] Get earnings history data success",
  GET_EARNINGS_HISTORY_FAILURE = "[Earnings] Get earnings history data failure",

  GET_EARNINGS_PDF = "[Earnings] Get earnings PDF",
}

export const EarningsActions = {
  getEarningsGraphDataForCompanyAdmin: (payload: GetEarningsParams) =>
    createAction(
      EarningsActionTypes.GET_EARNINGS_GRAPH_DATA_FOR_COMPANY_ADMIN,
      payload
    ),
  setParamsForGraphDataForCompanyAdmin: (payload: GetEarningsParams) =>
    createAction(EarningsActionTypes.GET_EARNINGS_GRAPH_DATA_PARAMS, payload),
  getEarningsGraphDataForCompanyAdminSuccess: (payload: EarningsGraphData[]) =>
    createAction(
      EarningsActionTypes.GET_EARNINGS_GRAPH_DATA_FOR_COMPANY_ADMIN_SUCCESS,
      payload
    ),
  getEarningsGraphDataForCompanyAdminFailure: (payload: string) =>
    createAction(
      EarningsActionTypes.GET_EARNINGS_GRAPH_DATA_FOR_COMPANY_ADMIN_FAILURE,
      payload
    ),

  getEarningsHistory: (payload: GetEarningsParams) =>
    createAction(EarningsActionTypes.GET_EARNINGS_HISTORY, payload),
  getEarningsHistorySuccess: (payload: EarningsListForCompanyAdmin) =>
    createAction(EarningsActionTypes.GET_EARNINGS_HISTORY_SUCCESS, payload),
  getEarningsHistoryFailure: (payload: string) =>
    createAction(EarningsActionTypes.GET_EARNINGS_HISTORY_FAILURE, payload),

  getEarningsPDF: (payload: GetEarningsPDFParams) =>
    createAction(EarningsActionTypes.GET_EARNINGS_PDF, payload),
};

export type EarningsActions = ActionsUnion<typeof EarningsActions>;

export type getEarningsGraphDataForCompanyAdminType = ActionsOfType<
  EarningsActions,
  EarningsActionTypes.GET_EARNINGS_GRAPH_DATA_FOR_COMPANY_ADMIN
>;
export type getEarningsHistoryType = ActionsOfType<
  EarningsActions,
  EarningsActionTypes.GET_EARNINGS_HISTORY
>;
export type getEarningsPDFType = ActionsOfType<
  EarningsActions,
  EarningsActionTypes.GET_EARNINGS_PDF
>;
