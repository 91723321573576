import React, { SyntheticEvent } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "./MySnackbarContentWrapper";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import useActions from "../../hooks/useActions";
import { AppActions } from "../../store/actions/app.actions";

interface OwnProps {}

type Props = OwnProps;

const CustomizedSnackbar: React.FC<Props> = () => {
  const {
    snackbar: { isOpen, variant, message },
  } = useSelector((state: AppState) => state.app);
  const appActions = useActions<typeof AppActions>(AppActions);

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    appActions.closeSnackbar();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={isOpen}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <MySnackbarContentWrapper
        onClose={handleClose}
        variant={variant}
        message={message}
      />
    </Snackbar>
  );
};

export default CustomizedSnackbar;
