import { put, takeLatest } from "@redux-saga/core/effects";
import { statisticApi } from "../../api/statistic.api";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import {
  CompanyWasherGeneralStatistics,
  CompanyWasherStatistics,
  GeneralStatistic,
  PromoterStatistic,
  WasherStatistic,
} from "../../models/responses/statistic.model";
import { showError } from "./utils";
import {
  getCompanyWasherStatisticsType,
  StatisticActions,
  StatisticActionTypes,
} from "../actions/statistic.actions";

function* getGeneralStatistic() {
  try {
    const { data } = yield statisticApi.getGeneralStatistic();
    if (data.result === ResponseStatus.SUCCESS) {
      const statData = yield transformAndValidate(GeneralStatistic, data.data);
      yield put(StatisticActions.setGeneralStatistic(statData));
    }
  } catch (e) {
    yield showError(e);
  }
}

function* getWasherStatistics() {
  try {
    const { data } = yield statisticApi.getMyWashStatistic();
    if (data.result === ResponseStatus.SUCCESS) {
      const statData = yield transformAndValidate(WasherStatistic, data.data);
      yield put(StatisticActions.setWasherStatistic(statData));
    }
  } catch (e) {
    yield showError(e);
  }
}

function* getPromoterStatistics() {
  try {
    const { data } = yield statisticApi.getMyPromoteStatistic();
    if (data.result === ResponseStatus.SUCCESS) {
      const statData = yield transformAndValidate(PromoterStatistic, data.data);
      yield put(StatisticActions.setPromoterStatistic(statData));
    }
  } catch (e) {
    yield showError(e);
  }
}

function* getCompanyWasherGeneralStatistics() {
  try {
    const { data } = yield statisticApi.getCompanyWasherGeneralStatistics();
    if (data.result === ResponseStatus.SUCCESS) {
      const statData = yield transformAndValidate(
        CompanyWasherGeneralStatistics,
        data.data
      );
      yield put(StatisticActions.setCompanyWasherGeneralStatistics(statData));
    }
  } catch (e) {
    yield showError(e);
  }
}

function* getCompanyWasherStatistics(action: getCompanyWasherStatisticsType) {
  try {
    const { data } = yield statisticApi.getCompanyWasherStatistics(
      action.payload
    );
    if (data.result === ResponseStatus.SUCCESS) {
      const statData = yield transformAndValidate(
        CompanyWasherStatistics,
        data.data
      );
      yield put(StatisticActions.setCompanyWasherStatistics(statData));
    }
  } catch (e) {
    yield showError(e);
  }
}

export function* StatisticSagas() {
  yield takeLatest(
    StatisticActionTypes.GET_GENERAL_STATISTIC,
    getGeneralStatistic
  );
  yield takeLatest(
    StatisticActionTypes.GET_WASHER_STATISTIC,
    getWasherStatistics
  );
  yield takeLatest(
    StatisticActionTypes.GET_PROMOTER_STATISTIC,
    getPromoterStatistics
  );
  yield takeLatest(
    StatisticActionTypes.GET_COMPANY_WASHER_GENERAL_STATISTICS,
    getCompanyWasherGeneralStatistics
  );
  yield takeLatest(
    StatisticActionTypes.GET_COMPANY_WASHER_STATISTICS,
    getCompanyWasherStatistics
  );
}
