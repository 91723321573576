import { HeadCell, ReferralsTableColumns } from "../../../models/table.models";
import texts from "../../../constants/texts";

const headCells: HeadCell<ReferralsTableColumns>[] = [
  {
    id: ReferralsTableColumns.INFO,
    label: texts.roles.promoter.referrals.tableHeaders.info,
    size: 3,
  },
  {
    id: ReferralsTableColumns.TYPE,
    label: texts.roles.promoter.referrals.tableHeaders.type,
    size: 2,
  },
  {
    id: ReferralsTableColumns.DATE,
    label: texts.roles.promoter.referrals.tableHeaders.date,
    size: 3,
  },
  {
    id: ReferralsTableColumns.COMMISSION,
    label: texts.roles.promoter.referrals.tableHeaders.commission,
    size: 4,
  },
];

export default headCells;
