import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const SignUpIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.5"
      height="11.263"
      viewBox="0 0 16.5 11.263"
      {...props}
    >
      <g
        id="Group_341"
        data-name="Group 341"
        transform="translate(-437 -500.716)"
      >
        <g id="user_7_" data-name="user (7)" transform="translate(437 500.716)">
          <g id="Group_22" data-name="Group 22" transform="translate(0)">
            <path
              id="Path_29"
              data-name="Path 29"
              d="M9.614,7.281a5.61,5.61,0,0,0-2.14-1.342,3.256,3.256,0,1,0-3.684,0A5.64,5.64,0,0,0,0,11.263H.88a4.752,4.752,0,1,1,9.5,0h.88A5.6,5.6,0,0,0,9.614,7.281ZM5.632,5.632A2.376,2.376,0,1,1,8.007,3.256,2.378,2.378,0,0,1,5.632,5.632Z"
              transform="translate(0)"
              fill="currentColor"
            />
          </g>
        </g>
        <line
          id="Line_114"
          data-name="Line 114"
          y2="4"
          transform="translate(451.5 504.5)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
        <line
          id="Line_115"
          data-name="Line 115"
          y2="4"
          transform="translate(453.5 506.5) rotate(90)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default SignUpIcon;
