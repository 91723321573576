import { ResultModel } from "./result.model";
import {
  IsDate,
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from "class-validator";
import { Transform, Type } from "class-transformer";
import { BasicModel } from "./basic.model";

export enum CarTypes {
  BIKE = "bike",
  MINIVAN = "minivan",
  SEDAN = "sedan",
  SUV = "suv",
}

export class CarType {
  @IsUUID()
  id: string;
  @IsEnum(CarTypes)
  name: CarTypes;
}

export class Car extends BasicModel {
  @IsDefined()
  @Type(() => CarType)
  @ValidateNested()
  carType: CarType;
  @IsUUID()
  carTypeId: string;
  @IsOptional()
  @IsDate()
  @Type(() => Date)
  lastWash: Date | null;
  @IsString()
  licensePlate: string;
  @IsString()
  model: string;
  @IsOptional()
  @IsNumber()
  @Type(() => Number)
  @Transform((value) => (typeof value === "number" ? value : 0))
  ordersCount?: number;
  @IsString()
  ownerId: string;
}

export class Cars {
  @IsDefined()
  @Type(() => Car)
  @ValidateNested()
  items: Car[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export type CarResponse = ResultModel<Car>;
export type CarsResponse = ResultModel<Cars>;
