import React from "react";
import { Modal } from "@material-ui/core";

interface OwnProps {
  open: boolean;
  onClose: () => void;
  videoId: string;
}

type Props = OwnProps;

const VideoModal: React.FC<Props> = ({ onClose, open, videoId }) => {
  return (
    <Modal
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <iframe
        title={`video-${videoId}`}
        width="80%"
        height="80%"
        src={`https://www.youtube.com/embed/${videoId}?feature=oembed`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Modal>
  );
};

export default VideoModal;
