import {
  CompanyPayedOrdersColumns,
  HeadCell,
} from "../../../../../../models/table.models";
import texts from "../../../../../../constants/texts";

const headCells: HeadCell<CompanyPayedOrdersColumns>[] = [
  {
    id: CompanyPayedOrdersColumns.ID,
    label: texts.roles.companyAdmin.payouts.requestPayout.tableHeaders.id,
    size: 3,
  },
  {
    id: CompanyPayedOrdersColumns.DATE,
    label: texts.roles.companyAdmin.payouts.requestPayout.tableHeaders.date,
    size: 3,
  },
  {
    id: CompanyPayedOrdersColumns.WASHER_NAME,
    label:
      texts.roles.companyAdmin.payouts.requestPayout.tableHeaders.washerName,
    size: 3,
  },
  {
    id: CompanyPayedOrdersColumns.COMMISSION,
    label:
      texts.roles.companyAdmin.payouts.requestPayout.tableHeaders.commission,
    size: 2,
  },
];

export default headCells;
