import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import withRoot from "./mui/withRoot";
import { withWidth } from "@material-ui/core";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
};

export default withRoot(withWidth()(App));
