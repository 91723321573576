import React from "react";
import { TextField } from "@material-ui/core";

interface OwnProps {}

type Props = OwnProps;

const DateRangeInput: React.FC<Props> = React.forwardRef(
  ({ placeholder, ...props }: any, ref) => {
    return (
      <TextField size="small" label={placeholder} {...props} inputRef={ref} />
    );
  }
);

export default DateRangeInput;
