import React, { ReactNode, ReactNodeArray, useEffect, useState } from "react";
import { SortParams } from "../../models/utils.models";
import {
  HeadCell,
  HeaderValue,
  TableHeaders,
  TablePaginationParams,
} from "../../models/table.models";
import TableHeader from "../../shared/sharedTable/TableHeader";
import { Grid, TablePagination } from "@material-ui/core";
import TableLoader from "../TableLoader";
import useDelay from "../../hooks/useDelay";
import useQuery from "../../hooks/useQuery";
import { useHistory, useLocation } from "react-router-dom";
import { QueryParams } from "../../constants/routeNames";

interface OwnProps {
  title: string;
  subtitle: string;
  values?: HeaderValue[];
  isLoading: boolean;
  total: number;
  headCells: HeadCell<TableHeaders>[];
  onPaginationChange: (values: TablePaginationParams) => void;
  withoutSorting?: boolean;
  headCellsButton?: ReactNode;
  valuesComponents?: ReactNodeArray;
  tableHeaderLayout?: ReactNode;
  tableHeaderRightSide?: ReactNode;
  search?: string;
}

type Props = OwnProps;

const TableLayout: React.FC<Props> = (props) => {
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();

  const [size, setSize] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [sort, setSort] = useState<SortParams>();
  const [sortBy, setSortBy] = useState<TableHeaders>();

  const querySize = query.get(QueryParams.SIZE);
  const queryPage = query.get(QueryParams.PAGE);
  const querySort = query.get(QueryParams.SORT);
  const querySortBy = query.get(QueryParams.SORT_BY);

  useEffect(() => {
    if (typeof querySize == "string") {
      setSize(Number(querySize));
    }
    if (typeof queryPage == "string") {
      setPage(Number(queryPage));
    }
    if (typeof querySort == "string") {
      setSort(querySort as SortParams);
    }
    if (typeof querySortBy == "string") {
      setSortBy(querySortBy as TableHeaders);
    }
  }, [location.search, querySize, queryPage, querySort, querySortBy]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    query.set(QueryParams.SIZE, event.target.value);
    query.set(QueryParams.PAGE, "0");
    history.push({
      pathname: location.pathname,
      search: query.toString(),
    });
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    query.set(QueryParams.PAGE, newPage.toString());
    history.push({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  const handleChangeSort = (property: TableHeaders) => {
    const isDesc = sortBy === property && sort === SortParams.DESC;
    query.set(QueryParams.SORT, isDesc ? SortParams.ASC : SortParams.DESC);
    query.set(QueryParams.SORT_BY, property);
    history.push({
      pathname: location.pathname,
      search: query.toString(),
    });
  };
  useEffect(() => {
    handleChangePage(undefined, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.search]);

  useEffect(() => {
    props.onPaginationChange({
      size,
      page,
      ...(sort && { sort }),
      ...(sortBy && { sortBy }),
      ...(props.search && { searchParam: props.search }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, page, sort, sortBy, props.search, props.onPaginationChange]);

  if (useDelay(props.isLoading, 1000)) {
    return <TableLoader />;
  }

  return (
    <Grid item container xs={12}>
      <TableHeader
        {...props}
        order={sort}
        orderBy={sortBy}
        onSortChange={!props.withoutSorting ? handleChangeSort : undefined}
        headerLayout={props.tableHeaderLayout}
        headerRightSide={props.tableHeaderRightSide}
      />

      {props.children}
      <Grid item xs={12}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.total}
          rowsPerPage={size}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default TableLayout;
