import { RouteNames } from "../../constants/routeNames";
import WasherVerification from "../../pages/washer/WasherVerification";
import { UserRoutes } from "../../models/router.models";

const pendingWasherRoutes: UserRoutes = {
  routes: [
    {
      path: `/${RouteNames.VERIFICATION}`,
      component: WasherVerification,
      exact: true,
    },
  ],
  defaultRoute: `/${RouteNames.VERIFICATION}`,
};

export default pendingWasherRoutes;
