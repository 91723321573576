import React from "react";

interface OwnProps {}

type Props = OwnProps;

const VerificationIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        id="add-contact"
        d="M17.3,5.1a.7.7,0,0,0-.7.7v9.387a1.408,1.408,0,0,1-.9,1.31,1.747,1.747,0,0,0-.021-.6,6.832,6.832,0,0,0-4.231-4.96A3.656,3.656,0,1,0,6.6,10.921,6.833,6.833,0,0,0,2.323,15.9a1.747,1.747,0,0,0-.021.6,1.408,1.408,0,0,1-.9-1.31V2.812A1.408,1.408,0,0,1,2.812,1.406H10.2A.7.7,0,1,0,10.2,0H2.812A2.816,2.816,0,0,0,0,2.812V15.187A2.816,2.816,0,0,0,2.812,18H15.187A2.816,2.816,0,0,0,18,15.187V5.8A.7.7,0,0,0,17.3,5.1Zm-8.263.847a2.25,2.25,0,1,1-2.25,2.25A2.252,2.252,0,0,1,9.034,5.944ZM3.767,16.469a.331.331,0,0,1-.068-.281,5.416,5.416,0,0,1,10.6,0,.337.337,0,0,1-.332.406H4.031A.334.334,0,0,1,3.767,16.469Zm7.759-13.3a.7.7,0,0,1,.994-.995l1.6,1.6a.09.09,0,0,0,.121,0L16.723.294a.7.7,0,1,1,1.145.817l-2.5,3.5-.024.032A1.5,1.5,0,0,1,14.28,5.2q-.049,0-.1,0a1.5,1.5,0,0,1-1.032-.412l-.012-.012Zm0,0"
        transform="translate(0 0)"
      />
    </svg>
  );
};

export default VerificationIcon;
