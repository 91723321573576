import { UserRoutes } from "../../models/router.models";
import { RouteNames } from "../../constants/routeNames";
import PromoterDashboard from "../../pages/promoter/PromoterDashboard";
import Referrals from "../../pages/promoter/Referrals";
import PromoterPayouts from "../../pages/promoter/PromoterPayouts";
import Settings from "../../pages/Settings";

const promoterRoutes: UserRoutes = {
  routes: [
    {
      path: `/${RouteNames.DASHBOARD}`,
      component: PromoterDashboard,
      exact: true,
    },
    {
      path: `/${RouteNames.REFERRALS}`,
      component: Referrals,
      exact: true,
    },
    {
      path: `/${RouteNames.PAYOUTS}`,
      component: PromoterPayouts,
    },
    {
      path: `/${RouteNames.SETTINGS}`,
      component: Settings,
    },
  ],
  defaultRoute: `/${RouteNames.DASHBOARD}`,
};

export default promoterRoutes;
