import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import { Companies, Company } from "../../models/responses/company.model";
import { createCompanyData } from "../../models/requests/Company";

export enum CompanyActionTypes {
  GET_ALL_COMPANIES = "[Company] Get all companies",
  GET_ALL_COMPANIES_SUCCESS = "[Company] Get all companies success",
  GET_ALL_COMPANIES_FAILURE = "[Company] Get all companies failure",

  GET_MY_ACTIVE_COMPANY = "[Company] Get my active company",
  GET_MY_ACTIVE_COMPANY_SUCCESS = "[Company] Get my active company success",
  GET_MY_ACTIVE_COMPANY_FAILURE = "[Company] Get my active company failure",

  CREATE_COMPANY = "[Company] Create company",
  CREATE_COMPANY_SUCCESS = "[Company] Create company success",
  CREATE_COMPANY_FAILURE = "[Company] Create company failure",
}

export const CompanyActions = {
  getAllCompanies: () => createAction(CompanyActionTypes.GET_ALL_COMPANIES),
  getAllCompaniesSuccess: (payload: Companies) =>
    createAction(CompanyActionTypes.GET_ALL_COMPANIES_SUCCESS, payload),
  getAllCompaniesFailure: (payload: string) =>
    createAction(CompanyActionTypes.GET_ALL_COMPANIES_FAILURE, payload),

  getMyActiveCompany: () =>
    createAction(CompanyActionTypes.GET_MY_ACTIVE_COMPANY),
  getMyActiveCompanySuccess: (payload: Company) =>
    createAction(CompanyActionTypes.GET_MY_ACTIVE_COMPANY_SUCCESS, payload),
  getMyActiveCompanyFailure: (payload: string) =>
    createAction(CompanyActionTypes.GET_MY_ACTIVE_COMPANY_FAILURE, payload),

  createCompany: (payload: createCompanyData) =>
    createAction(CompanyActionTypes.CREATE_COMPANY, payload),
  createCompanySuccess: (payload: Company) =>
    createAction(CompanyActionTypes.CREATE_COMPANY_SUCCESS, payload),
  createCompanyFailure: (payload: string) =>
    createAction(CompanyActionTypes.CREATE_COMPANY_FAILURE, payload),
};

export type CompanyActions = ActionsUnion<typeof CompanyActions>;

export type createCompanyType = ActionsOfType<
  CompanyActions,
  CompanyActionTypes.CREATE_COMPANY
>;
