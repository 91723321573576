import { all, fork } from "redux-saga/effects";
import { AuthSagas } from "./auth.sagas";
import { StatisticSagas } from "./statistic.sagas";
import { UsersSagas } from "./users.sagas";
import { OrdersSagas } from "./orders.sagas";
import { CarsSagas } from "./cars.sagas";
import { ReviewsSagas } from "./reviews.sagas";
import { CompanySagas } from "./company.sagas";
import { WasherVerificationSagas } from "./washerVerification.sagas";
import { PendingWashersSagas } from "./pendingWashers.sagas";
import { PaymentSagas } from "./payment.sagas";
import { PayoutsSagas } from "./payouts.sagas";
import { EquipmentSagas } from "./equipment.sagas";
import { SessionSagas } from "./session.sagas";
import { LogSagas } from "./log.sagas";
import { ReferralsSagas } from "./referrals.sagas";
import { EarningsSaga } from "./earnings.sagas";
import { MobileBuildBlacklistSagas } from "./mobileBuildBlacklist.sagas";

export default function* rootSaga() {
  yield all([
    fork(AuthSagas),
    fork(StatisticSagas),
    fork(UsersSagas),
    fork(OrdersSagas),
    fork(CarsSagas),
    fork(ReviewsSagas),
    fork(CompanySagas),
    fork(WasherVerificationSagas),
    fork(PendingWashersSagas),
    fork(PaymentSagas),
    fork(PayoutsSagas),
    fork(EquipmentSagas),
    fork(SessionSagas),
    fork(LogSagas),
    fork(ReferralsSagas),
    fork(EarningsSaga),
    fork(MobileBuildBlacklistSagas),
  ]);
}
