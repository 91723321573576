import styled from "styled-components";
import { unitSize } from "../constants/sizes";
import colors from "../constants/colors";
import { StatusColors } from "../models/utils.models";

function getColors(color: StatusColors) {
  switch (color) {
    case StatusColors.GREEN:
      return {
        text: colors.green,
        border: colors.lightGreen,
        background: colors.greenBackground,
      };
    case StatusColors.YELLOW:
      return {
        text: colors.yellow,
        border: colors.yellow,
        background: colors.veryLightYellow,
      };
    case StatusColors.RED:
      return {
        text: colors.darkRed,
        border: colors.lightRed,
        background: colors.redBackground,
      };
    case StatusColors.LIGHT_BLUE:
      return {
        text: colors.blue,
        border: colors.lightBlue,
        background: colors.lightBlue2,
      };
    case StatusColors.GREY:
      return {
        text: colors.textSecondary,
        border: colors.lightGrey,
      };
  }
}

interface BorderedValueProps {
  color: StatusColors;
  filled?: boolean;
}

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${unitSize * 4}px;
  padding-left: ${unitSize * 2}px;
  padding-right: ${unitSize * 2}px;
  color: ${(props: BorderedValueProps) => getColors(props.color)?.text};
  border: 1px solid
    ${(props: BorderedValueProps) => getColors(props.color)?.border};
  background-color: ${(props: BorderedValueProps) =>
    props.filled ? getColors(props.color)?.background : "inherit"};
  border-radius: 5px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
`;
