import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import {
  getAdminPayoutsParams,
  getUserPayoutsParams,
  requestCompanyPayoutsData,
  requestPromoterPayoutData,
  requestWasherPayoutData,
} from "../../models/requests/Payouts";
import { Payouts } from "../../models/responses/payout.model";
import { CompanyOrders, Orders } from "../../models/responses/order.model";
import { ID } from "../../models/utils.models";
import { Referrals } from "../../models/responses/referrals.model";

export enum PayoutsActionTypes {
  GET_PAYOUTS_FOR_USER = "[Payouts] Get payouts for washer",
  GET_PAYOUTS_FOR_ADMIN = "[Payouts] Get payouts for admin",
  GET_PAYOUTS_SUCCESS = "[Payouts] Get payouts success",
  GET_PAYOUTS_FAILURE = "[Payouts] Get payouts failure",

  GET_PAYED_ORDERS_LIST_FOR_WASHER = "[Payouts] Get payed orders list for washer",
  GET_PAYED_ORDERS_LIST_SUCCESS = "[Payouts] Get payed orders list success",
  GET_PAYED_ORDERS_LIST_FAILURE = "[Payouts] Get payed orders list failure",

  GET_UNPAID_COMPANY_ORDERS = "[Payouts] Get unpaid company orders",
  GET_UNPAID_COMPANY_ORDERS_SUCCESS = "[Payouts] Get unpaid company orders success",
  GET_UNPAID_COMPANY_ORDERS_FAILURE = "[Payouts] Get unpaid company orders failure",

  GET_PAYED_REFERRALS_LIST_FOR_PROMOTER = "[Payouts] Get payed referrals list for promoter",
  GET_PAYED_REFERRALS_LIST_SUCCESS = "[Payouts] Get payed referrals list success",
  GET_PAYED_REFERRALS_LIST_FAILURE = "[Payouts] Get payed referrals list failure",

  REQUEST_WASHER_PAYOUT = "[Payouts] Request washer payout",
  REQUEST_PROMOTER_PAYOUT = "[Payouts] Request promoter payout",
  REQUEST_COMPANY_PAYOUT = "[Payouts] Request company payout",
  REQUEST_PAYOUT_SUCCESS = "[Payouts] Request payout success",
  REQUEST_PAYOUT_FAILURE = "[Payouts] Request payout failure",

  ACCEPT_PAYOUT = "[Payouts] Accept payout",
  REJECT_PAYOUT = "[Payouts] Reject payout",
  DISPATCH_WASHER_PAYOUT_SUCCESS = "[Payouts] Dispatch washer payout success",
  DISPATCH_WASHER_PAYOUT_FAILURE = "[Payouts] Dispatch washer payout failure",
}

export const PayoutsActions = {
  getPayoutsForUser: (payload: getUserPayoutsParams) =>
    createAction(PayoutsActionTypes.GET_PAYOUTS_FOR_USER, payload),
  getPayoutsForAdmin: (payload: getAdminPayoutsParams) =>
    createAction(PayoutsActionTypes.GET_PAYOUTS_FOR_ADMIN, payload),
  getPayoutsSuccess: (payload: Payouts) =>
    createAction(PayoutsActionTypes.GET_PAYOUTS_SUCCESS, payload),
  getPayoutsFailure: (payload: string) =>
    createAction(PayoutsActionTypes.GET_PAYOUTS_FAILURE, payload),

  getPayedOrdersListForWasher: () =>
    createAction(PayoutsActionTypes.GET_PAYED_ORDERS_LIST_FOR_WASHER),
  getPayedOrdersListSuccess: (payload: Orders) =>
    createAction(PayoutsActionTypes.GET_PAYED_ORDERS_LIST_SUCCESS, payload),
  getPayedOrdersListFailure: (payload: string) =>
    createAction(PayoutsActionTypes.GET_PAYED_ORDERS_LIST_FAILURE, payload),

  getUnpaidCompanyOrders: () =>
    createAction(PayoutsActionTypes.GET_UNPAID_COMPANY_ORDERS),
  getUnpaidCompanyOrdersSuccess: (payload: CompanyOrders) =>
    createAction(PayoutsActionTypes.GET_UNPAID_COMPANY_ORDERS_SUCCESS, payload),
  getUnpaidCompanyOrdersFailure: (payload: string) =>
    createAction(PayoutsActionTypes.GET_UNPAID_COMPANY_ORDERS_FAILURE, payload),

  getPayedReferralsListForPromoter: () =>
    createAction(PayoutsActionTypes.GET_PAYED_REFERRALS_LIST_FOR_PROMOTER),
  getPayedReferralsListSuccess: (payload: Referrals) =>
    createAction(PayoutsActionTypes.GET_PAYED_REFERRALS_LIST_SUCCESS, payload),
  getPayedReferralsListFailure: (payload: string) =>
    createAction(PayoutsActionTypes.GET_PAYED_REFERRALS_LIST_FAILURE, payload),

  requestWasherPayout: (payload: requestWasherPayoutData) =>
    createAction(PayoutsActionTypes.REQUEST_WASHER_PAYOUT, payload),
  requestPromoterPayout: (payload: requestPromoterPayoutData) =>
    createAction(PayoutsActionTypes.REQUEST_PROMOTER_PAYOUT, payload),
  requestCompanyPayout: (payload: requestCompanyPayoutsData) =>
    createAction(PayoutsActionTypes.REQUEST_COMPANY_PAYOUT, payload),
  requestPayoutSuccess: () =>
    createAction(PayoutsActionTypes.REQUEST_PAYOUT_SUCCESS),
  requestPayoutFailure: (payload: string) =>
    createAction(PayoutsActionTypes.REQUEST_PAYOUT_FAILURE, payload),

  acceptPayout: (payload: { payoutId: ID }) =>
    createAction(PayoutsActionTypes.ACCEPT_PAYOUT, payload),
  rejectPayout: (payload: { payoutId: ID; message: string }) =>
    createAction(PayoutsActionTypes.REJECT_PAYOUT, payload),
  dispatchWasherPayoutSuccess: (payload: string) =>
    createAction(PayoutsActionTypes.DISPATCH_WASHER_PAYOUT_SUCCESS, payload),
  dispatchWasherPayoutFailure: (payload: string) =>
    createAction(PayoutsActionTypes.DISPATCH_WASHER_PAYOUT_FAILURE, payload),
};

export type PayoutsActions = ActionsUnion<typeof PayoutsActions>;

export type getPayoutsForWasherType = ActionsOfType<
  PayoutsActions,
  PayoutsActionTypes.GET_PAYOUTS_FOR_USER
>;
export type getPayoutsForAdminType = ActionsOfType<
  PayoutsActions,
  PayoutsActionTypes.GET_PAYOUTS_FOR_ADMIN
>;
export type requestWasherPayoutType = ActionsOfType<
  PayoutsActions,
  PayoutsActionTypes.REQUEST_WASHER_PAYOUT
>;
export type requestPromoterPayoutType = ActionsOfType<
  PayoutsActions,
  PayoutsActionTypes.REQUEST_PROMOTER_PAYOUT
>;
export type requestCompanyPayoutType = ActionsOfType<
  PayoutsActions,
  PayoutsActionTypes.REQUEST_COMPANY_PAYOUT
>;
export type acceptPayoutType = ActionsOfType<
  PayoutsActions,
  PayoutsActionTypes.ACCEPT_PAYOUT
>;
export type rejectPayoutType = ActionsOfType<
  PayoutsActions,
  PayoutsActionTypes.REJECT_PAYOUT
>;
