import { put, takeLatest } from "@redux-saga/core/effects";
import {
  changeSearchValueType,
  getActiveOrderType,
  getFutureOrdersForActiveUserType,
  getOrdersListType,
  getPastOrdersForActiveUserType,
  OrdersActions,
  OrdersActionTypes,
} from "../actions/orders.actions";
import { orderApi } from "../../api/order.api";
import {
  Order,
  Orders,
  OrderTimeFrame,
} from "../../models/responses/order.model";
import { SortParams } from "../../models/utils.models";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import { showError } from "./utils";
import { delay } from "redux-saga/effects";

function* getOrdersList(action: getOrdersListType) {
  try {
    const { data } = yield orderApi.getOrdersList(
      action.payload,
      SortParams.DESC
    );
    if (data.result === ResponseStatus.SUCCESS) {
      const orders = yield transformAndValidate(Orders, data.data);
      yield put(OrdersActions.getOrdersListSuccess(orders));
    }
  } catch (e) {
    yield showError(e);
    yield put(OrdersActions.getOrdersListFailure(e.message));
  }
}

function* getPastOrdersForUser(action: getPastOrdersForActiveUserType) {
  try {
    const { data } = yield orderApi.getOrdersByUserId(
      action.payload,
      OrderTimeFrame.NON_FUTURE,
      SortParams.DESC
    );
    if (data.result === ResponseStatus.SUCCESS) {
      const pastOrders = yield transformAndValidate(Orders, data.data);
      yield put(OrdersActions.getPastOrdersForActiveUserSuccess(pastOrders));
    }
  } catch (e) {
    yield showError(e);
    yield put(OrdersActions.getPastOrdersForActiveUserFailure(e.message));
  }
}

function* getFutureOrdersForUser(action: getFutureOrdersForActiveUserType) {
  try {
    const { data } = yield orderApi.getOrdersByUserId(
      action.payload,
      OrderTimeFrame.FUTURE
    );
    if (data.result === ResponseStatus.SUCCESS) {
      const futureOrders = yield transformAndValidate(Orders, data.data);
      yield put(
        OrdersActions.getFutureOrdersForActiveUserSuccess(futureOrders)
      );
    }
  } catch (e) {
    yield showError(e);
    yield put(OrdersActions.getFutureOrdersForActiveUserFailure(e.message));
  }
}

function* getOrder(action: getActiveOrderType) {
  try {
    const { data } = yield orderApi.getOrder(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const activeOrder = yield transformAndValidate(Order, data.data);
      yield put(OrdersActions.getActiveOrderSuccess(activeOrder));
    }
  } catch (e) {
    yield showError(e);
    yield put(OrdersActions.getActiveOrderFailure(e.message));
  }
}

function* getLastCompleteOrder() {
  try {
    const { data } = yield orderApi.getLastCompleteOrder();
    if (data.result === ResponseStatus.SUCCESS) {
      const lastCompleteOrder = yield transformAndValidate(Orders, data.data);
      yield put(
        OrdersActions.getLastCompleteOrderSuccess(lastCompleteOrder.items[0])
      );
    }
  } catch (e) {
    yield showError(e);
    yield put(OrdersActions.getLastCompleteOrderFailure(e.message));
  }
}

function* changeSearchValue(action: changeSearchValueType) {
  yield delay(400);
  yield put(OrdersActions.setSearchValue(action.payload));
}

export function* OrdersSagas() {
  yield takeLatest(OrdersActionTypes.GET_ORDERS_LIST, getOrdersList);
  yield takeLatest(
    OrdersActionTypes.GET_PAST_ORDERS_FOR_ACTIVE_USER,
    getPastOrdersForUser
  );
  yield takeLatest(
    OrdersActionTypes.GET_FUTURE_ORDERS_FOR_ACTIVE_USER,
    getFutureOrdersForUser
  );
  yield takeLatest(OrdersActionTypes.GET_ACTIVE_ORDER_DATA, getOrder);
  yield takeLatest(
    OrdersActionTypes.GET_LAST_COMPLETE_ORDER,
    getLastCompleteOrder
  );
  yield takeLatest(OrdersActionTypes.CHANGE_SEARCH_VALUE, changeSearchValue);
}
