import {
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { ResultModel } from "./result.model";
import { User } from "./user.model";
import { BasicModel } from "./basic.model";
import { Currency } from "../utils.models";

export enum PayoutStatus {
  REQUESTED = "REQUESTED",
  REJECTED = "REJECTED",
  PAID = "PAID",
}

export class Payout extends BasicModel {
  @IsDefined()
  @IsString()
  invoiceFileId: string;

  @IsEnum(PayoutStatus)
  status: PayoutStatus;

  @IsOptional()
  @IsString()
  description?: string | null;

  @IsNumber()
  @Type(() => Number)
  amount: number;

  @IsEnum(Currency)
  currency: Currency;

  @IsDefined()
  @IsString()
  userId: string;

  @IsDefined()
  @Type(() => User)
  @ValidateNested()
  user: User;
}

export class Payouts {
  @IsDefined()
  @Type(() => Payout)
  @ValidateNested()
  items: Payout[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export type PayoutResponse = ResultModel<Payout>;
export type PayoutsResponse = ResultModel<Payouts>;
