import React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import CouponBackground from "../Images/CouponBackground.jpg";
import CouponIcon from "../shared/icons/CouponIcon";
import colors from "../constants/colors";
import PromoCode from "../styled/PromoCode";
import CopyIcon from "../shared/icons/CopyIcon";
import CopyToClipboard from "../shared/CopyToClipboard";
import { Coupon } from "../models/responses/coupon.model";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: theme.spacing(16),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(7),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundImage: `url(${CouponBackground})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    text: {
      color: theme.palette.common.white,
    },
    copyText: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      borderBottom: ".5px dashed rgba(255, 255, 255, .25)",
      cursor: "pointer",
      "&:hover": {
        borderBottomColor: theme.palette.common.white,
      },
    },
  })
);

interface OwnProps {
  coupon: Coupon;
  title: string;
  subtitle: string;
}

type Props = OwnProps;

const CouponCode: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box display="flex" flexBasis="40%">
        <CouponIcon color={colors.yellow} />
        <Box ml={4}>
          <Typography variant="h4" className={classes.text} gutterBottom>
            {props.title}
          </Typography>
          <Typography variant="body1" className={classes.text}>
            {props.subtitle}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" overflow="hidden">
        <PromoCode>{props.coupon.code}</PromoCode>
        <Box ml={4} display="flex" alignItems="center">
          <CopyIcon color={colors.white} />
          <CopyToClipboard>
            {({ copy }) => (
              <Typography
                variant="body1"
                className={classes.copyText}
                onClick={() => copy(props.coupon.code)}
              >
                Copy my coupon code
              </Typography>
            )}
          </CopyToClipboard>
        </Box>
      </Box>
    </Paper>
  );
};

export default CouponCode;
