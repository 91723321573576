import React from "react";
import {
  PendingUserStatus,
  StatusColors,
} from "../../../../models/utils.models";
import StatusCell from "../../../../styled/StatusCell";

interface OwnProps {
  status: PendingUserStatus;
}

type Props = OwnProps;

const PendingStatusCell: React.FC<Props> = (props) => {
  const getColorByStatus = (status: PendingUserStatus) => {
    switch (status) {
      case PendingUserStatus.EXAM:
        return StatusColors.GREEN;
      case PendingUserStatus.PENDING:
        return StatusColors.YELLOW;
    }
  };
  return (
    <StatusCell color={getColorByStatus(props.status)}>
      {props.status}
    </StatusCell>
  );
};

export default PendingStatusCell;
