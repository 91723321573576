import * as yup from "yup";
import texts from "../constants/texts";
import { UserRole } from "../models/responses/user.model";

const phoneRegExp = /^\+?[1-9]\d{1,14}$/;

const firstName = yup.string().required("First Name shouldn't be empty ");
const secondName = yup.string().required("Last Name shouldn't be empty ");
const email = yup
  .string()
  .email(texts.validations.email.notValid)
  .required(texts.validations.email.isRequired);
const password = yup
  .string()
  .min(6, texts.validations.password.short)
  .required(texts.validations.password.required);
const phoneConfirmationCode = yup
  .string()
  .matches(/^[0-9]{5,}$/, texts.validations.authorizationCode.matches)
  .required(texts.validations.authorizationCode.required);
const emailConfirmationCode = yup
  .string()
  .min(6, "Code is too short")
  .required(texts.validations.authorizationCode.required);
const phoneNumber = yup
  .string()
  .matches(phoneRegExp, texts.validations.phoneNumber.matches)
  .required(texts.validations.phoneNumber.required);
const passwordConfirm = yup
  .string()
  .oneOf([yup.ref("password"), null], texts.validations.passwordConfirm.matches)
  .required(texts.validations.passwordConfirm.required);
const consent = yup
  .bool()
  .test("consent", texts.validations.consent.test, (value) => value === true)
  .required(texts.validations.consent.required);
const country = yup
  .string()
  .min(2, texts.validations.country.short)
  .required(texts.validations.country.required);
const address = yup.string().required(texts.validations.address.required);
const postalCode = yup.string().required(texts.validations.address.required);
const userType = yup
  .string()
  .oneOf([UserRole.COMPANY_ADMIN, UserRole.PENDING_WASHER, UserRole.PROMOTER])
  .required(texts.validations.userType.required);
export const LoginSchema = yup.object().shape({
  email,
  password,
});
export const EmailSchema = yup.object().shape({
  email,
});
export const PhoneSchema = yup.object().shape({
  phoneNumber,
});
export const PhoneConfirmationCodeSchema = yup.object().shape({
  authorizationCode: phoneConfirmationCode,
});
export const EmailConfirmationCodeSchema = yup.object().shape({
  authorizationCode: emailConfirmationCode,
});
export const ConfirmPasswordSchema = yup.object().shape({
  password,
  passwordConfirm,
});
export const SignUpShouldVerifySchema = yup.object().shape({
  phoneNumber,
  email,
});
export const SignUpDataSchema = yup.object().shape({
  userType,
  firstName,
  secondName,
  email,
  password,
  passwordConfirm,
  authorizationCode: phoneConfirmationCode,
  consent,
  emailVerificationCode: emailConfirmationCode,
});
export const editPersonalUserDataSchema = yup.object().shape({
  firstName,
  secondName,
});
export const deliveryAddressSchema = yup.object().shape({
  deliveryPhoneNumber: phoneNumber,
  deliveryAddress: address,
});

export const washerVerificationSchema = yup.object().shape({
  avatar: yup.mixed().required("Profile Photo is required"),
  cleanPhoto: yup.array().min(1, "required"),
  insurancePhoto: yup.array().min(1, "required"),
  driverLicensePhoto: yup.array().min(1, "required"),
  firstName,
  secondName,
  country,
  postalCode,
  address,
});

export const createCompanyVerificationSchema = yup.object().shape({
  name: yup.string().required("Company Name shouldn't be empty "),
  country,
  postalCode,
  address,
});
