import React, { useEffect, useState } from "react";
import useActions from "../../../../hooks/useActions";
import { StatisticActions } from "../../../../store/actions/statistic.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import DashboardStatistics from "../../../../components/DashboardStatistics";
import CompanyIcon from "../../../../shared/icons/CompanyIcon";
import WasherIcon from "../../../../shared/icons/WasherIcon";
import moment from "moment";
import { StatisticItem } from "../../../../components/DashboardStatistics/DashboardStatistics";
import WalletIcon from "../../../../shared/icons/WalletIcon";
import { formatCurrency } from "../../../../helpers/utils/utils";
import texts from "../../../../constants/texts";

interface OwnProps {}

type Props = OwnProps;

const PromoterStatistics: React.FC<Props> = (props) => {
  const [statistics, setStatistics] = useState<StatisticItem[]>([]);
  const statisticActions = useActions<typeof StatisticActions>(
    StatisticActions
  );
  const statisticState = useSelector((state: AppState) => state.statistic);

  useEffect(() => {
    statisticActions.getPromoterStatistic();
  }, [statisticActions]);

  useEffect(() => {
    if (statisticState.promoterStatistic) {
      const newStatistics = [
        {
          Icon: WasherIcon,
          name: "Registration date",
          value: moment(statisticState.promoterStatistic.createdTime).format(
            "DD.MM.YYYY"
          ),
        },
        {
          Icon: CompanyIcon,
          name: "Companies referred",
          value: statisticState.promoterStatistic.companiesReferred.toString(),
        },
        {
          Icon: WasherIcon,
          name: "Washers referred",
          value: statisticState.promoterStatistic.washersReferred.toString(),
        },
        {
          Icon: WalletIcon,
          name: "Total fee amount",
          value: formatCurrency(
            statisticState.promoterStatistic.totalFeeAmount
          ),
        },
      ];
      setStatistics(newStatistics);
    }
  }, [statisticState.promoterStatistic]);

  return (
    <DashboardStatistics
      title={texts.roles.promoter.dashboard.statisticsTitle}
      subtitle={texts.roles.promoter.dashboard.statisticsSubtitle}
      items={statistics}
    />
  );
};

export default PromoterStatistics;
