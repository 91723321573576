import { ResponseStatus } from "../../models/responses/result.model";
import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { sendFile, showError } from "./utils";
import {
  sendVerificationFormType,
  WasherVerificationActions,
  WasherVerificationActionTypes,
} from "../actions/washerVerification.actions";
import { pendingWasherApi } from "../../api/pendingWasher.api";
import { AppActions } from "../actions/app.actions";
import { SnackbarVariant } from "../../models/utils.models";
import texts from "../../constants/texts";
import { transformAndValidate } from "class-transformer-validator";
import { ApplicationForm } from "../../models/responses/applicationForm.model";
import { getMyUserData } from "./auth.sagas";
import { Auth } from "../../models/responses/auth.model";
import { setToken } from "../../helpers/auth";

function* sendVerificationForm(action: sendVerificationFormType) {
  try {
    const { avatar, ...formData } = action.payload;
    let avatarFileId;
    if (avatar) {
      avatarFileId = yield call(sendFile, avatar, "avatar");
    }
    const cleanPhoto = yield all(
      formData.cleanPhoto.map((file) => {
        return call(sendFile, file, "CleanVehiclePhotos");
      })
    );
    const insurancePhoto = yield all(
      formData.insurancePhoto.map((file) => {
        return call(sendFile, file, "AutoInsurancePhotos");
      })
    );
    const driverLicensePhoto = yield all(
      formData.driverLicensePhoto.map((file) => {
        return call(sendFile, file, "DriverLicencePhotos");
      })
    );

    const { data } = yield pendingWasherApi.sendVerificationForm({
      ...formData,
      avatarFileId,
      cleanPhoto,
      insurancePhoto,
      driverLicensePhoto,
    });
    if (data.result === ResponseStatus.SUCCESS) {
      yield put(WasherVerificationActions.sendVerificationFormSuccess());
      yield put(
        AppActions.showSnackbar({
          variant: SnackbarVariant.SUCCESS,
          message: texts.washerVerification.successNotification,
        })
      );
      yield put(WasherVerificationActions.getMyVerificationForm());
    }
  } catch (e) {
    yield put(WasherVerificationActions.sendVerificationFormFailure(e.message));
    yield showError(e);
  }
}

function* getMyVerificationForm() {
  try {
    const { data } = yield pendingWasherApi.getMyVerificationForm();
    if (data.result === ResponseStatus.SUCCESS) {
      const myVerificationForm = data.data
        ? yield transformAndValidate(ApplicationForm, data.data)
        : null;
      yield put(
        WasherVerificationActions.getMyVerificationFormSuccess(
          myVerificationForm
        )
      );
    }
  } catch (e) {
    yield put(
      WasherVerificationActions.getMyVerificationFormFailure(e.message)
    );
    yield showError(e);
  }
}

function* submitExam() {
  try {
    const { data } = yield pendingWasherApi.submitExamByWasher();
    if (data.result === ResponseStatus.SUCCESS) {
      const authData = yield transformAndValidate(Auth, data.data);
      yield put(WasherVerificationActions.submitExamSuccess());
      yield setToken(authData.token);
      yield call(getMyUserData);
    }
  } catch (e) {
    yield put(WasherVerificationActions.submitExamFailure(e.message));
    yield showError(e);
  }
}

export function* WasherVerificationSagas() {
  yield takeLatest(
    WasherVerificationActionTypes.SEND_VERIFICATION_FORM,
    sendVerificationForm
  );
  yield takeLatest(
    WasherVerificationActionTypes.GET_MY_VERIFICATION_FORM,
    getMyVerificationForm
  );
  yield takeLatest(WasherVerificationActionTypes.SUBMIT_EXAM, submitExam);
}
