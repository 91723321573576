import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  toolBar: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(6),
    height: theme.spacing(13),
  },
  orderId: {
    marginBottom: theme.spacing(1),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  paper: {
    padding: theme.spacing(3, 4),
  },
  value: {
    color: theme.palette.primary.main,
  },
  divider: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    height: theme.spacing(3),
  },
  questionDivider: {
    width: theme.spacing(10),
    marginTop: theme.spacing(1),
  },
  redButton: {
    marginTop: theme.spacing(1),
    color: "rgba(190, 87, 87, 1)",
    borderColor: "rgba(190, 87, 87, 0.5)",
    "&:hover": {
      borderColor: "#f50057",
      backgroundColor: "rgba(190, 87, 87, 0.08)",
    },
  },
}));
