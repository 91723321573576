import styled from "styled-components";
import { unitSize } from "../constants/sizes";
import colors from "../constants/colors";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 7px;
  margin-left: ${unitSize * 2}px;
  min-height: ${unitSize * 2.5}px;
  background-color: ${colors.yellow};
  border-radius: 5px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: ${colors.white};
`;
