import React from "react";
import PromoterStatistics from "./components/PromoterStatistics";
import { Grid } from "@material-ui/core";
import PromoterCoupon from "./components/PromoterCoupon";

interface OwnProps {}

type Props = OwnProps;

const PromoterDashboard: React.FC<Props> = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PromoterCoupon />
      </Grid>
      <Grid item xs={12}>
        <PromoterStatistics />
      </Grid>
    </Grid>
  );
};

export default PromoterDashboard;
