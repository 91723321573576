import React from "react";
import { Box, Grid, MenuItem, TextField } from "@material-ui/core";
import TitledPaper from "../../../../shared/TitledPaper";
import { FormikHelpers, useFormik } from "formik";
import { PriceRegion } from "../PricingMap";
import CustomButton from "../../../../shared/CustomButton";
import { Currency } from "../../../../models/utils.models";

const currencies = [
  {
    value: Currency.USD,
    label: "$",
  },
  {
    value: Currency.EUR,
    label: "€",
  },
  {
    value: Currency.BTC,
    label: "฿",
  },
  {
    value: Currency.JPY,
    label: "¥",
  },
];

type EditPriceRegionInfoType = Pick<
  PriceRegion,
  "name" | "color" | "zIndex" | "currency"
>;

interface OwnProps {
  priceRegion: PriceRegion;
  onSubmit: (data: EditPriceRegionInfoType & Pick<PriceRegion, "id">) => void;
}

type Props = OwnProps;

const RegionInfo: React.FC<Props> = (props) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: props.priceRegion.name,
      color: props.priceRegion.color,
      zIndex: props.priceRegion.zIndex,
      currency: props.priceRegion.currency,
    },
    onSubmit: handleFormSubmit,
    // validationSchema: deliveryAddressSchema, TODO
    initialErrors: {
      name: "init",
      color: "init",
      zIndex: "init",
    },
  });

  function handleFormSubmit(
    values: EditPriceRegionInfoType,
    actions: FormikHelpers<any>
  ) {
    props.onSubmit({ ...values, id: props.priceRegion.id });
    actions.setSubmitting(false);
  }

  return (
    <TitledPaper title="Price region info">
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box pt={6} />
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              id="name"
              name="name"
              label="Name"
              helperText={
                formik.errors.name && formik.touched.name && formik.errors.name
              }
              error={!!formik.errors.name && formik.touched.name}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.zIndex}
              id="zIndex"
              name="zIndex"
              label="Priority"
              helperText={
                formik.errors.zIndex &&
                formik.touched.zIndex &&
                formik.errors.zIndex
              }
              error={!!formik.errors.zIndex && formik.touched.zIndex}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="currency"
              name="currency"
              select
              label="Currency"
              value={formik.values.currency}
              onChange={formik.handleChange}
              // helperText="Please select region currency"
              error={!!formik.errors.currency && formik.touched.currency}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Box flexBasis="100%" display="flex" alignItems="center" mt={4}>
          <CustomButton
            color="secondary"
            disabled={!formik.isValid}
            type="submit"
            variant="contained"
            // loading={companyState.createCompanyLoading}
          >
            Save
          </CustomButton>
        </Box>
      </form>
    </TitledPaper>
  );
};

export default RegionInfo;
