import React from "react";
import CouponCode from "../../../../components/CouponCode";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import texts from "../../../../constants/texts";

interface OwnProps {}

type Props = OwnProps;

const PromoterCoupon: React.FC<Props> = () => {
  const coupon = useSelector(
    (state: AppState) => state.auth.authorizedUser?.activeCoupon
  );
  if (!coupon) {
    return null;
  }

  return (
    <CouponCode
      coupon={coupon}
      title={texts.roles.promoter.dashboard.couponTitle}
      subtitle={texts.roles.promoter.dashboard.couponSubtitle}
    />
  );
};

export default PromoterCoupon;
