import { Type } from "class-transformer";
import { IsNumber, IsString, IsUrl } from "class-validator";
import { BasicModel } from "./basic.model";

export class Avatar extends BasicModel {
  @IsString()
  key: string;
  @IsString()
  ownerId: string;
  @IsNumber()
  @Type(() => Number)
  size: number;
  @IsString()
  tag: string;
  @IsString()
  type: string;
  @IsUrl()
  url: string;
}
