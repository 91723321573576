import { NavTab } from "../../../models/router.models";
import texts from "../../../constants/texts";
import { RouteNames } from "../../../constants/routeNames";

const tabs: NavTab[] = [
  {
    title: texts.roles.admin.pendingWashers.menu.individual,
    path: `/${RouteNames.INDIVIDUAL}`,
  },
  {
    title: texts.roles.admin.pendingWashers.menu.company,
    path: `/${RouteNames.COMPANY}`,
  },
];

export default tabs;
