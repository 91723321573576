import React from "react";
import { Box, Typography } from "@material-ui/core";

interface OwnProps {
  Icon: React.FC;
  title: string;
  value: string;
}

type Props = OwnProps;

const DashboardStatisticItem: React.FC<Props> = (props) => {
  const { Icon, title, value } = props;
  return (
    <Box color="primary.main" mt={6} mb={3}>
      <Icon />
      <Box mt={2}>
        <Typography variant="h2" component="p" color="primary" gutterBottom>
          {value}
        </Typography>
        <Typography variant="subtitle1" component="p" color="textSecondary">
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default DashboardStatisticItem;
