import styled from "styled-components";
import { unitSize } from "../constants/sizes";

export default styled.div`
  height: ${unitSize * 18}px;
  width: ${unitSize * 2}px;
  background: radial-gradient(
    ellipse farthest-side at left bottom,
    rgba(0, 0, 0, 0.08),
    transparent
  );
  align-self: flex-end;
`;
