import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const WasherIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.617"
      height="27.271"
      viewBox="0 0 20.617 27.271"
      {...props}
    >
      <g id="Group_10" data-name="Group 10" transform="translate(0.652 1.39)">
        <g
          id="Rectangle_8"
          data-name="Rectangle 8"
          transform="translate(3.494)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.39"
        >
          <rect width="12.326" height="15.847" rx="6.163" stroke="none" />
          <rect
            x="-0.695"
            y="-0.695"
            width="13.716"
            height="17.238"
            rx="6.858"
            fill="none"
          />
        </g>
        <g
          id="Ellipse_2"
          data-name="Ellipse 2"
          transform="translate(8.777 2.201)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.39"
        >
          <circle cx="0.88" cy="0.88" r="0.88" stroke="none" />
          <circle cx="0.88" cy="0.88" r="1.576" fill="none" />
        </g>
        <path
          id="Path_11"
          data-name="Path 11"
          d="M119.019,352.451s6.273-4.127,14.857,0"
          transform="translate(-116.791 -344.527)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.39"
        />
        <g
          id="Path_12"
          data-name="Path 12"
          transform="translate(-119.563 -350.401)"
          fill="none"
          strokeLinecap="round"
          strokeMiterlimit="10"
        >
          <path
            d="M126.579,366.634v1.6l1.266.66,1.375-.386,1.266.386,1.375-.66v-1.6l-1.706.44a8.106,8.106,0,0,1-2.137-.035Z"
            stroke="none"
          />
          <path
            d="M 126.5790100097656 366.6340026855469 L 126.5790100097656 368.2297668457031 L 127.8448486328125 368.8902587890625 L 129.2202606201172 368.5046081542969 L 130.4860992431641 368.8902587890625 L 131.8615112304688 368.2297668457031 L 131.8615112304688 366.6340026855469 L 130.1555633544922 367.0740966796875 C 129.9471893310547 367.1123352050781 129.6318054199219 367.1293334960938 129.2980346679688 367.1293334960938 C 128.8244171142578 367.1293334960938 128.3136749267578 367.0950927734375 128.0183715820312 367.0386352539062 L 126.5790100097656 366.6340026855469 M 125.2755889892578 364.9136352539062 L 126.9317474365234 365.3792114257812 L 128.3085479736328 365.7662658691406 C 128.5032348632812 365.7970581054688 128.8854370117188 365.825927734375 129.2980346679688 365.825927734375 C 129.5842895507812 365.825927734375 129.7849273681641 365.8116149902344 129.8861694335938 365.7974853515625 L 131.5359191894531 365.3719177246094 L 133.1649322509766 364.95166015625 L 133.1649322509766 366.6340026855469 L 133.1649322509766 368.2297668457031 L 133.1649322509766 369.0497741699219 L 132.4257507324219 369.4047241210938 L 131.0503234863281 370.0652160644531 L 130.5923004150391 370.2851867675781 L 130.1062469482422 370.1370849609375 L 129.205078125 369.862548828125 L 128.1967468261719 370.145263671875 L 127.6996154785156 370.28466796875 L 127.2418975830078 370.0458374023438 L 125.9760589599609 369.3853454589844 L 125.2755889892578 369.0198364257812 L 125.2755889892578 368.2297668457031 L 125.2755889892578 366.6340026855469 L 125.2755889892578 364.9136352539062 Z"
            stroke="none"
            fill="currentColor"
          />
        </g>
        <path
          id="Path_13"
          data-name="Path 13"
          d="M122.048,369.458h-2.793a3.754,3.754,0,0,0-3.754,3.754v3.454"
          transform="translate(-115.5 -351.437)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.303"
        />
        <path
          id="Path_14"
          data-name="Path 14"
          d="M135.66,369.458h2.793a3.754,3.754,0,0,1,3.755,3.754v3.454"
          transform="translate(-122.894 -351.437)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.303"
        />
      </g>
    </svg>
  );
};

export default WasherIcon;
