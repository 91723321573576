import { GridSize } from "@material-ui/core/Grid";
import { SortParams } from "./utils.models";

export interface HeadCell<K = string> {
  id: K;
  label: string;
  size: GridSize;
}

export enum OrderTableColumns {
  ID = "id",
  CAR = "car",
  STATUS = "status",
  DATE = "scheduledTime",
  TIME = "time",
  OPTIONS = "options",
}

export enum CarTableColumns {
  TYPE = "carTypeId",
  MODEL = "model",
  LICENCE_PLATE = "licensePlate",
  TOTAL_WASH = "ordersCount",
  LAST_WASH = "lastWash",
}

export enum UserTableColumns {
  ID = "id",
  EMAIL = "email",
  FULL_NAME = "fullName",
  PHONE_NUMBER = "phoneNumber",
  ORDERS = "completedOrders",
}

export enum PromotersTableColumns {
  ID = "id",
  EMAIL = "email",
  FULL_NAME = "fullName",
  PHONE_NUMBER = "phoneNumber",
  REFERRALS = "referralsCount",
}

export enum PendingWashersTableColumns {
  DATE = "createdTime",
  EMAIL = "email",
  FULL_NAME = "fullName",
  PHONE_NUMBER = "phoneNumber",
  STATUS = "status",
  COMPANY = "company",
}

export enum EquipmentTableColumns {
  EQUIPMENT = "equipment",
  USAGE = "usage",
  LAST_REFILLED_DATE = "lastRefilledDate",
}

export enum CreditsTableColumns {
  VALUE = "value",
  DESCRIPTION = "description",
}

export enum EarningsTableColumns {
  ID = "id",
  TYPE = "referralType",
  NAME = "name",
  DESCRIPTION = "description",
}

export enum ReferralsTableColumns {
  INFO = "info",
  TYPE = "referralType",
  DATE = "registrationDate",
  COMMISSION = "commission",
}

export enum BankingCardsColumns {
  TYPE = "type",
  CARD_HOLDER = "cardHolder",
  CARD_NUMBER = "cardNumber",
  EXPIRATION_DATE = "expirationDate",
}

export enum AdminPayoutsColumns {
  ID = "id",
  DATE = "date",
  FULL_NAME = "fullName",
  AMOUNT = "amount",
}

export enum OwnPayoutsColumns {
  ID = "id",
  DATE = "date",
  TOTAL = "total",
  STATUS = "status",
}

export enum WasherPayedOrdersColumns {
  ID = "id",
  DATE = "date",
  CLIENT_NAME = "clientName",
  PRICE = "price",
}

export enum CompanyPayedOrdersColumns {
  ID = "id",
  DATE = "date",
  WASHER_NAME = "washerName",
  COMMISSION = "commission",
}

export enum PromoterPayedReferralsColumns {
  ID = "id",
  DATE = "date",
  REFERRAL_NAME = "referralName",
  PRICE = "price",
}

export enum EquipmentRequestTableColumns {
  ID = "id",
  EMAIL = "email",
  FULL_NAME = "fullName",
  PHONE_NUMBER = "phoneNumber",
}

export enum SessionsColumns {
  STATUS = "status",
  START_TIME = "startTime",
  END_TIME = "endTime",
  DURATION = "duration",
  COMPLETED_ORDERS = "completedOrders",
}

export enum LogsColumns {
  ACTIVITY_TYPE = "activityType",
  DATE = "date",
}

export enum PackagesColumns {
  ACTIONS = "actions",
  NAME = "name",
  PRICE = "price",
  WASHER_PAYOUT_PRICE = "washerPayoutPrice",
}

export type TableHeaders =
  | CreditsTableColumns
  | UserTableColumns
  | CarTableColumns
  | OrderTableColumns
  | EquipmentTableColumns
  | EarningsTableColumns
  | ReferralsTableColumns
  | PendingWashersTableColumns
  | BankingCardsColumns
  | AdminPayoutsColumns
  | OwnPayoutsColumns
  | WasherPayedOrdersColumns
  | EquipmentRequestTableColumns
  | SessionsColumns
  | LogsColumns
  | CompanyEarningsTableColumns
  | WashersListTableColumns
  | UsersListTableColumns
  | WasherProfileTableColumns
  | PromoterPayedReferralsColumns
  | PromotersTableColumns
  | CompanyPayedOrdersColumns
  | PackagesColumns;

export interface HeaderValue {
  title: string;
  value: number;
  unit?: string;
}

export interface TablePaginationParams<T = TableHeaders> {
  page: number;
  size: number;
  sort?: SortParams;
  searchParam?: string;
  sortBy?: T;
  userId?: string;
}

export enum CompanyEarningsTableColumns {
  TRANSACTION_TYPE = "transactionType",
  DATE_AND_TIME = "DateAndTime",
  AMOUNT = "amount",
  DESCRIPTION = "description",
}

export enum WashersListTableColumns {
  WASHER = "Washer",
  DATE_REGISTRATION = "Date registration",
  TOTAL_WASH = "Total Wash",
  COMMISSION = "Commission",
}

export enum UsersListTableColumns {
  USER = "User",
  DATE_REGISTRATION = "Date registration",
  TOTAL_WASH = "Total Wash",
}

export enum WasherProfileTableColumns {
  ID = "id",
  CAR = "car",
  DATE = "date",
  TIME = "time",
  OPTIONS = "options",
  WASH_PRICE = "washPrice",
}
