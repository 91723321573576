import { createStyles, makeStyles, Theme } from "@material-ui/core";
import colors from "../../constants/colors";

const getColor = (props: StyleProps) => {
  if (props.isDragAccept) {
    return colors.green;
  }
  if (props.isDragReject) {
    return colors.lightRed;
  }
  return "#ccc";
};

interface StyleProps {
  isDragAccept: boolean;
  isDragReject: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageIcon: {
      marginRight: theme.spacing(2),
    },
    dropZone: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2.5),
      borderWidth: 2,
      borderRadius: 5,
      borderColor: (props: StyleProps) => getColor(props),
      borderStyle: "dashed",
      color: "#ccc",
      outline: "none",
      transition: "border .24s ease-in-out",
      cursor: "pointer",
    },
    list: {
      marginTop: theme.spacing(5),
    },
    listItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
    },
    listItemIcon: {
      minWidth: theme.spacing(4.5),
    },
    listItemText: {
      fontSize: 10,
      fontWeight: 400,
      colors: theme.palette.text.secondary,
    },
    rejectFileIcon: {
      padding: 0,
    },
  })
);
