import { RouteNames } from "../../constants/routeNames";
import Clients from "../../pages/admin/Clients";
import Washers from "../../pages/admin/Washers";
import { UserRoutes } from "../../models/router.models";
import PendingWashersRouter from "../../pages/admin/PendingWashers";
import AdminPayouts from "../../pages/admin/AdminPayouts";
import Equipments from "../../pages/admin/AdminEquipments";
import Promoters from "../../pages/admin/Promoters";
import Settings from "../../pages/Settings";
import Blacklist from "../../pages/admin/Blacklist";
import PricingMap from "../../pages/admin/PricingMap";
import OrdersRouter from "../../pages/admin/Orders/OrdersRouter";

const adminRoutes: UserRoutes = {
  routes: [
    {
      path: `/${RouteNames.PROMOTERS}`,
      component: Promoters,
    },
    {
      path: `/${RouteNames.CLIENTS}`,
      component: Clients,
    },
    {
      path: `/${RouteNames.WASHERS}`,
      component: Washers,
    },
    {
      path: `/${RouteNames.PENDING_WASHERS}`,
      component: PendingWashersRouter,
    },
    {
      path: `/${RouteNames.ORDERS}`,
      component: OrdersRouter,
    },
    {
      path: `/${RouteNames.PAYOUTS}`,
      component: AdminPayouts,
    },
    {
      path: `/${RouteNames.EQUIPMENT}`,
      component: Equipments,
    },
    {
      path: `/${RouteNames.SETTINGS}`,
      component: Settings,
    },
    {
      path: `/${RouteNames.BLACKLIST}`,
      component: Blacklist,
      exact: true,
    },
    {
      path: `/${RouteNames.PRICING_MAP}`,
      component: PricingMap,
      exact: true,
    },
  ],
  defaultRoute: `/${RouteNames.PROMOTERS}`,
};

export default adminRoutes;
