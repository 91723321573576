import styled from "styled-components";
import { unitSize } from "../constants/sizes";
import colors from "../constants/colors";

export default styled.div`
  padding: ${unitSize * 0.5}px ${unitSize}px;
  background: ${(props) => props.color};
  border-radius: 5px;
  color: ${colors.white};
  font-size: 8px;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
`;
