import { createStyles, makeStyles, Theme } from "@material-ui/core";
import colors from "../../../../../../constants/colors";
import { fade } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: theme.spacing(1),
      height: theme.spacing(5),
    },
    itemIcon: {
      width: 6,
      color: theme.palette.secondary.main,
    },
    listItemIcon: {
      alignSelf: "flex-end",
      paddingBottom: 2,
      minWidth: 21,
    },
    button: {
      width: theme.spacing(9),
      height: theme.spacing(4),
      borderRadius: 5,
      boxShadow: "none",
      fontSize: 11,
    },
    usageStatusContainer: {
      width: theme.spacing(20),
      position: "relative",
      backgroundColor: colors.lightGrey,
      borderRadius: 80,
      height: 10,
    },
    textField: {
      marginRight: theme.spacing(2),
      width: theme.spacing(7),
      "& input": {
        padding: theme.spacing(1),
        textAlign: "center",
      },
    },
  })
);

export const useInputStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 11,
      fontWeight: 300,
      border: "1px solid #E8E8E8",
      overflow: "hidden",
      borderRadius: 5,
      backgroundColor: colors.white,
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.06)",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:hover": {
        backgroundColor: colors.white,
      },
      "&$focused": {
        backgroundColor: colors.white,
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
      "&$disabled": {
        backgroundColor: colors.white,
      },
    },
    focused: {},
    disabled: {},
  })
);
