import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Brightness1OutlinedIcon from "@material-ui/icons/Brightness1Outlined";
import { useStyles } from "./style";
import { EquipmentRequest } from "../../../../../../models/responses/equipment.model";
import CustomButton from "../../../../../../shared/CustomButton";
import useActions from "../../../../../../hooks/useActions";
import { EquipmentActions } from "../../../../../../store/actions/equipment.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../store/reducers";
import { AppActions } from "../../../../../../store/actions/app.actions";
import { MuiSlider } from "../../../../../../shared/MuiSlider";
import { unitSize } from "../../../../../../constants/sizes";

interface OwnProps {
  data: EquipmentRequest;
}

type Props = OwnProps;

const RequestedEquipment: React.FC<Props> = (props) => {
  const classes = useStyles();

  const appActions = useActions<typeof AppActions>(AppActions);
  const equipmentActions = useActions<typeof EquipmentActions>(
    EquipmentActions
  );
  const equipmentState = useSelector((state: AppState) => state.equipment);

  const isApproving = useMemo(
    () => equipmentState.approvingEquipmentRequests.includes(props.data.id),
    [equipmentState.approvingEquipmentRequests, props.data.id]
  );

  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    setValue(props.data.userEquipment.value);
  }, [props.data.userEquipment.value]);

  const handleRefillClick = () => {
    appActions.showConfirmationDialog({
      withReason: false,
      title: `Approve refill ${props.data.userEquipment.equipment.title}`,
      description: "Are you sure?",
      open: true,
      onSubmit: () =>
        equipmentActions.approveEquipmentRequestsForWasher({
          id: props.data.id,
          value,
        }),
    });
  };

  const handleValueChange = (event: any, newValue: number | number[]) => {
    if (newValue >= props.data.userEquipment.value) {
      setValue(newValue as number);
    }
  };

  return (
    <ListItem className={classes.listItem}>
      <Box display="flex" alignItems="center">
        <ListItemIcon className={classes.listItemIcon}>
          <Brightness1OutlinedIcon className={classes.itemIcon} />
        </ListItemIcon>
        <ListItemText primary={props.data.userEquipment.equipment.title} />
      </Box>
      <Box display="flex" alignItems="center" width={0.6} ml={5}>
        <Box
          mr={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Box mr={2}>
            <Typography>0</Typography>
          </Box>
          <MuiSlider
            value={value}
            onChange={handleValueChange}
            valueLabelDisplay="auto"
            min={0}
            max={props.data.userEquipment.equipment.maxValue}
            disabled={props.data.completed}
          />
          <Box ml={2} minWidth={6 * unitSize}>
            <Typography>
              {props.data.userEquipment.equipment.maxValue}{" "}
              {props.data.userEquipment.equipment.valueUnit.toLowerCase()}
            </Typography>
          </Box>
        </Box>
        <CustomButton
          className={classes.button}
          color="primary"
          disabled={
            isApproving ||
            props.data.completed ||
            value <= props.data.userEquipment.value
          }
          variant="outlined"
          loading={isApproving}
          onClick={handleRefillClick}
        >
          {props.data.completed ? "Refilled" : "Refill"}
        </CustomButton>
      </Box>
    </ListItem>
  );
};

export default RequestedEquipment;
