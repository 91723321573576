import React from "react";
import ChangeEmailForm from "./ChangeEmailForm";
import ConfirmEmailCodeForm from "./ConfirmEmailCodeForm";

interface OwnProps {
  step: number;
}

type Props = OwnProps;

const ChangeEmail: React.FC<Props> = (props) => {
  switch (props.step) {
    case 1:
      return <ChangeEmailForm />;
    case 2:
      return <ConfirmEmailCodeForm />;
    default:
      return null;
  }
};

export default ChangeEmail;
