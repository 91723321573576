import React, { FunctionComponent } from "react";
import useActions from "../../hooks/useActions";
import { AuthActions } from "../../store/actions/auth.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import { Formik, FormikHelpers } from "formik";
import { PhoneConfirmationCodeSchema } from "../../helpers/validations";
import FormLayout from "./FormLayout";
import { Grid } from "@material-ui/core";
import FormInput from "../../shared/FormInput";
import SecureInput from "../../shared/SecureInput";
import texts from "../../constants/texts";

interface OwnProps {}

type Props = OwnProps;

interface CodeForm {
  authorizationCode: string;
}

const ConfirmationCodeForm: FunctionComponent<Props> = () => {
  const authActions = useActions<typeof AuthActions>(AuthActions);
  const authState = useSelector((state: AppState) => state.auth);
  const handleCodeSubmit = (
    values: CodeForm,
    actions: FormikHelpers<CodeForm>
  ) => {
    const request = {
      email: authState.resetEmail,
      authorizationCode: values.authorizationCode,
    };
    authActions.confirmResetCode(request);
    actions.setSubmitting(false);
  };
  return (
    <Formik
      initialValues={{ authorizationCode: "" }}
      onSubmit={handleCodeSubmit}
      validationSchema={PhoneConfirmationCodeSchema}
    >
      {({
        values,
        handleChange,
        errors,
        touched,
        isValid,
        handleSubmit,
        handleBlur,
      }) => (
        <FormLayout
          buttonColor="primary"
          buttonText={texts.auth.login.forgotPassword.confirmButton}
          buttonDisabled={!isValid}
          description={texts.auth.login.forgotPassword.stepTwoDescription}
          handleSubmit={handleSubmit}
          loading={authState.isConfirmCodeLoading}
        >
          <Grid item xs={7}>
            <FormInput
              type="email"
              value={authState.resetEmail}
              variant="filled"
              margin="normal"
              fullWidth
              id="email"
              label={texts.auth.login.forgotPassword.recoveryEmailLabel}
              name="email"
              disabled
            />
          </Grid>
          <Grid item xs={5}>
            <SecureInput
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.authorizationCode}
              variant="filled"
              margin="normal"
              fullWidth
              name="authorizationCode"
              label={texts.auth.login.forgotPassword.verificationCodeLabel}
              id="verification_code"
              autoFocus
              error={!!errors.authorizationCode && touched.authorizationCode}
              helperText={
                errors.authorizationCode &&
                touched.authorizationCode &&
                errors.authorizationCode
              }
            />
          </Grid>
        </FormLayout>
      )}
    </Formik>
  );
};

export default ConfirmationCodeForm;
