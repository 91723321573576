import React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import FireIcon from "../../shared/icons/FireIcon";
import ToggleButton from "../../styled/ToggleButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 5, 4, 4),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })
);

interface OwnProps {
  hot?: boolean;
  title: string;
  description: string;
}

type Props = OwnProps;

const TutorialsHeader: React.FC<Props> = ({ hot, title, description }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Box display="flex">
        {hot && <FireIcon />}
        <Box ml={3}>
          <Typography variant="h4" component="h3">
            {title}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
        </Box>
      </Box>
      <ToggleButton />
    </Paper>
  );
};

export default TutorialsHeader;
