import styled from "styled-components";
import { unitSize } from "../constants/sizes";
import colors from "../constants/colors";
import { StatusColors } from "../models/utils.models";

function getColors(color: StatusColors) {
  switch (color) {
    case StatusColors.GREEN:
      return colors.green;
    case StatusColors.YELLOW:
      return colors.yellow;
    case StatusColors.RED:
      return colors.lightRed;
    case StatusColors.GREY:
      return colors.lightGrey;
  }
}

interface StatusCellProps {
  color: StatusColors;
}

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${unitSize * 4}px;
  padding-left: ${unitSize * 2}px;
  padding-right: ${unitSize * 2}px;
  background-color: ${(props: StatusCellProps) => getColors(props.color)};
  color: ${colors.white};
  border-radius: 5px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
`;
