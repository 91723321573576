import {
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import * as React from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import useRoundedTextInput from "../mui/styles/roundedTextInput";
import { InputProps } from "@material-ui/core/Input";
import { unitSize } from "../constants/sizes";

const useStyles = makeStyles((theme: Theme) => ({
  startAdornment: {
    color: theme.palette.primary.main,
  },
}));

interface OwnProps {
  StartAdornment?: React.ComponentType;
}

type Props = OwnProps & TextFieldProps;

const FormInput: React.FC<Props> = ({ StartAdornment, ...props }) => {
  const classes = useStyles({ withStartAdornment: !!StartAdornment });
  const inputClasses = useRoundedTextInput();
  return (
    <TextField
      {...props}
      InputProps={
        {
          classes: inputClasses,
          disableUnderline: true,
          startAdornment: StartAdornment && (
            <InputAdornment position="start" className={classes.startAdornment}>
              <StartAdornment />
            </InputAdornment>
          ),
        } as Partial<InputProps>
      }
      InputLabelProps={{
        style: {
          left: StartAdornment ? unitSize * 3.5 : 0,
        },
      }}
    />
  );
};

export default FormInput;
