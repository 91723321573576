import { axiosInstance } from "./base.api";
import {
  CompanyWasherGeneralStatisticsResponse,
  CompanyWasherStatisticsResponse,
  GeneralStatisticResponse,
  PromoterStatisticResponse,
  WasherStatisticResponse,
} from "../models/responses/statistic.model";
import MockAdapter from "axios-mock-adapter";
import { ResponseStatus } from "../models/responses/result.model";
import { companyWasherStatistics } from "../mock/requests/companyWasherStatistics";
import { companyWasherGeneralStatistics } from "../mock/requests/companyWasherGeneralStatistics";

export const statisticApi = {
  getGeneralStatistic() {
    return axiosInstance.get<GeneralStatisticResponse>("/app/statistics");
  },
  getMyWashStatistic() {
    return axiosInstance.get<WasherStatisticResponse>(
      "/private/users/statistic/washer/me"
    );
  },

  getMyPromoteStatistic() {
    return axiosInstance.get<PromoterStatisticResponse>(
      "/private/users/statistic/promoter/me"
    );
  },

  getCompanyWasherGeneralStatistics() {
    const mock = new MockAdapter(axiosInstance, { delayResponse: 500 });

    mock.onGet("/private/washers/statistics").reply(200, {
      result: ResponseStatus.SUCCESS,
      data: companyWasherGeneralStatistics,
    });
    const req = axiosInstance.get<CompanyWasherGeneralStatisticsResponse>(
      "/private/washers/statistics"
    );
    mock.restore();
    return req;
  },

  getCompanyWasherStatistics(userId: string) {
    const mock = new MockAdapter(axiosInstance, { delayResponse: 500 });

    mock.onGet(`/private/washers/${userId}/statistics`).reply(200, {
      result: ResponseStatus.SUCCESS,
      data: companyWasherStatistics,
    });
    const req = axiosInstance.get<CompanyWasherStatisticsResponse>(
      `/private/washers/${userId}/statistics`
    );
    mock.restore();
    return req;
  },
};
