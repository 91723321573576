import { ResultModel } from "./result.model";
import { IsJWT } from "class-validator";

export interface Auth {
  token: string;
}

export class Auth {
  @IsJWT()
  token: string;
}

export interface PromoCode {
  isValid: boolean;
  name?: string;
}

export type AuthResponse = ResultModel<Auth>;
export type PromoCodeResponse = ResultModel<PromoCode>;
