import colors from "../../../constants/colors";

export default {
  selectMenu: {
    "&:focus": {
      background: colors.white,
    },
    "&:disable": {
      background: colors.white,
    },
  },
};
