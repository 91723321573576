import React from "react";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import { User } from "../../../../../../models/responses/user.model";
import { useStyles } from "./style";
import CopyToClipboard from "../../../../../../shared/CopyToClipboard";
import { numberWithSpaces } from "../../../../../../helpers/utils/utils";
import UserRating from "../../../../../../components/UserProfile/UserInfo/UserRating";
import CustomRouterLink from "../../../../../../shared/CustomRouterLink";
import { RouteNames } from "../../../../../../constants/routeNames";

interface OwnProps {
  data: User;
}

type Props = OwnProps;

const WasherInfo: React.FC<Props> = (props) => {
  const fullName = `${props.data.firstName || ""} ${
    props.data.firstName || ""
  }`;
  const avatarSrc = props.data.avatarFileId;
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      {avatarSrc ? (
        <Avatar alt={fullName} src={avatarSrc} className={classes.avatar} />
      ) : (
        <Avatar className={classes.avatar}>
          {fullName.trim().charAt(0).toUpperCase()}
        </Avatar>
      )}
      <Box ml={3}>
        <Box display="flex" alignItems="center">
          <Box>
            <Box display="flex" alignItems="center">
              <Typography
                variant="h4"
                color="primary"
                component={CustomRouterLink}
                to={`/${RouteNames.WASHERS}/${props.data.id}`}
              >
                {fullName}
              </Typography>
              <Box ml={2}>
                <CopyToClipboard>
                  {({ copy }) => (
                    <Chip
                      color="secondary"
                      size="small"
                      onClick={() => copy(props.data.id)}
                      label={`Washer ID:  ${props.data.id.slice(0, 8)}`}
                    />
                  )}
                </CopyToClipboard>
              </Box>
            </Box>
            <Box mt={1}>
              <Typography variant="body1" color="textSecondary">
                admin@carshine.io. / Registred date: 12.10.2019 - 19:00 AM
              </Typography>
            </Box>
          </Box>
          <Box ml={21}>
            <UserRating value={props.data.rating} />
          </Box>
        </Box>

        <Box mt={4} display="flex" alignItems="center">
          <Box>
            <Typography color="primary" variant="h4">
              {numberWithSpaces(1280)}h
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Total Online
            </Typography>
          </Box>
          <Divider className={classes.divider} orientation="vertical" />
          <Box>
            <Typography color="primary" variant="h4">
              {numberWithSpaces(props.data.completedOrders || 0)}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Total car washed
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default WasherInfo;
