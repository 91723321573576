import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import { Referrals } from "../../models/responses/referrals.model";
import { TablePaginationParams } from "../../models/table.models";

export enum ReferralsActionTypes {
  GET_PROMOTERS_REFERRALS_LIST = "[Referrals] Get referrals list for Promoter",
  GET_PROMOTERS_REFERRALS_LIST_SUCCESS = "[Referrals] Get referrals list for Promoter success",
  GET_PROMOTERS_REFERRALS_LIST_FAILURE = "[Referrals] Get referrals list for Promoter failure",
}

export const ReferralsActions = {
  getPromotersReferralsList: (payload: TablePaginationParams) =>
    createAction(ReferralsActionTypes.GET_PROMOTERS_REFERRALS_LIST, payload),
  getPromotersReferralsListSuccess: (payload: Referrals) =>
    createAction(
      ReferralsActionTypes.GET_PROMOTERS_REFERRALS_LIST_SUCCESS,
      payload
    ),
  getPromotersReferralsListFailure: (payload: string) =>
    createAction(
      ReferralsActionTypes.GET_PROMOTERS_REFERRALS_LIST_FAILURE,
      payload
    ),
};

export type ReferralsActions = ActionsUnion<typeof ReferralsActions>;

export type getPromotersReferralsListType = ActionsOfType<
  ReferralsActions,
  ReferralsActionTypes.GET_PROMOTERS_REFERRALS_LIST
>;
