import React from "react";

interface OwnProps {}

type Props = OwnProps;

const CreditCardIcon: React.FC<Props> = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="18.053"
      height="13.257"
      viewBox="0 0 18.053 13.257"
    >
      <g
        id="credit-card_2_"
        data-name="credit-card (2)"
        transform="translate(0 -68)"
      >
        <g id="Group_278" data-name="Group 278" transform="translate(0 68)">
          <g id="Group_277" data-name="Group 277" transform="translate(0 0)">
            <path
              id="Path_577"
              data-name="Path 577"
              d="M269.705,324a.705.705,0,0,0,0,1.41h.987a.705.705,0,0,0,0-1.41Z"
              transform="translate(-259.515 -314.974)"
            />
            <path
              id="Path_578"
              data-name="Path 578"
              d="M17.348,75.62a.705.705,0,0,0,.705-.705V70.821A2.824,2.824,0,0,0,15.232,68H2.821A2.824,2.824,0,0,0,0,70.821v7.616a2.824,2.824,0,0,0,2.821,2.821H15.232a2.824,2.824,0,0,0,2.821-2.821.705.705,0,0,0-1.41,0,1.412,1.412,0,0,1-1.41,1.41H2.821a1.412,1.412,0,0,1-1.41-1.41V70.821a1.412,1.412,0,0,1,1.41-1.41H15.232a1.412,1.412,0,0,1,1.41,1.41v4.094A.705.705,0,0,0,17.348,75.62Z"
              transform="translate(0 -68)"
            />
            <path
              id="Path_579"
              data-name="Path 579"
              d="M366.692,324h-.987a.705.705,0,0,0,0,1.41h.987a.705.705,0,1,0,0-1.41Z"
              transform="translate(-352.13 -314.974)"
            />
            <path
              id="Path_580"
              data-name="Path 580"
              d="M173.705,325.41h.987a.705.705,0,0,0,0-1.41h-.987a.705.705,0,0,0,0,1.41Z"
              transform="translate(-166.9 -314.974)"
            />
            <path
              id="Path_581"
              data-name="Path 581"
              d="M82.231,161.648v-2.821a.705.705,0,0,0-.705-.705H78.705a.705.705,0,0,0-.705.705v2.821a.705.705,0,0,0,.705.705h2.821A.705.705,0,0,0,82.231,161.648Zm-1.41-.705H79.41v-1.41h1.41Z"
              transform="translate(-75.25 -154.944)"
            />
            <path
              id="Path_582"
              data-name="Path 582"
              d="M77.705,324a.705.705,0,0,0,0,1.41h.987a.705.705,0,0,0,0-1.41Z"
              transform="translate(-74.285 -314.974)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CreditCardIcon;
