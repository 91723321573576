import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import VerificationSentIcon from "../../../../shared/icons/VerificationSentIcon";
import texts from "../../../../constants/texts";

interface OwnProps {}

type Props = OwnProps;

const VerificationSent: React.FC<Props> = () => {
  return (
    <Box
      width={1}
      height={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt={10}
    >
      <Paper>
        <Box py={8} px={16} textAlign="center">
          <VerificationSentIcon />
          <Box mt={6}>
            <Typography>{texts.washerVerification.formSent1}</Typography>
            <Typography>{texts.washerVerification.formSent2}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default VerificationSent;
