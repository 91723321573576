import { Sessions } from "../../models/responses/session.model";
import { SessionActions, SessionActionTypes } from "../actions/session.actions";
import { formatDate } from "../../helpers/utils/date.utils";

class StateModel {
  sessions: Sessions = {
    items: [],
    total: 0,
    averageOnlineTime: "",
    totalOnlineTime: "",
  };
  sessionsLoading = false;
  sessionsError = "";
}

const initialState = new StateModel();

export function sessionReducer(
  state = initialState,
  action: SessionActions
): StateModel {
  switch (action.type) {
    case SessionActionTypes.GET_USER_SESSIONS:
      return {
        ...state,
        sessionsError: "",
        sessionsLoading: true,
      };
    case SessionActionTypes.GET_USER_SESSIONS_SUCCESS:
      return {
        ...state,
        sessionsLoading: false,
        sessions: action.payload,
      };
    case SessionActionTypes.GET_USER_SESSIONS_FAILURE:
      return {
        ...state,
        sessionsLoading: false,
        sessionsError: action.payload,
      };
    default:
      return state;
  }
}

export const sessionSelector = {
  getFormattedSessions: (state: StateModel) =>
    state.sessions.items.map((item) => ({
      id: item.id,
      status: item.status,
      startTime: formatDate(item.startTime).join(" / "),
      endTime: formatDate(item.endTime).join(" / "),
      duration: item.duration,
      completedOrders: item.completedOrdersNumber.toString(),
    })),
};
