import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const BankIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="20.214"
      height="20.056"
      viewBox="0 0 20.214 20.056"
      {...props}
    >
      <g id="bank" transform="translate(0 -1.999)">
        <g id="Group_324" data-name="Group 324" transform="translate(0 1.999)">
          <g id="Group_323" data-name="Group 323">
            <circle
              id="Ellipse_117"
              data-name="Ellipse 117"
              cx="1"
              cy="1"
              r="1"
              transform="translate(9 2.8)"
            />
            <path
              id="Path_658"
              data-name="Path 658"
              d="M19.425,470H.79a.79.79,0,0,0,0,1.579H19.425a.79.79,0,0,0,0-1.579Z"
              transform="translate(0 -451.523)"
            />
            <path
              id="Path_659"
              data-name="Path 659"
              d="M20.214,6.934a.79.79,0,0,0-.469-.722L10.408,2.067a.79.79,0,0,0-.642,0L.468,6.213A.79.79,0,0,0,0,6.934V9.027c0,.007,0,.013,0,.02s0,.013,0,.02a.79.79,0,0,0,.79.79.7.7,0,0,1,.671.79V18.01c0,.017,0,.033,0,.049s0,.032,0,.049a.79.79,0,0,0,.79.79H18.043a.79.79,0,0,0,0-1.579H15.674V10.646a.79.79,0,0,1,1.579,0V14.14a.79.79,0,1,0,1.579,0V10.646c0-.185.043-.79.592-.79a.79.79,0,0,0,.79-.79c0-.007,0-.013,0-.02s0-.013,0-.02ZM4.619,17.318H3.04V10.646a.79.79,0,0,1,1.579,0Zm3.158,0H6.2V10.646a.79.79,0,1,1,1.579,0Zm3.158,0H9.357V10.646a.79.79,0,0,1,1.579,0Zm3.158,0H12.516V10.646a.79.79,0,0,1,1.579,0Zm4.54-9.081H1.579v-.79l8.509-3.794,8.547,3.795Z"
              transform="translate(0 -1.999)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BankIcon;
