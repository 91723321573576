import React from "react";
import { Orders } from "../../models/responses/order.model";
import OrdersListRow from "./OrdersListRow";
import orderListHeadCells from "./orderListHeadCells";

interface OwnProps {
  data: Orders;
}

type Props = OwnProps;

const OrdersList: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data.items.map((order) => (
        <OrdersListRow
          key={order.id}
          order={order}
          headCells={orderListHeadCells}
        />
      ))}
    </>
  );
};

export default OrdersList;
