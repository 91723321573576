import styled from "styled-components";
import ArrowDownIcon from "../shared/icons/ArrowDownIcon";
import { unitSize } from "../constants/sizes";

export default styled(ArrowDownIcon)`
  && {
    top: auto;
    right: ${unitSize * 2}px;
  }
`;
