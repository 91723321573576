import { ReviewsActions, ReviewsActionTypes } from "../actions/reviews.actions";
import { Reviews } from "../../models/responses/review.model";

class StateModel {
  reviewsForActiveUser: Reviews = { items: [], total: 0 };
  reviewsForActiveUserLoading: boolean = false;
  reviewsForActiveUserError: string = "";
}

const initialState = new StateModel();

export function reviewsReducer(
  state = initialState,
  action: ReviewsActions
): StateModel {
  switch (action.type) {
    case ReviewsActionTypes.GET_REVIEWS_FOR_ACTIVE_USER: {
      return {
        ...state,
        reviewsForActiveUserLoading: true,
        reviewsForActiveUserError: "",
        reviewsForActiveUser: initialState.reviewsForActiveUser,
      };
    }
    case ReviewsActionTypes.GET_REVIEWS_FOR_ACTIVE_USER_SUCCESS: {
      return {
        ...state,
        reviewsForActiveUserLoading: false,
        reviewsForActiveUser: action.payload,
      };
    }
    case ReviewsActionTypes.GET_REVIEWS_FOR_ACTIVE_USER_FAILURE: {
      return {
        ...state,
        reviewsForActiveUserLoading: false,
        reviewsForActiveUserError: action.payload,
      };
    }

    default:
      return state;
  }
}

export const ReviewsSelectors = {};
