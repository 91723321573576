import { ResultModel } from "./result.model";
import { Transform, Type } from "class-transformer";
import {
  IsBoolean,
  IsDefined,
  IsEmail,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { Avatar } from "./avatar.model";
import { BasicModel } from "./basic.model";
import { Company } from "./company.model";
import { Coupon } from "./coupon.model";
import { ID } from "../utils.models";

export enum UserRole {
  ADMIN = "ADMIN",
  CLIENT = "CLIENT",
  WASHER = "WASHER",
  PENDING_WASHER = "PENDING_WASHER",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  PROMOTER = "PROMOTER",
}

export class User extends BasicModel {
  @IsOptional()
  @IsBoolean()
  isVerified: boolean | null;

  @IsOptional()
  @ValidateNested()
  @Type(() => Avatar)
  avatar: Avatar | null;

  @IsOptional()
  @IsString()
  avatarFileId: string | null;

  @IsOptional()
  @IsNumber()
  completedOrders: number;

  @IsOptional()
  @IsEmail()
  email: string | null;

  @IsOptional()
  @Transform((value) => (typeof value === "string" ? value : ""))
  @IsString()
  firstName: string;

  @IsOptional()
  @Transform((value) => (typeof value === "string" ? value : ""))
  @IsString()
  secondName: string;

  // @IsMobilePhone("any")
  @IsString()
  phoneNumber: string;

  @IsOptional()
  @IsString()
  deliveryPhoneNumber: string | null;

  @IsOptional()
  @IsString()
  deliveryAddress: string | null;

  @IsOptional()
  @Type(() => Number)
  rating: number;

  @IsEnum(UserRole)
  role: UserRole;

  @IsOptional()
  @IsString()
  companyId: ID | null;

  @IsOptional()
  company: Company | null;

  @IsOptional()
  @ValidateNested()
  @Type(() => Coupon)
  activeCoupon: Coupon | null;

  @IsOptional()
  @IsNumber()
  companiesReferred?: number;

  @IsOptional()
  @IsNumber()
  washersReferred?: number;

  @IsOptional()
  @IsNumber()
  totalFeeAmount?: number;

  @IsOptional()
  @IsNumber()
  countReferralUsers: number;
}

export class Users {
  @IsDefined()
  @Type(() => User)
  @ValidateNested()
  items: User[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export class CompanyUser {
  avatar?: string;
  id: string;
  last_wash_datetime: string;
  name: string;
  rating: string;
  registration_datetime: string;
  total_wash_count: number;
}

export class CompanyUsers {
  items: CompanyUser[];
  total: number;
  filtered_total?: number;
}

export class CompanyWasher {
  avatar?: string;
  id: string;
  is_active: boolean;
  name: string;
  registration_datetime: string;
  total_commission: string;
  total_wash_count: number;
}

export class CompanyWashers {
  items: CompanyWasher[];
  total: number;
  filtered_total?: number;
}

export class CompanyWasherListOptions {
  id: string;
  title: string;
}

export class CompanyWasherList {
  car: string;
  client_avatar: string;
  commission: string;
  datetime: string;
  id: string;
  options: CompanyWasherListOptions[];
  price: string;
  took_time: string;
}

export class CompanyWasherHistory {
  items: CompanyWasherList[];
  total: number;
}

export type UserResponse = ResultModel<User>;
export type UsersResponse = ResultModel<Users>;
export type CompanyUsersResponse = ResultModel<CompanyUsers>;
export type CompanyWashersResponse = ResultModel<CompanyWashers>;
export type CompanyWasherHistoryResponse = ResultModel<CompanyWasherHistory>;
