export default {
  text: {
    textTransform: "none",
  },
  // contained: {},
  label: {
    textTransform: "none",
  },
  outlined: {
    fontSize: 12,
  },
};
