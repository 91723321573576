import React, { useRef } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
// @ts-ignore
import { formatDate, parseDate } from "react-day-picker/moment";
import { Box, Typography } from "@material-ui/core";
import "react-day-picker/lib/style.css";
import "./style.css";
import DateRangeInput from "./DateRangeInput";

interface OwnProps {
  onFromChange: (from: Date) => void;
  onToChange: (to: Date) => void;
  to: Date;
  from: Date;
}

type Props = OwnProps;

const DateRangePicker: React.FC<Props> = ({
  onFromChange,
  onToChange,
  from,
  to,
}) => {
  const modifiers = { start: from, end: to };
  let lastMonth = new Date();

  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const toInput = useRef(null);
  const focusTo = () => {
    if (toInput.current) {
      // @ts-ignore
      toInput.current.getInput().focus();
    }
  };
  const handleFromChange = (from: Date) => {
    onFromChange(from);
    if (!to) {
      focusTo();
    }
  };

  const handleToChange = (to: Date) => {
    onToChange(to);
  };
  return (
    <Box mt={4} className="InputFromTo">
      <DayPickerInput
        value={from}
        format="MM/DD/YYYY"
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder="From"
        onDayChange={handleFromChange}
        component={DateRangeInput}
        dayPickerProps={{
          selectedDays: [from, { from, to }],
          modifiers,
          disabledDays: [{ after: new Date() }, { after: to }],
          numberOfMonths: 2,
          toMonth: new Date(),
        }}
      />
      <Box px={2}>
        <Typography> - </Typography>
      </Box>

      <DayPickerInput
        value={to}
        format="MM/DD/YYYY"
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder="To"
        onDayChange={handleToChange}
        ref={toInput}
        component={DateRangeInput}
        dayPickerProps={{
          selectedDays: [from, { from, to }],
          modifiers,
          disabledDays: {
            after: new Date(),
            before: from,
          },
          numberOfMonths: 2,
          toMonth: new Date(),
        }}
      />
    </Box>
  );
};

export default DateRangePicker;
