import { EarningsListForCompanyAdmin } from "../../models/responses/earnings.model";

export const promoterEarnings: EarningsListForCompanyAdmin = {
  items: [
    {
      id: "2d647e49-ac45-4447-8db1-a6523dec185f",
      amount: 100,
      datetime: "2020-04-09T08:51:05.000Z",
      description: "Flatland the sky calls to us citizens",
      transaction_type: "REFERRAL_BONUS",
      type: "washer",
      name: "John Smith",
    },
    {
      id: "2d647e49-ac45-4447-8db1-a6523dec185f",
      amount: 100,
      datetime: "2020-04-09T08:51:05.000Z",
      description: "Flatland the sky calls to us citizens",
      transaction_type: "REFERRAL_BONUS",
      type: "company",
      name: "Washerly ltd.",
    },
  ],
  total: 2,
};
