import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import {
  ConfirmationDialogModel,
  SnackbarPayloadModel,
} from "../../models/ui.models";

export enum AppActionTypes {
  SHOW_SNACKBAR = "[App] Show snackbar",
  CLOSE_SNACKBAR = "[App] Close snackbar",

  SHOW_CONFIRMATION_DIALOG = "[App] Show confirmation dialog",
  CLOSE_CONFIRMATION_DIALOG = "[App] Close confirmation dialog",
}

export const AppActions = {
  showSnackbar: (payload: SnackbarPayloadModel) =>
    createAction(AppActionTypes.SHOW_SNACKBAR, payload),
  closeSnackbar: () => createAction(AppActionTypes.CLOSE_SNACKBAR),

  showConfirmationDialog: (payload: ConfirmationDialogModel) =>
    createAction(AppActionTypes.SHOW_CONFIRMATION_DIALOG, payload),
  closeConfirmationDialog: () =>
    createAction(AppActionTypes.CLOSE_CONFIRMATION_DIALOG),
};

export type AppActions = ActionsUnion<typeof AppActions>;
