import { PackageStatus } from "../models/responses/package.model";
import colors from "./colors";
import texts from "./texts";

export function getPackageStatusProps(status: PackageStatus) {
  switch (status) {
    case PackageStatus.CREATED:
      return {
        statusColor: colors.textSecondary,
        statusText: texts.PackageStatus.created,
      };
    case PackageStatus.START:
      return {
        statusColor: colors.yellow,
        statusText: texts.PackageStatus.inProcess,
      };
    case PackageStatus.COMPLETE:
      return {
        statusColor: colors.green,
        statusText: texts.PackageStatus.done,
      };
  }
}
