import { axiosInstance } from "./base.api";
import {
  CompanyOrdersResponse,
  OrderResponse,
  OrdersResponse,
  OrderTimeFrame,
} from "../models/responses/order.model";
import { ID, SortParams } from "../models/utils.models";
import {
  GetOrdersForCompanyParams,
  GetOrdersForUserParams,
  GetOrdersListParams,
} from "../models/requests/Orders";

export const orderApi = {
  getOrdersList(
    params: GetOrdersListParams,
    sort: SortParams = SortParams.DESC
  ) {
    const config = {
      params: {
        ...params,
        sort,
      },
    };
    return axiosInstance.get<OrdersResponse>(`/private/orders/all`, config);
  },

  getOrdersByUserId(
    params: GetOrdersForUserParams,
    timeFrame: OrderTimeFrame = OrderTimeFrame.ALL,
    sort: SortParams = SortParams.DESC
  ) {
    const config = {
      params: {
        timeFrame,
        ...params,
        sort,
      },
    };
    return axiosInstance.get<OrdersResponse>(`/private/orders`, config);
  },

  getCompanyOrders(
    params: GetOrdersForCompanyParams,
    sort: SortParams = SortParams.DESC
  ) {
    const config = {
      params: {
        ...params,
        sort,
      },
    };
    return axiosInstance.get<CompanyOrdersResponse>(
      `/private/companies/orders`,
      config
    );
  },

  getOrder(id: ID) {
    return axiosInstance.get<OrderResponse>(`/private/orders/${id}`);
  },

  getLastCompleteOrder() {
    const config = {
      params: {
        size: 1,
      },
    };
    return axiosInstance.get<OrdersResponse>(`/private/orders`, config);
  },
};
