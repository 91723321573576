import {
  WasherVerificationActions,
  WasherVerificationActionTypes,
} from "../actions/washerVerification.actions";
import { ApplicationForm } from "../../models/responses/applicationForm.model";

class StateModel {
  sendingVerificationForm: boolean = false;
  sendVerificationFormError: string = "";
  myVerificationFormLoading: boolean = false;
  myVerificationFormError: string = "";
  myVerificationForm: ApplicationForm | null = null;
  submitWasherExamLoading: boolean = false;
  submitWasherExamError: string = "";
}

const initialState = new StateModel();

export function washerVerificationReducer(
  state = initialState,
  action: WasherVerificationActions
): StateModel {
  switch (action.type) {
    case WasherVerificationActionTypes.SEND_VERIFICATION_FORM: {
      return {
        ...state,
        sendingVerificationForm: true,
        sendVerificationFormError: "",
      };
    }
    case WasherVerificationActionTypes.SEND_VERIFICATION_FORM_SUCCESS: {
      return {
        ...state,
        sendingVerificationForm: false,
      };
    }
    case WasherVerificationActionTypes.SEND_VERIFICATION_FORM_FAILURE: {
      return {
        ...state,
        sendingVerificationForm: false,
        sendVerificationFormError: action.payload,
      };
    }
    case WasherVerificationActionTypes.GET_MY_VERIFICATION_FORM: {
      return {
        ...state,
        myVerificationFormLoading: true,
        myVerificationFormError: "",
        myVerificationForm: null,
      };
    }
    case WasherVerificationActionTypes.GET_VERIFICATION_FORM_SUCCESS: {
      return {
        ...state,
        myVerificationFormLoading: false,
        myVerificationForm: action.payload,
      };
    }
    case WasherVerificationActionTypes.GET_VERIFICATION_FORM_FAILURE: {
      return {
        ...state,
        myVerificationFormLoading: false,
        myVerificationFormError: action.payload,
      };
    }
    case WasherVerificationActionTypes.SUBMIT_EXAM: {
      return {
        ...state,
        submitWasherExamError: "",
        submitWasherExamLoading: true,
      };
    }
    case WasherVerificationActionTypes.SUBMIT_EXAM_SUCCESS: {
      return {
        ...state,
        submitWasherExamLoading: false,
      };
    }
    case WasherVerificationActionTypes.SUBMIT_EXAM_FAILURE: {
      return {
        ...state,
        submitWasherExamLoading: false,
        submitWasherExamError: action.payload,
      };
    }

    default:
      return state;
  }
}
