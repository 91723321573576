import {
  HeadCell,
  PromoterPayedReferralsColumns,
} from "../../../../../../models/table.models";

const headCells: HeadCell<PromoterPayedReferralsColumns>[] = [
  {
    id: PromoterPayedReferralsColumns.ID,
    label: "Referral ID",
    size: 3,
  },
  {
    id: PromoterPayedReferralsColumns.DATE,
    label: "Date",
    size: 3,
  },
  {
    id: PromoterPayedReferralsColumns.REFERRAL_NAME,
    label: "Referral Name",
    size: 3,
  },
  {
    id: PromoterPayedReferralsColumns.PRICE,
    label: "Earning",
    size: 2,
  },
];

export default headCells;
