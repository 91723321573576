import React from "react";
import { StatusColors } from "../../models/utils.models";
import { OrderStatus } from "../../models/responses/order.model";
import ColoredText from "../../styled/ColoredText";
import { Typography } from "@material-ui/core";

interface OwnProps {
  status: OrderStatus;
}

type Props = OwnProps;

const OrderStatusCell: React.FC<Props> = (props) => {
  switch (props.status) {
    case OrderStatus.ACCEPTED: {
      return (
        <Typography noWrap>
          <ColoredText color={StatusColors.YELLOW}>Accepted</ColoredText>
        </Typography>
      );
    }
    case OrderStatus.CANCELED: {
      return (
        <Typography noWrap>
          <ColoredText color={StatusColors.RED}>Canceled</ColoredText>
        </Typography>
      );
    }
    case OrderStatus.COMPLETED: {
      return (
        <Typography noWrap>
          <ColoredText color={StatusColors.GREEN}>Completed</ColoredText>
        </Typography>
      );
    }
    case OrderStatus.DECLINED: {
      return (
        <Typography noWrap>
          <ColoredText color={StatusColors.RED}>Declined</ColoredText>
        </Typography>
      );
    }
    case OrderStatus.IN_PROGRESS:
    case OrderStatus.ALL_PACKAGES_COMPLETE:
    case OrderStatus.WASHER_ARRIVED: {
      return (
        <Typography noWrap>
          <ColoredText color={StatusColors.YELLOW}>In progress</ColoredText>
        </Typography>
      );
    }
    case OrderStatus.OFFERED:
    case OrderStatus.WASHER_ARRIVING:
    case OrderStatus.PUBLIC_OFFER: {
      return (
        <Typography noWrap>
          <ColoredText color={StatusColors.YELLOW}>Offered</ColoredText>
        </Typography>
      );
    }
    default:
      return null;
  }
};

export default OrderStatusCell;
