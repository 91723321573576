import styled from "styled-components";
import { unitSize } from "../constants/sizes";

interface AppBarSpacerProps {
  withStatistic: boolean;
}

export default styled.div`
  height: ${(props: AppBarSpacerProps) =>
    props.withStatistic ? unitSize * 25 : unitSize * 11.5}px;
`;
