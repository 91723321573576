import { HeadCell, PackagesColumns } from "../../../../../models/table.models";

const headCells: HeadCell<PackagesColumns>[] = [
  {
    id: PackagesColumns.ACTIONS,
    label: "Actions",
    size: 2,
  },
  {
    id: PackagesColumns.NAME,
    label: "Name",
    size: 4,
  },
  {
    id: PackagesColumns.PRICE,
    label: "Price",
    size: 3,
  },
  {
    id: PackagesColumns.WASHER_PAYOUT_PRICE,
    label: "Washer Payout Price",
    size: 3,
  },
];

export default headCells;
