import React, { useEffect, useMemo } from "react";
import { Grid } from "@material-ui/core";
import WasherInfo from "./components/WasherInfo";
import DeliveryAddress from "../../../../components/equipments/DeliveryAddress";
import RequestedEquipments from "./components/RequestedEquipments";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import useActions from "../../../../hooks/useActions";
import { UsersActions } from "../../../../store/actions/users.actions";
import Loading from "../../../../shared/Loading";
import { EquipmentActions } from "../../../../store/actions/equipment.actions";
import { getUnitType } from "../../../../models/responses/equipment.model";

interface OwnProps {}

type Props = OwnProps;

const EquipmentRequestsForWasher: React.FC<Props> = () => {
  const { activeUser, activeUserLoading } = useSelector(
    (state: AppState) => state.users
  );
  const usersActions = useActions<typeof UsersActions>(UsersActions);
  const equipmentActions = useActions<typeof EquipmentActions>(
    EquipmentActions
  );
  const equipmentState = useSelector((state: AppState) => state.equipment);

  const liquids = useMemo(
    () =>
      equipmentState.equipmentRequests.items.filter(
        (item) =>
          getUnitType(item.userEquipment.equipment.valueUnit) === "liquid"
      ),
    [equipmentState.equipmentRequests]
  );

  const items = useMemo(
    () =>
      equipmentState.equipmentRequests.items.filter(
        (item) => getUnitType(item.userEquipment.equipment.valueUnit) === "item"
      ),
    [equipmentState.equipmentRequests]
  );

  const params = useParams<{ userId: string }>();
  useEffect(() => {
    usersActions.getActiveUserData(params.userId);
    equipmentActions.getEquipmentRequestsForWasher(params.userId);
  }, [params.userId, usersActions, equipmentActions]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {activeUserLoading && <Loading />}
        {activeUser && <WasherInfo data={activeUser} />}
      </Grid>
      <Grid item xs={12}>
        {activeUser && (
          <DeliveryAddress
            phoneNumber={activeUser?.deliveryPhoneNumber || ""}
            address={activeUser?.deliveryAddress || ""}
          />
        )}
      </Grid>
      {!!liquids.length && (
        <Grid item xs={12}>
          <RequestedEquipments title="Liquids" data={liquids} />
        </Grid>
      )}
      {!!items.length && (
        <Grid item xs={12}>
          <RequestedEquipments title="Items" data={items} />
        </Grid>
      )}
    </Grid>
  );
};

export default EquipmentRequestsForWasher;
