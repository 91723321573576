import { select, takeLatest } from "redux-saga/effects";
import {
  addMobileBuildType,
  MobileBuildBlacklistActions,
  MobileBuildBlacklistActionTypes,
  removeMobileBuildType,
} from "../actions/mobileBuildBlacklist.actions";
import { put } from "@redux-saga/core/effects";
import { ResponseStatus } from "../../models/responses/result.model";
import { showError } from "./utils";
import { mobileBuildBlacklistApi } from "../../api/mobileBuildBlacklist.api";
import { transformAndValidate } from "class-transformer-validator";
import {
  MobileBuild,
  MobileBuilds,
} from "../../models/responses/MobileBuildBlacklist.model";
import { AppState } from "../reducers";

function* getMobileBuilds() {
  try {
    const { data } = yield mobileBuildBlacklistApi.getAllBuildIds();
    if (data.result === ResponseStatus.SUCCESS) {
      const buildIds = yield transformAndValidate(MobileBuilds, data.data);
      yield put(MobileBuildBlacklistActions.getMobileBuildsSuccess(buildIds));
    }
  } catch (e) {
    yield showError(e);
    yield put(MobileBuildBlacklistActions.getMobileBuildsFailure(e.message));
  }
}

function* addMobileBuild(action: addMobileBuildType) {
  try {
    const { data } = yield mobileBuildBlacklistApi.addBuildId(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const addedBuildId = yield transformAndValidate(MobileBuild, data.data);
      const buildIds: MobileBuilds = yield select(
        (state: AppState) => state.mobileBuildBlacklist.mobileBuilds
      );
      const newBuildIds: MobileBuilds = {
        items: [...buildIds.items, addedBuildId],
        total: buildIds.total + 1,
      };
      yield put(MobileBuildBlacklistActions.addMobileBuildSuccess(newBuildIds));
    }
  } catch (e) {
    yield showError(e);
    yield put(MobileBuildBlacklistActions.addMobileBuildFailure(e.message));
  }
}

function* removeMobileBuild(action: removeMobileBuildType) {
  try {
    const { data } = yield mobileBuildBlacklistApi.removeBuildId(
      action.payload
    );
    if (data.result === ResponseStatus.SUCCESS) {
      const removedBuildId = action.payload;
      const buildIds: MobileBuilds = yield select(
        (state: AppState) => state.mobileBuildBlacklist.mobileBuilds
      );
      const newBuildIds: MobileBuilds = {
        items: buildIds.items.filter((item) => item.buildId !== removedBuildId),
        total: buildIds.total - 1,
      };
      yield put(
        MobileBuildBlacklistActions.removeMobileBuildSuccess(newBuildIds)
      );
    }
  } catch (e) {
    yield showError(e);
    yield put(MobileBuildBlacklistActions.removeMobileBuildFailure(e.message));
  }
}

export function* MobileBuildBlacklistSagas() {
  yield takeLatest(
    MobileBuildBlacklistActionTypes.GET_MOBILE_BUILDS,
    getMobileBuilds
  );
  yield takeLatest(
    MobileBuildBlacklistActionTypes.ADD_MOBILE_BUILD,
    addMobileBuild
  );
  yield takeLatest(
    MobileBuildBlacklistActionTypes.REMOVE_MOBILE_BUILD,
    removeMobileBuild
  );
}
