import React from "react";
import useActions from "../../../../hooks/useActions";
import { AuthActions } from "../../../../store/actions/auth.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import { Formik, FormikHelpers } from "formik";
import { EmailSchema } from "../../../../helpers/validations";
import FormLayout from "../../../ForgotPasswordModal/FormLayout";
import { Grid } from "@material-ui/core";
import FormInput from "../../../../shared/FormInput";

interface OwnProps {}

type Props = OwnProps;

interface EmailForm {
  email: string;
}

const ChangeEmailForm: React.FC<Props> = () => {
  const authActions = useActions<typeof AuthActions>(AuthActions);
  const { isSendVerifyCodeLoading } = useSelector(
    (state: AppState) => state.auth
  );
  const handleEmailSubmit = (
    values: EmailForm,
    actions: FormikHelpers<EmailForm>
  ) => {
    const request = {
      email: values.email,
      template: "Your Washerly code for confirm Email is $otp",
    };
    authActions.verifyUserEmail(request);
    actions.setSubmitting(false);
  };
  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={handleEmailSubmit}
      validationSchema={EmailSchema}
    >
      {({
        values,
        handleChange,
        errors,
        touched,
        isValid,
        handleSubmit,
        handleBlur,
      }) => (
        <FormLayout
          buttonColor="primary"
          buttonText="Send code"
          buttonDisabled={!isValid}
          description="Please enter new email address"
          handleSubmit={handleSubmit}
          loading={isSendVerifyCodeLoading}
        >
          <Grid item xs={12}>
            <FormInput
              type="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              variant="filled"
              margin="normal"
              fullWidth
              id="email"
              label="New Email address"
              name="email"
              autoComplete="email"
              autoFocus
              helperText={errors.email && touched.email && errors.email}
              error={!!errors.email && touched.email}
            />
          </Grid>
        </FormLayout>
      )}
    </Formik>
  );
};

export default ChangeEmailForm;
