import { Page } from "../../../../models/router.models";
import { RouteNames } from "../../../../constants/routeNames";
import VerificationIcon from "../../../../shared/icons/VerificationIcon";
import TutorialsIcon from "../../../../shared/icons/TutorialsIcon";
import StartTheExamIcon from "../../../../shared/icons/StartTheExamIcon";
import texts from "../../../../constants/texts";

const pendingWasherMenu: Page[] = [
  {
    title: texts.roles.washer.menu.verification,
    path: `/${RouteNames.VERIFICATION}`,
    Icon: VerificationIcon,
  },
  {
    title: texts.roles.washer.menu.tutorials,
    path: `/${RouteNames.TUTORIALS}`,
    Icon: TutorialsIcon,
    disabled: true,
  },
  {
    title: texts.roles.washer.menu.startTheExam,
    path: `/${RouteNames.START_THE_EXAM}`,
    Icon: StartTheExamIcon,
    disabled: true,
  },
];

export default pendingWasherMenu;
