import React from "react";
import {
  createStyles,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { formatDate } from "../../../../../helpers/utils/date.utils";
import texts from "../../../../../constants/texts";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      padding: 0,
      display: "flex",
      alignItems: "center",
      height: theme.spacing(5),
      marginTop: theme.spacing(1),
    },
    date: {
      marginLeft: theme.spacing(5),
      color: theme.palette.text.primary,
      fontWeight: 400,
    },
  })
);

interface OwnProps {
  date: Date | null;
}

type Props = OwnProps;

const DateRow: React.FC<Props> = ({ date }) => {
  const classes = useStyles();
  const [day, time] = formatDate(date);
  return (
    <ListItem className={classes.listItem}>
      <Typography>{texts.equipment.lastRefilled}:</Typography>
      <Typography className={classes.date}>
        {day} / {time}
      </Typography>
    </ListItem>
  );
};

export default DateRow;
