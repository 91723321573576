import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsNumber,
  IsString,
  IsUUID,
  ValidateIf,
} from "class-validator";
import { Type } from "class-transformer";

export enum PackageStatus {
  CREATED = "CREATED",
  START = "START",
  COMPLETE = "COMPLETE",
}

export class Package {
  @IsUUID()
  id: string;
  @ValidateIf((o) => o.status === PackageStatus.COMPLETE)
  @IsDate()
  @Type(() => Date)
  closeTime: Date | null;
  @IsString()
  currency: string;
  @IsString()
  duration: string;
  @IsBoolean()
  @Type(() => Boolean)
  extraDirty: boolean;
  @IsUUID()
  iconFileId: string;
  @IsString()
  nameCode: string;
  @IsUUID()
  orderId: string;
  @IsNumber()
  @Type(() => Number)
  price: number;
  @ValidateIf((o) => o.status !== PackageStatus.CREATED)
  @IsDate()
  @Type(() => Date)
  startTime: Date | null;
  @IsEnum(PackageStatus)
  status: PackageStatus;
  @IsString()
  title: string;
}
