import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { RouteNames } from "../../../constants/routeNames";
import { Grid } from "@material-ui/core";
import NavTabs from "../../../components/NavTabs";
import tabs from "./tabs";
import AdminPayoutsList from "./components/AdminPayoutsList";
import texts from "../../../constants/texts";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const AdminPayouts: React.FC<Props> = () => {
  useTitle(`Washerly Admin Payouts`);
  const match = useRouteMatch();
  return (
    <Grid container spacing={2}>
      <NavTabs tabs={tabs}>
        <Switch>
          <Route exact path={`${match.path}/${RouteNames.REQUESTED}`}>
            <AdminPayoutsList
              title={texts.roles.admin.payouts.requested.title}
              subtitle={texts.roles.admin.payouts.requested.subtitle}
            />
          </Route>
          <Route exact path={`${match.path}/${RouteNames.COMPLETED}`}>
            <AdminPayoutsList
              title={texts.roles.admin.payouts.completed.title}
              subtitle={texts.roles.admin.payouts.completed.subtitle}
              isCompleted
            />
          </Route>
          <Redirect to={`${match.path}/${RouteNames.REQUESTED}`} />
        </Switch>
      </NavTabs>
    </Grid>
  );
};

export default AdminPayouts;
