import { User, Users } from "../../models/responses/user.model";
import { UsersActions, UsersActionTypes } from "../actions/users.actions";

class StateModel {
  promotersLoading: boolean = false;
  promotersError: string = "";
  promotersSearch: string = "";
  promoters: Users = { items: [], total: 0 };
  clientsLoading: boolean = false;
  clientsError: string = "";
  clientsSearch: string = "";
  clients: Users = { items: [], total: 0 };
  washersLoading: boolean = false;
  washersError: string = "";
  washersSearch: string = "";
  washers: Users = { items: [], total: 0 };
  activeUser: User | null = null;
  activeUserLoading: boolean = false;
  activeUserError: string = "";
  updateUserLoading: boolean = false;
  updateUserError: string = "";
}

const initialState = new StateModel();

export function usersReducer(
  state = initialState,
  action: UsersActions
): StateModel {
  switch (action.type) {
    case UsersActionTypes.GET_PROMOTERS: {
      return {
        ...state,
        promotersLoading: true,
        promotersError: "",
      };
    }
    case UsersActionTypes.GET_PROMOTERS_SUCCESS: {
      return {
        ...state,
        promotersLoading: false,
        promotersError: "",
        promoters: action.payload,
      };
    }
    case UsersActionTypes.GET_PROMOTERS_FAILURE: {
      return {
        ...state,
        promotersLoading: false,
        promotersError: action.payload,
      };
    }
    case UsersActionTypes.SET_PROMOTERS_SEARCH_VALUE: {
      return {
        ...state,
        promotersSearch: action.payload,
      };
    }
    case UsersActionTypes.GET_CLIENTS: {
      return {
        ...state,
        clientsLoading: true,
        clientsError: "",
      };
    }
    case UsersActionTypes.GET_CLIENTS_SUCCESS: {
      return {
        ...state,
        clientsLoading: false,
        clientsError: "",
        clients: action.payload,
      };
    }
    case UsersActionTypes.GET_CLIENTS_FAILURE: {
      return {
        ...state,
        clientsLoading: false,
        clientsError: action.payload,
      };
    }
    case UsersActionTypes.SET_CLIENTS_SEARCH_VALUE: {
      return {
        ...state,
        clientsSearch: action.payload,
      };
    }
    case UsersActionTypes.GET_WASHERS: {
      return {
        ...state,
        washersLoading: true,
        washersError: "",
      };
    }
    case UsersActionTypes.GET_COMPANY_WASHERS: {
      return {
        ...state,
        washersLoading: true,
        washersError: "",
      };
    }
    case UsersActionTypes.GET_WASHERS_SUCCESS: {
      return {
        ...state,
        washersLoading: false,
        washersError: "",
        washers: action.payload,
      };
    }
    case UsersActionTypes.GET_WASHERS_FAILURE: {
      return {
        ...state,
        washersLoading: false,
        washersError: action.payload,
      };
    }
    case UsersActionTypes.SET_WASHERS_SEARCH_VALUE: {
      return {
        ...state,
        washersSearch: action.payload,
      };
    }
    case UsersActionTypes.GET_ACTIVE_USER_DATA: {
      return {
        ...state,
        activeUserLoading: true,
        activeUserError: "",
        activeUser: null,
      };
    }
    case UsersActionTypes.GET_ACTIVE_USER_SUCCESS: {
      return {
        ...state,
        activeUserLoading: false,
        activeUserError: "",
        activeUser: action.payload,
      };
    }
    case UsersActionTypes.GET_ACTIVE_USER_FAILURE: {
      return {
        ...state,
        activeUserLoading: false,
        activeUserError: action.payload,
      };
    }
    case UsersActionTypes.UPDATE_USER_DATA: {
      return {
        ...state,
        updateUserLoading: true,
        updateUserError: "",
      };
    }
    case UsersActionTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        updateUserLoading: false,
        updateUserError: "",
        activeUser: action.payload,
      };
    }
    case UsersActionTypes.UPDATE_USER_FAILURE: {
      return {
        ...state,
        updateUserLoading: false,
        updateUserError: action.payload,
      };
    }

    default:
      return state;
  }
}

function getFormattedUsers(state: StateModel, userType: "clients" | "washers") {
  return state[userType].items.map(
    ({ id, email, firstName, secondName, phoneNumber, completedOrders }) => ({
      id,
      email: email || "",
      fullName: `${firstName} ${secondName}`,
      phoneNumber,
      completedOrders: completedOrders.toString(),
    })
  );
}

function getFormattedPromoters(state: StateModel) {
  return state.promoters.items.map(
    ({
      id,
      email,
      firstName,
      secondName,
      phoneNumber,
      countReferralUsers,
    }) => ({
      id,
      email: email || "",
      fullName: `${firstName} ${secondName}`,
      phoneNumber,
      referralsCount: countReferralUsers.toString(),
    })
  );
}

export const UserSelectors = {
  getFormattedUsers,
  getFormattedPromoters,
};
