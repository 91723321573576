import React, { useCallback } from "react";
import Popup from "../../shared/Popup";
import { useDropzone } from "react-dropzone";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import ImageIcon from "../../shared/icons/ImageIcon";
import ImageFileIcon from "../../shared/icons/ImageFileIcon";
import ColoredText from "../../styled/ColoredText";
import colors from "../../constants/colors";
import RejectIcon from "../../shared/icons/RejectIcon";
import { formatBytes } from "../../helpers/utils/utils";
import CustomButton from "../../shared/CustomButton";
import { useStyles } from "./style";

interface OwnProps {
  open: boolean;
  onClose: () => void;
  title: string;
  files: File[];
  onChange: (files: File[]) => void;
}

type Props = OwnProps;

const UploadFilesModal: React.FC<Props> = (props) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        return;
      }
      const newFiles = [...props.files];
      acceptedFiles.forEach((file) => {
        if (
          props.files.find((oldFile) => oldFile.name === file.name) ===
          undefined
        ) {
          newFiles.push(file);
        }
      });
      props.onChange(newFiles);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.files, props.onChange]
  );
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const classes = useStyles({ isDragAccept, isDragReject });

  const removeFile = (removeIndex: number) => {
    const newFiles = props.files.filter((file, index) => index !== removeIndex);
    props.onChange(newFiles);
  };
  const handleSubmit = () => {
    props.onClose();
  };
  return (
    <Popup
      title="Upload file"
      subtitle={props.title}
      open={props.open}
      handleClose={props.onClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
      >
        <List className={classes.list}>
          {props.files.map((value: File, index: number) => (
            <ListItem key={value.name} divider className={classes.listItem}>
              <Box display="flex" alignItems="center">
                <ListItemIcon className={classes.listItemIcon}>
                  <ImageFileIcon />
                </ListItemIcon>
                <Box>
                  <Typography className={classes.listItemText}>
                    Image name:{" "}
                    <ColoredText color={colors.blue}>{value.name}</ColoredText>
                  </Typography>
                  <Typography className={classes.listItemText}>
                    Size:{" "}
                    <ColoredText color={colors.blue}>
                      {formatBytes(value.size)}
                    </ColoredText>
                  </Typography>
                </Box>
              </Box>
              <IconButton
                className={classes.rejectFileIcon}
                onClick={() => removeFile(index)}
              >
                <RejectIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Box {...getRootProps({ className: classes.dropZone })}>
          <input {...getInputProps()} />
          <ImageIcon className={classes.imageIcon} />
          <Typography variant="body1" color="textSecondary">
            Drag & Drop upload files
          </Typography>
        </Box>
        <Box mt={4}>
          <CustomButton
            variant="contained"
            fullWidth
            color="secondary"
            disabled={!props.files.length}
            onClick={handleSubmit}
          >
            Confirm
          </CustomButton>
        </Box>
      </Box>
    </Popup>
  );
};

export default UploadFilesModal;
