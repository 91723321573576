import React from "react";
import { SortParams } from "../../models/utils.models";

interface OwnProps {
  order?: SortParams;
}

type Props = OwnProps;

const SortIcon: React.FC<Props> = ({ order }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="12"
      viewBox="0 0 7 12"
    >
      <g id="_1" data-name="1" transform="translate(-289 -430)">
        <g
          id="Polygon_3"
          data-name="Polygon 3"
          transform="translate(289 430)"
          opacity={order === SortParams.ASC ? "1" : "0.3"}
          fill={order === SortParams.ASC ? "#67c011" : "#fff"}
        >
          <path
            d="M 6.039669036865234 4.5 L 0.9603310227394104 4.5 L 3.5 0.8719014525413513 L 6.039669036865234 4.5 Z"
            stroke="none"
          />
          <path
            d="M 3.5 1.743788480758667 L 1.920651912689209 4 L 5.079348087310791 4 L 3.5 1.743788480758667 M 3.5 0 L 7 5 L 0 5 L 3.5 0 Z"
            stroke="none"
            fill={order === SortParams.ASC ? "#67c011" : "#707070"}
          />
        </g>
        <g
          id="Polygon_4"
          data-name="Polygon 4"
          transform="translate(296 442) rotate(180)"
          opacity={order === SortParams.DESC ? "1" : "0.3"}
          fill={order === SortParams.DESC ? "#67c011" : "#fff"}
        >
          <path
            d="M 6.039669036865234 4.5 L 0.9603310227394104 4.5 L 3.5 0.8719014525413513 L 6.039669036865234 4.5 Z"
            stroke="none"
          />
          <path
            d="M 3.5 1.743788480758667 L 1.920651912689209 4 L 5.079348087310791 4 L 3.5 1.743788480758667 M 3.5 0 L 7 5 L 0 5 L 3.5 0 Z"
            stroke="none"
            fill={order === SortParams.DESC ? "#67c011" : "#707070"}
          />
        </g>
      </g>
    </svg>
  );
};

export default SortIcon;
