import React, { useCallback } from "react";
import UsersList from "../../../../components/UsersList/UsersList";
import { ID } from "../../../../models/utils.models";
import TableLayout from "../../../../shared/sharedTable/TableLayout";
import { headCells } from "./headCells";
import { useHistory, useRouteMatch } from "react-router-dom";
import texts from "../../../../constants/texts";
import { TablePaginationParams } from "../../../../models/table.models";
import useActions from "../../../../hooks/useActions";
import { EquipmentActions } from "../../../../store/actions/equipment.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import { EquipmentSelectors } from "../../../../store/reducers/equipment.reducer";

interface OwnProps {}

type Props = OwnProps;

const WashersWithRequestsList: React.FC<Props> = () => {
  const equipmentActions = useActions<typeof EquipmentActions>(
    EquipmentActions
  );
  const equipmentState = useSelector((state: AppState) => state.equipment);
  const formattedWashers = EquipmentSelectors.getFormattedWashers(
    equipmentState
  );

  const history = useHistory();
  const match = useRouteMatch();

  const handlePaginationChange = useCallback(
    (values: TablePaginationParams) => {
      equipmentActions.getWashersListWithActiveRequests(values);
    },
    [equipmentActions]
  );

  return (
    <TableLayout
      title={texts.roles.admin.equipments.requestList.title}
      subtitle={texts.roles.admin.equipments.requestList.subtitle}
      isLoading={equipmentState.washersWithRequestsLoading}
      onPaginationChange={handlePaginationChange}
      headCells={headCells}
      total={equipmentState.washersWithRequests.total}
    >
      <UsersList
        headCells={headCells}
        data={formattedWashers}
        redirectItemTo={(id: ID) => history.push(`${match.url}/${id}`)}
      />
    </TableLayout>
  );
};

export default WashersWithRequestsList;
