import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 16,
      // height: constants.spacing(9),
      overflow: "hidden",
      borderRadius: theme.spacing(2),
      backgroundColor: "#fcfcfb",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.05)",
      // transition: constants.transitions.create(['border-color', 'box-shadow']),
      "&:hover": {
        backgroundColor: "#fff",
      },
      "&$focused": {
        backgroundColor: "#fff",
        borderColor: theme.palette.primary.main,
      },
      "&$disabled": {
        backgroundColor: "#fff",
      },
    },
    focused: {},
    disabled: {},
  })
);
