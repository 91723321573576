import { TablePaginationParams } from "../table.models";
import { ID } from "../utils.models";
import { OrderTimeFrame } from "../responses/order.model";

export enum PAYOUT_STATUS_FILTER {
  PAID = "PAID",
  ALL = "ALL",
  REJECT = "REJECT",
  PAID_OR_IN_PROGRESS = "PAID_OR_IN_PROGRESS",
  NOT_REQUESTED = "NOT_REQUESTED",
  NOT_REQUESTED_OR_REJECT = "NOT_REQUESTED_OR_REJECT",
}

export enum OrdersListStatusFilter {
  ALL = "ALL",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  CANCELED = "CANCELED",
}

export interface GetOrdersForUserParams extends TablePaginationParams {
  userId?: ID;
  timeFrame?: OrderTimeFrame;
  statusFilter?: "COMPLETED" | "ALL" | "NOT_COMPLETED_WITHOUT_CANCELED";
  paymentStatusFilter?: "PAID" | "ALL" | "UNPAID";
  payoutStatusFilter?: PAYOUT_STATUS_FILTER;
}

export interface GetOrdersListParams extends TablePaginationParams {
  timeFrame?: OrderTimeFrame;
  statusFilter?: OrdersListStatusFilter;
  paymentStatusFilter?: "PAID" | "ALL" | "UNPAID";
  payoutStatusFilter?: PAYOUT_STATUS_FILTER;
}

export interface GetOrdersForCompanyParams extends TablePaginationParams {
  companyId?: ID;
  payoutStatus?: "ALL" | "PAID" | "UNPAID" | "NOT_REQUESTED_OR_UNPAID";
}
