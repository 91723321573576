import React from "react";
import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import ArrowRightIcon from "../../shared/icons/ArrowRightIcon";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: "pointer",
    "&:hover": {
      color: colors.green,
      "& $arrow": {
        transform: "translateX(8px)",
      },
    },
  },
  arrow: {
    transition: "0.5s",
  },
}));

interface OwnProps {
  Icon: React.FC;
  title: string;
  contact: string;
  link: string;
}

type Props = OwnProps;

const ContactRow: React.FC<Props> = ({ Icon, title, contact, link }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={2}
      className={classes.root}
      onClick={() => {
        window.location.assign(link);
      }}
    >
      <Box color="secondary.main" display="flex" alignItems="center">
        <Icon />
        <Box ml={0.5}>
          <Typography variant="subtitle2">{title}</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle2">{contact}</Typography>
        <Box ml={1} className={classes.arrow}>
          <ArrowRightIcon small />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactRow;
