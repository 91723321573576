import React, { useEffect, useState } from "react";
import { StatisticItem } from "../../../../components/DashboardStatistics/DashboardStatistics";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import WasherIcon from "../../../../shared/icons/WasherIcon";
import moment from "moment";
import CompanyIcon from "../../../../shared/icons/CompanyIcon";
import WalletIcon from "../../../../shared/icons/WalletIcon";
import { formatCurrency } from "../../../../helpers/utils/utils";
import DashboardStatistics from "../../../../components/DashboardStatistics";
import texts from "../../../../constants/texts";

interface OwnProps {}

type Props = OwnProps;

const CompanyStatistics: React.FC<Props> = (props) => {
  const [statistics, setStatistics] = useState<StatisticItem[]>([]);

  const currentCompany = useSelector(
    (state: AppState) => state.company.activeCompany
  );

  useEffect(() => {
    if (currentCompany) {
      const newStatistics = [
        {
          Icon: WasherIcon,
          name: "Company Washers",
          value: currentCompany.companyWashersCount?.toString() || "0",
        },
        {
          Icon: WasherIcon,
          name: "Orders Completed",
          value: currentCompany.completedOrders?.toString() || "0",
        },
        {
          Icon: CompanyIcon,
          name: "Registration Date",
          value: moment(currentCompany.createdTime).format("DD.MM.YYYY"),
        },
        {
          Icon: WalletIcon,
          name: "Total Payout Amount",
          value: formatCurrency(currentCompany.totalOrdersPriceAmount || 0),
        },
      ];
      setStatistics(newStatistics);
    }
  }, [currentCompany]);

  return (
    <DashboardStatistics
      title={texts.roles.companyAdmin.dashboard.statisticsTitle}
      subtitle={texts.roles.companyAdmin.dashboard.statisticsSubtitle}
      items={statistics}
    />
  );
};

export default CompanyStatistics;
