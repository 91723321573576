import { NavTab } from "../../../../models/router.models";
import texts from "../../../../constants/texts";
import { RouteNames } from "../../../../constants/routeNames";

export const tabs: NavTab[] = [
  {
    title: texts.userProfile.menu.sessions,
    path: `/${RouteNames.SESSIONS}`,
  },
  {
    title: texts.userProfile.menu.logs,
    path: `/${RouteNames.LOGS}`,
  },
  {
    title: texts.userProfile.menu.orders,
    path: `/${RouteNames.ORDERS}`,
  },
  {
    title: texts.userProfile.menu.futureWashes,
    path: `/${RouteNames.FUTURE_WASH}`,
  },
  {
    title: texts.userProfile.menu.reviews,
    path: `/${RouteNames.REVIEWS}`,
  },
  {
    title: texts.userProfile.menu.equipment,
    path: `/${RouteNames.EQUIPMENT}`,
  },
  {
    title: texts.userProfile.menu.adminComments,
    path: `/${RouteNames.ADMIN_COMMENTS}`,
  },
];
