import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import { AddBuildIdDto } from "../../models/requests/MobileBuildBlacklist";
import { MobileBuilds } from "../../models/responses/MobileBuildBlacklist.model";

export enum MobileBuildBlacklistActionTypes {
  GET_MOBILE_BUILDS = "[MobileBuildBlacklist] Get mobile builds",
  GET_MOBILE_BUILDS_SUCCESS = "[MobileBuildBlacklist] Get mobile builds success",
  GET_MOBILE_BUILDS_FAILURE = "[MobileBuildBlacklist] Get mobile builds failure",

  ADD_MOBILE_BUILD = "[MobileBuildBlacklist] Add mobile build",
  ADD_MOBILE_BUILD_SUCCESS = "[MobileBuildBlacklist] Add mobile build success",
  ADD_MOBILE_BUILD_FAILURE = "[MobileBuildBlacklist] Add mobile build failure",

  REMOVE_MOBILE_BUILD = "[MobileBuildBlacklist] Remove mobile build",
  REMOVE_MOBILE_BUILD_SUCCESS = "[MobileBuildBlacklist] Remove mobile build success",
  REMOVE_MOBILE_BUILD_FAILURE = "[MobileBuildBlacklist] Remove mobile build failure",
}

export const MobileBuildBlacklistActions = {
  getMobileBuilds: () =>
    createAction(MobileBuildBlacklistActionTypes.GET_MOBILE_BUILDS),
  getMobileBuildsSuccess: (payload: MobileBuilds) =>
    createAction(
      MobileBuildBlacklistActionTypes.GET_MOBILE_BUILDS_SUCCESS,
      payload
    ),
  getMobileBuildsFailure: (payload: string) =>
    createAction(
      MobileBuildBlacklistActionTypes.GET_MOBILE_BUILDS_FAILURE,
      payload
    ),

  addMobileBuild: (payload: AddBuildIdDto) =>
    createAction(MobileBuildBlacklistActionTypes.ADD_MOBILE_BUILD, payload),
  addMobileBuildSuccess: (payload: MobileBuilds) =>
    createAction(
      MobileBuildBlacklistActionTypes.ADD_MOBILE_BUILD_SUCCESS,
      payload
    ),
  addMobileBuildFailure: (payload: string) =>
    createAction(
      MobileBuildBlacklistActionTypes.ADD_MOBILE_BUILD_FAILURE,
      payload
    ),

  removeMobileBuild: (payload: string) =>
    createAction(MobileBuildBlacklistActionTypes.REMOVE_MOBILE_BUILD, payload),
  removeMobileBuildSuccess: (payload: MobileBuilds) =>
    createAction(
      MobileBuildBlacklistActionTypes.REMOVE_MOBILE_BUILD_SUCCESS,
      payload
    ),
  removeMobileBuildFailure: (payload: string) =>
    createAction(
      MobileBuildBlacklistActionTypes.REMOVE_MOBILE_BUILD_FAILURE,
      payload
    ),
};

export type MobileBuildBlacklistActions = ActionsUnion<
  typeof MobileBuildBlacklistActions
>;

export type addMobileBuildType = ActionsOfType<
  MobileBuildBlacklistActions,
  MobileBuildBlacklistActionTypes.ADD_MOBILE_BUILD
>;
export type removeMobileBuildType = ActionsOfType<
  MobileBuildBlacklistActions,
  MobileBuildBlacklistActionTypes.REMOVE_MOBILE_BUILD
>;
