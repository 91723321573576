import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import { PendingWashers } from "../../models/responses/pendingWasher.model";
import { ApplicationForm } from "../../models/responses/applicationForm.model";
import { PendingWashersRequestParams } from "../../models/requests/PendingWashers";

export enum PendingWashersActionsTypes {
  GET_PENDING_WASHERS = "[PendingWashers] Get pending washers",
  GET_PENDING_WASHERS_SUCCESS = "[PendingWashers] Get pending washers success",
  GET_PENDING_WASHERS_FAILURE = "[PendingWashers] Get pending washers failure",
  SET_PENDING_WASHERS_SEARCH_VALUE = "[PendingWashers] Set pending washers search value",
  PENDING_WASHERS_SEARCH_VALUE = "[PendingWashers] Pending washers search value",

  GET_ACTIVE_WASHER_CV = "[PendingWashers] Get active washer CV",
  GET_ACTIVE_WASHER_CV_SUCCESS = "[PendingWashers] Get active washer CV success",
  GET_ACTIVE_WASHER_CV_FAILURE = "[PendingWashers] Get active washer CV failure",

  APPROVE_ACTIVE_WASHER_CV = "[PendingWashers] Approve washer cv",
  REJECT_ACTIVE_WASHER_CV = "[PendingWashers] Reject washer cv",
  CHANGE_WASHER_CV_STATUS_SUCCESS = "[PendingWashers] Change active washer cv status success",
  CHANGE_WASHER_CV_STATUS_FAILURE = "[PendingWashers] Change active washer cv status failure",
}

export const PendingWashersActions = {
  getPendingWashers: (payload: PendingWashersRequestParams) =>
    createAction(PendingWashersActionsTypes.GET_PENDING_WASHERS, payload),
  getPendingWashersSuccess: (payload: PendingWashers) =>
    createAction(
      PendingWashersActionsTypes.GET_PENDING_WASHERS_SUCCESS,
      payload
    ),
  getPendingWashersFailure: (payload: string) =>
    createAction(
      PendingWashersActionsTypes.GET_PENDING_WASHERS_FAILURE,
      payload
    ),

  setPendingWashersSearchValue: (payload: string) =>
    createAction(
      PendingWashersActionsTypes.SET_PENDING_WASHERS_SEARCH_VALUE,
      payload
    ),
  pendingWashersSearchValue: (payload: string) =>
    createAction(
      PendingWashersActionsTypes.PENDING_WASHERS_SEARCH_VALUE,
      payload
    ),

  getActiveWasherCV: (payload: string) =>
    createAction(PendingWashersActionsTypes.GET_ACTIVE_WASHER_CV, payload),
  getActiveWasherCVSuccess: (payload: ApplicationForm) =>
    createAction(
      PendingWashersActionsTypes.GET_ACTIVE_WASHER_CV_SUCCESS,
      payload
    ),
  getActiveWasherCVFailure: (payload: string) =>
    createAction(
      PendingWashersActionsTypes.GET_ACTIVE_WASHER_CV_FAILURE,
      payload
    ),

  approveActiveWasherCv: () =>
    createAction(PendingWashersActionsTypes.APPROVE_ACTIVE_WASHER_CV),
  rejectActiveWasherCv: (payload: string) =>
    createAction(PendingWashersActionsTypes.REJECT_ACTIVE_WASHER_CV, payload),
  changeWasherCvStatusSuccess: (payload: ApplicationForm) =>
    createAction(
      PendingWashersActionsTypes.CHANGE_WASHER_CV_STATUS_SUCCESS,
      payload
    ),
  changeWasherCvStatusFailure: (payload: string) =>
    createAction(
      PendingWashersActionsTypes.CHANGE_WASHER_CV_STATUS_FAILURE,
      payload
    ),
};

export type PendingWashersActions = ActionsUnion<typeof PendingWashersActions>;

export type getPendingWashersType = ActionsOfType<
  PendingWashersActions,
  PendingWashersActionsTypes.GET_PENDING_WASHERS
>;
export type pendingWashersSearchValueType = ActionsOfType<
  PendingWashersActions,
  PendingWashersActionsTypes.PENDING_WASHERS_SEARCH_VALUE
>;
export type getActiveWasherCVType = ActionsOfType<
  PendingWashersActions,
  PendingWashersActionsTypes.GET_ACTIVE_WASHER_CV
>;
export type rejectActiveWasherCvType = ActionsOfType<
  PendingWashersActions,
  PendingWashersActionsTypes.REJECT_ACTIVE_WASHER_CV
>;
