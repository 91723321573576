import { ResultModel } from "./result.model";
import { Car } from "./car.model";
import { User } from "./user.model";
import { Package } from "./package.model";
import { Review } from "./review.model";
import { Attachment } from "./attachment.model";
import {
  IsDate,
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  ValidateIf,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { BasicModel } from "./basic.model";
import { Currency, ID } from "../utils.models";
import { Company } from "./company.model";

export enum OrderTimeFrame {
  FUTURE = "FUTURE",
  NON_FUTURE = "NON_FUTURE",
  ALL = "ALL",
}

export enum OrderStatus {
  OFFERED = "OFFERED",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  IN_PROGRESS = "IN_PROGRESS",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  WASHER_ARRIVING = "WASHER_ARRIVING",
  WASHER_ARRIVED = "WASHER_ARRIVED",
  PUBLIC_OFFER = "PUBLIC_OFFER",
  ALL_PACKAGES_COMPLETE = "ALL_PACKAGES_COMPLETE",
}

export enum OrderPaymentStatus {
  NEED_CUSTOMER_APPROVE = "NEED_CUSTOMER_APPROVE",
  UNPAID = "UNPAID",
  IN_PROGRESS = "IN_PROGRESS",
  PAID = "PAID",
}

export enum CompanyOrderPayoutStatus {
  UNPAID = "UNPAID",
  PAID = "PAID",
}

export class Order extends BasicModel {
  @IsDefined()
  @Type(() => Attachment)
  @ValidateNested()
  attachment: Attachment[];
  @IsDefined()
  @Type(() => Car)
  @ValidateNested()
  car: Car;
  @IsUUID()
  carId: string;
  @IsEnum(Currency)
  currency: Currency;
  @IsDefined()
  @Type(() => User)
  @ValidateNested()
  customer: User;
  @IsString()
  customerId: string;
  @ValidateIf((o) => o.status === OrderStatus.COMPLETED)
  @IsDate()
  @Type(() => Date)
  endTime: Date | null;
  @IsDefined()
  @Type(() => Package)
  @ValidateNested()
  packages: Package[];
  @IsOptional()
  @Type(() => Review)
  @ValidateNested()
  washerAboutCustomerReview: Review;
  @IsOptional()
  @Type(() => Review)
  @ValidateNested()
  customerAboutWasherReview: Review;
  @IsDate()
  @Type(() => Date)
  scheduledTime: Date;
  @ValidateIf(
    (o) =>
      o.status === OrderStatus.COMPLETED || o.status === OrderStatus.IN_PROGRESS
  )
  @IsDate()
  @Type(() => Date)
  startTime: Date | null;
  @IsEnum(OrderStatus)
  status: OrderStatus;
  @IsOptional()
  @Type(() => User)
  @ValidateNested()
  washer: User | null;
  @IsOptional()
  @IsString()
  washerId: string | null;
  @IsNumber()
  @Type(() => Number)
  orderPrice: number;
  @IsEnum(OrderPaymentStatus)
  paymentStatus: OrderPaymentStatus;
  @IsNumber()
  washerPayoutPrice: number;
  @IsOptional()
  @IsNumber()
  tipPayoutPrice?: number | null;
  @IsNumber()
  locationLat: number;
  @IsNumber()
  locationLong: number;
}

export class Orders {
  @IsDefined()
  @Type(() => Order)
  @ValidateNested()
  items: Order[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export class CompanyOrder extends BasicModel {
  @IsUUID()
  companyId: ID;
  @Type(() => Company)
  @ValidateNested()
  company: Company;
  @IsEnum(Currency)
  currency: Currency;
  @IsNumber()
  feeAmount: number;
  @IsNumber()
  feePercent: number;
  @IsUUID()
  orderId: ID;
  @Type(() => Order)
  @ValidateNested()
  order: Order;
  @IsEnum(CompanyOrderPayoutStatus)
  payoutStatus: CompanyOrderPayoutStatus;
  @IsString()
  washerId: ID;
  @Type(() => User)
  @ValidateNested()
  washer: User;
}

export class CompanyOrders {
  @IsDefined()
  @Type(() => CompanyOrder)
  @ValidateNested()
  items: CompanyOrder[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export type OrderResponse = ResultModel<Order>;
export type OrdersResponse = ResultModel<Orders>;
export type CompanyOrderResponse = ResultModel<CompanyOrder>;
export type CompanyOrdersResponse = ResultModel<CompanyOrders>;
