import React from "react";
import NavTabs from "../../../components/NavTabs";
import tabs from "./tabs";
import { RouteNames } from "../../../constants/routeNames";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import texts from "../../../constants/texts";
import { Grid } from "@material-ui/core";
import WasherPayoutsList from "./components/WasherPayoutsList";
import RequestPayout from "./RequestPayout";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const WasherPayouts: React.FC<Props> = () => {
  useTitle(`Washerly Payouts`);
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/${RouteNames.REQUEST_PAYOUT}`}>
        <RequestPayout />
      </Route>
      <Grid container spacing={2}>
        <NavTabs tabs={tabs}>
          <Switch>
            <Route exact path={`${match.path}/${RouteNames.REQUESTED}`}>
              <WasherPayoutsList
                title={texts.roles.admin.payouts.requested.title}
                subtitle={texts.roles.admin.payouts.requested.subtitle}
              />
            </Route>
            <Route exact path={`${match.path}/${RouteNames.COMPLETED}`}>
              <WasherPayoutsList
                title={texts.roles.admin.payouts.completed.title}
                subtitle={texts.roles.admin.payouts.completed.subtitle}
                isCompleted
              />
            </Route>
            <Redirect to={`${match.path}/${RouteNames.REQUESTED}`} />
          </Switch>
        </NavTabs>
      </Grid>
    </Switch>
  );
};

export default WasherPayouts;
