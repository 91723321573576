import { IsNumber, IsString, ValidateNested } from "class-validator";
import { Type } from "class-transformer";
import { ResultModel } from "./result.model";

export class MobileBuild {
  @IsString()
  buildId: string;
}

export class MobileBuilds {
  @Type(() => MobileBuild)
  @ValidateNested()
  items: MobileBuild[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export type MobileBuildResponse = ResultModel<MobileBuild>;
export type MobileBuildsResponse = ResultModel<MobileBuilds>;
