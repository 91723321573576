import React, { useCallback } from "react";
import OrdersList from "../../OrdersList";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import useActions from "../../../hooks/useActions";
import { OrdersActions } from "../../../store/actions/orders.actions";
import orderListHeadCells from "../../OrdersList/orderListHeadCells";
import TableLayout from "../../../shared/sharedTable/TableLayout";
import { TablePaginationParams } from "../../../models/table.models";
import { UserProfileRouterProps } from "../../../models/router.models";
import texts from "../../../constants/texts";
import { useParams } from "react-router-dom";

interface OwnProps {}

type Props = OwnProps;

const FutureOrders: React.FC<Props> = () => {
  const params = useParams<UserProfileRouterProps>();
  const ordersState = useSelector((state: AppState) => state.orders);
  const ordersActions = useActions<typeof OrdersActions>(OrdersActions);
  const { userId } = params;

  const handlePaginationChange = useCallback(
    (values: TablePaginationParams) => {
      ordersActions.getFutureOrdersForActiveUser({ ...values, userId });
    },
    [userId, ordersActions]
  );

  return (
    <>
      <TableLayout
        title={texts.futureOrders.headerTitle}
        subtitle={texts.futureOrders.headerSubtitle}
        total={+ordersState.pastOrdersForActiveUser.total}
        headCells={orderListHeadCells}
        onPaginationChange={handlePaginationChange}
        isLoading={ordersState.futureOrdersForActiveUserLoading}
        withoutSorting
      >
        <OrdersList data={ordersState.futureOrdersForActiveUser} />
      </TableLayout>
    </>
  );
};

export default FutureOrders;
