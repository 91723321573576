import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { unitSize } from "../../../../../constants/sizes";
import IdCell from "../../../../../shared/sharedTable/IdCell";
import {
  AdminPayoutsColumns,
  HeadCell,
} from "../../../../../models/table.models";
import FirstLayer from "../../../../../styled/FirstLayer";
import SecondLayer from "../../../../../styled/SecondLayer";
import BorderedValue from "../../../../../styled/BorderedValue";
import { StatusColors } from "../../../../../models/utils.models";
import InvoicesIcon from "../../../../../shared/icons/InvoicesIcon";
import texts from "../../../../../constants/texts";
import { PayoutStatus } from "../../../../../models/responses/payout.model";
import useActions from "../../../../../hooks/useActions";
import { AppActions } from "../../../../../store/actions/app.actions";
import { useStyles } from "./style";
import { attachmentApi } from "../../../../../api/attachment.api";
import { PayoutsActions } from "../../../../../store/actions/payouts.actions";

interface OwnProps {
  columns: HeadCell<AdminPayoutsColumns>[];
  row: {
    id: string;
    date: string;
    fullName: string;
    amount: string;
    status: PayoutStatus;
    invoiceFileId: string;
  };
}

type Props = OwnProps;

const AdminPayoutRow: React.FC<Props> = (props) => {
  const classes = useStyles();
  const appActions = useActions<typeof AppActions>(AppActions);
  const payoutsActions = useActions<typeof PayoutsActions>(PayoutsActions);
  const [invoiceURL, setInvoiceURL] = useState<string>();

  useEffect(() => {
    attachmentApi
      .getFile(props.row.invoiceFileId)
      .then((resp) => setInvoiceURL(resp.data.data?.url))
      .catch((e) => console.error(e));
  }, [props.row.invoiceFileId]);

  const handleAccept = () => {
    payoutsActions.acceptPayout({ payoutId: props.row.id });
  };
  const handleReject = (message: string) => {
    payoutsActions.rejectPayout({ payoutId: props.row.id, message });
  };
  const handleInvoiceClick = () => {
    if (invoiceURL) {
      window.open(invoiceURL);
    }
  };

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container>
          {props.columns.map(({ id, size }, index) => (
            <Grid key={id} item xs={size}>
              <Box
                pb={3}
                pt={2}
                pr={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  height={unitSize * 3}
                  className={classes.textContainer}
                >
                  {index > 0 && (
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                  )}
                  {id === "id" ? (
                    <IdCell id={props.row.id} />
                  ) : (
                    <Typography color="textPrimary" variant="body1" noWrap>
                      {props.row[id]}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
          <Grid item xs={5} className={classes.actionLayer}>
            <FirstLayer open openWidth="10%" />
            <SecondLayer>
              <Box pr={2} display="flex" alignItems="center">
                {props.row.status === PayoutStatus.REQUESTED && (
                  <>
                    <Box mr={2}>
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() =>
                          appActions.showConfirmationDialog({
                            withReason: true,
                            title: "Reject payout",
                            description: "Are you sure?",
                            open: true,
                            onSubmit: handleReject,
                          })
                        }
                      >
                        Reject
                      </Button>
                    </Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() =>
                        appActions.showConfirmationDialog({
                          withReason: false,
                          title: "Accept payout",
                          description: "Are you sure?",
                          open: true,
                          onSubmit: handleAccept,
                        })
                      }
                    >
                      Mark as paid
                    </Button>
                  </>
                )}
                {props.row.status === PayoutStatus.PAID && (
                  <BorderedValue color={StatusColors.GREEN}>
                    Payed Out
                  </BorderedValue>
                )}
                {props.row.status === PayoutStatus.REJECTED && (
                  <BorderedValue color={StatusColors.RED}>
                    Rejected
                  </BorderedValue>
                )}
                <IconButton
                  className={classes.icon}
                  onClick={handleInvoiceClick}
                >
                  <Box>
                    <InvoicesIcon />
                    <Typography color="inherit">
                      {texts.orders.invoice}
                    </Typography>
                  </Box>
                </IconButton>
              </Box>
            </SecondLayer>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default AdminPayoutRow;
