import React, { Fragment, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowLeftIcon from "../../../../../shared/icons/ArrowLeftIcon";
import CopyToClipboard from "../../../../../shared/CopyToClipboard";
import { useHistory, useParams } from "react-router-dom";
import { formatDate } from "../../../../../helpers/utils/date.utils";
import UserAvatarWithName from "../../../../../components/UserProfile/UserInfo/UserAvatarWithName";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../store/reducers";
import useActions from "../../../../../hooks/useActions";
import { PendingWashersActions } from "../../../../../store/actions/pendingWashers.actions";
import { RouteParams } from "../../../../../constants/routeNames";
import { getImageUrl } from "../../../../../helpers/utils/utils";
import texts from "../../../../../constants/texts";
import { unitSize } from "../../../../../constants/sizes";
import {
  experienceTexts,
  VerificationFormStatus,
} from "../../../../../models/requests/VerificationForm";
import MuiSwitch from "../../../../../shared/MuiSwitch";
import InputWithAdornment from "../../../../../shared/InputWithAdornment";
import MuiToggleSwitch from "../../../../../shared/MuiToggleSwitch";
import ImageThumbnails from "../../../../../shared/ImageThumbnails";
import { StatusColors } from "../../../../../models/utils.models";
import BorderedValue from "../../../../../styled/BorderedValue";
import { AppActions } from "../../../../../store/actions/app.actions";
import Loading from "../../../../../shared/Loading";
import { useStyles } from "./style";

interface OwnProps {}

type Props = OwnProps;

const PendingWasherApplication: React.FC<Props> = () => {
  const params = useParams<{ [RouteParams.USER_ID]: string }>();
  const classes = useStyles();
  const history = useHistory();
  const pendingWashersActions = useActions<typeof PendingWashersActions>(
    PendingWashersActions
  );
  const appActions = useActions<typeof AppActions>(AppActions);
  const pendingWashersState = useSelector(
    (state: AppState) => state.pendingWashers
  );
  const { activeWasherCV, changeActiveCvStatusLoading } = pendingWashersState;

  const handleRejectDialogSubmit = (message: string) => {
    pendingWashersActions.rejectActiveWasherCv(message);
  };
  useEffect(() => {
    pendingWashersActions.getActiveWasherCV(params[RouteParams.USER_ID]);
  }, [params, pendingWashersActions]);
  const handleGoBack = () => {
    history.goBack();
  };
  if (activeWasherCV) {
    const [date, time] = formatDate(new Date(activeWasherCV.createdTime));
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.toolBar}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              height={1}
            >
              <Box display="flex" alignItems="center">
                <IconButton onClick={handleGoBack}>
                  <ArrowLeftIcon />
                </IconButton>
                <Box ml={2} textAlign="left">
                  <CopyToClipboard>
                    {({ copy }) => (
                      <Typography
                        variant="h4"
                        className={classes.orderId}
                        style={{ cursor: "pointer" }}
                        onClick={() => copy(activeWasherCV.id)}
                      >
                        Application #{activeWasherCV.id}
                      </Typography>
                    )}
                  </CopyToClipboard>
                  <Typography variant="body1" color="textSecondary">
                    {date} {time}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <UserAvatarWithName
                avatarSrc={
                  activeWasherCV.user.avatar &&
                  getImageUrl(activeWasherCV.user.avatar.id)
                }
                fullName={`${activeWasherCV.user.firstName} ${activeWasherCV.user.secondName}`}
                email={activeWasherCV.user.email || undefined}
              />
              {activeWasherCV.status === VerificationFormStatus.PENDING ? (
                <Box width={unitSize * 26}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      appActions.showConfirmationDialog({
                        withReason: false,
                        title: "Approve Washer Application",
                        description: "You are going to approve this washer",
                        open: true,
                        onSubmit: pendingWashersActions.approveActiveWasherCv,
                      })
                    }
                  >
                    {
                      texts.roles.admin.pendingWashers.application.buttons
                        .approve
                    }
                  </Button>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() =>
                      appActions.showConfirmationDialog({
                        title: "Form rejection",
                        description:
                          "Please confirm and specify reason of rejection",
                        open: true,
                        onSubmit: (message) =>
                          handleRejectDialogSubmit(message!),
                        withReason: true,
                      })
                    }
                    classes={{
                      root: classes.redButton,
                    }}
                  >
                    {
                      texts.roles.admin.pendingWashers.application.buttons
                        .reject
                    }
                  </Button>
                  {changeActiveCvStatusLoading && <Loading />}
                </Box>
              ) : (
                <BorderedValue
                  color={
                    activeWasherCV.status === VerificationFormStatus.APPROVE
                      ? StatusColors.GREEN
                      : StatusColors.RED
                  }
                >
                  {activeWasherCV.status === VerificationFormStatus.APPROVE
                    ? "Approved"
                    : "Rejected"}
                </BorderedValue>
              )}
            </Box>
            <Box
              ml={11}
              mt={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.value} variant="h4">
                    {activeWasherCV.country}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {texts.roles.admin.pendingWashers.application.country}
                  </Typography>
                </Box>
                <Divider className={classes.divider} orientation="vertical" />
                <Box>
                  <Typography className={classes.value} variant="h4">
                    {activeWasherCV.address}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {texts.roles.admin.pendingWashers.application.address}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.value} variant="h4">
                    {activeWasherCV.postalCode}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {texts.roles.admin.pendingWashers.application.postalCode}
                  </Typography>
                </Box>
                <Divider className={classes.divider} orientation="vertical" />
                <Box>
                  <Typography className={classes.value} variant="h4">
                    {activeWasherCV.user.phoneNumber}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {texts.roles.admin.pendingWashers.application.phoneNumber}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {activeWasherCV.questions.map((question, index, arr) => (
              <Fragment key={question.id}>
                <Box
                  display="flex"
                  width={0.5}
                  justifyContent="space-between"
                  mt={2}
                >
                  <Typography variant="subtitle1" color="primary">
                    {question.name}
                  </Typography>
                  <MuiSwitch disabled checked={question.value} />
                </Box>
                {index < arr.length - 1 && (
                  <Divider className={classes.questionDivider} />
                )}
              </Fragment>
            ))}
            <Box
              mt={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MuiToggleSwitch
                leftText="Individual"
                rightText="Company"
                checked={!!activeWasherCV.user.companyId}
                disabled
              />
            </Box>
            <Box mt={2}>
              {!!activeWasherCV.user.company && (
                <InputWithAdornment
                  fullWidth={false}
                  adornment="Company name"
                  value={activeWasherCV.user.company.name}
                  disabled
                />
              )}
            </Box>
            <Box mt={2} display="flex">
              <Typography>
                Experience: {experienceTexts[activeWasherCV.experience]}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {!activeWasherCV.photos.length ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant={"h1"} align="center">
                  No attachments...
                </Typography>
              </Box>
            ) : (
              <ImageThumbnails
                attachment={activeWasherCV.photos.map((item) => item.fileId)}
                size={35}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  } else return null;
};

export default PendingWasherApplication;
