import { CarResponse, CarsResponse } from "../models/responses/car.model";
import { CarsRequestParams } from "../models/requests/Cars";
import { ID } from "../models/utils.models";
import { axiosInstance } from "./base.api";

export const carApi = {
  getCarsByUserId(params: CarsRequestParams) {
    const config = {
      params,
    };
    return axiosInstance.get<CarsResponse>(`/private/cars`, config);
  },

  getCarById(id: ID) {
    return axiosInstance.get<CarResponse>(`/private/cars/${id}`);
  },
};
