export enum ErrorMessages {
  ERROR_CAR_LICENSE_PLATE_IS_EXIST = "A car with provided licence plate already exists",
  ERROR_DB_FIND_CAR_BY_LICENSE_PLATE_ISSUE = "Can't find car with provided licence plate",
  ERROR_VALIDATION_ISSUE = "Can't validate action",
  ERROR_EXCESS_FIELD = "Trying to send excess data",
  ERROR_FORBIDDEN_ENDPOINT = "Action forbidden",
  ERROR_EMPTY_PHONE_NUMBER_FIELD = "Please insert your phone number",
  ERROR_EXCEEDED_SMS_MAXIMUM = "You have reached maximum number of validation SMS requests",
  ERROR_EXCEEDED_EMAIL_MAXIMUM = "You have reached maximum number of validation email requests",
  ERROR_INVALID_PHONE_NUMBER = "Please provide a valid phone number",
  ERROR_INVALID_EMAIL = "Please provide a valid email",
  ERROR_EMPTY_EMAIL = "Please provide an email",
  ERROR_INVALID_PASSWORD = "Provided password is invalid",
  ERROR_EMPTY_PASSWORD = "Please provide a password with at least 8 characters",
  ERROR_NO_SUCH_USER = "User doesn't exist",
  ERROR_INVALID_WASHER_FIELD = "Wrong property provided for washer",
  ERROR_INVALID_ROLE_FIELD = "Such user role doesn't exist",
  ERROR_AUTH_ISSUE_NOT_ATOMIC_USER_DATA = "Provided data is in wrong format",
  ERROR_EMPTY_AUTHORIZATION_CODE = "Please provide a SMS authorization code",
  ERROR_AUTHORIZATION_CODE_INVALID = "Wrong SMS authorization code",
  ERROR_AUTHORIZATION_CODE_INVALID_LENGTH_SMALL = "Provided SMS authorization code is too short",
  ERROR_AUTHORIZATION_CODE_INVALID_LENGTH_LARGE = "Provided SMS authorization code is too long",
  ERROR_EMAIL_VERIFICATION_CODE_INVALID_LENGTH_SMALL = "Provided email authorization code is too short",
  ERROR_EMAIL_VERIFICATION_CODE_INVALID_LENGTH_LARGE = "Provided email authorization code is too long",
  ERROR_EMPTY_EMAIL_VERIFICATION_CODE = "Please provide an email authorization code",
  ERROR_EMAIL_VERIFICATION_CODE_INVALID = "Wrong email authorization code",
  ERROR_EMAIL_VERIFICATION_CODE_NOT_FOUND = "Given email authorization code doesn't exist",
  ERROR_DIFFERENT_EMAIL_VERIFICATION_CODE = "Given email authorization code has expired",
  ERROR_AUTHORIZATION_CODE_NOT_FOUND = "Given email authorization code doesn't exist",
  ERROR_DIFFERENT_AUTHORIZATION_CODE = "Given SMS authorization code has expired",
  ERROR_DB_CONNECTION_ISSUE = "Server connection issue",
  ERROR_DB_CAN_NOT_SET_END_TIME = "Cannot set end time on order",
  ERROR_AUTHENTICATION_INVALID_TOKEN = "Your session has expired, please login again",
  ERROR_AUTHENTICATION_EMPTY_TOKEN = "Your session cannot be found, please login again",
  ERROR_AUTHENTICATION_INVALID_TOKEN_UNKNOWN_USER = "This user cannot be found, please login again",
  ERROR_SMS_SENDING_ISSUE = "Validation SMS cannot be sent",
  ERROR_TOKEN_PAYLOAD_ISSUE = "Session information not given for this action",
  ERROR_INVALID_USER_FIRST_NAME = "Please provide a valid first name",
  ERROR_EMPTY_USER_FIRST_NAME = "Please provide a first name",
  ERROR_INVALID_USER_SECOND_NAME = "Please provide a valid second name",
  ERROR_INVALID_USER_PUSH_TOKEN = "Provided push notification credentials are invalid",
  ERROR_INVALID_USER_ONLINE_STATUS = "Provided user online status doesn't exist",
  ERROR_EMPTY_CAR_MODEL = "Car doesn't exist",
  ERROR_INVALID_CAR_MODEL = "Provided car information is wrong",
  ERROR_LICENSE_PLATE_EXISTS = "A car with given licence plate already exists",
  ERROR_EMPTY_CAR_TYPE = "Please provide a car type",
  ERROR_EMPTY_LICENSE_PLATE = "Please provide a licence plate",
  ERROR_INVALID_CAR_TYPE = "Provided car type doesn't exist",
  ERROR_INVALID_LICENSE_PLATE = "Please provide a valid licence plate",
  ERROR_EMPTY_CAR_ID = "Car identifier is empty",
  ERROR_INVALID_CAR_ID = "Such car doesn't exist",
  ERROR_INVALID_USER_EMAIL = "Please provide a valid user email",
  ERROR_USER_EMAIL_EXIST = "A user with provided email already exists",
  ERROR_INVALID_USER_PASSWORD = "Please provide a valid password",
  ERROR_INVALID_USER_ID = "Such user doesn't exist",
  ERROR_EMPTY_USER_ID_FIELD = "User cannot be identified",
  ERROR_INVALID_PACKAGE_ID = "Package cannot be identified",
  ERROR_EMPTY_PACKAGE_NAME = "Please provide a package name",
  ERROR_EMPTY_PACKAGE_TIME = "Please provide a package duration",
  ERROR_INVALID_PACKAGE_NAME = "Please provide a valid package name",
  ERROR_INVALID_PACKAGE_PRICE = "Please provide a valid package price",
  ERROR_INVALID_PACKAGE_TIME = "Please provide a valid package duration",
  ERROR_INVALID_PACKAGE_EXTRA_DIRTY = "Please provide a valid extra dirty status",
  ERROR_EMPTY_PACKAGE_INDEX_FIELD = "Please provide a package index",
  ERROR_INVALID_PACKAGE_INDEX_FIELD = "Please provide a valid package index",
  ERROR_EMPTY_PACKAGE_ICON_FILE_ID = "Please provide a package icon",
  ERROR_EMPTY_PACKAGE_ID = "Package cannot be identified",
  ERROR_INVALID_USER_LATITUDE = "Provided user latitude is in wrong format",
  ERROR_INVALID_WASHER_RADIUS = "Provided user radius is in wrong format",
  ERROR_LESS_USER_LATITUDE = "Provided latitude is too small",
  ERROR_LARGE_USER_LATITUDE = "Provided latitude is too large",
  ERROR_INVALID_USER_LONGITUDE = "Provided longitude is invalid",
  ERROR_LESS_USER_LONGITUDE = "Provided longitude is too small",
  ERROR_LARGE_USER_LONGITUDE = "Provided longitude is too large",
  ERROR_EMPTY_USER_LATITUDE = "Must provide latitude with location",
  ERROR_EMPTY_USER_LONGITUDE = "Must provide longitude with location",
  ERROR_EMPTY_AUTH_HEADER = "No login credentials are provided with this action",
  ERROR_INVALID_FORMAT_AUTH_HEADER = "Login credentials provided for this action are in wrong format",
  ERROR_INVALID_AUTH_TOKEN = "User information provided for this action is in wrong format",
  ERROR_INVALID_USER_LOCATION = "Provided location is in wrong format",
  ERROR_CAN_NOT_HAVE_RADIUS = "This user is not allowed to set radius",
  ERROR_EMPTY_SMS_TEMPLATE = "SMS content is empty",
  ERROR_INVALID_SMS_TEMPLATE = "SMS content is invalid",
  ERROR_CLOUD_STORAGE_ISSUE = "Server instance issue",
  ERROR_INVALID_CAR_OWNER = "Car owner is invalid",
  ERROR_INVALID_ORDER_ID = "Order cannot be identified",
  ERROR_INVALID_ORDER_TIME = "Provided order time is invalid",
  ERROR_INVALID_ORDER_PACKAGE_ID = "Order contains invalid package",
  ERROR_CLOSED_ORDER_PACKAGE = "This package has been finished already",
  ERROR_ORDER_PACKAGE_ALREADY_STARTED = "This package has been started already",
  ERROR_EMPTY_ORDER_ID = "Order cannot be identified",
  ERROR_EMPTY_COMMUNICATION_RATING = "Please provide communication rating",
  ERROR_INVALID_COMMUNICATION_RATING = "Provided communication rating is invalid",
  ERROR_EMPTY_WASH_TIME_RATING = "Please provide wash druration",
  ERROR_INVALID_WASH_TIME_RATING = "Provided wash duration is invalid",
  ERROR_EMPTY_QUALITY_RATING = "Please provide quality rating",
  ERROR_INVALID_QUALITY_RATING = "Provided quality rating is invalid",
  ERROR_INVALID_FEED_MESSAGE = "Provided message is invalid",
  ERROR_INVALID_USER_SEARCH_DISTANCE = "Provided user search radius is invalid",
  ERROR_INVALID_FILE_ID = "File doesn't exist",
  ERROR_EMPTY_FILE_ID = "Must provide file identifier",
  ERROR_INVALID_PERMISSION_FOR_DELETE_FILE = "You don't have permission to delete this file",
  ERROR_INVALID_FILE_PERMISSION = "Provided file permission doesn't exist",
  ERROR_UNKNOWN_PERMISSION_FOR_ENDPOINT = "Unknown permission for action",
  ERROR_DUPLICATE_FILE = "File with provided identifier already exists",
  ERROR_UNSUPPORTED_EXTRA_DIRTY_FOR_PACKAGE = "This package cannot have extra dirty flag set",
  ERROR_EMPTY_PACKAGE_TITLE = "Please provide package title",
  ERROR_INVALID_PACKAGE_TITLE = "Provided package title is invalid",
  ERROR_ORDER_NOT_AVAILABLE = "This order was canceled or accepted by someone else",
  ERROR_UNKNOWN_DISPATCH_ACTION = "Given dispatch action doesn't exist",
  ERROR_GET_USER_INFO = "Can't access user information",
  ERROR_INVALID_ACTION_PAYLOAD = "Provided properties for this action are invalid",
  ERROR_INVALID_ORDER_WASHER_REVIEW = "Provided washer review is invalid",
  ERROR_EMPTY_ACTION_PAYLOAD = "Please provide necessary properties for this dispatch action",
  ERROR_ORDER_PACKAGE_NOT_AVAILABLE = "This package is not available anymore",
  ERROR_HAS_NOT_COMPLETE_PACKAGE = "Previous package is not complete",
  ERROR_INVALID_ORDER_PACKAGE_CLOSED_TIME = "Provided package end time is invalid",
  ERROR_EMPTY_DISPATCH_ACTION_TYPE = "Please provide dispatch action type",
  ERROR_INVALID_DISPATCH_ACTION_TYPE = "Such dispatch action doesn't exist",
  ERROR_INVALID_ORDER_PACKAGE_TIME = "Provided package duration is invalid ",
  ERROR_DB_FIND_CAR_ISSUE = "Car cannot be found",
  ERROR_DB_FIND_CARS_ISSUE = "Cars cannot be found",
  ERROR_DB_SAVE_CAR_ISSUE = "Car cannot be added",
  ERROR_DB_DELETE_CAR_ISSUE = "Car cannot be deleted",
  ERROR_DB_FIND_BLUEPRINT_PACKAGES_ISSUE = "Can't find packages",
  ERROR_DB_FIND_BLUEPRINT_PACKAGE_ISSUE = "Can't find package",
  ERROR_DB_SAVE_BLUEPRINT_PACKAGE_ISSUE = "Can't add package",
  ERROR_DB_SAVE_BLUEPRINT_PACKAGE_EQUIPMENT_ISSUE = "Can't save package because of equipment",
  ERROR_DB_SAVE_ORDER_PACKAGE_EQUIPMENT_ISSUE = "Can't save order because of equipment",
  ERROR_DB_DELETE_BLUEPRINT_PACKAGE_ISSUE = "Can't delete package",
  ERROR_DB_SAVE_CAR_FILE_ISSUE = "Can't save car file",
  ERROR_DB_FIND_CAR_FILES_ISSUE = "Can't find car files",
  ERROR_DB_FIND_CAR_FILE_ISSUE = "Can't find car file",
  ERROR_DB_DELETE_CAR_FILE_ISSUE = "Can't delete car file",
  ERROR_DB_SAVE_FILE_ISSUE = "Can't save file",
  ERROR_DB_FIND_FILE_ISSUE = "Can't find file",
  ERROR_DB_FIND_THUMBNAIL_ISSUE = "Can't find thumbnail",
  ERROR_DB_SAVE_THUMBNAIL_ISSUE = "Can't save thumbnail",
  ERROR_INVALID_THUMBNAIL_SIZE = "Provided thumbnail size is invalid",
  ERROR_DB_DELETE_FILE = "Can't delete file",
  ERROR_DB_FIND_ORDER_ISSUE = "Order cannot be found",
  ERROR_DB_FIND_ORDERS_ISSUE = "Orders cannot be found",
  ERROR_DB_FIND_AVAILABLE_ORDERS_ISSUE = "Can't find available orders",
  ERROR_DB_FIND_DECLINED_ORDERS_ISSUE = "Can't access declined orders",
  ERROR_DB_FIND_ORDER_PACKAGES_ISSUE = "Can't find packages for order",
  ERROR_DB_FIND_ORDER_PACKAGE_ISSUE = "Can't find package for order",
  ERROR_DB_UPDATE_ORDER_STATUS_ISSUE = "Can't update order status",
  ERROR_DB_DELETE_ORDER_ISSUE = "Can't delete order",
  ERROR_DB_GET_ORDER_STATUS_ISSUE = "Can't detect order status",
  ERROR_DB_SAVE_REVIEW_ISSUE = "Can't save review",
  ERROR_DB_SAVE_ORDER = "Can't save order",
  ERROR_DB_FIND_REVIEW_ISSUE = "Can't find review",
  ERROR_DB_UPDATE_REVIEW_RATING_ISSUE = "Can't change review rating",
  ERROR_DB_GET_USER_REVIEWS_ISSUE = "Can't find reviews for this user",
  ERROR_DB_GET_ORDER_REVIEWS_ISSUE = "Can't find reviews for this order",
  ERROR_DB_SAVE_USER_ISSUE = "Can't save user information",
  ERROR_DB_START_USER_SESSION = "Can't start user session",
  ERROR_DB_UPDATE_USER_SESSION = "Can't update user session",
  ERROR_DB_END_USER_SESSION = "Can't end user session",
  ERROR_DB_MODIFY_USER_ISSUE = "Can't update user information",
  ERROR_DB_FIND_USER_ISSUE = "Can't find user",
  ERROR_DB_FIND_APPROVED_USERS_ISSUE = "Can't find approved users",
  ERROR_DB_CAN_NOT_CHANGE_USER_ROLE_ISSUE = "Can't change role for this user",
  ERROR_DB_USER_SEARCH_ISSUE = "Can't search for users",
  ERROR_DB_SAVE_USER_FILE_ISSUE = "User file cannot be saved",
  ERROR_DB_FIND_USER_FILES_ISSUE = "User files cannot be found",
  ERROR_DB_FIND_USER_FILE_ISSUE = "User file cannot be found",
  ERROR_DB_DELETE_USER_FILE_ISSUE = "User file cannot be deleted",
  ERROR_EMPTY_FILE_TAG_FIELD = "User file identifier is empty",
  ERROR_INVALID_IMAGE_CROP_SIZE = "Provided image crop size is invalid",
  ERROR_INVALID_IMAGE_TYPE = "Provided image is in wrong format",
  ERROR_IMAGE_COPIES_INVALID_JSON_FORMAT = "Copied image is in wrong format",
  ERROR_IMAGE_CAN_NOT_CROP = "Provided image cannot be cropped",
  ERROR_INVALID_IMAGE_COPIES_INFO = "Image cannot be copied",
  ERROR_PAGINATION_INVALID_PAGE_NUMBER = "Invalid pagination number provided",
  ERROR_PAGINATION_INVALID_PAGE_SIZE = "Invalid page size provided for pagination",
  ERROR_PAGINATION_INVALID_SORT_TYPE = "Invalid sort type provided for pagination",
  ERROR_PAGINATION_INVALID_SORT_BY_PARAM = "Invalid sort by type provided for pagination",
  ERROR_WS_UNKNOWN_ISSUE = "Unknown issue with socket",
  ERROR_WS_EMPTY_HEADERS_TOKEN = "Socket doesn't contain correct header",
  ERROR_WS_EMPTY_PARAMS = "Empty socket parameters provided",
  ERROR_WS_EMPTY_BODY = "Empty body provided with socket",
  ERROR_WS_EMPTY_ORDER_ID_FIELD = "No order identifier provided with socket",
  ERROR_WS_INVALID_ORDER_ID_FIELD = "Provided order identifier is invalid",
  ERROR_WS_EMPTY_QUERY = "Please provide a query for socket",
  ERROR_WS_EMPTY_USER_ID = "No user identifier is provided for socket",
  ERROR_EMPTY_WASHER_ID = "Washer cannot be identified",
  ERROR_INVALID_WASHER_ID = "Washer identifier is invalid",
  ERROR_EMPTY_TIME_FIELD = "Please provide time",
  ERROR_EMPTY_EXTRA_DIRTY_FLAG = "Please provide extra dirty flag",
  ERROR_INVALID_EXTRA_DIRTY_FLAG = "Extra dirty flag status is invalid",
  ERROR_EMPTY_CURRENCY_FIELD = "Please provide currency",
  ERROR_EMPTY_USER_ROLE = "Please provide user role",
  ERROR_SEND_EMAIL_ISSUE = "Email cannot be sent",
  ERROR_DB_FIND_USERS_ISSUE = "Can't find users",
  ERROR_DB_GET_APP_STATISTICS = "Can't get user logs",
  ERROR_INVALID_ORDER_PRICE = "Provided order price is invalid",
  ERROR_INVALID_TIP_AMOUNT = "Provided tip amount is invalid",
  ERROR_EMPTY_ORDER_PRICE = "Please provide order price",
  ERROR_INVALID_ORDER_CURRENCY = "Provided order currency is invalid",
  ERROR_EMPTY_ORDER_CURRENCY = "Please provide order currency",
  ERROR_INVALID_ORDER_ATTACHMENT = "Provided order attachment is invalid",
  ERROR_INVALID_REVIEW_FILE = "Review contains invalid file",
  ERROR_INVALID_ORDER_PAYLOAD = "Order properties are invalid",
  ERROR_ORDER_TIP_EXISTS = "This tip was already set",
  ERROR_DB_SET_ORDER_START_TIME = "Invalid order start time",
  ERROR_INVALID_ORDER_LATITUDE = "Invalid order latitude",
  ERROR_INVALID_ORDER_LONGITUDE = "Invalid order longiture",
  ERROR_DB_SAVE_ORDER_LOCATION_ISSUE = "Can't save order location",
  ERROR_DB_ON_UPDATE_ORDER_STATUS = "Order status cannot be changed",
  ERROR_DB_PAY_TIP = "Order tip cannot be paid",
  ERROR_DB_SAVE_ORDER_ATTACHMENT_ISSUE = "Order attachment cannot be saved",
  ERROR_EMPTY_ORDER_LATITUDE = "Please provide order latitude",
  ERROR_EMPTY_ORDER_LONGITUDE = "Please provide order longitude",
  ERROR_EMPTY_RADIUS_FIELD = "Please provide order radius",
  ERROR_INVALID_SEARCH_PARAM = "Provided search parameter is invalid",
  ERROR_INVALID_ORDER_TIME_FRAME = "Order cannot be created in this time frame",
  ERROR_INVALID_ORDER_STATUS_FILTER_FIELD = "Orders cannot be filtered by provided status",
  ERROR_INVALID_ORDER_PAYMENT_STATUS_FILTER = "Order payments cannot be filtered by provided payment status",
  ERROR_INVALID_ORDER_PAYOUT_STATUS_FILTER = "Order payout cannot be filtered by provided payout status",
  ERROR_INVALID_PAYOUT_STATUS_FILTER = "Orders cannot be filtered by provided payout status",
  ERROR_INVALID_REVIEW_TYPE = "Provided review type is invalid",
  ERROR_DB_DELCINE_ORDER_FOR_WASHER = "Order cannot be declined",
  ERROR_DECLINED_ORDER_EXISTS = "You already declined this order",
  ERROR_INVALID_ORDER_CUSTOMER_REVIEW = "Provided customer review is invalid",
  ERROR_USER_EXISTS = "Such user already exists",
  ERROR_PAYMENT_CAN_NOT_CREATE_SUBSCRIBE = "Payment subscription cannot be made",
  ERROR_PAYMENT_CAN_NOT_CREATE_TRANSACTION = "Payment cannot be made",
  ERROR_PAYMENT_CAN_NOT_GET_TRANSACTION = "Payment cannot be accessed",
  ERROR_DB_FIND_USER_PAYMENT_CARDS = "This user doesn't have a payment method",
  ERROR_DB_FIND_PAYMENT_CARD = "Payment method cannot be found",
  ERROR_INVALID_PAYMENT_CARD_ID = "Payment method cannot be identified",
  ERROR_EMPTY_PAYMENT_CARD_ID = "Payment method doesn't contain card information",
  ERROR_DB_DELETE_PAYMENT_CARD = "This card cannot be deleted",
  ERROR_DB_FIND_PAYMENT_CARD_HISTORY = "Can't access payment history for this card",
  ERROR_EMPTY_ORDER_PACKAGE_ID = "Package for this order cannot be identified",
  ERROR_EMPTY_CURRENCY = "Please provide currency",
  ERROR_INVALID_CURRENCY = "Provided currency is invalid",
  ERROR_EMPTY_FIRST_NAME_FIELD = "Please provide first name",
  ERROR_INVALID_FIRST_NAME_FIELD = "Provided first name is invalid",
  ERROR_EMPTY_SECOND_NAME_FIELD = "Please provide your surname",
  ERROR_INVALID_SECOND_NAME_FIELD = "Provided surname is invalid",
  ERROR_EMPTY_PAYMENT_CARD_EXPIRATION_YEAR = "Please provide a card expiration year",
  ERROR_INVALID_PAYMENT_CARD_EXPIRATION_YEAR = "Provided card expiration year is invalid",
  ERROR_EMPTY_PAYMENT_CARD_SECURITY_CODE = "Please provide a card security code",
  ERROR_INVALID_PAYMENT_CARD_SECURITY_CODE = "Provided card security code is invalid",
  ERROR_EMPTY_PAYMENT_CARD_EXPIRATION_MONTH = "Please provide a card expiration month",
  ERROR_INVALID_PAYMENT_CARD_EXPIRATION_MONTH = "Provided card expiration month is invalid",
  ERROR_EMPTY_PAYMENT_CARD_NUMBER = "Please provide a card number",
  ERROR_INVALID_PAYMENT_CARD_NUMBER = "Provided card number is invalid",
  ERROR_EMPTY_PAYMENT_CARD_NAME = "Please provide card holders name",
  ERROR_INVALID_PAYMENT_CARD_NAME = "Provided card holders name is invalid",
  ERROR_DB_ON_UPDATE_ORDER_PAYMENT_STATUS = "Oder payment cannot be updated",
  ERROR_PAYMENT_UNSUPPORTED_OPERATION = "This user hasn't added a payment method yet",
  ERROR_PAYMENT_CREATE_SETUP_INTENT_ISSUE = "Payment method cannot be added",
  ERROR_ISSUE_ON_GET_LIST_OF_PAYMENT_CLIENT_SECRET = "User payment information cannot be accessed",
  ERROR_PAYMENT_ATTACH_PAYMENT_METHOD_TO_USER = "Payment method cannot be attached to user",
  ERROR_PAYMENT_ISSUE_ON_SET_CUSTOMER_PAYMENT_AS_DEFAULT = "This payment method cannot be set as default",
  ERROR_PAYMENT_ISSUE_ON_GET_STRIPE_USER_INFO = "Payment method user information cannot be found",
  ERROR_PAYMENT_ISSUE_ON_DELETE_STRIPE_PAYMENT_METHOD = "Payment method cannot be deleted",
  ERROR_PAYMENT_ISSUE_ON_GET_STRIPE_USER_SETUP_INTENT_LIST = "Can' return payment methods",
  ERROR_PAYMENT_ISSUE_ON_GET_STRIPE_USER_SETUP_INTENT = "Can' return payment method",
  ERROR_PAYMENT_ISSUE_ON_GET_STRIPE_USER_PAYMENT_METHOD_LIST = "Can' return payment methods",
  ERROR_PAYMENT_ISSUE_ON_PAY = "Payment failed",
  ERROR_ISSUE_ON_FIND_USER_WITH_PAYMENT_INFO = "Can't find user with payment info",
  ERROR_DB_ISSUE_ON_GET_ORDER_PAYMENT_INTENT = "Can't find payment intent",
  ERROR_PAYMENT_INVALID_SIGNATURE = "Invalid signature for payment",
  ERROR_PAYMENT_CHECK_WEBHOOK_SIGNATURE = "Payment check failed",
  ERROR_DB_SAVE_COMPANY_ISSUE = "Company cannot be saved",
  ERROR_DB_GET_COMPANY_ISSUE = "Such company does not exist",
  ERROR_INVALID_QUERY_PARAMS_LIST = "Invalid parameters provided for query",
  ERROR_COMPANY_FOR_ADMIN_EXISTS = "Such company already exists",
  ERROR_EMPTY_COMPANY_NAME = "Please provide a company name",
  ERROR_INVALID_COMPANY_NAME = "Provided company name is not valid",
  ERROR_EMPTY_COMPANY_COUNTRY = "Please provide a country of origin for this company",
  ERROR_INVALID_COMPANY_COUNTRY = "Provided country doesn't exist",
  ERROR_EMPTY_COMPANY_POSTAL_CODE = "Please provide postal code for company",
  ERROR_INVALID_COMPANY_POSTAL_CODE = "Provided postal code doesn't exist",
  ERROR_EMPTY_COMPANY_ADDRESS = "Please provide company address",
  ERROR_INVALID_COMPANY_ADDRESS = "Provided company address is invalid",
  ERROR_INVALID_COMPANY_ID = "Company cannot be identified",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_COUNTRY = "Provided country doesn't exist",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_COUNTRY = "Please provide your country of origin",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_POSTAL_CODE = "Provided postal code doesn't exist",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_POSTAL_CODE = "Please provide a postal code",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_ADDRESS = "Provided address is invalid",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_ADDRESS = "Please provide your address",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_CARD_NUMBER = "Provided card number is invalid",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_CARD_NUMBER = "Please provide a card number",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_EXPERIENCE = "Provided expierence properties are invalid",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_EXPERIENCE = "Please fill in your previous experience",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_QUESTION = "The provided answer to verification question is invalid",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_QUESTION = "Please answer the verification question",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_CLEAN_PHOTO = "Provided photo is invalid",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_CLEAN_PHOTO = "Please provide a photo of you",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_INSURANCE_PHOTO = "Provided insurance photo is invalid",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_INSURANCE_PHOTO = "Please provide an insurance photo",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_DRIVER_LICENSE_PHOTO = "Provided drivers photo is invalid",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_DRIVER_LICENSE_PHOTO = "Please provide a drivers photo",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_COMPANY_ID = "Provided company information is invalid",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_QUESTION_NAME = "Please provide a question name",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_QUESTION_NAME = "Provided question name is invalid",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_QUESTION_VALUE = "Please provide an answer to your question",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_QUESTION_VALUE = "Your provided answer is invalid",
  ERROR_DB_CAN_NOT_SAVE_VERIFICATION_FORM_QUESTION_ISSUE = "Verification form cannot be saved because of invalid question",
  ERROR_DB_CAN_NOT_SAVE_VERIFICATION_FORM_FILE_ISSUE = "Verification form cannot be saved because of invalid file",
  ERROR_DB_CAN_NOT_SAVE_VERIFICATION_FORM_ISSUE = "Verification form cannot be saved",
  ERROR_DB_CAN_NOT_GET_VERIFICATION_FORM_ISSUE = "Verification form cannot be found",
  ERROR_INVALID_VERIFICATION_FORM_ID = "Verification form cannot be identified",
  ERROR_DB_CAN_NOT_GET_VERIFICATION_FORM_BY_USER_ID_ISSUE = "User for this verification form cannot be found",
  ERROR_VERIFICATION_FORM_FOR_THIS_USER_EXIST = "Cannot finish verification form, such user already exists",
  ERROR_DB_CAN_NOT_REVIEW_VERIFICATION_FORM_ISSUE = "Verification form cannot be reviewed",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_STATUS = "Invalid verification form status",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_STATUS = "Please provide a verification form status",
  ERROR_INVALID_WASHER_VERIFICATION_FORM_MESSAGE = "Please provide a valid verification form message",
  ERROR_EMPTY_WASHER_VERIFICATION_FORM_MESSAGE = "Please provide a verification form message",
  ERROR_TRANSACTION_MANAGER_UNDEFINED = "Manager is undefined",
  ERROR_ISSUE_ON_SAVE_CLIENT_SECRET = "Client credentials cannot be saved",
  ERROR_DB_ISSUE_ON_UPDATE_ORDER_PAYMENT_STATUS = "Order payment cannot be updated",
  ERROR_DB_ISSUE_ON_DELETE_ORDER_TIP_INFO = "Order tip cannot be deleted because of info issue",
  ERROR_INVALID_COMPANY_EMPLOYEE_PARAM = "Provided company employee is invalid",
  ERROR_INVALID_STATUS_PARAM = "Provided status is invalid",
  ERROR_PAYMENT_UNSUPPORTED_OPERATION_FOR_CUSTOMER = "Customer has not set up a payment method",
  ERROR_PAYMENT_REFUND_MONEY_ISSUE = "Failed to refund payment, insufficient funds",
  ERROR_PAYMENT_UNSUPPORTED_OPERATION_FOR_ORDER = "This order doesn't have a payment method assigned",
  ERROR_PAYMENT_REFUND_ISSUE = "Failed to refund payment",
  ERROR_DB_GET_WASHER_STATISTICS_ISSUE = "Can't return washer statistics",
  ERROR_WASHER_VERIFY_FORM_REVIEWED = "Washer form cannot be verified",
  ERROR_INVALID_ORDER_IDS = "Provided order identifications are invalid",
  ERROR_EMPTY_ORDER_IDS_FIELD = "Provided order identifier is invalid",
  ERROR_INVALID_CARD_NUMBER = "Provided card number is invalid",
  ERROR_EMPTY_CARD_NUMBER = "Please provide a card number",
  ERROR_INVALID_WASHER_PAYOUT_ID = "Provided washer payout is invalid",
  ERROR_EMPTY_WASHER_PAYOUT_ID = "Please provide an identifier for washer payout",
  ERROR_DB_CREATE_WASHER_PAYOUT_ISSUE = "Failed to create washer payout",
  ERROR_DB_UPDATE_WASHER_PAYOUT_ISSUE = "Failed to updated washer payout",
  ERROR_DB_GET_WASHER_PAYOUTS_ISSUE = "Can'return washer payouts",
  ERROR_DB_GET_WASHER_PAYOUT_ISSUE = "Can'return washer payout",
  ERROR_DB_ISSUE_ON_UPDATE_ORDER_PACKAGE_EXTRA_DIRTY_FLAG = "Can\t update package extra dirty flag for this order",
  ERROR_INVALID_PACKAGE_EXTRA_TIME = "Please provide a valid extra dirty time",
  ERROR_INVALID_WASHER_PAYOUT_DESCRIPTION = "Please provide a valid payout description",
  ERROR_INVALID_WASHER_PAYOUT_ACTION_PAYLOAD = "Please provide valid properties for payout",
  VERIFICATION_FORM_FOR_THIS_USER_NOT_EXIST = "Verification form for this user doesn't exist",
  ERROR_INVALID_EQUIPMENT_NAME_CODE = "Provided equipment name code is invalid",
  ERROR_EQUIPMENT_NAME_CODE_VERY_LONG = "Provided equipment name code is too long",
  ERROR_EQUIPMENT_NAME_CODE_EXIST = "Provided equipment name code already exists",
  ERROR_EMPTY_EQUIPMENT_NAME_CODE_FIELD = "Please provide a name code for this equipment",
  ERROR_EQUIPMENT_TITLE_VERY_LONG = "Provided equipment title is too long",
  ERROR_INVALID_EQUIPMENT_TITLE = "Provided equipment title is invalid",
  ERROR_EMPTY_EQUIPMENT_TITLE_FIELD = "Please provide a title for this equipment",
  ERROR_EQUIPMENT_DESCRIPTION_VERY_LONG = "Provided equipment description is too long",
  ERROR_INVALID_EQUIPMENT_DESCRIPTION = "Provided equipment description is invalid",
  ERROR_INVALID_EQUIPMENT_VALUE_UNIT = "Provided equipment value is invalid",
  ERROR_EMPTY_EQUIPMENT_VALUE_UNIT_FIELD = "Please provide the equipment unit value",
  ERROR_DB_ON_CREATE_EQUIPMENT = "Equipment creation has failed",
  ERROR_DB_ON_GET_EQUIPMENT = "Equipment returning failed",
  ERROR_EQUIPMENT_NOT_EXIST = "Specified equipment doesn't exist",
  ERROR_INVALID_EQUIPMENT_ID = "Equipment identificator is invalid",
  ERROR_EMPTY_EQUIPMENT_ID_FIELD = "Equipment identificator is invalid",
  ERROR_INVALID_EQUIPMENT_VALUE = "Provided equipment value is invalid",
  ERROR_EMPTY_EQUIPMENT_VALUE_FIELD = "Please provide an equipment value",
  ERROR_INVALID_EQUIPMENT_MAX_VALUE = "Provided equipment max value is invalid",
  ERROR_EMPTY_EQUIPMENT_MAX_VALUE_FIELD = "Please provide an equipment max value",
  ERROR_DB_ON_SAVE_USER_EQUIPMENT_RELATION = "Database error when saving equipment for user",
  ERROR_DB_ON_GET_USER_EQUIPMENT = "Can't return user equipment",
  ERROR_DB_ON_SET_NEW_MAX_VALUE_FOR_USER_EQUIPMENT = "Can't update max value for this equipment",
  ERROR_INVALID_DELIVERY_ADDRESS = "Provided delivery address is invalid",
  ERROR_EMPTY_USER_EQUIPMENT_ID_FIELD = "Equipment cannot be identified",
  ERROR_INVALID_USER_EQUIPMENT_ID = "Provided identifier for user equipment is invalid",
  ERROR_USER_EQUIPMENT_REQUEST_EXIST = "Provided request for equipment already exists",
  ERROR_INVALID_USER_EQUIPMENT_REQUEST_FILTER = "Provided filter for equipment is invalid",
  ERROR_DB_GET_CURRENT_SESSION = "User session can't  be found",
  ERROR_DB_GET_ALL_USER_SESSIONS = "Can't return user sessions",
  ERROR_DB_GET_TOTAL_AND_AVERAGE_ONLINE_TIME = "Can't return online time statistics",
  ERROR_CURRENT_SESSION_NOT_EXIST = "User session doesn't exist",
  ERROR_INVALID_DATE_TIME_FROM = "Provided starting time is invalid",
  ERROR_INVALID_DATE_TIME_TO = "Provided end time is invalid",
  ERROR_DB_ON_DELETE_ALL_USERS_EQUIPMENT = "Can't delete equipment for this user",
  ERROR_DB_ON_GET_ALL_EQUIPMENTS = "Can't return equipment",
  ERROR_DB_GET_ALL_WASHERS = "Cant't return all washers",
  ERROR_DB_ON_UPDATE_USER_EQUIPMENT_VALUE = "Can't update user equipment",
  ERROR_INVALID_EQUIPMENT_INFO = "Provided info for equipment is invalid",
  ERROR_DB_CREATE_LOG_RECORD = "Log cannot be created",
  ERROR_DB_GET_LOG_RECORD = "Can't return logs",
  ERROR_DB_GET_USER_LOG_RECORDS = "Can't return logs for this user",
  ERROR_INVALID_SUBSCRIBE_TO_NEWS_LETTER_FIELD = "Provided value for subscribe to newsletter is invalid",
  ERROR_SEND_VERIFICATION_SMS = "Verification SMS cannot be sent",
  ERROR_SEND_VERIFICATION_EMAIL = "Verification email cannot be sent",
  ERROR_INVALID_VALIDATE_CODE = "Provided verification code is wrong",
  ERROR_EMPTY_VALIDATE_CODE = "Please provide a SMS verification code",
  ERROR_VERIFY_USER_EMAIL = "Provided email cannot be verified",
  ERROR_VERIFY_USER_PHONE = "Provided phone number cannot be verified",
  ERROR_INVALID_COUPON_CODE = "Provided coupon code cannot verified",
  ERROR_DB_CREATE_COUPON = "Coupon code cannot be created",
  ERROR_DB_UPDATE_COUPON = "Coupon code cannot be updated",
  ERROR_DB_FIND_COUPON = "Coupon code cannot be found",
  ERROR_COUPON_NOT_EXIST = "Coupon code doesn't exist",
  ERROR_COUPON_EXPIRED = "This coupon is no longer in use",
  ERROR_DB_CREATE_REFERRAL_USER_RELATION = "Creating refereal has failed",
  ERROR_DB_UPDATE_REFERRAL_USER_RELATION = "Updating referal has failed",
  ERROR_DB_FIND_REFERRAL_USER_RELATION = "Can't find referal",
  ERROR_DB_FIND_REFERRAL_USER_RELATION_LIST = "Can't find referal for this user",
  ERROR_REFERRAL_RELATION_WITH_THIS_USER_EXIST = "This user already contains referal",
  ERROR_REFERRAL_RELATION_WITH_THIS_USER_NOT_EXIT = "This user cannot be reffered by provided referal",
  ERROR_DB_FIND_COUNT_OF_COMPLETED_ORDERS = "Can't return count of completed orders",
  ERROR_DB_GET_PROMOTER_STATISTICS_ISSUE = "Can't return promoter statistics",
  ERROR_USER_CAN_NOT_USE_COUPON = "Provided coupon cannot be used",
  ERROR_INVALID_ONLY_WITH_FEE_AMOUNT_FIELD = "Provided fee amount is invalid",
  ERROR_DB_UPDATE_WASHER_EQUIPMENT_REQUEST = "Washer equipment cannot be updated",
  ERROR_INVALID_LAST_NAME_FIELD = "Provided last name is invalid",
  ERROR_EMPTY_LAST_NAME_FIELD = "Please provide your last name",
  ERROR_INVALID_SUBJECT_FIELD = "Provided subject is invalid",
  ERROR_EMPTY_SUBJECT_FIELD = "Please provide a subject",
  ERROR_INVALID_MESSAGE_FIELD = "Provided message field is invalid",
  ERROR_EMPTY_MESSAGE_FIELD = "Please provide a message",
  ERROR_INVALID_USER_PHONE_NUMBER = "Provided user phone number is invalid",
  ERROR_EMPTY_WASHER_PAYOUT_PRICE = "Please provide a washer payout price",
  ERROR_INVALID_WASHER_PAYOUT_PRICE = "Provided washer payout price is invalid",
  DATA_ERROR = "Data error. Report to developers",
  OTHER_ERROR = "Something went wrong...",
  ERROR_EMPTY_BANK_ACCOUNT = "Bank account cannot be empty",
  ERROR_INVALID_BANK_ACCOUNT = "Please provide valid bank account",
  ERROR_INVALID_EQUIPMENT_AMOUNT = "Provided equipment amount is invalid",
}
