import React, { ReactNode } from "react";
import Popup from "../../shared/Popup";
import useActions from "../../hooks/useActions";
import { AuthActions } from "../../store/actions/auth.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import EmailForm from "./EmailForm";
import ConfirmationCodeForm from "./ConfirmationCodeForm";
import NewPasswordForm from "./NewPasswordForm";
import texts from "../../constants/texts";

const ForgotPassword: React.FC = () => {
  const authActions = useActions<typeof AuthActions>(AuthActions);
  const { isForgotModalOpen, forgotModalStep } = useSelector(
    (state: AppState) => state.auth
  );

  function handleModalClose() {
    authActions.closeForgotModal();
  }

  function renderContent(currentStep: number): ReactNode | null {
    switch (currentStep) {
      case 1:
        return <EmailForm />;
      case 2:
        return <ConfirmationCodeForm />;
      case 3:
        return <NewPasswordForm />;
      default:
        return null;
    }
  }

  return (
    <Popup
      open={isForgotModalOpen}
      handleClose={handleModalClose}
      title={texts.auth.login.forgotPassword.modalTitle}
    >
      {renderContent(forgotModalStep)}
    </Popup>
  );
};

export default ForgotPassword;
