import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ClientsPage from "./ClientsPage";
import { RouteParams } from "../../../constants/routeNames";
import ClientProfile from "./ClientProfile";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const ClientsRouter: React.FC<Props> = () => {
  useTitle(`Washerly Clients`);
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <ClientsPage />
      </Route>
      <Route
        path={`${path}/:${RouteParams.USER_ID}/`}
        component={ClientProfile}
      />
    </Switch>
  );
};

export default ClientsRouter;
