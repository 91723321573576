import { axiosInstance } from "./base.api";
import { SortParams } from "../models/utils.models";
import { ReferralsResponse } from "../models/responses/referrals.model";
import { TablePaginationParams } from "../models/table.models";

export const referralsApi = {
  getPromotersReferralsList(params: TablePaginationParams) {
    const config = {
      params: {
        ...params,
        sort: SortParams.DESC,
      },
    };
    return axiosInstance.get<ReferralsResponse>(`/private/referrals`, config);
  },
};
