export enum RouteNames {
  ROOT = "",
  SIGN_UP = "sign-up",
  DASHBOARD = "dashboard",
  PROMOTERS = "promoters",
  CLIENTS = "clients",
  WASHERS = "washers",
  PENDING_WASHERS = "pending-washers",
  LOGS = "logs",
  ORDERS = "orders",
  CARS = "cars",
  FUTURE_WASH = "future-washes",
  NON_FUTURE_WASH = "non-future-washes",
  BANKING_CARDS = "banking-cards",
  CREDITS = "credits",
  REVIEWS = "reviews",
  EDIT_PROFILE = "edit-profile",
  PERSONAL = "personal",
  LOCATION = "location",
  FINANCE = "finance",
  HISTORY = "history",
  VERIFICATION = "verification",
  TUTORIALS = "tutorials",
  START_THE_EXAM = "start-the-exam",
  EQUIPMENT = "equipment",
  ADMIN_COMMENTS = "admin-comments",
  REFERRALS = "referrals",
  EARNINGS = "earnings",
  SETTINGS = "settings",
  INDIVIDUAL = "individual",
  COMPANY = "company",
  APPLICATION = "application",
  PAYMENTS = "payments",
  PAYOUTS = "payouts",
  REQUESTED = "requested",
  COMPLETED = "completed",
  REQUEST_PAYOUT = "request-payout",
  SESSIONS = "sessions",
  BLACKLIST = "mobile-build-blacklist",
  PRICING_MAP = "pricing-map",
}

export enum QueryParams {
  SIZE = "size",
  PAGE = "page",
  SORT = "sort",
  SORT_BY = "sortBy",
  FROM = "from",
  TO = "to",
  EARNINGS_TYPE = "earningsType",
  YEAR = "year",
  MONTH = "month",
  WEEK = "week",
  SELECTED_DAY = "selectedDay",
}

export enum RouteParams {
  USER_TYPE = "usersType",
  USER_ID = "userId",
  ORDER_ID = "orderId",
  EQUIPMENT_REQUEST_ID = "equipmentRequestId",
}
