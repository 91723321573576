import React, { useEffect, useState } from "react";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Package, PackageStatus } from "../../models/responses/package.model";
import colors from "../../constants/colors";
import moment from "moment";
import OrderProgressSection from "../../styled/OrderProgressSection";
import OrderProgressBar from "../../styled/OrderProgressBar";
import texts from "../../constants/texts";
import useVisible from "../../hooks/useVisible";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 20,
      backgroundColor: colors.lightGrey,
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
    },
    inProgressLabel: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 5,
      padding: theme.spacing(0.5, 2),
      fontSize: "10px",
      fontWeight: 400,
      color: theme.palette.secondary.main,
      fontFamily: "'Roboto', sans-serif",
    },
  })
);

interface OwnProps {
  packages: Package[];
  startTime: Date;
}

type Props = OwnProps;

const OrderProgress: React.FC<Props> = ({ packages, startTime }) => {
  const classes = useStyles();
  const [timePassed, setTimePassed] = useState(0);

  const [progress, progressVisible] = useVisible();

  const totalPredicted = packages.reduce((acc, pack) => {
    return acc + moment.duration(pack.duration).asMilliseconds();
  }, 0);
  const calculatePackagePercent = (duration: string | number) => {
    const time = moment.duration(duration).asMilliseconds();
    return +((time / totalPredicted) * 100).toFixed(2);
  };
  useEffect(() => {
    const passedInterval = setInterval(() => {
      setTimePassed(
        moment.duration(moment().diff(moment(startTime))).asMilliseconds()
      );
    }, 1000);
    return () => clearInterval(passedInterval);
  }, [startTime]);
  const calculateProgress = () => {
    const minProgress = packages.reduce((acc, pack) => {
      return (
        acc +
        (pack.status === PackageStatus.COMPLETE
          ? moment.duration(pack.duration).asMilliseconds()
          : 0)
      );
    }, 0);
    const maxProgress = packages.reduce((acc, pack) => {
      return (
        acc +
        (pack.status === PackageStatus.COMPLETE ||
        pack.status === PackageStatus.START
          ? moment.duration(pack.duration).asMilliseconds()
          : 0)
      );
    }, 0);
    const progress =
      timePassed < maxProgress
        ? Math.max(minProgress, timePassed)
        : maxProgress;
    return +((progress / totalPredicted) * 100).toFixed(2);
  };
  const timeLeft = totalPredicted - timePassed;
  return (
    <>
      <Grid item xs={8} className={classes.root}>
        {packages.map((pack) => (
          <OrderProgressSection
            key={pack.id}
            isDone={pack.status === PackageStatus.COMPLETE}
            width={calculatePackagePercent(pack.duration)}
          />
        ))}
        <OrderProgressBar
          ref={progress}
          width={progressVisible ? calculateProgress() : 0}
        />
      </Grid>
      <Grid item xs={4}>
        <Box justifyContent="flex-end" alignItems="center" display="flex">
          <Box mr={2}>
            <Typography variant="body1" color="textSecondary">
              {texts.orders.timeLeft}:
            </Typography>
          </Box>
          <Box mr={3}>
            <Typography variant="h5" color="secondary">
              {moment.utc(Math.max(timeLeft, 0)).format("HH : mm : ss")}
            </Typography>
          </Box>
          <Box className={classes.inProgressLabel}>
            {texts.orders.inProcess}
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default OrderProgress;
