import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const SignInIcon: React.FC<Props> = (props) => {
  return (
    <svg
      id="user_7_"
      data-name="user (7)"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="11.263"
      height="11.263"
      viewBox="0 0 11.263 11.263"
      {...props}
    >
      <g id="Group_22" data-name="Group 22" transform="translate(0)">
        <path
          id="Path_29"
          data-name="Path 29"
          d="M9.614,7.281a5.61,5.61,0,0,0-2.14-1.342,3.256,3.256,0,1,0-3.684,0A5.64,5.64,0,0,0,0,11.263H.88a4.752,4.752,0,1,1,9.5,0h.88A5.6,5.6,0,0,0,9.614,7.281ZM5.632,5.632A2.376,2.376,0,1,1,8.007,3.256,2.378,2.378,0,0,1,5.632,5.632Z"
          transform="translate(0)"
        />
      </g>
    </svg>
  );
};

export default SignInIcon;
