import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const PhotoIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="28.806"
      height="25.993"
      viewBox="0 0 28.806 25.993"
      {...props}
    >
      <path
        id="camera"
        d="M26.555,15.866V8.552A2.253,2.253,0,0,0,24.3,6.3h-.056a3.375,3.375,0,0,1-3.1-2.286l-.34-1a1.125,1.125,0,0,0-1.065-.763H13.737a1.129,1.129,0,0,0-1.058.743l-.389,1.079A3.385,3.385,0,0,1,9.114,6.3H4.5a2.253,2.253,0,0,0-2.25,2.25v12.94a2.253,2.253,0,0,0,2.25,2.25h.563V13.053a1.125,1.125,0,1,1,2.25,0v10.69H24.3a2.253,2.253,0,0,0,2.25-2.25,1.125,1.125,0,0,1,2.25,0,4.506,4.506,0,0,1-4.5,4.5H4.5a4.506,4.506,0,0,1-4.5-4.5V8.552a4.506,4.506,0,0,1,4.5-4.5H9.114a1.129,1.129,0,0,0,1.058-.743l.389-1.079A3.385,3.385,0,0,1,13.737,0h6.007a3.375,3.375,0,0,1,3.2,2.288l.34,1a1.125,1.125,0,0,0,1.065.763h.041a4.506,4.506,0,0,1,4.42,4.5v7.314a1.125,1.125,0,0,1-2.25,0Zm-2.7-1.407a7.033,7.033,0,1,1-7.033-7.033A7.041,7.041,0,0,1,23.855,14.459Zm-2.25,0a4.782,4.782,0,1,0-4.782,4.782A4.788,4.788,0,0,0,21.6,14.459ZM5.064,9.114A1.125,1.125,0,1,0,6.189,7.989,1.125,1.125,0,0,0,5.064,9.114Zm0,0"
      />
    </svg>
  );
};

export default PhotoIcon;
