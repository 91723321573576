import React from "react";
import { Grid } from "@material-ui/core";
import LastOrder from "./components/LastOrder";
import MyOrdersList from "./components/MyOrdersList/MyOrdersList";
import MyRating from "./components/MyRating";
import Logs from "./components/Logs/Logs";

interface OwnProps {}

type Props = OwnProps;

const WasherDashboard: React.FC<Props> = () => {
  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item container xs={8} spacing={2}>
        <LastOrder />
        <MyOrdersList />
      </Grid>
      <Grid item xs={4} container spacing={2}>
        <MyRating />
        <Logs />
      </Grid>
    </Grid>
  );
};

export default WasherDashboard;
