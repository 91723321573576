import { put, takeLatest } from "@redux-saga/core/effects";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import { showError } from "./utils";
import {
  getPaymentMethodsForActiveUserType,
  PaymentActions,
  PaymentActionTypes,
} from "../actions/payment.actions";
import { paymentApi } from "../../api/payment.api";
import { PaymentMethods } from "../../models/responses/payment.model";

function* getPaymentMethodsForUser(action: getPaymentMethodsForActiveUserType) {
  try {
    const { data } = yield paymentApi.getPaymentMethodsByUserId(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const paymentMethods = yield transformAndValidate(
        PaymentMethods,
        data.data
      );
      yield put(
        PaymentActions.getPaymentMethodsForActiveUserSuccess(
          paymentMethods.items
        )
      );
    }
  } catch (e) {
    yield showError(e);
    yield put(PaymentActions.getPaymentMethodsForActiveUserFailure(e.message));
  }
}

export function* PaymentSagas() {
  yield takeLatest(
    PaymentActionTypes.GET_PAYMENT_METHODS_FOR_ACTIVE_USER,
    getPaymentMethodsForUser
  );
}
