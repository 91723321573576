import {
  HeadCell,
  PendingWashersTableColumns,
} from "../../../../../models/table.models";
import texts from "../../../../../constants/texts";

export const pendingCompanyWashersListHeadCells: HeadCell<
  PendingWashersTableColumns
>[] = [
  {
    id: PendingWashersTableColumns.DATE,
    label: texts.users.tableHeaders.applicationDate,
    size: 2,
  },
  {
    id: PendingWashersTableColumns.EMAIL,
    label: texts.users.tableHeaders.email,
    size: 2,
  },
  {
    id: PendingWashersTableColumns.FULL_NAME,
    label: texts.users.tableHeaders.fullName,
    size: 2,
  },
  {
    id: PendingWashersTableColumns.PHONE_NUMBER,
    label: texts.users.tableHeaders.phone,
    size: 2,
  },
  {
    id: PendingWashersTableColumns.COMPANY,
    label: texts.users.tableHeaders.company,
    size: 2,
  },
  {
    id: PendingWashersTableColumns.STATUS,
    label: texts.users.tableHeaders.status,
    size: 1,
  },
];

export const pendingIndividualWashersListHeadCells: HeadCell<
  PendingWashersTableColumns
>[] = [
  {
    id: PendingWashersTableColumns.DATE,
    label: texts.users.tableHeaders.applicationDate,
    size: 2,
  },
  {
    id: PendingWashersTableColumns.EMAIL,
    label: texts.users.tableHeaders.email,
    size: 2,
  },
  {
    id: PendingWashersTableColumns.FULL_NAME,
    label: texts.users.tableHeaders.fullName,
    size: 3,
  },
  {
    id: PendingWashersTableColumns.PHONE_NUMBER,
    label: texts.users.tableHeaders.phone,
    size: 2,
  },
  {
    id: PendingWashersTableColumns.STATUS,
    label: texts.users.tableHeaders.status,
    size: 2,
  },
];
