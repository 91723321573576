import {
  EquipmentRequestTableColumns,
  HeadCell,
} from "../../../../models/table.models";
import texts from "../../../../constants/texts";

export const headCells: HeadCell<EquipmentRequestTableColumns>[] = [
  {
    id: EquipmentRequestTableColumns.ID,
    label: texts.roles.admin.equipments.requestList.tableHeaders.id,
    size: 2,
  },
  {
    id: EquipmentRequestTableColumns.EMAIL,
    label: texts.roles.admin.equipments.requestList.tableHeaders.email,
    size: 3,
  },
  {
    id: EquipmentRequestTableColumns.FULL_NAME,
    label: texts.roles.admin.equipments.requestList.tableHeaders.fullName,
    size: 3,
  },
  {
    id: EquipmentRequestTableColumns.PHONE_NUMBER,
    label: texts.roles.admin.equipments.requestList.tableHeaders.phone,
    size: 3,
  },
];
