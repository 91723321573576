import React from "react";
import { Grid } from "@material-ui/core";
import OptionsBlock from "../../../components/Logs/OptionsBlock";
import LogsList from "../../../components/Logs/LogsList";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const WasherLogs: React.FC<Props> = (props) => {
  useTitle(`Washerly Logs`);
  return (
    <Grid item container spacing={2}>
      <Grid item xs={4}>
        <OptionsBlock />
      </Grid>
      <Grid container item xs={8}>
        <LogsList />
      </Grid>
    </Grid>
  );
};

export default WasherLogs;
