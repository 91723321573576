import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import TopBar from "./components/TopBar";
import PayedReferralsList from "./components/PayedReferralsList/PayedReferralsList";
import SubmitBlock from "./components/SubmitBlock";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import useActions from "../../../../hooks/useActions";
import { PayoutsActions } from "../../../../store/actions/payouts.actions";
import { requestPromoterPayoutData } from "../../../../models/requests/Payouts";
import EmptyData from "../../../../components/EmptyData";
import EmptyOrdersIcon from "../../../../shared/icons/empty/EmptyOrdersIcon";

interface OwnProps {}

type Props = OwnProps;

const RequestPayout: React.FC<Props> = () => {
  const [selected, setSelected] = React.useState<string[]>([]);
  const payoutsSelector = useSelector((state: AppState) => state.payouts);
  const payoutsActions = useActions<typeof PayoutsActions>(PayoutsActions);

  useEffect(() => {
    payoutsActions.getPayedReferralsListForPromoter();
  }, [payoutsActions]);

  const handleSubmit = (bankAccount: string) => {
    const data: requestPromoterPayoutData = {
      referralUserIds: selected,
      bankAccount,
    };
    payoutsActions.requestPromoterPayout(data);
    setSelected([]);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TopBar />
      </Grid>
      {!payoutsSelector.payedReferralsLoading &&
      !payoutsSelector.payedReferrals.total ? (
        <EmptyData
          title="List of Referrals"
          subtitle={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
          emptyText="You don't have payed referrals"
          Icon={EmptyOrdersIcon}
        />
      ) : (
        <>
          <PayedReferralsList
            selected={selected}
            onSelectedChange={setSelected}
          />
          <Grid item xs={12}>
            <SubmitBlock
              disabled={!selected.length}
              loading={payoutsSelector.requestPayoutLoading}
              onSubmit={(cardNumber) => handleSubmit(cardNumber)}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RequestPayout;
