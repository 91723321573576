import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import TopBar from "./components/TopBar";
import PayedOrdersList from "./components/PayedOrdersList/PayedOrdersList";
import SubmitBlock from "./components/SubmitBlock";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import useActions from "../../../../hooks/useActions";
import { PayoutsActions } from "../../../../store/actions/payouts.actions";
import { requestWasherPayoutData } from "../../../../models/requests/Payouts";
import texts from "../../../../constants/texts";
import EmptyData from "../../../../components/EmptyData";
import EmptyOrdersIcon from "../../../../shared/icons/empty/EmptyOrdersIcon";

interface OwnProps {}

type Props = OwnProps;

const RequestPayout: React.FC<Props> = () => {
  const [selected, setSelected] = React.useState<string[]>([]);
  const payoutsSelector = useSelector((state: AppState) => state.payouts);
  const payoutsActions = useActions<typeof PayoutsActions>(PayoutsActions);

  useEffect(() => {
    payoutsActions.getPayedOrdersListForWasher();
  }, [payoutsActions]);

  const handleSubmit = (bankAccount: string) => {
    const data: requestWasherPayoutData = {
      orderIds: selected,
      bankAccount,
    };
    payoutsActions.requestWasherPayout(data);
    setSelected([]);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TopBar />
      </Grid>
      {!payoutsSelector.payedOrdersLoading &&
      !payoutsSelector.payedOrders.total ? (
        <EmptyData
          title={texts.roles.washer.payouts.requestPayout.payedOrdersListTitle}
          subtitle={
            texts.roles.washer.payouts.requestPayout.payedOrdersListSubtitle
          }
          emptyText={
            texts.roles.washer.payouts.requestPayout.emptyPayedOrdersText
          }
          Icon={EmptyOrdersIcon}
        />
      ) : (
        <>
          <PayedOrdersList selected={selected} onSelectedChange={setSelected} />
          <Grid item xs={12}>
            <SubmitBlock
              disabled={!selected.length}
              loading={payoutsSelector.requestPayoutLoading}
              onSubmit={(cardNumber) => handleSubmit(cardNumber)}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RequestPayout;
