import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import {
  CompanyWasherGeneralStatistics,
  CompanyWasherStatistics,
  GeneralStatistic,
  PromoterStatistic,
  WasherStatistic,
} from "../../models/responses/statistic.model";

export enum StatisticActionTypes {
  GET_GENERAL_STATISTIC = "[Statistic] Get general statistic",
  SET_GENERAL_STATISTIC = "[Statistic] Set general statistic",

  GET_WASHER_STATISTIC = "[Statistic] Get washer statistic",
  SET_WASHER_STATISTIC = "[Statistic] Set washer statistic",

  GET_PROMOTER_STATISTIC = "[Statistic] Get promoter statistic",
  SET_PROMOTER_STATISTIC = "[Statistic] Set promoter statistic",

  GET_COMPANY_WASHER_STATISTICS = "[Statistic] Get company washer statistics",
  SET_COMPANY_WASHER_STATISTICS = "[Statistic] Set company washer statistics",

  GET_COMPANY_WASHER_GENERAL_STATISTICS = "[Statistic] Get company washer general statistics",
  SET_COMPANY_WASHER_GENERAL_STATISTICS = "[Statistic] Set company washer general statistics",
}

export const StatisticActions = {
  getGeneralStatistic: () =>
    createAction(StatisticActionTypes.GET_GENERAL_STATISTIC),
  setGeneralStatistic: (payload: GeneralStatistic) =>
    createAction(StatisticActionTypes.SET_GENERAL_STATISTIC, payload),

  getWasherStatistic: () =>
    createAction(StatisticActionTypes.GET_WASHER_STATISTIC),
  setWasherStatistic: (payload: WasherStatistic) =>
    createAction(StatisticActionTypes.SET_WASHER_STATISTIC, payload),

  getPromoterStatistic: () =>
    createAction(StatisticActionTypes.GET_PROMOTER_STATISTIC),
  setPromoterStatistic: (payload: PromoterStatistic) =>
    createAction(StatisticActionTypes.SET_PROMOTER_STATISTIC, payload),

  // Stats in washer profile..
  getCompanyWasherStatistics: (userId: string) =>
    createAction(StatisticActionTypes.GET_COMPANY_WASHER_STATISTICS, userId),
  setCompanyWasherStatistics: (payload: CompanyWasherStatistics) =>
    createAction(StatisticActionTypes.SET_COMPANY_WASHER_STATISTICS, payload),

  // General stats in washers page
  getCompanyWasherGeneralStatistics: () =>
    createAction(StatisticActionTypes.GET_COMPANY_WASHER_GENERAL_STATISTICS),
  setCompanyWasherGeneralStatistics: (
    payload: CompanyWasherGeneralStatistics
  ) =>
    createAction(
      StatisticActionTypes.SET_COMPANY_WASHER_GENERAL_STATISTICS,
      payload
    ),
};

export type StatisticActions = ActionsUnion<typeof StatisticActions>;
export type getCompanyWasherStatisticsType = ActionsOfType<
  StatisticActions,
  StatisticActionTypes.GET_COMPANY_WASHER_STATISTICS
>;
