import React from "react";
import { Grid } from "@material-ui/core";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { RouteNames } from "../../constants/routeNames";
import EditDataMenu from "./EditDataMenu";
import EditPersonal from "./EditPersonal";
import EditFinance from "./EditFinance";
import EditLocation from "./EditLocation";
import { Redirect } from "react-router";
import { User } from "../../models/responses/user.model";

interface OwnProps {
  user?: User;
}

type Props = OwnProps;

const EditUserData: React.FC<Props> = (props) => {
  const match = useRouteMatch();
  return (
    <>
      <EditDataMenu />
      <Grid item xs={10}>
        <Switch>
          <Route exact path={`${match.path}/${RouteNames.PERSONAL}`}>
            <EditPersonal user={props.user} />
          </Route>
          <Route exact path={`${match.path}/${RouteNames.LOCATION}`}>
            <EditLocation />
          </Route>
          <Route exact path={`${match.path}/${RouteNames.FINANCE}`}>
            <EditFinance />
          </Route>
          <Redirect to={`${match.path}/${RouteNames.PERSONAL}`} />
        </Switch>
      </Grid>
    </>
  );
};

export default EditUserData;
