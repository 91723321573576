import { axiosInstance } from "./base.api";
import { ID } from "../models/utils.models";
import { PaymentMethodsResponse } from "../models/responses/payment.model";

export const paymentApi = {
  getPaymentMethodsByUserId(userId: ID) {
    const config = {
      params: {
        userId,
      },
    };
    return axiosInstance.get<PaymentMethodsResponse>(
      "/private/payments/payment-method",
      config
    );
  },
};
