import { NavTab } from "../../../models/router.models";
import { RouteNames } from "../../../constants/routeNames";

const tabs: NavTab[] = [
  {
    title: "Non Future",
    path: `/${RouteNames.NON_FUTURE_WASH}`,
  },
  {
    title: "Future",
    path: `/${RouteNames.FUTURE_WASH}`,
  },
];

export default tabs;
