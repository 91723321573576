import React, { SVGProps } from "react";

type Props = SVGProps<any>;

const EmptyLogsIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.919"
      height="50.242"
      viewBox="0 0 49.919 50.242"
      {...props}
    >
      <g
        id="Group_295"
        data-name="Group 295"
        transform="translate(-133.898 -92.065)"
        opacity="0.5"
      >
        <path
          id="Path_611"
          data-name="Path 611"
          d="M179.556,128.484v8.77a5.28,5.28,0,0,1-5.28,5.28H145.059a.88.88,0,0,1-.88-.88V102.628a.88.88,0,0,1,.88-.88h33.617a.88.88,0,0,1,.88.88v11.011"
          transform="translate(-1.347 -1.259)"
          fill="none"
          stroke="#6275a8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.063"
        />
        <path
          id="Path_612"
          data-name="Path 612"
          d="M139.547,134.147h-3.773a.834.834,0,0,1-.774-.883V94.05a.834.834,0,0,1,.774-.883h29.57a.834.834,0,0,1,.773.883v3.2"
          fill="none"
          stroke="#6275a8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.205"
        />
        <path
          id="Path_613"
          data-name="Path 613"
          d="M188.492,115.684"
          transform="translate(-7.847 -3.303)"
          fill="none"
          stroke="#6275a8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.205"
        />
        <path
          id="Path_614"
          data-name="Path 614"
          d="M183.885,115.684"
          transform="translate(-7.172 -3.303)"
          fill="none"
          stroke="#6275a8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.205"
        />
        <path
          id="Path_615"
          data-name="Path 615"
          d="M190,133.079"
          transform="translate(-8.069 -5.855)"
          fill="none"
          stroke="#6275a8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.205"
        />
        <path
          id="Path_616"
          data-name="Path 616"
          d="M183.885,133.079"
          transform="translate(-7.172 -5.855)"
          fill="none"
          stroke="#6275a8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.205"
        />
        <line
          id="Line_86"
          data-name="Line 86"
          x2="11.734"
          transform="translate(152.073 115.485)"
          fill="none"
          stroke="#6275a8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.063"
        />
        <line
          id="Line_87"
          data-name="Line 87"
          y1="8.297"
          x2="8.297"
          transform="translate(174.061 115.654)"
          fill="none"
          stroke="#6275a8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.063"
        />
        <line
          id="Line_88"
          data-name="Line 88"
          x1="8.297"
          y1="8.297"
          transform="translate(174.061 115.654)"
          fill="none"
          stroke="#6275a8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.063"
        />
        <line
          id="Line_89"
          data-name="Line 89"
          x2="7.451"
          transform="translate(152.073 120.882)"
          fill="none"
          stroke="#6275a8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.063"
        />
        <line
          id="Line_90"
          data-name="Line 90"
          x2="7.451"
          transform="translate(152.073 126.28)"
          fill="none"
          stroke="#6275a8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.063"
        />
      </g>
    </svg>
  );
};

export default EmptyLogsIcon;
