import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React from "react";
import { ButtonProps } from "@material-ui/core/Button";
import clsx from "clsx";
import { unitSize } from "../constants/sizes";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: theme.spacing(4.5),
      fontSize: 14,
      boxShadow: "none",
      position: "relative",
    },
    rounded: {
      height: theme.spacing(5.5),
      borderRadius: theme.spacing(10),
    },
    leftIcon: {
      marginRight: unitSize,
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: theme.spacing(-1.5),
      marginLeft: theme.spacing(-1.5),
    },
    large: {
      width: theme.spacing(30),
      fontSize: 12,
    },
  })
);

interface OwnProps {
  rounded?: boolean;
  loading?: boolean;
  large?: boolean;
  LeftIcon?: React.FC<SvgIconProps>;
}

type Props = OwnProps & ButtonProps;

const CustomButton: React.FC<Props> = ({
  className,
  rounded,
  LeftIcon,
  loading,
  large,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box position="relative">
      <Button
        className={clsx(
          className,
          classes.root,
          rounded && classes.rounded,
          large && classes.large
        )}
        {...props}
      >
        {LeftIcon && <LeftIcon className={classes.leftIcon} />}
        {props.children}
        {loading && (
          <CircularProgress
            size={unitSize * 3}
            className={classes.buttonProgress}
          />
        )}
      </Button>
    </Box>
  );
};

export default CustomButton;
