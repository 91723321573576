import React from "react";

interface OwnProps {}

type Props = OwnProps;

const StartTheExamIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="16.393"
      height="20.679"
      viewBox="0 0 16.393 20.679"
    >
      <g id="exam" transform="translate(-53.056)">
        <g id="Group_334" data-name="Group 334" transform="translate(53.056)">
          <g id="Group_333" data-name="Group 333" transform="translate(0)">
            <path
              id="Path_671"
              data-name="Path 671"
              d="M68.641,14.217a.808.808,0,0,0,.808-.808V3.231A3.235,3.235,0,0,0,66.218,0H56.287a3.235,3.235,0,0,0-3.231,3.231V17.448a3.235,3.235,0,0,0,3.231,3.231h9.931a3.235,3.235,0,0,0,3.231-3.231.808.808,0,0,0-1.616,0,1.617,1.617,0,0,1-1.616,1.616H56.287a1.617,1.617,0,0,1-1.616-1.616V3.231a1.617,1.617,0,0,1,1.616-1.616h9.931a1.617,1.617,0,0,1,1.616,1.616V13.409A.808.808,0,0,0,68.641,14.217Z"
              transform="translate(-53.056)"
            />
            <path
              id="Path_672"
              data-name="Path 672"
              d="M126.983,180.664a.808.808,0,0,0,.808-.808v-3.433a2.423,2.423,0,0,0-4.847,0v3.433a.808.808,0,1,0,1.616,0v-1.171h1.616v1.171A.808.808,0,0,0,126.983,180.664Zm-2.423-3.595v-.646a.808.808,0,1,1,1.616,0v.646Z"
              transform="translate(-120.121 -166.972)"
            />
            <path
              id="Path_673"
              data-name="Path 673"
              d="M271.367,200.847a.808.808,0,0,0,.808-.808v-.808h.808a.808.808,0,0,0,0-1.616h-.808v-.808a.808.808,0,0,0-1.616,0v.808h-.808a.808.808,0,0,0,0,1.616h.808v.808A.808.808,0,0,0,271.367,200.847Z"
              transform="translate(-260.225 -188.084)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StartTheExamIcon;
