import { Order } from "../models/responses/order.model";
import { AuthSelectors } from "../store/reducers/auth.reducer";
import { UserRole } from "../models/responses/user.model";
import { formatCurrency } from "../helpers/utils/utils";
import { Currency } from "../models/utils.models";
import { useSelector } from "react-redux";

export function useRoleOrderPrice(order: Order | null) {
  const role = useSelector(AuthSelectors.getAuthorizedUserRole);
  if (!order) {
    return "";
  }
  const currency = order.currency || Currency.EUR;
  if (role === UserRole.WASHER) {
    return formatCurrency(order.washerPayoutPrice, currency);
  }
  return formatCurrency(order.orderPrice, currency);
}
