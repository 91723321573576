import React from "react";
import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import ClockIcon from "../../shared/icons/ClockIcon";
import colors from "../../constants/colors";
import Dirty from "../../styled/Dirty";
import PackageStatus from "../../styled/PackageStatus";
import { Package } from "../../models/responses/package.model";
import { getPackageStatusProps } from "../../constants/utils";
import { getImageUrl } from "../../helpers/utils/utils";
import texts from "../../constants/texts";

interface StyleProps {
  borderBottom: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 4),
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    color: colors.lightBlueGrey,
    borderBottom: ({ borderBottom }: StyleProps) =>
      borderBottom ? `1px solid ${colors.lightGrey}` : "none",
    "&>div": {
      borderRight: `1px solid ${colors.lightGrey}`,
    },
    "&:nth-child(4n+4)": {
      "&>div": {
        borderRight: "none",
      },
    },
    "&:last-child": {
      "&>div": {
        borderRight: "none",
      },
    },
  },
  packageIcon: {
    maxWidth: theme.spacing(5),
    maxHeight: theme.spacing(3),
  },
  packageTitle: {
    color: theme.palette.primary.main,
    fontSize: "12px",
    fontWeight: 300,
    marginTop: theme.spacing(2),
  },
}));

interface OwnProps {
  servicePack: Package;
  bb: boolean;
}

type Props = OwnProps;

const PackageItem: React.FC<Props> = ({ servicePack, bb }) => {
  const { statusColor, statusText } = getPackageStatusProps(servicePack.status);
  const classes = useStyles({ borderBottom: bb });
  return (
    <Grid item xs={3} className={classes.root}>
      <Box pr={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <img
            className={classes.packageIcon}
            src={getImageUrl(servicePack.iconFileId)}
            alt={servicePack.title}
          />
          <Box display="flex" alignItems="center">
            <ClockIcon />
            <Box ml={1}>
              <Typography variant="h6">{servicePack.duration}</Typography>
            </Box>
          </Box>
        </Box>
        <Typography className={classes.packageTitle}>
          {servicePack.title}
        </Typography>
        <Box display="flex" mt={1}>
          {servicePack.extraDirty && (
            <Dirty>{texts.orderDetails.extraDirty}</Dirty>
          )}
          <PackageStatus color={statusColor}>{statusText}</PackageStatus>
        </Box>
      </Box>
    </Grid>
  );
};

export default PackageItem;
