import { NavTab } from "../../../models/router.models";
import { RouteNames } from "../../../constants/routeNames";

const tabs: NavTab[] = [
  {
    title: "Requested",
    path: `/${RouteNames.REQUESTED}`,
  },
  {
    title: "Completed",
    path: `/${RouteNames.COMPLETED}`,
  },
];

export default tabs;
