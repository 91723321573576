import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import TableHeader from "../../../../../shared/sharedTable/TableHeader";
import useActions from "../../../../../hooks/useActions";
import { StatisticActions } from "../../../../../store/actions/statistic.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../store/reducers";
import { HeaderValue } from "../../../../../models/table.models";
import headCells from "./headCells";
import MyOrdersHeader from "../MyOrdersHeader";
import { OrdersActions } from "../../../../../store/actions/orders.actions";
import MyOrdersListRow from "../MyOrdersListRow";
import texts from "../../../../../constants/texts";
import EmptyData from "../../../../../components/EmptyData";
import EmptyOrdersIcon from "../../../../../shared/icons/empty/EmptyOrdersIcon";
import TableLoader from "../../../../../shared/TableLoader";

interface OwnProps {}

type Props = OwnProps;

const MyOrdersList: React.FC<Props> = () => {
  const [stats, setStats] = useState<HeaderValue[]>([]);
  const statisticActions = useActions<typeof StatisticActions>(
    StatisticActions
  );
  const ordersActions = useActions<typeof OrdersActions>(OrdersActions);
  const { washerStatistic } = useSelector((state: AppState) => state.statistic);
  const ordersState = useSelector((state: AppState) => state.orders);
  const data = ordersState.pastOrdersForActiveUser.items;
  useEffect(() => {
    statisticActions.getWasherStatistic();
    ordersActions.getPastOrdersForActiveUser({ size: 5, page: 0 });
  }, [ordersActions, statisticActions]);
  useEffect(() => {
    if (washerStatistic !== null) {
      const newStats = [
        {
          title: "Future",
          value: washerStatistic.futureOrderCount,
        },
        {
          title: "Complete",
          value: washerStatistic.completedOrderCount,
        },
      ];
      setStats(newStats);
    }
  }, [washerStatistic]);
  if (ordersState.pastOrdersForActiveUserLoading) {
    return <TableLoader />;
  }
  return (
    <Grid item xs={12} container>
      {!!data.length ? (
        <TableHeader
          title={texts.roles.washer.dashboard.ordersList.title}
          subtitle={texts.roles.washer.dashboard.ordersList.subtitle}
          values={stats}
          headCells={headCells}
          headerLayout={
            <MyOrdersHeader
              accepted={washerStatistic?.acceptedOrdersCount || 0}
              declined={washerStatistic?.declinedOrdersCount || 0}
            />
          }
        />
      ) : (
        <EmptyData
          title={texts.roles.washer.dashboard.ordersList.title}
          subtitle={texts.roles.washer.dashboard.ordersList.subtitle}
          emptyText={texts.roles.washer.dashboard.ordersList.emptyText}
          Icon={EmptyOrdersIcon}
        />
      )}
      {data.map((order) => (
        <MyOrdersListRow key={order.id} order={order} headCells={headCells} />
      ))}
    </Grid>
  );
};

export default MyOrdersList;
