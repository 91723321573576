import styled from "styled-components";

interface OrderProgressBarProps {
  width: number;
}

export default styled.span`
  position: absolute;
  content: "";
  display: block;
  left: 0;
  height: 100%;
  width: ${(props: OrderProgressBarProps) => props.width}%;
  background-image: linear-gradient(to right, #f8b600, #ff9500);
  border-radius: 20px;
  transition: 2s ease;
`;
