import { delay, put, takeLatest } from "@redux-saga/core/effects";
import {
  clientsSearchValueType,
  getActiveUserType,
  getClientsType,
  getCompanyWashersType,
  getWashersType,
  promotersSearchValueType,
  updateUserType,
  UsersActions,
  UsersActionTypes,
  washersSearchValueType,
} from "../actions/users.actions";
import { userApi } from "../../api/user.api";
import { AppActions } from "../actions/app.actions";
import { SnackbarVariant } from "../../models/utils.models";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import { User, Users } from "../../models/responses/user.model";
import { showError } from "./utils";

function* getPromoters(action: getClientsType) {
  yield delay(100);
  try {
    const { data } = yield userApi.getPromoters(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const clients = yield transformAndValidate(Users, data.data);
      yield put(UsersActions.getPromotersSuccess(clients));
    }
  } catch (e) {
    yield put(UsersActions.getPromotersFailure(e.message));
    yield showError(e);
  }
}

function* getClients(action: getClientsType) {
  yield delay(100);
  try {
    const { data } = yield userApi.getClients(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const clients = yield transformAndValidate(Users, data.data);
      yield put(UsersActions.getClientsSuccess(clients));
    }
  } catch (e) {
    yield put(UsersActions.getClientsFailure(e.message));
    yield showError(e);
  }
}

function* getWashers(action: getWashersType) {
  yield delay(100);
  try {
    const { data } = yield userApi.getWashers(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const washers = yield transformAndValidate(Users, data.data);
      yield put(UsersActions.getWashersSuccess(washers));
    }
  } catch (e) {
    yield put(UsersActions.getWashersFailure(e.message));
    yield showError(e);
  }
}

function* getCompanyWashers(action: getCompanyWashersType) {
  yield delay(100);
  try {
    const { data } = yield userApi.getCompanyWashers(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const washers = yield transformAndValidate(Users, data.data);
      yield put(UsersActions.getWashersSuccess(washers));
    }
  } catch (e) {
    yield put(UsersActions.getWashersFailure(e.message));
    yield showError(e);
  }
}

function* getActiveUserData(action: getActiveUserType) {
  try {
    const { data } = yield userApi.getUserById(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const activeUser = yield transformAndValidate(User, data.data);
      yield put(UsersActions.getActiveUserSuccess(activeUser));
    }
  } catch (e) {
    yield put(UsersActions.getActiveUserFailure(e.message));
    yield showError(e);
  }
}

function* updateUserData(action: updateUserType) {
  try {
    const { userId, ...reqBody } = action.payload;
    const { data } = yield userApi.updateUserPersonalData(reqBody, userId);
    if (data.result === ResponseStatus.SUCCESS) {
      const updatedUser = yield transformAndValidate(User, data.data);
      yield put(UsersActions.updateUserSuccess(updatedUser));
      yield put(
        AppActions.showSnackbar({
          variant: SnackbarVariant.SUCCESS,
          message: "User data update success!",
        })
      );
    }
  } catch (e) {
    yield put(UsersActions.updateUserFailure(e.message));
    yield showError(e);
  }
}

function* setPromoterSearchValue(action: promotersSearchValueType) {
  yield delay(400);
  yield put(UsersActions.setPromotersSearchValue(action.payload));
}

function* setClientSearchValue(action: clientsSearchValueType) {
  yield delay(400);
  yield put(UsersActions.setClientsSearchValue(action.payload));
}

function* setWasherSearchValue(action: washersSearchValueType) {
  yield delay(400);
  yield put(UsersActions.setWashersSearchValue(action.payload));
}

export function* UsersSagas() {
  yield takeLatest(UsersActionTypes.GET_PROMOTERS, getPromoters);
  yield takeLatest(UsersActionTypes.GET_CLIENTS, getClients);
  yield takeLatest(UsersActionTypes.GET_WASHERS, getWashers);
  yield takeLatest(UsersActionTypes.GET_COMPANY_WASHERS, getCompanyWashers);
  yield takeLatest(UsersActionTypes.GET_ACTIVE_USER_DATA, getActiveUserData);
  yield takeLatest(UsersActionTypes.UPDATE_USER_DATA, updateUserData);

  // Search
  yield takeLatest(
    UsersActionTypes.PROMOTERS_SEARCH_VALUE,
    setPromoterSearchValue
  );
  yield takeLatest(UsersActionTypes.CLIENTS_SEARCH_VALUE, setClientSearchValue);
  yield takeLatest(UsersActionTypes.WASHERS_SEARCH_VALUE, setWasherSearchValue);
}
