import React from "react";
import EditUserData from "../../components/EditUserData";
import { Grid } from "@material-ui/core";

interface OwnProps {}

type Props = OwnProps;

const Settings: React.FC<Props> = () => {
  return (
    <Grid container spacing={2}>
      <EditUserData />
    </Grid>
  );
};

export default Settings;
