import React from "react";
import TableHeader from "../../../../../shared/sharedTable/TableHeader";
import headCells from "./headCells";
import PackageListRow, { UpdatePackageDataType } from "./PackageListRow";
import { Grid } from "@material-ui/core";

interface OwnProps {
  packages: any;
  onChange: (packages: any) => void;
}

type Props = OwnProps;

const PackagesList: React.FC<Props> = (props) => {
  const handlePackageChange = (updatedPack: UpdatePackageDataType) => {
    const newPackages = props.packages.map((pack: any) => {
      if (pack.id === updatedPack.id) {
        return { ...pack, ...updatedPack };
      }
      return pack;
    });
    props.onChange(newPackages);
  };

  return (
    <Grid item xs={12} container>
      <TableHeader
        title="Packages"
        subtitle="List of packages"
        headCells={headCells}
      />
      {props.packages.map((pack: any) => (
        <PackageListRow
          onChange={handlePackageChange}
          key={pack.id}
          row={{
            id: pack.id,
            title: pack.title,
            price: pack.price,
            washerPayoutPrice: pack.washerPayoutPrice,
          }}
        />
      ))}
    </Grid>
  );
};

export default PackagesList;
