import { BasicModel } from "./basic.model";
import {
  IsDate,
  IsDefined,
  IsEnum,
  IsNumber,
  IsString,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { ResultModel } from "./result.model";

export enum SESSION_STATUS {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export class Session extends BasicModel {
  @IsEnum(SESSION_STATUS)
  status: SESSION_STATUS;
  @IsString()
  userId: string;
  @IsDate()
  @Type(() => Date)
  startTime: Date;
  @IsDate()
  @Type(() => Date)
  endTime: Date;
  @IsString()
  duration: string;
  @IsNumber()
  completedOrdersNumber: number;
}

export class Sessions {
  @IsDefined()
  @Type(() => Session)
  @ValidateNested()
  items: Session[];
  @IsNumber()
  @Type(() => Number)
  total: number;
  @IsString()
  @Type(() => String)
  totalOnlineTime: string;
  @Type(() => String)
  @IsString()
  averageOnlineTime: string;
}

export type SessionResponse = ResultModel<Session>;
export type SessionsResponse = ResultModel<Sessions>;
