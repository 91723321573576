import React from "react";
import ChangePhoneForm from "./ChangePhoneForm";
import ConfirmPhoneCodeForm from "./ConfirmPhoneCodeForm";

interface OwnProps {
  step: number;
}

type Props = OwnProps;

const ChangePhone: React.FC<Props> = (props) => {
  switch (props.step) {
    case 1:
      return <ChangePhoneForm />;
    case 2:
      return <ConfirmPhoneCodeForm />;
    default:
      return null;
  }
};

export default ChangePhone;
