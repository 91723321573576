import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import { Cars } from "../../models/responses/car.model";
import { CarsRequestParams } from "../../models/requests/Cars";

export enum CarsActionTypes {
  GET_CARS_FOR_ACTIVE_USER = "[Cars] Get cars for active user",
  GET_CARS_FOR_ACTIVE_USER_SUCCESS = "[Cars] Get cars for active user success",
  GET_CARS_FOR_ACTIVE_USER_FAILURE = "[Cars] Get cars for active user failure",
}

export const CarsActions = {
  getCarsForActiveUser: (payload: CarsRequestParams) =>
    createAction(CarsActionTypes.GET_CARS_FOR_ACTIVE_USER, payload),
  getCarsForActiveUserSuccess: (payload: Cars) =>
    createAction(CarsActionTypes.GET_CARS_FOR_ACTIVE_USER_SUCCESS, payload),
  getCarsForActiveUserFailure: (payload: string) =>
    createAction(CarsActionTypes.GET_CARS_FOR_ACTIVE_USER_FAILURE, payload),
};

export type CarsActions = ActionsUnion<typeof CarsActions>;

export type getCarsForActiveUserType = ActionsOfType<
  CarsActions,
  CarsActionTypes.GET_CARS_FOR_ACTIVE_USER
>;
