import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import DateRangePicker from "../../../shared/DateRangePicker";
import CustomButton from "../../../shared/CustomButton";
import useQuery from "../../../hooks/useQuery";
import { QueryParams } from "../../../constants/routeNames";
import TitledPaper from "../../../shared/TitledPaper";

interface OwnProps {}

type Props = OwnProps;

const OptionsBlock: React.FC<Props> = () => {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const history = useHistory();
  let query = useQuery();
  const location = useLocation();

  const updatePath = () => {
    history.push({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  const handleFromChange = (newFrom: Date) => {
    setFrom(newFrom);
  };

  const handleToChange = (newTo: Date) => {
    setTo(newTo);
  };

  const handleSubmit = () => {
    if (from instanceof Date) {
      query.set(QueryParams.FROM, from.toISOString());
    } else {
      query.delete(QueryParams.FROM);
    }
    if (to instanceof Date) {
      query.set(
        QueryParams.TO,
        new Date(to.getTime() + 86399000).toISOString()
      );
    } else {
      query.delete(QueryParams.TO);
    }
    updatePath();
  };

  const queryFrom = query.get(QueryParams.FROM);
  const queryTo = query.get(QueryParams.TO);

  useEffect(() => {
    if (typeof queryFrom == "string") {
      setFrom(new Date(queryFrom));
    }
    if (typeof queryTo == "string") {
      setTo(new Date(queryTo));
    }
  }, [queryFrom, queryTo]);

  return (
    <TitledPaper title="Option">
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <DateRangePicker
          onFromChange={handleFromChange}
          onToChange={handleToChange}
          to={to}
          from={from}
        />
        <Box mt={4}>
          <CustomButton
            fullWidth
            color="secondary"
            // disabled
            onClick={handleSubmit}
            variant="contained"
            // loading={updateActiveUserLoading}
          >
            Search
          </CustomButton>
        </Box>
      </Box>
    </TitledPaper>
  );
};

export default OptionsBlock;
