import { ResultModel } from "./result.model";
import { User } from "./user.model";
import {
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { BasicModel } from "./basic.model";

export class Referral extends BasicModel {
  @ValidateNested()
  @Type(() => User)
  referralUser: User;

  @ValidateNested()
  @Type(() => User)
  referrerUser: User;

  @IsString()
  referralUserId: string;

  @IsString()
  referrerUserId: string;

  feePercent: number;

  @IsNumber()
  feeAmount: number;

  @IsOptional()
  @IsString()
  orderId?: string;
}

export class Referrals {
  @IsDefined()
  @Type(() => Referral)
  @ValidateNested()
  items: Referral[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export type ReferralResponse = ResultModel<Referral>;
export type ReferralsResponse = ResultModel<Referrals>;
