import React from "react";

interface OwnProps {}

type Props = OwnProps;

const VanIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="40"
      height="26.023"
      viewBox="0 0 40 26.023"
    >
      <path
        id="Path_213"
        data-name="Path 213"
        d="M40,80.587a6.267,6.267,0,0,0-.287-1.841L36.6,68.835A5.845,5.845,0,0,0,31,64.722H3.77A3.774,3.774,0,0,0,0,68.492V83.115a3.774,3.774,0,0,0,3.77,3.77h.783a4.832,4.832,0,0,0,9.466,0H25.277a4.831,4.831,0,0,0,9.481-.084A6.264,6.264,0,0,0,40,80.587ZM27.338,85.914a2.672,2.672,0,1,1,2.671,2.672A2.675,2.675,0,0,1,27.338,85.914Zm-15.38,0a2.672,2.672,0,1,1-2.671-2.672A2.675,2.675,0,0,1,11.958,85.914ZM34.663,84.62a4.831,4.831,0,0,0-9.337.106H13.969a4.831,4.831,0,0,0-9.366,0H3.77a1.612,1.612,0,0,1-1.61-1.611v-3.4H37.739a4.1,4.1,0,0,1-3.075,4.9ZM3.77,66.882H31a3.694,3.694,0,0,1,2.683,1.146h-6.17a1.08,1.08,0,0,0-1.08,1.08v6.156a1.08,1.08,0,0,0,1.08,1.08H36.7l.381,1.215H2.16V68.492A1.612,1.612,0,0,1,3.77,66.882Zm30.995,3.305,1.253,4H28.6v-4Z"
        transform="translate(0 -64.722)"
      />
    </svg>
  );
};

export default VanIcon;
