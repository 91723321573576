import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import ArrowLeftIcon from "../../shared/icons/ArrowLeftIcon";
import RadialDivider from "../../styled/RadialDivider";
import ValueWithIcon from "../../shared/ValueWithIcon";
import FinanceIcon from "../../shared/icons/FinanceIcon";
import StopwatchIcon from "../../shared/icons/StopwatchIcon";
import { matchPath } from "react-router";
import useActions from "../../hooks/useActions";
import { OrdersActions } from "../../store/actions/orders.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import UserContactsCard from "./UserContactsCard";
import CarInfo from "./CarInfo";
import WashProcess from "./WashProcess";
import CopyToClipboard from "../../shared/CopyToClipboard";
import moment from "moment";
import { sortOrderPackages } from "../../helpers/utils/utils";
import {
  getFormattedTimeDuration,
  getTimeDifference,
} from "../../helpers/utils/date.utils";
import { OrderStatus } from "../../models/responses/order.model";
import texts from "../../constants/texts";
import { ID } from "../../models/utils.models";
import { useHistory, useRouteMatch } from "react-router-dom";
import OrderStatusLabel from "./OrderStatusLabel";
import { useRoleOrderPrice } from "../../hooks/useRoleOrderPrice";
import OrderLocationMap from "./OrderLocationMap";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  toolBar: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(6),
    height: theme.spacing(13),
  },
  orderId: {
    marginBottom: theme.spacing(1),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  valuesDivider: {
    marginRight: theme.spacing(3),
  },
}));

interface OwnProps {}

type Props = OwnProps;

const OrderDetails: React.FC<Props> = () => {
  const history = useHistory();
  const match = useRouteMatch<{ orderId: ID; userId: ID }>();
  const classes = useStyles();
  const [isOrderComplete, setIsOrderComplete] = useState(false);
  const [contactUserType, setContactUserType] = useState<"customer" | "washer">(
    "washer"
  );
  const ordersActions = useActions<typeof OrdersActions>(OrdersActions);
  const { activeOrder } = useSelector((state: AppState) => state.orders);

  useEffect(() => {
    ordersActions.getActiveOrderData(match.params.orderId);
  }, [match.params.orderId, ordersActions]);
  useEffect(() => {
    const currentUserType = matchPath<{ userType: string }>(match.path, {
      path: `/:userType/:userId/orders/:orderId`,
    });
    if (currentUserType && currentUserType.params.userType === "washers") {
      setContactUserType("customer");
    }
  }, [match.path]);
  useEffect(() => {
    if (activeOrder && activeOrder.status === OrderStatus.COMPLETED) {
      setIsOrderComplete(true);
    } else {
      setIsOrderComplete(false);
    }
  }, [activeOrder]);
  const handleGoBack = () => {
    history.goBack();
  };
  const orderPrice = useRoleOrderPrice(activeOrder);
  if (activeOrder) {
    const scheduledMomentDate = moment(activeOrder.scheduledTime);
    const scheduledDate = scheduledMomentDate.format("L");
    const scheduledTime = scheduledMomentDate.format("LT");
    const calculatedTime = activeOrder.packages.reduce((acc, pack) => {
      return acc + moment.duration(pack.duration).asMilliseconds();
    }, 0);

    const review =
      contactUserType === "washer"
        ? activeOrder.customerAboutWasherReview
        : activeOrder.washerAboutCustomerReview;
    const washTime =
      activeOrder.endTime && activeOrder.startTime
        ? getFormattedTimeDuration(
            getTimeDifference(activeOrder.startTime, activeOrder.endTime)
          )
        : getFormattedTimeDuration(calculatedTime);
    return (
      <Grid
        className={classes.container}
        container
        spacing={1}
        justify="space-between"
      >
        <Grid item xs={12}>
          <Paper className={classes.toolBar}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              height={1}
            >
              <Box display="flex" alignItems="center">
                <IconButton onClick={handleGoBack}>
                  <ArrowLeftIcon />
                </IconButton>
                <Box ml={2} textAlign="left">
                  <Box display="flex" alignItems="center">
                    <CopyToClipboard>
                      {({ copy }) => (
                        <Typography
                          variant="h4"
                          className={classes.orderId}
                          style={{ cursor: "pointer" }}
                          onClick={() => copy(activeOrder.id)}
                        >
                          {texts.orderDetails.orderNumber}
                          {activeOrder.id}
                        </Typography>
                      )}
                    </CopyToClipboard>
                    <OrderStatusLabel status={activeOrder.status} />
                  </Box>

                  <Typography variant="body1" color="textSecondary">
                    {scheduledDate} {scheduledTime}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex">
                <Box display="flex" mr={3}>
                  <RadialDivider className={classes.valuesDivider} />
                  <ValueWithIcon
                    IconComponent={FinanceIcon}
                    value={orderPrice}
                    title={texts.orderDetails.washPrice}
                  />
                </Box>
                <Box display="flex">
                  <RadialDivider className={classes.valuesDivider} />
                  <ValueWithIcon
                    IconComponent={StopwatchIcon}
                    value={washTime}
                    title={
                      !isOrderComplete
                        ? texts.orderDetails.expectedWashTime
                        : texts.orderDetails.washTime
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <UserContactsCard
            user={activeOrder[contactUserType]}
            review={review}
          />
        </Grid>
        <Grid item container spacing={1} xs={9}>
          <Grid item xs={12}>
            <CarInfo order={activeOrder} />
          </Grid>
          <Grid item xs={12}>
            <WashProcess
              packages={sortOrderPackages(activeOrder.packages)}
              startTime={activeOrder.startTime}
              isInProgress={activeOrder.status === OrderStatus.IN_PROGRESS}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <OrderLocationMap
            location={{
              lat: activeOrder.locationLat,
              lng: activeOrder.locationLong,
            }}
          />
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default OrderDetails;
