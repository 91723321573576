import React from "react";
import BorderedValue from "../../styled/BorderedValue";
import { StatusColors } from "../../models/utils.models";
import { OrderStatus } from "../../models/responses/order.model";
import Box from "@material-ui/core/Box";

interface OwnProps {
  status: OrderStatus;
}

type Props = OwnProps;

const OrderStatusLabel: React.FC<Props> = (props) => {
  return (
    <Box ml={2} mb={1}>
      {(() => {
        switch (props.status) {
          case OrderStatus.ACCEPTED: {
            return (
              <BorderedValue color={StatusColors.YELLOW}>
                Accepted
              </BorderedValue>
            );
          }
          case OrderStatus.CANCELED: {
            return (
              <BorderedValue color={StatusColors.RED}>Canceled</BorderedValue>
            );
          }
          case OrderStatus.COMPLETED: {
            return (
              <BorderedValue color={StatusColors.GREEN}>
                Completed
              </BorderedValue>
            );
          }
          case OrderStatus.DECLINED: {
            return (
              <BorderedValue color={StatusColors.RED}>Declined</BorderedValue>
            );
          }
          case OrderStatus.IN_PROGRESS: {
            return (
              <BorderedValue color={StatusColors.YELLOW}>
                In progress
              </BorderedValue>
            );
          }
          case OrderStatus.ALL_PACKAGES_COMPLETE: {
            return (
              <BorderedValue color={StatusColors.YELLOW}>
                All packages complete
              </BorderedValue>
            );
          }
          case OrderStatus.WASHER_ARRIVED: {
            return (
              <BorderedValue color={StatusColors.YELLOW}>
                Washer arrived
              </BorderedValue>
            );
          }
          case OrderStatus.OFFERED: {
            return (
              <BorderedValue color={StatusColors.YELLOW}>Offered</BorderedValue>
            );
          }
          case OrderStatus.WASHER_ARRIVING: {
            return (
              <BorderedValue color={StatusColors.YELLOW}>
                Washer arriving
              </BorderedValue>
            );
          }
          case OrderStatus.PUBLIC_OFFER: {
            return (
              <BorderedValue color={StatusColors.YELLOW}>
                Public offer
              </BorderedValue>
            );
          }
          default:
            return null;
        }
      })()}
    </Box>
  );
};

export default OrderStatusLabel;
