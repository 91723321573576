import React from "react";

interface OwnProps {}

type Props = OwnProps;

const DashboardIcon: React.FC<Props> = () => {
  return (
    <svg
      id="menu_1_"
      data-name="menu (1)"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g id="Group_80" data-name="Group 80" transform="translate(9.833)">
        <g id="Group_79" data-name="Group 79">
          <path
            id="Path_409"
            data-name="Path 409"
            d="M281.4,0h-2.63A2.772,2.772,0,0,0,276,2.768V5.4a2.772,2.772,0,0,0,2.768,2.768h2.63A2.772,2.772,0,0,0,284.167,5.4V2.768A2.772,2.772,0,0,0,281.4,0Zm1.384,5.4A1.386,1.386,0,0,1,281.4,6.783h-2.63A1.386,1.386,0,0,1,277.384,5.4V2.768a1.386,1.386,0,0,1,1.384-1.384h2.63a1.386,1.386,0,0,1,1.384,1.384Z"
            transform="translate(-276)"
          />
        </g>
      </g>
      <g id="Group_82" data-name="Group 82">
        <g id="Group_81" data-name="Group 81">
          <path
            id="Path_410"
            data-name="Path 410"
            d="M5.4,0H2.768A2.772,2.772,0,0,0,0,2.768V5.4A2.772,2.772,0,0,0,2.768,8.167H5.4A2.772,2.772,0,0,0,8.167,5.4V2.768A2.772,2.772,0,0,0,5.4,0ZM6.783,5.4A1.386,1.386,0,0,1,5.4,6.783H2.768A1.386,1.386,0,0,1,1.384,5.4V2.768A1.386,1.386,0,0,1,2.768,1.384H5.4A1.386,1.386,0,0,1,6.783,2.768Z"
          />
        </g>
      </g>
      <g id="Group_84" data-name="Group 84" transform="translate(0 9.833)">
        <g id="Group_83" data-name="Group 83">
          <path
            id="Path_411"
            data-name="Path 411"
            d="M5.4,276H2.768A2.772,2.772,0,0,0,0,278.768v2.63a2.772,2.772,0,0,0,2.768,2.768H5.4A2.772,2.772,0,0,0,8.167,281.4v-2.63A2.772,2.772,0,0,0,5.4,276Zm1.384,5.4A1.386,1.386,0,0,1,5.4,282.783H2.768A1.386,1.386,0,0,1,1.384,281.4v-2.63a1.386,1.386,0,0,1,1.384-1.384H5.4a1.386,1.386,0,0,1,1.384,1.384Z"
            transform="translate(0 -276)"
          />
        </g>
      </g>
      <g id="Group_86" data-name="Group 86" transform="translate(9.833 9.833)">
        <g id="Group_85" data-name="Group 85">
          <path
            id="Path_412"
            data-name="Path 412"
            d="M283.475,280.706a.692.692,0,0,0-.692.692,1.386,1.386,0,0,1-1.384,1.384h-2.63a1.386,1.386,0,0,1-1.384-1.384v-2.63a1.386,1.386,0,0,1,1.384-1.384h2.63a1.385,1.385,0,0,1,1.269.83.692.692,0,0,0,1.268-.555A2.77,2.77,0,0,0,281.4,276h-2.63A2.772,2.772,0,0,0,276,278.768v2.63a2.772,2.772,0,0,0,2.768,2.768h2.63a2.772,2.772,0,0,0,2.768-2.768A.692.692,0,0,0,283.475,280.706Z"
            transform="translate(-276 -276)"
          />
        </g>
      </g>
    </svg>
  );
};

export default DashboardIcon;
