import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { authApi } from "../../api/auth.api";
import {
  AuthActions,
  AuthActionTypes,
  changePasswordType,
  confirmResetCodeType,
  confirmVerifyCodeType,
  sendResetCodeToEmailType,
  signInType,
  signUpSendUserDataType,
  signUpSendVerificationCodeType,
  updateCurrentUserDataType,
  verifyUserEmailType,
  verifyUserPhoneNumberType,
} from "../actions/auth.actions";
import { removeToken, setToken } from "../../helpers/auth";
import { AppState } from "../reducers";
import { userApi } from "../../api/user.api";
import {
  ResponseStatus,
  ResultModel,
} from "../../models/responses/result.model";
import { User } from "../../models/responses/user.model";
import { showError } from "./utils";
import { transformAndValidate } from "class-transformer-validator";
import { Auth } from "../../models/responses/auth.model";
import { AxiosResponse } from "axios";
import { AppActions } from "../actions/app.actions";
import { SnackbarVariant } from "../../models/utils.models";
import { VERIFY_TYPE } from "../../models/requests/Credentials";

function* signIn(action: signInType) {
  try {
    const { data } = yield authApi.authorize(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const authData = yield transformAndValidate(Auth, data.data);
      yield setToken(authData.token);
      yield call(getMyUserData);
    }
  } catch (e) {
    yield put(AuthActions.signInFailure(e.message));
    yield showError(e);
  }
}

export function* getMyUserData() {
  try {
    const { data } = yield userApi.getMyUser();
    if (data.result === ResponseStatus.SUCCESS) {
      const authorizedUser = yield transformAndValidate(User, data.data);
      yield put(AuthActions.setMyUserData(authorizedUser));
    }
  } catch (e) {
    yield put(AuthActions.signInFailure(e.message));
    yield call(logOut);
    yield showError(e);
  }
}

function* logOut() {
  try {
    yield removeToken();
    yield put(AuthActions.unauthorize());
  } catch (e) {
    yield showError(e);
  }
}

function* sendCode(action: sendResetCodeToEmailType) {
  try {
    const { data } = yield authApi.resetPasswordFromEmail(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      yield put(AuthActions.sendResetCodeSuccess(action.payload.email));
    }
  } catch (e) {
    yield put(AuthActions.sendResetCodeFailure(e.message));
    yield showError(e);
  }
}

function* confirmCode(action: confirmResetCodeType) {
  try {
    const { data } = yield authApi.checkCodeFromEmail(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const authData = yield transformAndValidate(Auth, data.data);
      yield put(AuthActions.confirmResetCodeSuccess(authData.token));
    }
  } catch (e) {
    yield put(AuthActions.confirmResetCodeFailure(e.message));
    yield showError(e);
  }
}

function* changePassword(action: changePasswordType) {
  try {
    const token = yield select(
      (state: AppState) => state.auth.accessTokenForForgotPassword
    );
    const { data } = yield authApi.updateMyUserData(action.payload, token);
    if (data.result === ResponseStatus.SUCCESS) {
      yield setToken(token);
      yield call(getMyUserData);
    }
  } catch (e) {
    yield put(AuthActions.changePasswordFailure(e.message));
    yield showError(e);
  }
}

function* signUpSendVerificationCodes(action: signUpSendVerificationCodeType) {
  try {
    const responses: AxiosResponse<ResultModel>[] = yield all([
      call(
        [authApi, authApi.sendPhoneAuthorizationCode],
        {
          phoneNumber: action.payload.phoneNumber,
          template: "Your Washerly code is $otp",
        },
        "sign_up"
      ),
      call([authApi, authApi.sendEmailAuthorizationCode], {
        email: action.payload.email,
        template: "Your Washerly verification code is $otp",
      }),
    ]);
    if (responses.every(({ data }) => data.result === ResponseStatus.SUCCESS)) {
      yield put(AuthActions.signUpSendVerificationCodesSuccess());
    }
  } catch (e) {
    yield put(AuthActions.signUpFailure(e.message));
    yield showError(e);
  }
}

function* signUpSendUserData(action: signUpSendUserDataType) {
  try {
    const { data } = yield authApi.signUp(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const authData = yield transformAndValidate(Auth, data.data);
      yield put(AuthActions.signUpSuccess());
      yield setToken(authData.token);
      yield call(getMyUserData);
    }
  } catch (e) {
    yield put(AuthActions.signUpFailure(e.message));
    yield showError(e);
  }
}

function* updateCurrentUserData(action: updateCurrentUserDataType) {
  try {
    const { data } = yield userApi.updateUserPersonalData(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const updatedUser = yield transformAndValidate(User, data.data);
      yield put(AuthActions.updateCurrentUserSuccess(updatedUser));
      yield put(
        AppActions.showSnackbar({
          variant: SnackbarVariant.SUCCESS,
          message: "User data update success!",
        })
      );
    }
  } catch (e) {
    yield put(AuthActions.updateCurrentUserFailure(e.message));
    yield showError(e);
  }
}

function* verifyUserEmail(action: verifyUserEmailType) {
  try {
    const { data } = yield authApi.sendVerifyCode({
      ...action.payload,
      type: VERIFY_TYPE.EMAIL,
    });
    if (data.result === ResponseStatus.SUCCESS) {
      yield put(AuthActions.sendVerifyCodeSuccess());
    }
  } catch (e) {
    yield put(AuthActions.sendVerifyCodeFailure(e.message));
    yield showError(e);
  }
}

function* verifyUserPhoneNumber(action: verifyUserPhoneNumberType) {
  try {
    const { data } = yield authApi.sendVerifyCode({
      ...action.payload,
      type: VERIFY_TYPE.PHONE,
    });
    if (data.result === ResponseStatus.SUCCESS) {
      yield put(AuthActions.sendVerifyCodeSuccess());
    }
  } catch (e) {
    yield put(AuthActions.sendVerifyCodeFailure(e.message));
    yield showError(e);
  }
}

function* confirmVerifyCode(action: confirmVerifyCodeType) {
  try {
    const { data } = yield authApi.confirmVerifyCode(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      yield put(AuthActions.confirmVerifyCodeSuccess());
      yield put(
        AppActions.showSnackbar({
          variant: SnackbarVariant.SUCCESS,
          message: "User data changed successfully!",
        })
      );
      yield call(getMyUserData);
      yield put(AuthActions.closeChangeCredentialModal());
    }
  } catch (e) {
    yield put(AuthActions.confirmVerifyCodeFailure(e.message));
    yield showError(e);
  }
}

export function* AuthSagas() {
  yield takeLatest(AuthActionTypes.SIGN_IN, signIn);
  yield takeLatest(AuthActionTypes.SEND_RESET_CODE_TO_EMAIL, sendCode);
  yield takeLatest(AuthActionTypes.CONFIRM_RESET_CODE, confirmCode);
  yield takeLatest(AuthActionTypes.CHANGE_PASSWORD, changePassword);
  yield takeLatest(AuthActionTypes.LOG_OUT, logOut);
  yield takeLatest(AuthActionTypes.GET_MY_USER_DATA, getMyUserData);
  yield takeLatest(
    AuthActionTypes.SIGN_UP_SEND_VERIFICATION_CODES,
    signUpSendVerificationCodes
  );
  yield takeLatest(AuthActionTypes.SIGN_UP_SEND_USER_DATA, signUpSendUserData);
  yield takeLatest(
    AuthActionTypes.UPDATE_CURRENT_USER_DATA,
    updateCurrentUserData
  );
  yield takeLatest(AuthActionTypes.VERIFY_USER_EMAIL, verifyUserEmail);
  yield takeLatest(
    AuthActionTypes.VERIFY_USER_PHONE_NUMBER,
    verifyUserPhoneNumber
  );
  yield takeLatest(AuthActionTypes.CONFIRM_VERIFY_CODE, confirmVerifyCode);
}
