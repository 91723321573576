import React from "react";
import useActions from "../../../../hooks/useActions";
import { AuthActions } from "../../../../store/actions/auth.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import { Formik, FormikHelpers } from "formik";
import { EmailConfirmationCodeSchema } from "../../../../helpers/validations";
import FormLayout from "../../../ForgotPasswordModal/FormLayout";
import { Grid } from "@material-ui/core";
import FormInput from "../../../../shared/FormInput";
import SecureInput from "../../../../shared/SecureInput";

interface OwnProps {}

type Props = OwnProps;

interface CodeForm {
  authorizationCode: string;
}

const ConfirmEmailCodeForm: React.FC<Props> = () => {
  const authActions = useActions<typeof AuthActions>(AuthActions);
  const authState = useSelector((state: AppState) => state.auth);
  const handleCodeSubmit = (
    values: CodeForm,
    actions: FormikHelpers<CodeForm>
  ) => {
    authActions.confirmVerifyCode(values.authorizationCode);
    actions.setSubmitting(false);
  };
  return (
    <Formik
      initialValues={{ authorizationCode: "" }}
      onSubmit={handleCodeSubmit}
      validationSchema={EmailConfirmationCodeSchema}
    >
      {({
        values,
        handleChange,
        errors,
        touched,
        isValid,
        handleSubmit,
        handleBlur,
      }) => (
        <FormLayout
          buttonColor="primary"
          buttonText="Confirm"
          buttonDisabled={!isValid}
          description="Enter validation code from sms on field bellow"
          handleSubmit={handleSubmit}
          loading={authState.isConfirmVerifyCodeLoading}
        >
          <Grid item xs={7}>
            <FormInput
              type="email"
              value={authState.changeCredentialModalState.newEmail}
              variant="filled"
              margin="normal"
              fullWidth
              id="email"
              label="New Email address"
              name="email"
              disabled
            />
          </Grid>
          <Grid item xs={5}>
            <SecureInput
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.authorizationCode}
              variant="filled"
              margin="normal"
              fullWidth
              name="authorizationCode"
              label="Code"
              id="verification_code"
              autoFocus
              error={!!errors.authorizationCode && touched.authorizationCode}
              helperText={
                errors.authorizationCode &&
                touched.authorizationCode &&
                errors.authorizationCode
              }
            />
          </Grid>
        </FormLayout>
      )}
    </Formik>
  );
};

export default ConfirmEmailCodeForm;
