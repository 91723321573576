import React from "react";
import { RouteParams } from "../../../constants/routeNames";
import OrderDetails from "../../../components/OrderDetails";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useTitle } from "react-use";
import Orders from "./Orders";

interface OwnProps {}

type Props = OwnProps;

const OrdersRouter: React.FC<Props> = (props) => {
  useTitle(`Washerly Orders`);
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={match.path}>
        <Orders />
      </Route>
      <Route exact path={`${match.path}/:${RouteParams.ORDER_ID}`}>
        <OrderDetails />
      </Route>
    </Switch>
  );
};

export default OrdersRouter;
