import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import texts from "../../../../constants/texts";
import { useHistory } from "react-router-dom";
import { RouteNames } from "../../../../constants/routeNames";

interface OwnProps {}

type Props = OwnProps;

const RequestPayoutButton: React.FC<Props> = () => {
  const history = useHistory();
  return (
    <Grid item xs={4}>
      <Box display="flex" justifyContent="flex-end" alignItems="center" p={2}>
        <Button
          style={{ width: 200 }}
          variant="outlined"
          color="secondary"
          onClick={() => history.push(`${RouteNames.REQUEST_PAYOUT}`)}
        >
          {texts.roles.washer.payouts.requestPayoutButton}
        </Button>
      </Box>
    </Grid>
  );
};

export default RequestPayoutButton;
