import React from "react";

interface OwnProps {}

type Props = OwnProps;

const VisibleEyeIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.196"
      height="10"
      viewBox="0 0 13.196 10"
    >
      <g id="eye" transform="translate(0 -62.003)">
        <g
          id="Group_121"
          data-name="Group 121"
          transform="translate(3.686 64.065)"
        >
          <g id="Group_120" data-name="Group 120" transform="translate(0 0)">
            <path
              id="Path_435"
              data-name="Path 435"
              d="M145.952,142a2.938,2.938,0,1,0,2.938,2.938A2.941,2.941,0,0,0,145.952,142Zm0,4.845a1.907,1.907,0,1,1,1.907-1.907A1.909,1.909,0,0,1,145.952,146.847Z"
              transform="translate(-143.014 -142.002)"
              fill="#797979"
            />
          </g>
        </g>
        <g id="Group_123" data-name="Group 123" transform="translate(0 62.003)">
          <g id="Group_122" data-name="Group 122" transform="translate(0 0)">
            <path
              id="Path_436"
              data-name="Path 436"
              d="M12.862,65.878a9.964,9.964,0,0,0-2.3-2.439A6.558,6.558,0,0,0,6.609,62a6.592,6.592,0,0,0-3.953,1.432A10.035,10.035,0,0,0,.339,65.871a2.059,2.059,0,0,0,0,2.265,10.035,10.035,0,0,0,2.317,2.436A6.592,6.592,0,0,0,6.609,72a7.149,7.149,0,0,0,4.767-2.123.515.515,0,1,0-.705-.752,6.147,6.147,0,0,1-4.062,1.844c-2.5,0-4.578-2.139-5.409-3.4a1.029,1.029,0,0,1,0-1.132c.831-1.264,2.906-3.4,5.409-3.4S11.174,65.175,12,66.44a1.031,1.031,0,0,1,0,1.125.515.515,0,1,0,.864.563A2.063,2.063,0,0,0,12.862,65.878Z"
              transform="translate(0 -62.003)"
              fill="#797979"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default VisibleEyeIcon;
