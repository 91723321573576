import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";

interface OwnProps {}

type Props = OwnProps;

const FeatureInProgress: React.FC<Props> = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box mt={10}>
          <Typography variant={"h1"} align="center">
            Feature in progress...
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FeatureInProgress;
