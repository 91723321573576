import { TablePaginationParams } from "../table.models";

export enum VerificationFormStatusFilter {
  ALL = "ALL",
  REJECT = "REJECT",
  REQUESTED_OR_APPROVE = "REQUESTED_OR_APPROVE",
}

export interface PendingWashersRequestParams extends TablePaginationParams {
  isCompanyEmployee?: boolean;
  status: VerificationFormStatusFilter;
}
