import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import theme from "./theme";

function withRoot(Component: any) {
  function WithRoot(props: object) {
    return (
      <ThemeProvider theme={theme}>
        <Component {...props} />
      </ThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
