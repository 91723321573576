import React from "react";
import Sessions from "../../../components/Sessions";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const WasherHistory: React.FC<Props> = () => {
  useTitle(`Washerly Sessions`);
  return <Sessions />;
};

export default WasherHistory;
