import {
  PendingWashersActions,
  PendingWashersActionsTypes,
} from "../actions/pendingWashers.actions";
import { PendingWashers } from "../../models/responses/pendingWasher.model";
import { formatDate } from "../../helpers/utils/date.utils";
import { ApplicationForm } from "../../models/responses/applicationForm.model";
import { VerificationFormStatus } from "../../models/requests/VerificationForm";
import { PendingUserStatus } from "../../models/utils.models";

class StateModel {
  pendingWashersLoading: boolean = false;
  pendingWashersError: string = "";
  pendingWashersSearch: string = "";
  pendingWashers: PendingWashers = { items: [], total: 0 };
  activeWasherCV: ApplicationForm | null = null;
  activeWasherCVLoading: boolean = false;
  activeWasherCVError: string = "";
  changeActiveCvStatusLoading: boolean = false;
  changeActiveCvStatusError: string = "";
}

const initialState = new StateModel();

export function pendingWashersReducer(
  state = initialState,
  action: PendingWashersActions
) {
  switch (action.type) {
    case PendingWashersActionsTypes.GET_PENDING_WASHERS: {
      return {
        ...state,
        pendingWashersLoading: true,
        pendingWashersError: "",
      };
    }
    case PendingWashersActionsTypes.GET_PENDING_WASHERS_SUCCESS: {
      return {
        ...state,
        pendingWashersLoading: false,
        pendingWashersError: "",
        pendingWashers: action.payload,
      };
    }
    case PendingWashersActionsTypes.GET_PENDING_WASHERS_FAILURE: {
      return {
        ...state,
        pendingWashersLoading: false,
        pendingWashersError: action.payload,
      };
    }
    case PendingWashersActionsTypes.SET_PENDING_WASHERS_SEARCH_VALUE: {
      return {
        ...state,
        pendingWashersSearch: action.payload,
      };
    }
    case PendingWashersActionsTypes.GET_ACTIVE_WASHER_CV: {
      return {
        ...state,
        activeWasherCVLoading: true,
        activeWasherCVError: "",
        activeWasherCV: null,
      };
    }
    case PendingWashersActionsTypes.GET_ACTIVE_WASHER_CV_SUCCESS: {
      return {
        ...state,
        activeWasherCVLoading: false,
        activeWasherCVError: "",
        activeWasherCV: action.payload,
      };
    }
    case PendingWashersActionsTypes.GET_ACTIVE_WASHER_CV_FAILURE: {
      return {
        ...state,
        activeWasherCVLoading: false,
        activeWasherCVError: action.payload,
      };
    }
    case PendingWashersActionsTypes.APPROVE_ACTIVE_WASHER_CV: {
      return {
        ...state,
        changeActiveCvStatusLoading: true,
        changeActiveCvStatusError: "",
      };
    }
    case PendingWashersActionsTypes.REJECT_ACTIVE_WASHER_CV: {
      return {
        ...state,
        changeActiveCvStatusLoading: true,
        changeActiveCvStatusError: "",
      };
    }
    case PendingWashersActionsTypes.CHANGE_WASHER_CV_STATUS_SUCCESS: {
      return {
        ...state,
        changeActiveCvStatusLoading: false,
        activeWasherCV: action.payload,
      };
    }
    case PendingWashersActionsTypes.CHANGE_WASHER_CV_STATUS_FAILURE: {
      return {
        ...state,
        changeActiveCvStatusLoading: true,
        changeActiveCvStatusError: action.payload,
      };
    }
    default:
      return state;
  }
}

function getFormattedUsers(state: StateModel) {
  return state.pendingWashers.items.map((user) => {
    const [date, time] = formatDate(user.createdTime);
    return {
      id: user.verificationForm.id,
      createdTime: `${date} ${time}`,
      email: user.email || "",
      fullName: `${user.firstName} ${user.secondName}`,
      phoneNumber: user.phoneNumber,
      status:
        user.verificationForm.status === VerificationFormStatus.APPROVE
          ? PendingUserStatus.EXAM
          : PendingUserStatus.PENDING,
      company: user.company?.name || "",
    };
  });
}

export const PendingWashersSelectors = {
  getFormattedUsers,
};
