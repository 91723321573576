import React from "react";
import { Route, Switch } from "react-router";
import { RouteNames } from "../constants/routeNames";
import AppRouter from "./AppRouter";
import SignUp from "../pages/auth/SignUp";

interface OwnProps {}

type Props = OwnProps;

const RootRouter: React.FC<Props> = () => {
  return (
    <Switch>
      <Route path={`/${RouteNames.SIGN_UP}`} component={SignUp} />
      <AppRouter />
    </Switch>
  );
};

export default RootRouter;
