import React from "react";
import FeatureInProgress from "../../shared/FeatureInProgress";

interface OwnProps {}

type Props = OwnProps;

const EditFinance: React.FC<Props> = () => {
  return <FeatureInProgress />;
};

export default EditFinance;
