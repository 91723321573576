import React, { FunctionComponent } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { unitSize } from "../../constants/sizes";
import CustomButton from "../../shared/CustomButton";

interface OwnProps {
  description: string;
  handleSubmit: () => void;
  buttonColor: "primary" | "secondary";
  buttonText: string;
  buttonDisabled: boolean;
  loading?: boolean;
}

type Props = OwnProps;

const FormLayout: FunctionComponent<Props> = (props) => {
  const {
    children,
    description,
    handleSubmit,
    buttonColor,
    buttonText,
    buttonDisabled,
    loading,
  } = props;
  return (
    <Box>
      <Box
        mt={1}
        width={unitSize * 45}
        height={unitSize * 6}
        textOverflow="ellipsis"
      >
        <Typography component="p" variant="body2" color="textSecondary">
          {description}
        </Typography>
      </Box>
      <Box mt={3}>
        <Grid container spacing={1}>
          {children}
        </Grid>
        <Box
          mt={6}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box width={0.6}>
            <Typography component="p" variant="body2" color="textSecondary">
              After you compete all field(s) click on the button
            </Typography>
          </Box>
          <CustomButton
            onClick={handleSubmit}
            color={buttonColor}
            rounded
            disabled={buttonDisabled || loading}
            type="submit"
            variant="contained"
            loading={loading}
          >
            {buttonText}
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default FormLayout;
