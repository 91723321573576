import styled from "styled-components";
import colors from "../constants/colors";

interface ProgressBarProps {
  percent: number;
}

const getStatusColor = (value: number) => {
  if (value > 90) {
    return colors.green;
  }
  if (value > 30) {
    return colors.yellow;
  }
  return colors.red;
};

export default styled.span`
  position: absolute;
  content: "";
  display: block;
  left: 0;
  height: 100%;
  width: ${(props: ProgressBarProps) =>
    props.percent <= 100 ? props.percent : 100}%;
  background-color: ${(props: ProgressBarProps) =>
    getStatusColor(props.percent)};
  border-radius: 20px;
  transition: 2s ease;
`;
