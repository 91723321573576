import { AuthActions, AuthActionTypes } from "../actions/auth.actions";
import { User, UserRole } from "../../models/responses/user.model";
import { VERIFY_TYPE } from "../../models/requests/Credentials";

interface IChangeCredentialModalState {
  isOpen: boolean;
  type: VERIFY_TYPE | null;
  step: number;
  newEmail: string;
  newPhone: string;
}

class StateModel {
  isForgotModalOpen = false;
  forgotModalStep = 1;
  resetEmail = "";
  isSignInLoading = false;
  signInError = "";
  isSendCodeLoading = false;
  sendCodeError = "";
  isConfirmCodeLoading = false;
  confirmCodeError = "";
  isChangePasswordLoading = false;
  changePasswordError = "";
  accessTokenForForgotPassword = "";
  authorizedUser: User | null = null;
  isAuthChecked = false;
  isSignUpLoading = false;
  signUpError = "";
  isSignUpCodesSent = false;
  isUpdateUserLoading = false;
  updateUserError = "";
  isSendVerifyCodeLoading = false;
  sendVerifyCodeError = "";
  isConfirmVerifyCodeLoading = false;
  confirmVerifyCodeError = "";
  changeCredentialModalState: IChangeCredentialModalState = {
    isOpen: false,
    type: null,
    step: 1,
    newEmail: "",
    newPhone: "",
  };
}

const initialState = new StateModel();

export function authReducer(
  state = initialState,
  action: AuthActions
): StateModel {
  switch (action.type) {
    case AuthActionTypes.SET_MY_USER_DATA: {
      return {
        ...state,
        authorizedUser: action.payload,
        isAuthChecked: true,
        isSignInLoading: false,
      };
    }
    case AuthActionTypes.SIGN_IN: {
      return {
        ...state,
        isSignInLoading: true,
        signInError: "",
      };
    }
    case AuthActionTypes.SIGN_IN_FAILURE: {
      return {
        ...state,
        signInError: action.payload,
        isSignInLoading: false,
      };
    }
    case AuthActionTypes.UNAUTHORIZE: {
      return {
        ...state,
        isAuthChecked: true,
        authorizedUser: null,
      };
    }
    case AuthActionTypes.OPEN_FORGOT_MODAL: {
      return {
        ...state,
        forgotModalStep: 1,
        isForgotModalOpen: true,
      };
    }
    case AuthActionTypes.CLOSE_FORGOT_MODAL: {
      return {
        ...initialState,
        isAuthChecked: true,
      };
    }
    case AuthActionTypes.SEND_RESET_CODE_TO_EMAIL: {
      return {
        ...state,
        isSendCodeLoading: true,
        sendCodeError: "",
      };
    }
    case AuthActionTypes.SEND_RESET_CODE_SUCCESS: {
      return {
        ...state,
        resetEmail: action.payload,
        isSendCodeLoading: false,
        forgotModalStep: 2,
      };
    }
    case AuthActionTypes.SEND_RESET_CODE_FAILURE: {
      return {
        ...state,
        isSendCodeLoading: false,
        sendCodeError: action.payload,
      };
    }
    case AuthActionTypes.CONFIRM_RESET_CODE: {
      return {
        ...state,
        isConfirmCodeLoading: true,
        confirmCodeError: "",
      };
    }
    case AuthActionTypes.CONFIRM_RESET_CODE_SUCCESS: {
      return {
        ...state,
        isConfirmCodeLoading: false,
        forgotModalStep: 3,
        accessTokenForForgotPassword: action.payload,
      };
    }
    case AuthActionTypes.CONFIRM_RESET_CODE_FAILURE: {
      return {
        ...state,
        isConfirmCodeLoading: false,
        confirmCodeError: action.payload,
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD: {
      return {
        ...state,
        isChangePasswordLoading: true,
        changePasswordError: "",
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        isChangePasswordLoading: false,
        changePasswordError: action.payload,
      };
    }
    case AuthActionTypes.SIGN_UP_SEND_VERIFICATION_CODES: {
      return {
        ...state,
        isSignUpLoading: true,
        signUpError: "",
      };
    }
    case AuthActionTypes.SIGN_UP_SEND_USER_DATA: {
      return {
        ...state,
        isSignUpLoading: true,
        signUpError: "",
      };
    }
    case AuthActionTypes.SIGN_UP_FAILURE: {
      return {
        ...state,
        isSignUpLoading: false,
        signUpError: action.payload,
      };
    }
    case AuthActionTypes.SIGN_UP_SEND_VERIFICATION_CODES_SUCCESS: {
      return {
        ...state,
        isSignUpLoading: false,
        isSignUpCodesSent: true,
      };
    }
    case AuthActionTypes.SIGN_UP_SUCCESS: {
      return {
        ...initialState,
      };
    }
    case AuthActionTypes.UPDATE_CURRENT_USER_DATA:
      return {
        ...state,
        isUpdateUserLoading: true,
        updateUserError: "",
      };
    case AuthActionTypes.UPDATE_CURRENT_USER_SUCCESS:
      return {
        ...state,
        isUpdateUserLoading: false,
        authorizedUser: action.payload,
      };
    case AuthActionTypes.UPDATE_CURRENT_USER_FAILURE:
      return {
        ...state,
        isUpdateUserLoading: false,
        updateUserError: action.payload,
      };
    case AuthActionTypes.VERIFY_USER_EMAIL:
      return {
        ...state,
        changeCredentialModalState: {
          ...state.changeCredentialModalState,
          newEmail: action.payload.email,
        },
        isSendVerifyCodeLoading: true,
        sendVerifyCodeError: "",
      };
    case AuthActionTypes.VERIFY_USER_PHONE_NUMBER:
      return {
        ...state,
        changeCredentialModalState: {
          ...state.changeCredentialModalState,
          newPhone: action.payload.phoneNumber,
        },
        isSendVerifyCodeLoading: true,
        sendVerifyCodeError: "",
      };
    case AuthActionTypes.SEND_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        isSendVerifyCodeLoading: false,
        changeCredentialModalState: {
          ...state.changeCredentialModalState,
          step: 2,
        },
      };
    case AuthActionTypes.SEND_VERIFY_CODE_FAILURE:
      return {
        ...state,
        isSendVerifyCodeLoading: false,
        sendVerifyCodeError: action.payload,
      };
    case AuthActionTypes.CONFIRM_VERIFY_CODE:
      return {
        ...state,
        isConfirmVerifyCodeLoading: true,
        confirmVerifyCodeError: "",
      };
    case AuthActionTypes.CONFIRM_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        isConfirmVerifyCodeLoading: false,
        changeCredentialModalState: {
          ...state.changeCredentialModalState,
          step: 1,
        },
      };
    case AuthActionTypes.CONFIRM_VERIFY_CODE_FAILURE:
      return {
        ...state,
        isConfirmVerifyCodeLoading: false,
        confirmVerifyCodeError: action.payload,
      };
    case AuthActionTypes.OPEN_CHANGE_CREDENTIAL_MODAL:
      return {
        ...state,
        changeCredentialModalState: {
          ...state.changeCredentialModalState,
          isOpen: true,
          type: action.payload,
        },
      };
    case AuthActionTypes.CLOSE_CHANGE_CREDENTIAL_MODAL:
      return {
        ...state,
        changeCredentialModalState: {
          ...initialState.changeCredentialModalState,
        },
      };
    default:
      return state;
  }
}

export const AuthSelectors = {
  isAuthorized: (state: StateModel) =>
    !!state.authorizedUser && state.isAuthChecked,
  getAuthorizedUserFullName: (state: StateModel) =>
    state.authorizedUser &&
    `${state.authorizedUser.firstName || ""} ${
      state.authorizedUser.secondName || ""
    }`,
  getAuthorizedUserRole: (state: StateModel) =>
    state.authorizedUser && state.authorizedUser.role,
  isAdmin: (state: StateModel) =>
    state.authorizedUser && state.authorizedUser.role === UserRole.ADMIN,
};
