import { Cars } from "../../models/responses/car.model";
import { CarsActions, CarsActionTypes } from "../actions/cars.actions";

class StateModel {
  carsForActiveUser: Cars = { items: [], total: 0 };
  carsForActiveUserLoading: boolean = false;
  carsForActiveUserError: string = "";
}

const initialState = new StateModel();

export function carsReducer(
  state = initialState,
  action: CarsActions
): StateModel {
  switch (action.type) {
    case CarsActionTypes.GET_CARS_FOR_ACTIVE_USER: {
      return {
        ...state,
        carsForActiveUserLoading: true,
        carsForActiveUserError: "",
        carsForActiveUser: initialState.carsForActiveUser,
      };
    }
    case CarsActionTypes.GET_CARS_FOR_ACTIVE_USER_SUCCESS: {
      return {
        ...state,
        carsForActiveUserLoading: false,
        carsForActiveUser: action.payload,
      };
    }
    case CarsActionTypes.GET_CARS_FOR_ACTIVE_USER_FAILURE: {
      return {
        ...state,
        carsForActiveUserLoading: false,
        carsForActiveUserError: action.payload,
      };
    }

    default:
      return state;
  }
}

export const CarsSelectors = {
  getFormattedCarsForActiveUser: (state: StateModel) =>
    state.carsForActiveUser.items.map(
      ({ id, licensePlate, carType, model, ordersCount, lastWash }) => ({
        id,
        licensePlate,
        model,
        lastWash,
        ordersCount,
        carTypeId: carType.name,
      })
    ),
};
