import React, { ChangeEvent, useState } from "react";
import Box from "@material-ui/core/Box";
import CustomButton from "../../../../../shared/CustomButton";
import TitledPaper from "../../../../../shared/TitledPaper";
import PrimaryIconWithText from "../../../../../shared/PrimaryIconWithText";
import BankIcon from "../../../../../shared/icons/BankIcon";
import { unitSize } from "../../../../../constants/sizes";
import InputWithAdornment from "../../../../../shared/InputWithAdornment";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog";
import useActions from "../../../../../hooks/useActions";
import { AppActions } from "../../../../../store/actions/app.actions";

interface OwnProps {
  onSubmit: (cardNumber: string) => void;
  disabled: boolean;
  loading: boolean;
}

type Props = OwnProps;

const SubmitBlock: React.FC<Props> = (props) => {
  const [bankAccount, setBankAccount] = useState("");
  const appActions = useActions<typeof AppActions>(AppActions);
  const [isBankAccountTouched, setIsBankAccountTouched] = useState(false);

  const handleBankAccountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBankAccount(event.target.value);
  };

  const handleSubmit = () => {
    appActions.showConfirmationDialog({
      withReason: false,
      title: "Create new payout",
      description: "Are you sure?",
      open: true,
      onSubmit: () => props.onSubmit(bankAccount.trim()),
    });
  };
  const handleBankAccountBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsBankAccountTouched(true);
  };

  return (
    <TitledPaper
      title={"Submit Payout"}
      subtitle={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
    >
      <Box
        flexBasis="100%"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mt={4}
      >
        <PrimaryIconWithText
          Icon={BankIcon}
          text="Bank Account Number"
          maxWidth={unitSize * 15}
        />
        <Box ml={6} mr={6} width={unitSize * 40}>
          <InputWithAdornment
            adornment="Account"
            onChange={handleBankAccountChange}
            onBlur={handleBankAccountBlur}
            helperText={
              isBankAccountTouched &&
              !bankAccount &&
              "Bank account cannot be empty"
            }
            error={isBankAccountTouched && !bankAccount}
            id="bankAccount"
            name="bankAccount"
          />
        </Box>
        <CustomButton
          color="secondary"
          disabled={props.disabled || props.loading || !bankAccount.trim()}
          onClick={handleSubmit}
          variant="contained"
          loading={props.loading}
        >
          Request Payout
        </CustomButton>
      </Box>
      <ConfirmationDialog />
    </TitledPaper>
  );
};

export default SubmitBlock;
