import React from "react";

interface OwnProps {}

type Props = OwnProps;

const UserIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
    >
      <path
        id="user_8_"
        data-name="user (8)"
        d="M16.572,11.426a8.431,8.431,0,0,0-2.15-1.346.7.7,0,1,0-.571,1.285,7.032,7.032,0,0,1,1.793,1.117,2.8,2.8,0,0,1,.95,2.107v1.3a.7.7,0,0,1-.7.7H2.109a.7.7,0,0,1-.7-.7v-1.3a2.8,2.8,0,0,1,.95-2.107c.71-.624,2.78-2.076,6.644-2.076a5.209,5.209,0,1,0-3.169-1.08,9.775,9.775,0,0,0-4.4,2.1A4.21,4.21,0,0,0,0,14.59v1.3A2.112,2.112,0,0,0,2.109,18H15.891A2.112,2.112,0,0,0,18,15.891v-1.3A4.21,4.21,0,0,0,16.572,11.426ZM5.2,5.2A3.8,3.8,0,1,1,9,9,3.8,3.8,0,0,1,5.2,5.2Zm0,0"
      />
    </svg>
  );
};

export default UserIcon;
