import React from "react";

interface OwnProps {}

type Props = OwnProps;

const WashersIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="18.75"
      height="20"
      viewBox="0 0 18.75 20"
    >
      <g id="Group_228" data-name="Group 228" transform="translate(-125 -551)">
        <g id="clean-car" transform="translate(125 551)">
          <path
            id="Path_535"
            data-name="Path 535"
            d="M332.344,5.312a.586.586,0,0,0,1.172,0,1.76,1.76,0,0,1,1.758-1.758.586.586,0,1,0,0-1.172,1.8,1.8,0,0,1-1.758-1.8.586.586,0,0,0-1.172,0,1.8,1.8,0,0,1-1.758,1.8.586.586,0,1,0,0,1.172A1.76,1.76,0,0,1,332.344,5.312Zm.586-2.953a3.048,3.048,0,0,0,.6.6,2.951,2.951,0,0,0-.6.594,2.948,2.948,0,0,0-.6-.594A3.048,3.048,0,0,0,332.93,2.359Zm0,0"
            transform="translate(-317.109)"
          />
          <path
            id="Path_536"
            data-name="Path 536"
            d="M0,100.961v2.383A1.761,1.761,0,0,0,1.172,105v.687a1.758,1.758,0,1,0,3.516,0V105.1h8.2v.586a1.758,1.758,0,1,0,3.516,0V105a1.761,1.761,0,0,0,1.172-1.657v-2.383a2.934,2.934,0,0,0-2.463-2.892l-.848-3.394a1.755,1.755,0,0,0-1.705-1.331H6.445a1.76,1.76,0,0,1-1.758-1.758.586.586,0,1,0-1.172,0,1.76,1.76,0,0,1-1.758,1.758.586.586,0,0,0,0,1.172,1.756,1.756,0,0,1,1.414.715l-.71,2.838A2.934,2.934,0,0,0,0,100.961Zm3.516,4.727a.586.586,0,1,1-1.172,0V105.1H3.516Zm11.133.586a.587.587,0,0,1-.586-.586V105.1h1.172v.586A.587.587,0,0,1,14.648,106.273Zm1.758-5.312v2.383a.587.587,0,0,1-.586.586H1.758a.587.587,0,0,1-.586-.586v-2.383A1.76,1.76,0,0,1,2.928,99.2H14.651A1.76,1.76,0,0,1,16.406,100.961Zm-6.874-2.93a1.171,1.171,0,0,1,2.029,0ZM6.445,94.516h6.116a.585.585,0,0,1,.568.444l.768,3.072H12.816a2.344,2.344,0,0,0-4.539,0H3.68l.3-1.186a.585.585,0,0,0,.711-.572A1.76,1.76,0,0,1,6.445,94.516ZM4.1,93.342a2.951,2.951,0,0,0,.588.588,2.951,2.951,0,0,0-.588.588,2.951,2.951,0,0,0-.588-.588A2.955,2.955,0,0,0,4.1,93.342Zm0,0"
            transform="translate(0 -87.445)"
          />
          <path
            id="Path_537"
            data-name="Path 537"
            d="M302.666,331.058l-2.344,1.172a.586.586,0,1,0,.524,1.048l2.344-1.172a.586.586,0,1,0-.524-1.048Zm0,0"
            transform="translate(-288.279 -318.067)"
          />
          <path
            id="Path_538"
            data-name="Path 538"
            d="M63.188,332.23l-2.344-1.172a.586.586,0,1,0-.524,1.048l2.344,1.172a.586.586,0,1,0,.524-1.048Zm0,0"
            transform="translate(-57.653 -318.067)"
          />
          <path
            id="Path_539"
            data-name="Path 539"
            d="M182.93,361h-2.344a.586.586,0,0,0,0,1.172h2.344a.586.586,0,0,0,0-1.172Zm0,0"
            transform="translate(-172.969 -346.898)"
          />
        </g>
      </g>
    </svg>
  );
};

export default WashersIcon;
