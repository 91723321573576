import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import { PaymentMethod } from "../../models/responses/payment.model";

export enum PaymentActionTypes {
  GET_PAYMENT_METHODS_FOR_ACTIVE_USER = "[Payment] Get payment methods for active user",
  GET_PAYMENT_METHODS_FOR_ACTIVE_USER_SUCCESS = "[Payment] Get payment methods for active user success",
  GET_PAYMENT_METHODS_FOR_ACTIVE_USER_FAILURE = "[Payment] Get payment methods for active user failure",
}

export const PaymentActions = {
  getPaymentMethodsForActiveUser: (payload: string) =>
    createAction(
      PaymentActionTypes.GET_PAYMENT_METHODS_FOR_ACTIVE_USER,
      payload
    ),
  getPaymentMethodsForActiveUserSuccess: (payload: PaymentMethod[]) =>
    createAction(
      PaymentActionTypes.GET_PAYMENT_METHODS_FOR_ACTIVE_USER_SUCCESS,
      payload
    ),
  getPaymentMethodsForActiveUserFailure: (payload: string) =>
    createAction(
      PaymentActionTypes.GET_PAYMENT_METHODS_FOR_ACTIVE_USER_FAILURE,
      payload
    ),
};

export type PaymentActions = ActionsUnion<typeof PaymentActions>;

export type getPaymentMethodsForActiveUserType = ActionsOfType<
  PaymentActions,
  PaymentActionTypes.GET_PAYMENT_METHODS_FOR_ACTIVE_USER
>;
