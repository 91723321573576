import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import { WasherVerificationFormData } from "../../models/requests/VerificationForm";
import { ApplicationForm } from "../../models/responses/applicationForm.model";

export enum WasherVerificationActionTypes {
  SEND_VERIFICATION_FORM = "[WasherVerification] Send verification form",
  SEND_VERIFICATION_FORM_SUCCESS = "[WasherVerification] Send verification form success",
  SEND_VERIFICATION_FORM_FAILURE = "[WasherVerification] Send verification form failure",

  GET_MY_VERIFICATION_FORM = "[WasherVerification] Get my verification form",
  GET_VERIFICATION_FORM_SUCCESS = "[WasherVerification] Get verification form success",
  GET_VERIFICATION_FORM_FAILURE = "[WasherVerification] Get verification form failure",

  SUBMIT_EXAM = "[WasherVerification] Submit exam",
  SUBMIT_EXAM_SUCCESS = "[WasherVerification] Submit exam success",
  SUBMIT_EXAM_FAILURE = "[WasherVerification] Submit exam failure",
}

export const WasherVerificationActions = {
  sendVerificationForm: (payload: WasherVerificationFormData) =>
    createAction(WasherVerificationActionTypes.SEND_VERIFICATION_FORM, payload),
  sendVerificationFormSuccess: () =>
    createAction(WasherVerificationActionTypes.SEND_VERIFICATION_FORM_SUCCESS),
  sendVerificationFormFailure: (payload: string) =>
    createAction(
      WasherVerificationActionTypes.SEND_VERIFICATION_FORM_FAILURE,
      payload
    ),

  getMyVerificationForm: () =>
    createAction(WasherVerificationActionTypes.GET_MY_VERIFICATION_FORM),
  getMyVerificationFormSuccess: (payload: ApplicationForm) =>
    createAction(
      WasherVerificationActionTypes.GET_VERIFICATION_FORM_SUCCESS,
      payload
    ),
  getMyVerificationFormFailure: (payload: string) =>
    createAction(
      WasherVerificationActionTypes.GET_VERIFICATION_FORM_FAILURE,
      payload
    ),

  submitExam: () => createAction(WasherVerificationActionTypes.SUBMIT_EXAM),
  submitExamSuccess: () =>
    createAction(WasherVerificationActionTypes.SUBMIT_EXAM_SUCCESS),
  submitExamFailure: (payload: string) =>
    createAction(WasherVerificationActionTypes.SUBMIT_EXAM_FAILURE, payload),
};

export type WasherVerificationActions = ActionsUnion<
  typeof WasherVerificationActions
>;

export type sendVerificationFormType = ActionsOfType<
  WasherVerificationActions,
  WasherVerificationActionTypes.SEND_VERIFICATION_FORM
>;
