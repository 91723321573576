import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import colors from "../constants/colors";
import { Switch, SwitchProps } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 48,
    height: 28,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
        border: "none",
      },
    },
    "&:hover": {
      backgroundColor: "initial",
    },
  },
  thumb: {
    width: 24,
    height: 24,
    borderRadius: 5,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.11)",
  },
  track: {
    position: "relative",
    borderRadius: 5,
    backgroundColor: "rgba(0, 0, 0, .07)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"]),
    "&:after": {
      display: "block",
      position: "absolute",
      content: "''",
      width: 1,
      height: 6,
      backgroundColor: colors.textHint,
      right: 11,
      top: 12,
    },
  },
  checked: {},
  focusVisible: {},
}));

type Props = SwitchProps;

const MuiSwitch: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Switch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

export default MuiSwitch;
