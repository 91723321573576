import styled from "styled-components";
import colors from "../constants/colors";

export default styled.div`
  min-height: 43px;
  min-width: 140px;
  padding: 10px 35px;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  background-color: ${colors.darkBlue};
  border-radius: 80px;
  font-size: 17px;
  font-family: "Roboto", sans-serif;
  color: ${colors.white};
`;
