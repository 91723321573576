import {
  CompanyWasherGeneralStatistics,
  CompanyWasherStatistics,
  GeneralStatistic,
  PromoterStatistic,
  WasherStatistic,
} from "../../models/responses/statistic.model";
import {
  StatisticActions,
  StatisticActionTypes,
} from "../actions/statistic.actions";

class StateModel {
  generalStatistic: GeneralStatistic = {
    totalWashers: 0,
    totalClients: 0,
    beingWashed: 0,
    completeWashed: 0,
  };
  washerStatistic: WasherStatistic | null = null;
  promoterStatistic: PromoterStatistic | null = null;
  companyWasherGeneralStatistics: CompanyWasherGeneralStatistics | null = null;
  companyWasherStatistics: CompanyWasherStatistics = {
    avatar: "",
    email: "",
    id: "",
    name: "",
    our_commission: "",
    rating: "",
    registration_datetime: "",
    total_wash_count: 0,
  };
}

const initialState = new StateModel();

export function statisticReducer(
  state = initialState,
  action: StatisticActions
): StateModel {
  switch (action.type) {
    case StatisticActionTypes.SET_GENERAL_STATISTIC: {
      return {
        ...state,
        generalStatistic: action.payload,
      };
    }
    case StatisticActionTypes.SET_WASHER_STATISTIC: {
      return {
        ...state,
        washerStatistic: action.payload,
      };
    }
    case StatisticActionTypes.SET_PROMOTER_STATISTIC: {
      return {
        ...state,
        promoterStatistic: action.payload,
      };
    }
    case StatisticActionTypes.SET_COMPANY_WASHER_GENERAL_STATISTICS: {
      return {
        ...state,
        companyWasherGeneralStatistics: action.payload,
      };
    }
    case StatisticActionTypes.SET_COMPANY_WASHER_STATISTICS: {
      return {
        ...state,
        companyWasherStatistics: action.payload,
      };
    }
    default:
      return state;
  }
}
