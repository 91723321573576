import { IsDate, IsString } from "class-validator";
import { Type } from "class-transformer";

export class BasicModel {
  @IsDate()
  @Type(() => Date)
  createdTime: Date;
  @IsString()
  id: string;
}
