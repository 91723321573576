import { HeadCell, PromotersTableColumns } from "../../../models/table.models";
import texts from "../../../constants/texts";

const promotersListHeadCells: HeadCell<PromotersTableColumns>[] = [
  {
    id: PromotersTableColumns.ID,
    label: texts.users.tableHeaders.id,
    size: 1,
  },
  {
    id: PromotersTableColumns.EMAIL,
    label: texts.users.tableHeaders.email,
    size: 3,
  },
  {
    id: PromotersTableColumns.FULL_NAME,
    label: texts.users.tableHeaders.fullName,
    size: 3,
  },
  {
    id: PromotersTableColumns.PHONE_NUMBER,
    label: texts.users.tableHeaders.phone,
    size: 3,
  },
  {
    id: PromotersTableColumns.REFERRALS,
    label: texts.users.tableHeaders.referrals,
    size: 1,
  },
];

export default promotersListHeadCells;
