import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { RouteParams } from "../../../constants/routeNames";
import WasherProfile from "./WasherProfile";
import WashersPage from "./WashersPage";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const WashersRouter: React.FC<Props> = () => {
  useTitle(`Washerly Washers`);
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <WashersPage />
      </Route>
      <Route
        path={`${path}/:${RouteParams.USER_ID}/`}
        component={WasherProfile}
      />
    </Switch>
  );
};

export default WashersRouter;
