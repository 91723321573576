import React from "react";
import { Box, IconButton, Paper, Typography } from "@material-ui/core";
import ArrowLeftIcon from "../../../../../../shared/icons/ArrowLeftIcon";
import texts from "../../../../../../constants/texts";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";

interface OwnProps {}

type Props = OwnProps;

const TopBar: React.FC<Props> = () => {
  const classes = useStyles();
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <Paper className={classes.paper}>
      <IconButton onClick={handleGoBack}>
        <ArrowLeftIcon />
      </IconButton>
      <Box ml={2} textAlign="left">
        <Typography variant="h4">
          {texts.roles.washer.payouts.requestPayout.title}
        </Typography>
      </Box>
    </Paper>
  );
};

export default TopBar;
