import React from "react";

interface OwnProps {
  small?: boolean;
}

type Props = OwnProps;

const ArrowRightIcon: React.FC<Props> = ({ small }) => {
  const width = small ? 7 : 15;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="10.546"
      viewBox="0 0 15.092 10.546"
      fill="currentColor"
    >
      <path
        id="Union_4"
        data-name="Union 4"
        d="M-9134.055,645.3a.851.851,0,0,1-.007-1.207l3.192-3.231s0,0,0,0a.856.856,0,0,0,0-1.207l-3.192-3.19a.854.854,0,0,1,0-1.207.85.85,0,0,1,1.207,0l3.194,3.19a2.561,2.561,0,0,1,0,3.619l-3.189,3.231a.853.853,0,0,1-.606.255A.85.85,0,0,1-9134.055,645.3Zm-9.092-4.193a.855.855,0,0,1-.854-.854.853.853,0,0,1,.854-.854h9.095a.853.853,0,0,1,.854.854.855.855,0,0,1-.854.854Z"
        transform="translate(9144 -635)"
      />
    </svg>
  );
};

export default ArrowRightIcon;
