import { UserRole } from "../responses/user.model";

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpData {
  role: UserRole;
  firstName: string;
  secondName: string;
  email: string;
  phoneNumber: string;
  password: string;
  authorizationCode: string;
  emailVerificationCode: string;
  couponCode?: string;
}

export interface RequestSignUpPhoneVerificationCodeData {
  phoneNumber: string;
  template: string;
}

export interface RequestSignUpEmailVerificationCodeData {
  email: string;
  template: string;
}

export interface SendSignUpCodeFromEmailData {
  email: string;
  authorizationCode: string;
}

export interface EditPersonalUserData {
  firstName: string;
  secondName: string;
}
export enum VERIFY_TYPE {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
}

export type SendVerifyCodeType = { template: string; type: VERIFY_TYPE } & (
  | { email: string }
  | { phoneNumber: string }
);
