import React from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  EquipmentTableColumns,
  HeadCell,
} from "../../../../../models/table.models";
import { unitSize } from "../../../../../constants/sizes";
import PointIcon from "../../../../../shared/icons/PointIcon";
import BorderedValue from "../../../../../styled/BorderedValue";
import { StatusColors } from "../../../../../models/utils.models";
import FirstLayer from "../../../../../styled/FirstLayer";
import SecondLayer from "../../../../../styled/SecondLayer";
import ColoredStatusBar from "../../../../../styled/ColoredStatusBar";
import useVisible from "../../../../../hooks/useVisible";
import { useStyles } from "./style";
import { UserEquipment } from "../../../../../models/responses/equipment.model";
import useActions from "../../../../../hooks/useActions";
import { EquipmentActions } from "../../../../../store/actions/equipment.actions";
import { AppActions } from "../../../../../store/actions/app.actions";

interface OwnProps {
  headCells: HeadCell<EquipmentTableColumns>[];
  data: UserEquipment;
}

type Props = OwnProps;

const EquipmentRow: React.FC<Props> = (props) => {
  const classes = useStyles();
  const appActions = useActions<typeof AppActions>(AppActions);
  const equipmentActions = useActions<typeof EquipmentActions>(
    EquipmentActions
  );
  const [element, isVisible] = useVisible();
  const percent = Math.round(
    (props.data.value / props.data.equipment.maxValue) * 100
  );
  const isRequested = props.data.userEquipmentRequest !== null;
  const isFull = props.data.value === props.data.equipment.maxValue;

  const getStatusColor = (value: number) => {
    if (value > 90) {
      return StatusColors.GREEN;
    }
    if (value > 30) {
      return StatusColors.YELLOW;
    }
    return StatusColors.RED;
  };
  const renderCell = (id: EquipmentTableColumns) => {
    switch (id) {
      case EquipmentTableColumns.EQUIPMENT: {
        return (
          <Box display="flex" alignItems="center">
            <PointIcon />
            <Box ml={1.5}>
              <Typography variant="h6" color="primary">
                {props.data.equipment.title}
              </Typography>
            </Box>
          </Box>
        );
      }
      case EquipmentTableColumns.USAGE: {
        return (
          <Tooltip
            title={`${props.data.value} of ${props.data.equipment.maxValue}`}
            placement="top"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexGrow={1}
            >
              <Box className={classes.usageStatusContainer}>
                <ColoredStatusBar
                  ref={element}
                  percent={isVisible ? percent : 0}
                />
              </Box>
              <Box ml={2}>
                <BorderedValue color={getStatusColor(percent)}>
                  {props.data.value} {props.data.equipment.valueUnit}
                </BorderedValue>
              </Box>
            </Box>
          </Tooltip>
        );
      }
      default:
        return null;
    }
  };
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container className={classes.rowContainer}>
          {props.headCells.map(({ id, size }, index) => (
            <Grid key={id} item xs={size}>
              <Box
                minHeight={unitSize * 9}
                pr={2}
                display="flex"
                alignItems="center"
              >
                {index > 0 && (
                  <Divider className={classes.divider} orientation="vertical" />
                )}
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  className={classes.textContainer}
                >
                  {renderCell(id)}
                </Box>
              </Box>
            </Grid>
          ))}
          <Grid item xs={4} className={classes.actionLayer}>
            <FirstLayer open openWidth="10%" />
            <SecondLayer>
              <Box pr={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled={isRequested || isFull}
                  onClick={() =>
                    appActions.showConfirmationDialog({
                      withReason: false,
                      title: `Request refill ${props.data.equipment.title}`,
                      description: "Are you sure?",
                      open: true,
                      onSubmit: () =>
                        equipmentActions.createEquipmentRequest(props.data.id),
                    })
                  }
                >
                  {isRequested && "Requested"}
                  {isFull && "Full"}
                  {!isRequested &&
                    !isFull &&
                    `Request refill ${props.data.equipment.title}`}
                </Button>
              </Box>
            </SecondLayer>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default EquipmentRow;
