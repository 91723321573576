import { IsDefined, IsEnum, IsNumber, ValidateNested } from "class-validator";
import { Type } from "class-transformer";
import { ResultModel } from "./result.model";
import { User } from "./user.model";
import { VerificationFormStatus } from "../requests/VerificationForm";
import { BasicModel } from "./basic.model";

class VerificationForm extends BasicModel {
  @IsEnum(VerificationFormStatus)
  status: VerificationFormStatus;
}

export class PendingWasher extends User {
  @IsDefined()
  @Type(() => VerificationForm)
  @ValidateNested()
  verificationForm: VerificationForm;
}

export class PendingWashers {
  @IsDefined()
  @Type(() => PendingWasher)
  @ValidateNested()
  items: PendingWasher[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export type PendingWasherResponse = ResultModel<PendingWasher>;
export type PendingWashersResponse = ResultModel<PendingWashers>;
