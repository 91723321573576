import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const ReferralsIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        id="group"
        d="M9,4.219A2.109,2.109,0,1,0,6.891,2.109,2.112,2.112,0,0,0,9,4.219ZM9,1.406a.7.7,0,1,1-.7.7A.7.7,0,0,1,9,1.406Zm3.867,9.777a2.473,2.473,0,0,1-1.195,2.11l-.051.03a1.06,1.06,0,0,0-.512.9V17.3a.7.7,0,0,1-1.406,0V14.228a2.473,2.473,0,0,1,1.195-2.11l.051-.03a1.06,1.06,0,0,0,.512-.9V8.051c0-1.453-1.348-1.758-2.479-1.758S6.5,6.6,6.5,8.051v3.122a1.059,1.059,0,0,0,.52.909l.06.035A2.47,2.47,0,0,1,8.3,14.238V17.3a.7.7,0,0,1-1.406,0V14.238a1.058,1.058,0,0,0-.52-.909l-.06-.035A2.471,2.471,0,0,1,5.1,11.173V8.051A2.855,2.855,0,0,1,6.334,5.582a4.672,4.672,0,0,1,2.648-.7,4.672,4.672,0,0,1,2.648.7,2.855,2.855,0,0,1,1.237,2.469ZM3.516,5.625A2.109,2.109,0,1,0,1.406,3.516,2.112,2.112,0,0,0,3.516,5.625Zm0-2.812a.7.7,0,1,1-.7.7A.7.7,0,0,1,3.516,2.813ZM5.625,14.836V17.3a.7.7,0,0,1-1.406,0V14.836a.7.7,0,1,1,1.406,0ZM4.148,7.014a.7.7,0,0,1-.7.7c-2.039,0-2.039.958-2.039,1.318v2.988a1.06,1.06,0,0,0,.422.844,2.473,2.473,0,0,1,.984,1.969V17.3a.7.7,0,0,1-1.406,0V14.836a1.06,1.06,0,0,0-.422-.844A2.473,2.473,0,0,1,0,12.023V9.035A2.441,2.441,0,0,1,1.129,6.863a4.286,4.286,0,0,1,2.317-.553A.7.7,0,0,1,4.148,7.014ZM14.484,5.625a2.109,2.109,0,1,0-2.109-2.109A2.112,2.112,0,0,0,14.484,5.625Zm0-2.812a.7.7,0,1,1-.7.7A.7.7,0,0,1,14.484,2.813Zm-.7,12.023V17.3a.7.7,0,0,1-1.406,0V14.836a.7.7,0,0,1,1.406,0ZM18,9.035v2.988a2.473,2.473,0,0,1-.984,1.969,1.06,1.06,0,0,0-.422.844V17.3a.7.7,0,0,1-1.406,0V14.836a2.473,2.473,0,0,1,.984-1.969,1.06,1.06,0,0,0,.422-.844V9.035c0-.361,0-1.318-2.039-1.318a.7.7,0,0,1,0-1.406,4.286,4.286,0,0,1,2.317.553A2.441,2.441,0,0,1,18,9.035Zm0,0"
        fill="currentColor"
      />
    </svg>
  );
};

export default ReferralsIcon;
