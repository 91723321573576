import React from "react";
import { GOOGLE_MAPS_API_KEY } from "../../constants/config";
import TitledPaper from "../../shared/TitledPaper";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { MapCoordinate } from "../../models/utils.models";

interface OwnProps {
  location: MapCoordinate;
}

type Props = OwnProps;

const OrderLocationMap: React.FC<Props> = (props) => {
  const containerStyle = {
    width: "100%",
    height: "50vh",
    borderRadius: 12,
  };

  return (
    <TitledPaper title="Order location">
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={props.location}
          zoom={10}
        >
          <Marker position={props.location} />
        </GoogleMap>
      </LoadScript>
    </TitledPaper>
  );
};

export default OrderLocationMap;
