import React from "react";

interface OwnProps {}

type Props = OwnProps;

const FinanceIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="18"
      height="16.031"
      viewBox="0 0 18 16.031"
    >
      <path
        id="money-transfer_1_"
        data-name="money-transfer (1)"
        d="M18,2.813V6.891a.7.7,0,0,1-1.406,0V2.813a1.408,1.408,0,0,0-1.406-1.406H2.813A1.408,1.408,0,0,0,1.406,2.813V6.891A.7.7,0,0,1,0,6.891V2.813A2.816,2.816,0,0,1,2.813,0H15.188A2.816,2.816,0,0,1,18,2.813ZM17.3,9.7a.7.7,0,0,0-.7.7,1.408,1.408,0,0,1-1.406,1.406h-7a.7.7,0,0,0,0,1.406h7A2.816,2.816,0,0,0,18,10.406.7.7,0,0,0,17.3,9.7ZM9.18,8.988a.7.7,0,0,0,.064,1.4c.022,0,.044,0,.066,0a3.458,3.458,0,0,0,2.319-1.23,3.939,3.939,0,0,0,.922-2.553A3.684,3.684,0,0,0,9,2.808a3.684,3.684,0,0,0-3.551,3.8,4.127,4.127,0,0,0,.027.472.7.7,0,0,0,1.4-.162,2.7,2.7,0,0,1-.018-.31A2.28,2.28,0,0,1,9,4.214a2.28,2.28,0,0,1,2.145,2.391A2.316,2.316,0,0,1,9.18,8.988Zm6.113-2.413a.7.7,0,1,0-.7.7A.7.7,0,0,0,15.293,6.574Zm-11.18,0a.7.7,0,1,0-.7.7A.7.7,0,0,0,4.113,6.574Zm1.266,5.238H.7a.7.7,0,0,0,0,1.406H5.379a.7.7,0,1,0,0-1.406ZM6.434,9H.7a.7.7,0,0,0,0,1.406h5.73A.7.7,0,0,0,6.434,9ZM4.324,14.625H.7a.7.7,0,1,0,0,1.406H4.324a.7.7,0,1,0,0-1.406Zm0,0"
      />
    </svg>
  );
};

export default FinanceIcon;
