import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const PointIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      {...props}
    >
      <circle
        id="Ellipse_132"
        data-name="Ellipse 132"
        cx="4"
        cy="4"
        r="4"
        fill="#d0d0d0"
      />
    </svg>
  );
};

export default PointIcon;
