import React from "react";

interface OwnProps {}

type Props = OwnProps;

const SedanVagonIcon: React.FC<Props> = () => {
  return (
    <svg
      id="car_1_"
      data-name="car (1)"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="40"
      height="17.759"
      viewBox="0 0 40 17.759"
    >
      <path
        id="Path_212"
        data-name="Path 212"
        d="M.011,121.971l.419-4.3a6.792,6.792,0,0,1,3.031-5.035,1.012,1.012,0,0,1,.761-1.811l2.335.36c4.813-1.748,12.877-2.7,23.892,2.738a10.668,10.668,0,0,0,3.369,1.016,6.842,6.842,0,0,1,5.251,2.919c1.664,2.646.6,5.954.549,6.093a1.013,1.013,0,0,1-.961.694H35.3a3.943,3.943,0,0,1-7.622,0H10.3a3.943,3.943,0,0,1-7.622,0H2.441a2.441,2.441,0,0,1-2.429-2.678Zm4.557,1.665a1.918,1.918,0,1,0,1.918-1.918A1.92,1.92,0,0,0,4.568,123.636Zm25,0a1.918,1.918,0,1,0,1.918-1.918A1.92,1.92,0,0,0,29.567,123.636ZM2.675,122.623a3.943,3.943,0,0,1,7.622,0H27.674a3.943,3.943,0,0,1,7.622,0h2.564a5.342,5.342,0,0,0-.509-3.693,4.952,4.952,0,0,0-3.79-1.979,12.686,12.686,0,0,1-4.008-1.208c-10.422-5.148-17.928-4.253-22.35-2.633a1.01,1.01,0,0,1-.317.119,17.937,17.937,0,0,0-1.93.877,4.768,4.768,0,0,0-2.511,3.765l-.419,4.3a.416.416,0,0,0,.414.456h.235Z"
        transform="translate(0 -109.82)"
      />
    </svg>
  );
};

export default SedanVagonIcon;
