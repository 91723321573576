import { axiosInstance } from "./base.api";
import { FileResponse } from "../models/responses/file.model";
import { ID } from "../models/utils.models";

export const attachmentApi = {
  sendFile(data: FormData) {
    return axiosInstance.post<FileResponse>("/private/files", data);
  },
  getFile(id: ID) {
    return axiosInstance.get<FileResponse>(`/private/files/${id}`);
  },
};
