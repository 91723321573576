import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import { Order, Orders } from "../../models/responses/order.model";
import {
  GetOrdersForUserParams,
  GetOrdersListParams,
} from "../../models/requests/Orders";

export enum OrdersActionTypes {
  GET_ORDERS_LIST = "[Orders] Get orders list",
  GET_ORDERS_LIST_SUCCESS = "[Orders] Get orders list success",
  GET_ORDERS_LIST_FAILURE = "[Orders] Get orders list failure",
  CHANGE_SEARCH_VALUE = "[Orders] Change search value",
  SET_SEARCH_VALUE = "[Orders] Set search value",

  GET_PAST_ORDERS_FOR_ACTIVE_USER = "[Orders] Get orders for active user",
  GET_PAST_ORDERS_FOR_ACTIVE_USER_SUCCESS = "[Orders] Get orders for active user success",
  GET_PAST_ORDERS_FOR_ACTIVE_USER_FAILURE = "[Orders] Get orders for active user failure",

  GET_FUTURE_ORDERS_FOR_ACTIVE_USER = "[Orders] Get future orders for active user",
  GET_FUTURE_ORDERS_FOR_ACTIVE_USER_SUCCESS = "[Orders] Get future orders for active user success",
  GET_FUTURE_ORDERS_FOR_ACTIVE_USER_FAILURE = "[Orders] Get future orders for active user failure",

  GET_ACTIVE_ORDER_DATA = "[Orders] Get active order data",
  GET_ACTIVE_ORDER_SUCCESS = "[Orders] Get active order success",
  GET_ACTIVE_ORDER_FAILURE = "[Orders] Get active order failure",

  GET_LAST_COMPLETE_ORDER = "[Orders] Get last complete order",
  GET_LAST_COMPLETE_ORDER_SUCCESS = "[Orders] Get last complete order success",
  GET_LAST_COMPLETE_ORDER_FAILURE = "[Orders] Get last complete order failure",
}

export const OrdersActions = {
  getOrdersList: (payload: GetOrdersListParams) =>
    createAction(OrdersActionTypes.GET_ORDERS_LIST, payload),
  getOrdersListSuccess: (payload: Orders) =>
    createAction(OrdersActionTypes.GET_ORDERS_LIST_SUCCESS, payload),
  getOrdersListFailure: (payload: string) =>
    createAction(OrdersActionTypes.GET_ORDERS_LIST_FAILURE, payload),

  changeSearchValue: (payload: string) =>
    createAction(OrdersActionTypes.CHANGE_SEARCH_VALUE, payload),
  setSearchValue: (payload: string) =>
    createAction(OrdersActionTypes.SET_SEARCH_VALUE, payload),

  getPastOrdersForActiveUser: (payload: GetOrdersForUserParams) =>
    createAction(OrdersActionTypes.GET_PAST_ORDERS_FOR_ACTIVE_USER, payload),
  getPastOrdersForActiveUserSuccess: (payload: Orders) =>
    createAction(
      OrdersActionTypes.GET_PAST_ORDERS_FOR_ACTIVE_USER_SUCCESS,
      payload
    ),
  getPastOrdersForActiveUserFailure: (payload: string) =>
    createAction(
      OrdersActionTypes.GET_PAST_ORDERS_FOR_ACTIVE_USER_FAILURE,
      payload
    ),

  getFutureOrdersForActiveUser: (payload: GetOrdersForUserParams) =>
    createAction(OrdersActionTypes.GET_FUTURE_ORDERS_FOR_ACTIVE_USER, payload),
  getFutureOrdersForActiveUserSuccess: (payload: Orders) =>
    createAction(
      OrdersActionTypes.GET_FUTURE_ORDERS_FOR_ACTIVE_USER_SUCCESS,
      payload
    ),
  getFutureOrdersForActiveUserFailure: (payload: string) =>
    createAction(
      OrdersActionTypes.GET_FUTURE_ORDERS_FOR_ACTIVE_USER_FAILURE,
      payload
    ),

  getActiveOrderData: (payload: string) =>
    createAction(OrdersActionTypes.GET_ACTIVE_ORDER_DATA, payload),
  getActiveOrderSuccess: (payload: Order) =>
    createAction(OrdersActionTypes.GET_ACTIVE_ORDER_SUCCESS, payload),
  getActiveOrderFailure: (payload: string) =>
    createAction(OrdersActionTypes.GET_ACTIVE_ORDER_FAILURE, payload),

  getLastCompleteOrder: () =>
    createAction(OrdersActionTypes.GET_LAST_COMPLETE_ORDER),
  getLastCompleteOrderSuccess: (payload: Order) =>
    createAction(OrdersActionTypes.GET_LAST_COMPLETE_ORDER_SUCCESS, payload),
  getLastCompleteOrderFailure: (payload: string) =>
    createAction(OrdersActionTypes.GET_LAST_COMPLETE_ORDER_FAILURE, payload),
};

export type OrdersActions = ActionsUnion<typeof OrdersActions>;

export type getOrdersListType = ActionsOfType<
  OrdersActions,
  OrdersActionTypes.GET_ORDERS_LIST
>;
export type changeSearchValueType = ActionsOfType<
  OrdersActions,
  OrdersActionTypes.CHANGE_SEARCH_VALUE
>;
export type getPastOrdersForActiveUserType = ActionsOfType<
  OrdersActions,
  OrdersActionTypes.GET_PAST_ORDERS_FOR_ACTIVE_USER
>;
export type getFutureOrdersForActiveUserType = ActionsOfType<
  OrdersActions,
  OrdersActionTypes.GET_FUTURE_ORDERS_FOR_ACTIVE_USER
>;
export type getActiveOrderType = ActionsOfType<
  OrdersActions,
  OrdersActionTypes.GET_ACTIVE_ORDER_DATA
>;
