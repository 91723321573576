import React from "react";
import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import CompanyIcon from "../../../../../shared/icons/CompanyIcon";
import WasherIcon from "../../../../../shared/icons/WasherIcon";
import { ReferralsTableColumns } from "../../../../../models/table.models";
import { formatCurrency } from "../../../../../helpers/utils/utils";
import FilledCaption from "../../../../../styled/FilledCaption";
import { unitSize } from "../../../../../constants/sizes";
import headCells from "../../headCells";
import userAvatar from "../../../../../Images/defaultUserAvatar.svg";
import IdCell from "../../../../../shared/sharedTable/IdCell";
import { formatDate } from "../../../../../helpers/utils/date.utils";
import { useStyles } from "./style";
import { Referral } from "../../../../../models/responses/referrals.model";
import { UserRole } from "../../../../../models/responses/user.model";

const getReferralIconByType = (type: UserRole) => {
  switch (type) {
    case UserRole.COMPANY_ADMIN:
      return <CompanyIcon />;
    case UserRole.WASHER:
      return <WasherIcon />;
    default:
      return null;
  }
};

interface OwnProps {
  referral: Referral;
}

type Props = OwnProps;

const ReferralsListRow: React.FC<Props> = ({ referral }) => {
  const classes = useStyles();
  const renderCell = (id: ReferralsTableColumns) => {
    switch (id) {
      case ReferralsTableColumns.INFO: {
        return (
          <Box
            display="flex"
            alignItems="center"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <img src={userAvatar} alt="Avatar" className={classes.avatar} />
            <Box ml={2} overflow="hidden" textOverflow="ellipsis">
              <Typography variant="h6" color="primary" gutterBottom>
                {referral.referralUser.firstName || ""}{" "}
                {referral.referralUser.secondName || ""}
              </Typography>
              <IdCell id={referral.id} />
            </Box>
          </Box>
        );
      }
      case ReferralsTableColumns.TYPE: {
        return (
          <Box display="flex" alignItems="center" color="primary.main">
            {getReferralIconByType(referral.referralUser.role)}
            <Typography
              variant="body1"
              color={"primary"}
              style={{ marginLeft: 12 }}
            >
              {referral.referralUser.role}
            </Typography>
          </Box>
        );
      }
      case ReferralsTableColumns.DATE: {
        const [date, time] = formatDate(referral.referralUser.createdTime);
        return (
          <Box display="flex" flexDirection="column">
            <Typography variant="body1" color="textPrimary">
              {date}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {time}
            </Typography>
          </Box>
        );
      }
      case ReferralsTableColumns.COMMISSION: {
        return (
          <Box display="flex">
            <Typography variant="h4" color="primary">
              {formatCurrency(referral.feeAmount)}
            </Typography>
            <FilledCaption>$</FilledCaption>
          </Box>
        );
      }
      default:
        return null;
    }
  };
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container className={classes.rowContainer}>
          {headCells.map(({ id, size }, index) => (
            <Grid key={id} item xs={size}>
              <Box
                minHeight={unitSize * 11}
                pr={2}
                display="flex"
                alignItems="center"
              >
                {index > 0 && (
                  <Divider className={classes.divider} orientation="vertical" />
                )}
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  className={classes.textContainer}
                >
                  {renderCell(id)}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ReferralsListRow;
