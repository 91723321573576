import React from "react";

interface OwnProps {}

type Props = OwnProps;

const StopwatchIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="22.358"
      height="24.253"
      viewBox="0 0 22.358 24.253"
    >
      <g id="stopwatch" transform="translate(-20)">
        <g id="Group_75" data-name="Group 75" transform="translate(20)">
          <g id="Group_74" data-name="Group 74">
            <path
              id="Path_345"
              data-name="Path 345"
              d="M384.988,15.184l2.572,2.145a.947.947,0,1,0,1.213-1.455L386.2,13.729a.947.947,0,1,0-1.213,1.455Z"
              transform="translate(-367.375 -12.869)"
            />
            <path
              id="Path_346"
              data-name="Path 346"
              d="M33.993,17.55a.943.943,0,0,0,.606-.22l2.572-2.145a.947.947,0,1,0-1.213-1.455l-2.572,2.145a.947.947,0,0,0,.607,1.675Z"
              transform="translate(-32.427 -12.87)"
            />
            <path
              id="Path_347"
              data-name="Path 347"
              d="M39.084,5.169a11.1,11.1,0,0,0-6.957-3.234V.947a.947.947,0,0,0-1.895,0v.988a11.179,11.179,0,0,0-6.957,19.044,11.18,11.18,0,0,0,14.53,1.1.947.947,0,1,0-1.124-1.525,9.272,9.272,0,1,1,2.639-3.01.947.947,0,1,0,1.66.914A11.185,11.185,0,0,0,39.084,5.169Z"
              transform="translate(-20)"
            />
            <path
              id="Path_348"
              data-name="Path 348"
              d="M222.51,151.358a.947.947,0,0,0-1.332.14l-2.987,3.691a1.9,1.9,0,1,0,1.473,1.192l2.987-3.692A.947.947,0,0,0,222.51,151.358Z"
              transform="translate(-206.716 -143.988)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StopwatchIcon;
