import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { unitSize } from "../../../../../constants/sizes";
import IdCell from "../../../../../shared/sharedTable/IdCell";
import {
  HeadCell,
  OwnPayoutsColumns,
} from "../../../../../models/table.models";
import FirstLayer from "../../../../../styled/FirstLayer";
import SecondLayer from "../../../../../styled/SecondLayer";
import { PayoutStatus } from "../../../../../models/responses/payout.model";
import { StatusColors } from "../../../../../models/utils.models";
import StatusCell from "../../../../../styled/StatusCell";
import { capitalizeFirstLetter } from "../../../../../helpers/utils/utils";
import texts from "../../../../../constants/texts";
import { useStyles } from "./style";
import { attachmentApi } from "../../../../../api/attachment.api";

interface OwnProps {
  columns: HeadCell<OwnPayoutsColumns>[];
  row: { [key: string]: string };
}

type Props = OwnProps;

const WasherPayoutRow: React.FC<Props> = (props) => {
  const getColorByStatus = (status: PayoutStatus) => {
    switch (status) {
      case PayoutStatus.PAID:
        return StatusColors.GREEN;
      case PayoutStatus.REQUESTED:
        return StatusColors.YELLOW;
      case PayoutStatus.REJECTED:
        return StatusColors.RED;
    }
  };
  const [invoiceURL, setInvoiceURL] = useState<string>();

  useEffect(() => {
    attachmentApi
      .getFile(props.row.invoiceFileId)
      .then((resp) => setInvoiceURL(resp.data.data?.url))
      .catch((e) => console.error(e));
  }, [props.row.invoiceFileId]);

  const handleInvoiceClick = async () => {
    if (invoiceURL) {
      window.open(invoiceURL);
    }
  };
  const renderCell = (id: OwnPayoutsColumns) => {
    switch (id) {
      case OwnPayoutsColumns.ID:
        return <IdCell id={props.row.id} />;
      case OwnPayoutsColumns.STATUS:
        return (
          <StatusCell color={getColorByStatus(props.row[id] as PayoutStatus)}>
            {capitalizeFirstLetter(props.row[id].toLowerCase())}
          </StatusCell>
        );
      case OwnPayoutsColumns.TOTAL:
      case OwnPayoutsColumns.DATE:
        return (
          <Typography color="textPrimary" variant="body1" noWrap>
            {props.row[id]}
          </Typography>
        );
    }
  };
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container>
          {props.columns.map(({ id, size }, index) => (
            <Grid key={id} item xs={size}>
              <Box
                pb={2}
                pt={2}
                pr={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  height={unitSize * 4}
                  className={classes.textContainer}
                >
                  {index > 0 && (
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                  )}
                  {renderCell(id)}
                </Box>
              </Box>
            </Grid>
          ))}
          <Grid item xs={4} className={classes.actionLayer}>
            <FirstLayer open openWidth="10%" />
            <SecondLayer>
              <Box pr={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleInvoiceClick}
                >
                  {texts.roles.washer.payouts.viewInvoiceButton}
                </Button>
              </Box>
            </SecondLayer>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default WasherPayoutRow;
