import { AppActions, AppActionTypes } from "../actions/app.actions";
import { SnackbarVariant } from "../../models/utils.models";
import { ConfirmationDialogModel, SnackbarModel } from "../../models/ui.models";

class StateModel {
  snackbar: SnackbarModel = {
    variant: SnackbarVariant.SUCCESS,
    message: "",
    isOpen: false,
  };
  confirmationDialog: ConfirmationDialogModel = {
    description: "",
    onSubmit: () => {},
    open: false,
    title: "",
    withReason: false,
  };
}

const initialState = new StateModel();

export function appReducer(
  state = initialState,
  action: AppActions
): StateModel {
  switch (action.type) {
    case AppActionTypes.SHOW_SNACKBAR: {
      return {
        ...state,
        snackbar: {
          variant: action.payload.variant,
          message: action.payload.message,
          isOpen: true,
        },
      };
    }
    case AppActionTypes.CLOSE_SNACKBAR: {
      return {
        ...state,
        snackbar: {
          ...initialState.snackbar,
          variant: state.snackbar.variant,
        },
      };
    }
    case AppActionTypes.SHOW_CONFIRMATION_DIALOG: {
      return {
        ...state,
        confirmationDialog: action.payload,
      };
    }
    case AppActionTypes.CLOSE_CONFIRMATION_DIALOG: {
      return {
        ...state,
        confirmationDialog: initialState.confirmationDialog,
      };
    }

    default:
      return state;
  }
}
