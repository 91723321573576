import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { examQuestions } from "../../../mock/examQuestions";
import { getRandomElementsFromArray } from "../../../helpers/utils/utils";
import { useStyles } from "./style";
import CustomButton from "../../../shared/CustomButton";
import clsx from "clsx";
import { GreenRadio } from "../../../shared/Radio/GreenRadio";
import TitledPaper from "../../../shared/TitledPaper";
import { RedRadio } from "../../../shared/Radio/RedRadio";
import useActions from "../../../hooks/useActions";
import { WasherVerificationActions } from "../../../store/actions/washerVerification.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const WasherExam: React.FC<Props> = () => {
  useTitle(`Washerly Exam`);
  const numberOfQuestions = examQuestions.length;
  const classes = useStyles();
  const [questions, setQuestions] = useState<typeof examQuestions>([]);
  const [values, setValues] = useState<String[]>([]);
  const washerVerificationActions = useActions<
    typeof WasherVerificationActions
  >(WasherVerificationActions);
  const washerVerificationState = useSelector(
    (state: AppState) => state.washerVerification
  );

  const updateQuestions = useCallback(() => {
    const newQuestions = getRandomElementsFromArray(
      examQuestions,
      numberOfQuestions
    );
    setValues(newQuestions.map(() => ""));
    setQuestions(newQuestions);
  }, [numberOfQuestions]);

  useEffect(() => {
    updateQuestions();
  }, [updateQuestions]);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (index: number, value: string) => {
    if (!isSubmitted) {
      setValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] = value;
        return newValues;
      });
    }
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    const isCorrect = values.every(
      (value, index) => value === questions[index].correctAnswer
    );
    if (isCorrect) {
      washerVerificationActions.submitExam();
    }
  };

  const handleReset = () => {
    setIsSubmitted(false);
    updateQuestions();
    setValues(questions.map(() => ""));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitledPaper
          title="Washer Exam"
          subtitle="To pass the exam answer all questions correctly"
        />
      </Grid>
      <Grid item xs={12}>
        <TitledPaper title="">
          <Box display="flex" flexDirection="column" pt={4}>
            {questions.map((question, index) => {
              const isAnswerWrong =
                isSubmitted && question.correctAnswer !== values[index];
              return (
                <FormControl
                  key={`question${index}`}
                  component="fieldset"
                  className={classes.formControl}
                >
                  <Typography
                    variant={"h4"}
                    className={clsx({ [classes.bold]: isAnswerWrong })}
                  >
                    {question.question}
                  </Typography>
                  <RadioGroup
                    name={`question${index + 1}`}
                    value={values[index]}
                    onChange={(e, value) => handleChange(index, value)}
                  >
                    {Object.keys(question.variants).map((key, index, array) => (
                      <Fragment key={`variant${index}`}>
                        <FormControlLabel
                          classes={{
                            root: classes.formControlLabel,
                            label: clsx(classes.radioLabel, {
                              [classes.bold]:
                                isAnswerWrong && question.correctAnswer === key,
                              [classes.crossed]:
                                isAnswerWrong && question.correctAnswer !== key,
                            }),
                          }}
                          value={key}
                          control={
                            isAnswerWrong && question.correctAnswer === key ? (
                              <GreenRadio size="small" checked />
                            ) : isAnswerWrong && values[index] === key ? (
                              <RedRadio size="small" checked />
                            ) : (
                              <Radio color="primary" size="small" />
                            )
                          }
                          label={
                            question.variants[
                              key as keyof typeof question.variants
                            ]
                          }
                        />
                        {index < array.length - 1 && (
                          <Divider className={classes.divider} />
                        )}
                      </Fragment>
                    ))}
                  </RadioGroup>
                </FormControl>
              );
            })}
            {isSubmitted && !washerVerificationState.submitWasherExamLoading ? (
              <CustomButton
                color="secondary"
                variant="contained"
                onClick={handleReset}
              >
                Start again
              </CustomButton>
            ) : (
              <CustomButton
                color="secondary"
                disabled={values.some((item) => !item)}
                variant="contained"
                loading={washerVerificationState.submitWasherExamLoading}
                onClick={handleSubmit}
              >
                Submit
              </CustomButton>
            )}
          </Box>
        </TitledPaper>
      </Grid>
    </Grid>
  );
};

export default WasherExam;
