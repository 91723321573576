import {
  AdminPayoutsColumns,
  HeadCell,
} from "../../../../../models/table.models";
import texts from "../../../../../constants/texts";

const headCells: HeadCell<AdminPayoutsColumns>[] = [
  {
    id: AdminPayoutsColumns.ID,
    label: texts.roles.admin.payouts.tableHeaders.id,
    size: 1,
  },
  {
    id: AdminPayoutsColumns.DATE,
    label: texts.roles.admin.payouts.tableHeaders.date,
    size: 2,
  },
  {
    id: AdminPayoutsColumns.FULL_NAME,
    label: texts.roles.admin.payouts.tableHeaders.fullName,
    size: 2,
  },
  {
    id: AdminPayoutsColumns.AMOUNT,
    label: texts.roles.admin.payouts.tableHeaders.amount,
    size: 2,
  },
];

export default headCells;
