import {
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { ResultModel } from "./result.model";
import { BasicModel } from "./basic.model";
import { Coupon } from "./coupon.model";

export class Company extends BasicModel {
  @IsOptional()
  @ValidateNested()
  @Type(() => Coupon)
  activeCoupon?: Coupon;
  @IsString()
  name: string;
  @IsString()
  country: string;
  @IsString()
  postalCode: string;
  @IsString()
  address: string;
  @IsString()
  adminId: string;
  @IsOptional()
  @IsNumber()
  companyWashersCount?: number;
  @IsOptional()
  @IsNumber()
  completedOrders?: number;
  @IsOptional()
  @IsNumber()
  totalOrdersPriceAmount?: number;
}

export class Companies {
  @IsDefined()
  @Type(() => Company)
  @ValidateNested()
  items: Company[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export type CompanyResponse = ResultModel<Company>;
export type CompaniesResponse = ResultModel<Companies>;
