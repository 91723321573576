import React, { useEffect } from "react";
import UsersList from "../../../components/UsersList/UsersList";
import useActions from "../../../hooks/useActions";
import { UsersActions } from "../../../store/actions/users.actions";
import { ID } from "../../../models/utils.models";
import { RouteNames } from "../../../constants/routeNames";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import ListSearch from "../../../components/UsersList/ListSearch";
import userListHeadCells from "../../../components/UsersList/userListHeadCells";
import TableLayout from "../../../shared/sharedTable/TableLayout";
import texts from "../../../constants/texts";
import { UserSelectors } from "../../../store/reducers/users.reducer";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

interface OwnProps {}

type Props = OwnProps;

const WashersPage: React.FC<Props> = () => {
  const history = useHistory();
  const usersActions = useActions<typeof UsersActions>(UsersActions);
  const usersState = useSelector((state: AppState) => state.users);
  const washers = UserSelectors.getFormattedUsers(usersState, "washers");
  const statisticState = useSelector((state: AppState) => state.statistic);
  const searchValues = [
    texts.users.search.name,
    texts.users.search.email,
    texts.users.search.phoneNumber,
    texts.users.search.orderId,
  ];
  useEffect(() => {
    usersActions.setWashersSearchValue("");
  }, [usersActions]);

  const handleSearchChange = (value: string) => {
    usersActions.washersSearchValue(value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ListSearch
          handleSearchChange={handleSearchChange}
          count={usersState.washers.total}
          type="washer"
          searchValues={searchValues}
        />
      </Grid>
      <TableLayout
        search={usersState.washersSearch}
        title={texts.washers.headerTitle}
        subtitle={texts.washers.headerSubtitle}
        isLoading={usersState.washersLoading}
        total={+usersState.washers.total}
        headCells={userListHeadCells}
        onPaginationChange={usersActions.getCompanyWashers}
        values={[
          {
            title: texts.washers.total,
            value: statisticState.generalStatistic.totalWashers,
          },
        ]}
      >
        <UsersList
          headCells={userListHeadCells}
          data={washers}
          redirectItemTo={(id: ID) =>
            history.push(`/${RouteNames.WASHERS}/${id}`)
          }
        />
      </TableLayout>
    </Grid>
  );
};

export default WashersPage;
