import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(1),
    },
    rowContainer: {
      paddingLeft: theme.spacing(4),
      boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.03)",
    },
    divider: {
      marginRight: theme.spacing(2.5),
      height: theme.spacing(3),
    },
    textContainer: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: theme.palette.primary.main,
    },
    avatar: {
      width: 29,
    },
  })
);
