import { useEffect, useState } from "react";

export default (isLoading: boolean, delay = 300) => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(false);
    setTimeout(() => setReady(true), delay);
  }, [delay, isLoading]);
  return !ready || isLoading;
};
