import React from "react";

interface OwnProps {}

type Props = OwnProps;

const HistoryIcon: React.FC<Props> = () => {
  return (
    <svg
      id="timetable"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g id="Group_88" data-name="Group 88">
        <path
          id="Path_416"
          data-name="Path 416"
          d="M17.3,12.375a.7.7,0,0,0,.7-.7V4.219a2.816,2.816,0,0,0-2.812-2.812h-.914V.7a.7.7,0,0,0-1.406,0v.7h-3.2V.7A.7.7,0,0,0,8.262.7v.7H5.1V.7A.7.7,0,0,0,3.691.7v.7H2.813A2.816,2.816,0,0,0,0,4.219V15.188A2.816,2.816,0,0,0,2.813,18H15.188A2.816,2.816,0,0,0,18,15.188a.7.7,0,0,0-1.406,0,1.408,1.408,0,0,1-1.406,1.406H12.516V12.375Zm-.7-1.406H12.516V6.75h4.078ZM5.484,16.594H2.813a1.408,1.408,0,0,1-1.406-1.406V12.375H5.484Zm0-5.625H1.406V6.75H5.484Zm5.625,5.625H6.891V12.375h4.219Zm0-5.625H6.891V6.75h4.219Zm-9.7-5.625V4.219A1.408,1.408,0,0,1,2.813,2.813h.879v.7a.7.7,0,0,0,1.406,0v-.7H8.262v.7a.7.7,0,0,0,1.406,0v-.7h3.2v.7a.7.7,0,0,0,1.406,0v-.7h.914a1.408,1.408,0,0,1,1.406,1.406V5.344Z"
        />
      </g>
    </svg>
  );
};

export default HistoryIcon;
