import React from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import {
  createStyles,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import clsx from "clsx";
import palette from "../mui/theme/palette";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      fontSize: "14px",
    },
    inputAdornment: {
      whiteSpace: "nowrap",
      fontSize: "12px",
      marginRight: theme.spacing(3),
      "& p": {
        color: theme.palette.text.primary,
      },
    },
    errorAdornment: {
      "& p": {
        color: palette.error.main,
      },
    },
    underline: {
      "&:before": {
        borderBottom: "1px solid #E9E9E9",
      },
    },
  })
);

interface OwnProps {
  adornment: string;
}

type Props = OwnProps & TextFieldProps;

const InputWithAdornment: React.FC<Props> = ({ adornment, ...props }) => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      margin="normal"
      placeholder="Enter..."
      InputProps={{
        startAdornment: (
          <InputAdornment
            disablePointerEvents
            className={clsx(
              classes.inputAdornment,
              props.error && classes.errorAdornment
            )}
            position="start"
          >
            {adornment}
          </InputAdornment>
        ),
        classes: { root: classes.input, underline: classes.underline },
      }}
      {...props}
      variant="standard"
    />
  );
};

export default InputWithAdornment;
