import React from "react";
import UserInfo from "../../../../components/UserProfile/UserInfo/UserInfo";
import { RouteComponentProps } from "react-router";
import { routes } from "./routes";
import { UserProfileRouterProps } from "../../../../models/router.models";
import { Grid } from "@material-ui/core";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteNames, RouteParams } from "../../../../constants/routeNames";
import EditUserProfile from "../../../../components/UserProfile/EditUserProfile";
import NavTabs from "../../../../components/NavTabs";
import { tabs } from "./tabs";

interface OwnProps {}

type Props = OwnProps & RouteComponentProps<UserProfileRouterProps>;

const ClientProfile: React.FC<Props> = () => {
  return (
    <Grid container spacing={2}>
      <UserInfo />
      <Switch>
        <Route
          path={`/${RouteNames.CLIENTS}/:${RouteParams.USER_ID}/${RouteNames.EDIT_PROFILE}`}
          component={EditUserProfile}
        />
        <NavTabs tabs={tabs}>
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
            <Redirect
              to={`/${RouteNames.CLIENTS}/:${RouteParams.USER_ID}/${RouteNames.ORDERS}`}
            />
          </Switch>
        </NavTabs>
      </Switch>
    </Grid>
  );
};

export default ClientProfile;
