import React from "react";

interface OwnProps {
  size?: "small" | "normal" | "large";
}

type Props = OwnProps;

const StarIcon: React.FC<Props> = ({ size = "normal" }) => {
  const getSize = () => {
    switch (size) {
      case "normal":
        return 19;
      case "small":
        return 16;
      case "large":
        return 30;
    }
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width={getSize() + 4}
      height={getSize()}
      viewBox="0 0 19.039 18.33"
    >
      <g id="star" transform="translate(0.001 -9.527)">
        <g
          id="Group_117"
          data-name="Group 117"
          transform="translate(-0.001 9.527)"
        >
          <path
            id="Path_434"
            data-name="Path 434"
            d="M18.932,16.377a2.022,2.022,0,0,0-1.949-1.422h-3.8a.545.545,0,0,1-.535-.369l-1.176-3.643a2.05,2.05,0,0,0-3.9,0l-1.175,3.64a.546.546,0,0,1-.535.37h-3.8A2.063,2.063,0,0,0,.849,18.683l3.078,2.236a.561.561,0,0,1,.2.629L2.955,25.166A2.02,2.02,0,0,0,3.7,27.458a2.02,2.02,0,0,0,2.41,0l3.078-2.236a.561.561,0,0,1,.662,0l3.078,2.236a2.05,2.05,0,0,0,3.155-2.292l-.8-2.477a.744.744,0,1,0-1.414.46l.8,2.477a.563.563,0,0,1-.866.629l-3.078-2.236a2.045,2.045,0,0,0-2.41,0L5.236,26.255a.563.563,0,0,1-.866-.629l1.176-3.618A2.045,2.045,0,0,0,4.8,19.716L1.723,17.48a.558.558,0,0,1-.2-.643.55.55,0,0,1,.534-.395h3.8a2.036,2.036,0,0,0,1.95-1.4L8.983,11.4a.563.563,0,0,1,1.07,0l1.176,3.643a2.036,2.036,0,0,0,1.95,1.4h3.8a.55.55,0,0,1,.534.395.558.558,0,0,1-.2.643L15.216,19a.744.744,0,0,0,.874,1.2l2.1-1.525A2.03,2.03,0,0,0,18.932,16.377Z"
            transform="translate(0.001 -9.527)"
          />
        </g>
      </g>
    </svg>
  );
};

export default StarIcon;
