import React from "react";
import { HeadCell, LogsColumns } from "../../../../models/table.models";
import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { useStyles } from "../../../../pages/washer/WasherPayouts/components/WasherPayoutRow/style";
import { unitSize } from "../../../../constants/sizes";

interface OwnProps {
  row: { [key: string]: string };
  columns: HeadCell<LogsColumns>[];
}

type Props = OwnProps;

const LogRow: React.FC<Props> = (props) => {
  const renderCell = (id: LogsColumns) => {
    switch (id) {
      case LogsColumns.ACTIVITY_TYPE:
      case LogsColumns.DATE:
        return (
          <Typography color="textPrimary" variant="body1" noWrap>
            {props.row[id]}
          </Typography>
        );
    }
  };
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container>
          {props.columns.map(({ id, size }, index) => (
            <Grid key={id} item xs={size}>
              <Box
                pb={2}
                pt={2}
                pr={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  height={unitSize * 4}
                  className={classes.textContainer}
                >
                  {index > 0 && (
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                  )}
                  {renderCell(id)}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default LogRow;
