import React, { ChangeEvent, useState } from "react";
import {
  Avatar,
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(13),
      height: theme.spacing(13),
      "& svg": {
        width: "50%",
        height: "50%",
      },
    },

    label: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    fileInput: {
      display: "none",
    },
  })
);

interface OwnProps {
  onChange: (file: File) => void;
  required?: boolean;
}

type Props = OwnProps;

const AvatarField: React.FC<Props> = (props) => {
  const [imageSrc, setImageSrc] = useState("");
  const classes = useStyles();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      props.onChange(e.target.files[0]);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };
  return (
    <Grid item xs={4} justify="center" container>
      <input
        name="avatar"
        onChange={handleChange}
        accept="image/*"
        className={classes.fileInput}
        id="avatar"
        type="file"
      />
      <Tooltip title={imageSrc ? "Change Image" : "Download Image"}>
        <label htmlFor="avatar" className={classes.label}>
          <Avatar src={imageSrc} className={classes.avatar}>
            <AddAPhotoIcon />
          </Avatar>
        </label>
      </Tooltip>
      {props.required && !imageSrc && (
        <Box mt={1}>
          <Typography variant="body2" color="error">
            *required
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default AvatarField;
