import { typedEnv } from "../typed-env";
import axios from "axios";
import { getToken } from "../helpers/auth";
import store from "../store/configureStore";
import { AuthActions } from "../store/actions/auth.actions";
import { ResponseStatus } from "../models/responses/result.model";

const axiosInstance = axios.create({
  baseURL: typedEnv.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = await getToken();
  if (!config.headers.Authorization && token) {
    config.headers.Authorization = `bearer ${token}`;
  }
  config.headers["Content-Type"] = "application/json";
  return config;
});

axiosInstance.interceptors.response.use(
  async (response) => {
    const { data } = response;
    if (data.result === ResponseStatus.FAILURE) {
      if (data.error && data.error.startsWith("ERROR_AUTHENTICATION")) {
        store.dispatch(AuthActions.logOut());
      } else {
        throw new Error(data.error);
      }
    }
    return response;
  },
  (error) => {
    store.dispatch(AuthActions.logOut());
    return Promise.reject(error);
  }
);

export { axiosInstance };
