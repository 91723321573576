export enum Experience {
  NONE = "NONE",
  AMATEUR = "AMATEUR",
  PROFESSIONAL = "PROFESSIONAL",
}

export const experienceTexts = {
  [Experience.NONE]: "I have no experience with car wash",
  [Experience.AMATEUR]: "I wash my car at home",
  [Experience.PROFESSIONAL]: "I have worked as mobile retailer",
};

export enum QuestionName {
  ADULT = "Are you at least 18 years of age?",
  DRIVER_LICENSE = "Do you have a valid driver’s license?",
}

export interface Question {
  name: QuestionName;
  value: boolean;
}

export interface WasherVerificationData {
  avatarFileId?: string;
  firstName: string;
  secondName: string;
  email: string;
  phoneNumber: string;
  country: string;
  postalCode: string;
  address: string;
  questions: Question[];
  experience: Experience;
  cleanPhoto: string[];
  insurancePhoto: string[];
  driverLicensePhoto: string[];
}

export interface WasherVerificationFormData {
  avatar?: File;
  firstName: string;
  secondName: string;
  email: string;
  phoneNumber: string;
  country: string;
  postalCode: string;
  address: string;
  questions: Question[];
  experience: Experience;
  cleanPhoto: File[];
  insurancePhoto: File[];
  driverLicensePhoto: File[];
}

export enum VerificationFormStatus {
  APPROVE = "APPROVE",
  REJECT = "REJECT",
  PENDING = "PENDING",
}

export interface ReviewVerificationFormDto {
  status: Omit<VerificationFormStatus, VerificationFormStatus.PENDING>;
  message?: string;
}
