import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { RouteNames, RouteParams } from "../../../constants/routeNames";
import NavTabs from "../../../components/NavTabs";
import tabs from "./tabs";
import PendingWashers from "./components/PendingWashersList";
import texts from "../../../constants/texts";
import PendingWasherApplication from "./components/PendingWasherApplication";
import { Grid } from "@material-ui/core";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const PendingWashersRouter: React.FC<Props> = () => {
  useTitle(`Washerly Pending Washers`);
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/:type/:${RouteParams.USER_ID}`}>
        <PendingWasherApplication />
      </Route>
      <Grid container spacing={2}>
        <NavTabs tabs={tabs}>
          <Switch>
            <Route exact path={`${match.path}/${RouteNames.INDIVIDUAL}`}>
              <PendingWashers
                headerTitle={
                  texts.roles.admin.pendingWashers.individual.headerTitle
                }
                headerSubtitle={
                  texts.roles.admin.pendingWashers.individual.headerSubtitle
                }
              />
            </Route>
            <Route exact path={`${match.path}/${RouteNames.COMPANY}`}>
              <PendingWashers
                headerTitle={
                  texts.roles.admin.pendingWashers.company.headerTitle
                }
                headerSubtitle={
                  texts.roles.admin.pendingWashers.company.headerSubtitle
                }
                withCompany
              />
            </Route>
            <Redirect to={`${match.path}/${RouteNames.INDIVIDUAL}`} />
          </Switch>
        </NavTabs>
      </Grid>
    </Switch>
  );
};

export default PendingWashersRouter;
