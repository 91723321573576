import React, { useMemo, useState } from "react";
import {
  DrawingManager,
  GoogleMap,
  InfoWindow,
  LoadScript,
  Polygon,
} from "@react-google-maps/api";
import { PriceRegion } from "../../PricingMap";
import { MapCoordinate } from "../../../../../models/utils.models";
import { getPolygonOptions } from "./getPolygonOptions";
import {
  Box,
  Button,
  createStyles,
  Fade,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import TitledPaper from "../../../../../shared/TitledPaper";
import MuiToggleSwitch from "../../../../../shared/MuiToggleSwitch";
import SaveIcon from "@material-ui/icons/Save";
import ColorPicker from "../../../../../shared/ColorPicker";
import { GOOGLE_MAPS_API_KEY } from "../../../../../constants/config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
);

const GOOGLE_MAPS_LIBRARIES = ["drawing"];

export type ChangedPolygonType = {
  id: string;
  coords?: MapCoordinate[];
  color?: string;
};

interface OwnProps {
  priceRegions: PriceRegion[];
  onRegionCreate: (coords: MapCoordinate[]) => void;
  selectedRegionId?: string;
  onRegionClick: (id: string) => void;
  onPolygonChanged: (pol: ChangedPolygonType) => void;
}

type Props = OwnProps;

const Map: React.FC<Props> = (props) => {
  const [isDrawMode, setIsDrawMode] = useState(false);
  const [hoverRegion, setHoverRegion] = useState<PriceRegion>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [regionHoverState, setRegionHoverState] = useState({
    isVisible: false,
    lat: 0,
    lng: 0,
  });
  const [polygonInstances, setPolygonInstances] = useState<{
    [key: string]: any;
  }>({});
  const [changedPolygon, setChangedPolygon] = useState<ChangedPolygonType>();

  const containerStyle = {
    width: "100%",
    height: "50vh",
    borderRadius: 12,
  };

  const center = useMemo(() => ({ lat: 50, lng: 20 }), []);

  const handleMouseOver = (e: any, region: PriceRegion) => {
    e.stop();
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setHoverRegion(region);
    if (props.selectedRegionId !== region.id) {
      setRegionHoverState({ isVisible: true, lat, lng });
    }
  };

  const handleMouseOut = (e: any, id: string) => {
    e.stop();
    if (id === hoverRegion?.id) {
      setHoverRegion(undefined);
    }
    setRegionHoverState((prevState) => ({ ...prevState, isVisible: false }));
  };

  const handleMouseClick = (e: any, id: string) => {
    e.stop();
    setRegionHoverState((prevState) => ({ ...prevState, isVisible: false }));
    props.onRegionClick(id);
  };

  const savePolygonChanges = (id: string) => {
    const coords = polygonInstances[id]
      .getPath()
      .getArray()
      .map((item: any) => item.toJSON());

    setChangedPolygon({ id, coords });
  };

  const handleMouseUp = (e: any, id: string) => {
    if (isEditMode) {
      e.stop();
      if (
        e.edge !== undefined ||
        e.path !== undefined ||
        e.vertex !== undefined
      ) {
        savePolygonChanges(id);
      }
    }
  };

  const handleRightClick = (e: any, id: string) => {
    e.stop();
    if (e.vertex !== undefined) {
      const length = getPolygonVertexCount(id);
      if (length > 3) {
        polygonInstances[id].getPath().removeAt(e.vertex);
        savePolygonChanges(id);
      }
    }
  };

  const handleDiscardChanges = () => {
    if (changedPolygon) {
      const region = props.priceRegions.find(
        (reg) => reg.id === changedPolygon.id
      );
      if (region) {
        polygonInstances[changedPolygon.id].setPath(region.coords);
      }
      setChangedPolygon(undefined);
    }
    setIsEditMode(false);
  };

  const handleSaveChanges = () => {
    if (changedPolygon) {
      props.onPolygonChanged(changedPolygon);
      setChangedPolygon(undefined);
    }
    setIsEditMode(false);
  };

  const handlePolygonLoad = (polygon: any, id: string) => {
    setPolygonInstances((prev: { [key: string]: any }) => ({
      ...prev,
      [id]: polygon,
    }));
  };

  const getPolygonVertexCount = (id: string) => {
    return polygonInstances[id].getPath().getArray().length;
  };

  const handleEditClick = () => {
    const selectedRegion = props.priceRegions.find(
      (reg) => reg.id === props.selectedRegionId
    );
    if (selectedRegion) {
      setChangedPolygon({ id: selectedRegion.id, color: selectedRegion.color });
    }
    setIsEditMode(true);
  };

  const handleColorChange = (value: string) => {
    setChangedPolygon((prev) => prev && { ...prev, color: value });
  };

  const classes = useStyles();
  const TitleControls = (
    <Box display="flex" alignItems="center">
      {props.selectedRegionId && (
        <Box mr={4} display="flex" alignItems="center">
          {!!changedPolygon?.color && (
            <Box display="flex" alignItems="center" height={1} mr={4}>
              <Typography>Color</Typography>
              <Box ml={2}>
                <ColorPicker
                  color={changedPolygon?.color}
                  onColorChange={handleColorChange}
                />
              </Box>
            </Box>
          )}
          {isEditMode ? (
            <>
              <Button
                variant="contained"
                color="default"
                size="small"
                className={classes.button}
                onClick={handleDiscardChanges}
              >
                Discard
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<SaveIcon />}
                onClick={handleSaveChanges}
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditClick}
            >
              Edit
            </Button>
          )}
        </Box>
      )}
      <MuiToggleSwitch
        disabled={isEditMode}
        leftText="Select"
        rightText="Draw"
        checked={isDrawMode}
        onChange={() => setIsDrawMode((prevState) => !prevState)}
        disableRipple
      />
    </Box>
  );
  return (
    <TitledPaper
      title="Pricing map"
      subtitle="Pricing map"
      topRightElement={TitleControls}
    >
      <Box mt={2}>
        <LoadScript
          googleMapsApiKey={GOOGLE_MAPS_API_KEY}
          libraries={GOOGLE_MAPS_LIBRARIES}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={4}
          >
            {regionHoverState.isVisible && hoverRegion && (
              <InfoWindow
                position={{
                  lat: regionHoverState.lat,
                  lng: regionHoverState.lng,
                }}
              >
                <Box>
                  <Typography>{hoverRegion.name}</Typography>
                  <Typography>priority: {hoverRegion.zIndex}</Typography>
                </Box>
              </InfoWindow>
            )}
            {isDrawMode && (
              <DrawingManager
                drawingMode={"polygon"}
                options={{
                  drawingControl: false,
                }}
                onPolygonComplete={(polygon) => {
                  props.onRegionCreate(
                    polygon
                      .getPath()
                      .getArray()
                      .map((item: any) => item.toJSON())
                  );
                  setIsDrawMode(false);
                  polygon.setMap(null);
                }}
              />
            )}
            {props.priceRegions.map((region) => (
              <Polygon
                onLoad={(p) => handlePolygonLoad(p, region.id)}
                key={region.id}
                paths={region.coords}
                options={getPolygonOptions(
                  region.id === changedPolygon?.id && changedPolygon.color
                    ? changedPolygon.color
                    : region.color,
                  region.zIndex,
                  region.id === hoverRegion?.id,
                  region.id === props.selectedRegionId,
                  !isEditMode
                )}
                onMouseOver={(e) => handleMouseOver(e, region)}
                onMouseOut={(e) => handleMouseOut(e, region.id)}
                onClick={(e) => handleMouseClick(e, region.id)}
                onMouseUp={(e) => handleMouseUp(e, region.id)}
                onRightClick={(e) => handleRightClick(e, region.id)}
              />
            ))}
          </GoogleMap>
        </LoadScript>
      </Box>
      <Fade
        in={
          !!props.selectedRegionId &&
          getPolygonVertexCount(props.selectedRegionId) > 3
        }
      >
        <Box mt={1}>
          <Typography variant="body1" color="textSecondary">
            * Right click on the polygon vertex to remove it
          </Typography>
        </Box>
      </Fade>
    </TitledPaper>
  );
};

export default Map;
