import React, { useCallback, useMemo } from "react";
import headCells from "./headCells";
import TableLayout from "../../../shared/sharedTable/TableLayout";
import { TablePaginationParams } from "../../../models/table.models";
import useActions from "../../../hooks/useActions";
import { SessionActions } from "../../../store/actions/session.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import { sessionSelector } from "../../../store/reducers/session.reducer";
import StatisticComponent from "../StatisticComponent";
import useQuery from "../../../hooks/useQuery";
import { QueryParams } from "../../../constants/routeNames";
import { GetWasherSessionsParams } from "../../../models/requests/Sessions";
import { ID } from "../../../models/utils.models";
import SessionRow from "./SessionRow";

interface OwnProps {
  userId?: ID;
}

type Props = OwnProps;

const SessionsList: React.FC<Props> = (props) => {
  const sessionActions = useActions<typeof SessionActions>(SessionActions);
  const sessionState = useSelector((state: AppState) => state.session);
  const sessions = sessionSelector.getFormattedSessions(sessionState);
  let query = useQuery();
  const queryFrom = query.get(QueryParams.FROM);
  const queryTo = query.get(QueryParams.TO);

  const handlePaginationChange = useCallback(
    (values: TablePaginationParams) => {
      const params: GetWasherSessionsParams = { ...values };
      if (queryTo) {
        params.dateTimeTo = queryTo;
      }
      if (queryFrom) {
        params.dateTimeFrom = queryFrom;
      }
      if (props.userId) {
        params.userId = props.userId;
      }
      sessionActions.getUserSessions(params);
    },
    [queryFrom, queryTo, props.userId, sessionActions]
  );

  const statisticValues = useMemo(
    () => [
      { title: "Total Online", value: sessionState.sessions.totalOnlineTime },
      {
        title: "Average Online",
        value: sessionState.sessions.averageOnlineTime,
      },
    ],
    [sessionState]
  );

  return (
    <TableLayout
      title="Sessions"
      subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      isLoading={sessionState.sessionsLoading}
      total={sessionState.sessions.total}
      headCells={headCells}
      onPaginationChange={handlePaginationChange}
      valuesComponents={statisticValues.map((item) => (
        <StatisticComponent title={item.title} value={item.value} />
      ))}
    >
      {sessions.map((row) => (
        <SessionRow key={row.id} row={row} columns={headCells} />
      ))}
    </TableLayout>
  );
};

export default SessionsList;
