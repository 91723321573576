import { axiosInstance } from "./base.api";
import { AxiosRequestConfig } from "axios";
import {
  WasherEquipmentRequestResponse,
  WasherEquipmentRequestsResponse,
  WasherEquipmentsResponse,
} from "../models/responses/equipment.model";
import { ID } from "../models/utils.models";

export const equipmentApi = {
  getWasherEquipment(userId?: ID) {
    const config: AxiosRequestConfig = {
      params: {
        userId,
      },
    };
    return axiosInstance.get<WasherEquipmentsResponse>(
      "/private/equipments/washers",
      config
    );
  },

  createEquipmentRequest(userEquipmentId: ID) {
    const data = { userEquipmentId };
    return axiosInstance.post<WasherEquipmentRequestResponse>(
      "/private/equipments/requests",
      data
    );
  },

  getEquipmentRequestsForWasher(userId: ID) {
    const config: AxiosRequestConfig = {
      params: {
        userId,
      },
    };
    return axiosInstance.get<WasherEquipmentRequestsResponse>(
      "/private/equipments/washers/requests",
      config
    );
  },

  approveEquipmentRequest({ id, ...data }: { id: ID; value: number }) {
    return axiosInstance.put<WasherEquipmentRequestResponse>(
      `/private/equipments/washers/requests/${id}/approve`,
      data
    );
  },
};
