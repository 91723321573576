import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import { User, Users } from "../../models/responses/user.model";
import { ModifyUserInfoDto } from "../../models/requests/Users";
import { TablePaginationParams } from "../../models/table.models";
import { ID } from "../../models/utils.models";

export enum UsersActionTypes {
  GET_PROMOTERS = "[Users] Get promoters",
  GET_PROMOTERS_SUCCESS = "[Users] Get promoters success",
  GET_PROMOTERS_FAILURE = "[Users] Get promoters failure",
  SET_PROMOTERS_SEARCH_VALUE = "[Users] Set promoters search value",
  PROMOTERS_SEARCH_VALUE = "[Users] Promoters search value",

  GET_CLIENTS = "[Users] Get clients",
  GET_CLIENTS_SUCCESS = "[Users] Get clients success",
  GET_CLIENTS_FAILURE = "[Users] Get clients failure",
  SET_CLIENTS_SEARCH_VALUE = "[Users] Set clients search value",
  CLIENTS_SEARCH_VALUE = "[Users] Clients search value",

  GET_WASHERS = "[Users] Get washers",
  GET_COMPANY_WASHERS = "[Users] Get company washers",
  GET_WASHERS_SUCCESS = "[Users] Get washers success",
  GET_WASHERS_FAILURE = "[Users] Get washers failure",
  SET_WASHERS_SEARCH_VALUE = "[Users] Set washers search value",
  WASHERS_SEARCH_VALUE = "[Users] Washers search value",

  GET_ACTIVE_USER_DATA = "[Users] Get active user data",
  GET_ACTIVE_USER_SUCCESS = "[Users] Get active user success",
  GET_ACTIVE_USER_FAILURE = "[Users] Get active user failure",

  UPDATE_USER_DATA = "[Users] Update user data",
  UPDATE_USER_SUCCESS = "[Users] Update user success",
  UPDATE_USER_FAILURE = "[Users] Update user failure",
}

export const UsersActions = {
  getPromoters: (payload: TablePaginationParams) =>
    createAction(UsersActionTypes.GET_PROMOTERS, payload),
  getPromotersSuccess: (payload: Users) =>
    createAction(UsersActionTypes.GET_PROMOTERS_SUCCESS, payload),
  getPromotersFailure: (payload: string) =>
    createAction(UsersActionTypes.GET_PROMOTERS_FAILURE, payload),
  setPromotersSearchValue: (payload: string) =>
    createAction(UsersActionTypes.SET_PROMOTERS_SEARCH_VALUE, payload),
  promotersSearchValue: (payload: string) =>
    createAction(UsersActionTypes.PROMOTERS_SEARCH_VALUE, payload),

  getClients: (payload: TablePaginationParams) =>
    createAction(UsersActionTypes.GET_CLIENTS, payload),
  getClientsSuccess: (payload: Users) =>
    createAction(UsersActionTypes.GET_CLIENTS_SUCCESS, payload),
  getClientsFailure: (payload: string) =>
    createAction(UsersActionTypes.GET_CLIENTS_FAILURE, payload),
  clientsSearchValue: (payload: string) =>
    createAction(UsersActionTypes.CLIENTS_SEARCH_VALUE, payload),
  setClientsSearchValue: (payload: string) =>
    createAction(UsersActionTypes.SET_CLIENTS_SEARCH_VALUE, payload),

  getWashers: (payload: TablePaginationParams) =>
    createAction(UsersActionTypes.GET_WASHERS, payload),
  getCompanyWashers: (payload: TablePaginationParams) =>
    createAction(UsersActionTypes.GET_COMPANY_WASHERS, payload),
  getWashersSuccess: (payload: Users) =>
    createAction(UsersActionTypes.GET_WASHERS_SUCCESS, payload),
  getWashersFailure: (payload: string) =>
    createAction(UsersActionTypes.GET_WASHERS_FAILURE, payload),
  washersSearchValue: (payload: string) =>
    createAction(UsersActionTypes.WASHERS_SEARCH_VALUE, payload),
  setWashersSearchValue: (payload: string) =>
    createAction(UsersActionTypes.SET_WASHERS_SEARCH_VALUE, payload),

  getActiveUserData: (payload: string) =>
    createAction(UsersActionTypes.GET_ACTIVE_USER_DATA, payload),
  getActiveUserSuccess: (payload: User) =>
    createAction(UsersActionTypes.GET_ACTIVE_USER_SUCCESS, payload),
  getActiveUserFailure: (payload: string) =>
    createAction(UsersActionTypes.GET_ACTIVE_USER_FAILURE, payload),

  updateUserData: (payload: Partial<ModifyUserInfoDto> & { userId: ID }) =>
    createAction(UsersActionTypes.UPDATE_USER_DATA, payload),
  updateUserSuccess: (payload: User) =>
    createAction(UsersActionTypes.UPDATE_USER_SUCCESS, payload),
  updateUserFailure: (payload: string) =>
    createAction(UsersActionTypes.UPDATE_USER_FAILURE, payload),
};

export type UsersActions = ActionsUnion<typeof UsersActions>;

export type getPromotersType = ActionsOfType<
  UsersActions,
  UsersActionTypes.GET_PROMOTERS
>;
export type getClientsType = ActionsOfType<
  UsersActions,
  UsersActionTypes.GET_CLIENTS
>;
export type getWashersType = ActionsOfType<
  UsersActions,
  UsersActionTypes.GET_WASHERS
>;
export type getCompanyWashersType = ActionsOfType<
  UsersActions,
  UsersActionTypes.GET_COMPANY_WASHERS
>;

export type getActiveUserType = ActionsOfType<
  UsersActions,
  UsersActionTypes.GET_ACTIVE_USER_DATA
>;
export type updateUserType = ActionsOfType<
  UsersActions,
  UsersActionTypes.UPDATE_USER_DATA
>;

//Search
export type promotersSearchValueType = ActionsOfType<
  UsersActions,
  UsersActionTypes.PROMOTERS_SEARCH_VALUE
>;
export type clientsSearchValueType = ActionsOfType<
  UsersActions,
  UsersActionTypes.CLIENTS_SEARCH_VALUE
>;
export type washersSearchValueType = ActionsOfType<
  UsersActions,
  UsersActionTypes.WASHERS_SEARCH_VALUE
>;
