import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      position: "relative",
      display: "flex",
    },
    list: {
      marginTop: theme.spacing(4),
    },
    leftSide: {
      flexGrow: 4,
      padding: theme.spacing(4, 2, 3, 5),
    },
    rightSide: {
      position: "relative",
      flex: "1 1 auto",
    },
    rightSideContent: {
      alignSelf: "flex-start",
      width: "95%",
      padding: theme.spacing(4, 3, 3, 2),
    },
  })
);
