import React from "react";
import {
  Box,
  Button,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import texts from "../../constants/texts";
import CustomRouterLink from "../../shared/CustomRouterLink";
import { useRouteMatch } from "react-router-dom";
import colors from "../../constants/colors";
import { editDataPages } from "./editDataPages";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: "flex",
      padding: theme.spacing(1.5, 0, 2, 0),
    },
    active: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      "& $icon": {
        color: theme.palette.primary.main,
      },
      "& $title": {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
    },

    title: {
      color: theme.palette.text.secondary,
    },
    button: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      letterSpacing: 0,
      minHeight: theme.spacing(4.5),
      width: "100%",
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.2,
    },
    icon: {
      color: colors.blueGrey,
      minWidth: theme.spacing(5),
      height: theme.spacing(2.5),
    },
    paper: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(5),
    },
  })
);

interface OwnProps {}

type Props = OwnProps;

const EditDataMenu: React.FC<Props> = () => {
  const match = useRouteMatch();
  const classes = useStyles();
  return (
    <Grid item xs={2}>
      <Paper className={classes.paper}>
        <Box mb={3} pl={1}>
          <Typography variant="h4" component="h3">
            {texts.editProfile.menu.title}
          </Typography>
        </Box>
        <List>
          {editDataPages.map(({ title, path, Icon }) => (
            <React.Fragment key={path}>
              <ListItem className={classes.item} key={title}>
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  to={`${match.url}${path}`}
                >
                  <div className={classes.icon}>
                    <Icon />
                  </div>
                  <Typography variant="body1" className={classes.title}>
                    {title}
                  </Typography>
                </Button>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Grid>
  );
};

export default EditDataMenu;
