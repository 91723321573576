import React from "react";

interface OwnProps {}

type Props = OwnProps;

const LocationIcon: React.FC<Props> = () => {
  return (
    <svg
      fill="currentColor"
      id="compass"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        id="Group_280"
        data-name="Group 280"
        transform="translate(3.762 4.992)"
      >
        <g id="Group_279" data-name="Group 279">
          <path
            id="Path_583"
            data-name="Path 583"
            d="M116,142.2a.7.7,0,0,0-.777-.146l-7.8,3.41a.7.7,0,0,0,.029,1.3l2.908,1.118,1.1,2.872a.708.708,0,0,0,.671.486h0a.7.7,0,0,0,.643-.425l3.375-7.84A.7.7,0,0,0,116,142.2Zm-3.834,6.475-.611-1.588a.7.7,0,0,0-.4-.4l-1.6-.616,4.6-2.012Z"
            transform="translate(-107.001 -142)"
          />
        </g>
      </g>
      <g id="Group_282" data-name="Group 282" transform="translate(0 0)">
        <g id="Group_281" data-name="Group 281">
          <path
            id="Path_584"
            data-name="Path 584"
            d="M15.364,2.636A9,9,0,0,0,2.636,15.364,9,9,0,0,0,13.657,16.7a.7.7,0,0,0-.729-1.2,7.661,7.661,0,1,1,2.378-2.286A.7.7,0,0,0,16.475,14a8.978,8.978,0,0,0-1.111-11.36Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </svg>
  );
};

export default LocationIcon;
