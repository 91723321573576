import {
  EquipmentTableColumns,
  HeadCell,
} from "../../../../../models/table.models";
import texts from "../../../../../constants/texts";

const headCells: HeadCell<EquipmentTableColumns>[] = [
  {
    id: EquipmentTableColumns.EQUIPMENT,
    label: texts.washerEquipments.equipments.tableHeaders.equipment,
    size: 3,
  },
  {
    id: EquipmentTableColumns.USAGE,
    label: texts.washerEquipments.equipments.tableHeaders.usage,
    size: 5,
  },
];

export default headCells;
