import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { isDevMode } from "../typed-env";

const sagaMiddleware = createSagaMiddleware();

let middleware = isDevMode
  ? applyMiddleware(sagaMiddleware)
  : applyMiddleware(sagaMiddleware);

if (isDevMode) {
  middleware = composeWithDevTools(middleware);
}

const store = createStore(rootReducer, {}, middleware);
sagaMiddleware.run(rootSaga);

export default store;
