import moment, { Moment } from "moment";
import "moment-duration-format";

export function formatDate(date: Date | string | null) {
  if (date === null) {
    return ["_", "_"];
  }
  const momentDate = moment(date);
  const formattedDate = formatMomentDate(momentDate);
  const formattedTime = formatMomentTime(momentDate);
  return [formattedDate, formattedTime];
}

export function formatMomentDate(momentDate: Moment) {
  return momentDate.format("DD.MM.YYYY");
}

export function formatMomentTime(momentDate: Moment) {
  return momentDate.format("LT");
}

export function getTimeDifference(startTime: Date, endTime: Date) {
  return moment(endTime).diff(moment(startTime));
}

export function getFormattedTimeDuration(value: number) {
  return moment.duration(value, "milliseconds").format("hh : mm : ss", {
    trim: false,
  });
}

export function formatTwoDigitDate(num: number) {
  return ("0" + num).slice(-2);
}

export function getLastTwoDigitsOfYear(year: number) {
  return year.toString().substr(-2);
}
