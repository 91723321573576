import { AddBuildIdDto } from "../models/requests/MobileBuildBlacklist";
import { axiosInstance } from "./base.api";
import {
  MobileBuildResponse,
  MobileBuildsResponse,
} from "../models/responses/MobileBuildBlacklist.model";
import { ResultModel } from "../models/responses/result.model";

export const mobileBuildBlacklistApi = {
  addBuildId(data: AddBuildIdDto) {
    return axiosInstance.post<MobileBuildResponse>(
      "/private/mobile-build-blacklist",
      data
    );
  },
  getAllBuildIds() {
    const config = {
      params: { size: 0 },
    };
    return axiosInstance.get<MobileBuildsResponse>(
      "/private/mobile-build-blacklist/full-list",
      config
    );
  },
  removeBuildId(buildId: string) {
    return axiosInstance.delete<ResultModel>(
      `/private/mobile-build-blacklist/${buildId}`
    );
  },
};
