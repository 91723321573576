import React, { SVGProps } from "react";

type Props = SVGProps<any>;

const EmptyOrdersIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.636"
      height="37.534"
      viewBox="0 0 30.636 37.534"
      {...props}
    >
      <g
        id="Group_293"
        data-name="Group 293"
        transform="translate(-47.031)"
        opacity="0.5"
      >
        <path
          id="Path_598"
          data-name="Path 598"
          d="M56.971,9.22a.72.72,0,0,0-.72.72,1.585,1.585,0,1,1-1.585-1.585.72.72,0,1,0,0-1.44A3.025,3.025,0,1,0,57.691,9.94.72.72,0,0,0,56.971,9.22Z"
          fill="#6176a8"
        />
        <path
          id="Path_599"
          data-name="Path 599"
          d="M56.971,17.671a.72.72,0,0,0-.72.72,1.585,1.585,0,1,1-1.585-1.585.72.72,0,1,0,0-1.44,3.025,3.025,0,1,0,3.025,3.025A.72.72,0,0,0,56.971,17.671Z"
          fill="#6176a8"
        />
        <path
          id="Path_600"
          data-name="Path 600"
          d="M65.422,19.207H60.044a.721.721,0,0,0,0,1.441h5.378a.721.721,0,0,0,0-1.441Z"
          fill="#6176a8"
        />
        <path
          id="Path_601"
          data-name="Path 601"
          d="M65.422,16.134H60.044a.721.721,0,0,0,0,1.441h5.378a.721.721,0,0,0,0-1.441Z"
          fill="#6176a8"
        />
        <path
          id="Path_602"
          data-name="Path 602"
          d="M64.606,28.379a.721.721,0,0,0-.721-.721H60.044a.721.721,0,0,0,0,1.441h3.841A.721.721,0,0,0,64.606,28.379Z"
          fill="#6176a8"
        />
        <path
          id="Path_603"
          data-name="Path 603"
          d="M65.422,24.585H60.044a.721.721,0,0,0,0,1.441h5.378a.721.721,0,0,0,0-1.441Z"
          fill="#6176a8"
        />
        <path
          id="Path_604"
          data-name="Path 604"
          d="M73.057,11.476a.721.721,0,0,0-.72-.72H60.044a.721.721,0,0,0,0,1.441H72.337A.721.721,0,0,0,73.057,11.476Z"
          fill="#6176a8"
        />
        <path
          id="Path_605"
          data-name="Path 605"
          d="M60.044,9.123h9.219a.72.72,0,1,0,0-1.44H60.044a.72.72,0,0,0,0,1.44Z"
          fill="#6176a8"
        />
        <path
          id="Path_606"
          data-name="Path 606"
          d="M54.666,25.258a.721.721,0,0,0,0-1.441,3.025,3.025,0,1,0,3.025,3.025.721.721,0,0,0-1.441,0,1.584,1.584,0,1,1-1.584-1.584Z"
          fill="#6176a8"
        />
        <path
          id="Path_607"
          data-name="Path 607"
          d="M62.349,35.341H49.288a.817.817,0,0,1-.816-.816V2.257a.817.817,0,0,1,.816-.816H75.41a.817.817,0,0,1,.816.816V26.074a.721.721,0,0,0,1.441,0V2.257A2.26,2.26,0,0,0,75.41,0H49.288a2.259,2.259,0,0,0-2.257,2.257V34.525a2.26,2.26,0,0,0,2.257,2.257H62.349a.721.721,0,0,0,0-1.441Z"
          fill="#6176a8"
        />
        <path
          id="Path_608"
          data-name="Path 608"
          d="M68.169,37.271a.9.9,0,0,0,1.27,0l2.51-2.509,2.509,2.509A.9.9,0,0,0,75.729,36l-2.51-2.509,2.51-2.509a.9.9,0,0,0-1.271-1.271L71.949,32.22l-2.51-2.509a.9.9,0,0,0-1.27,1.271l2.509,2.509L68.169,36A.9.9,0,0,0,68.169,37.271Z"
          fill="#6176a8"
        />
      </g>
    </svg>
  );
};

export default EmptyOrdersIcon;
