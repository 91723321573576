import React, { FunctionComponent } from "react";
import FormLayout from "./FormLayout";
import { ConfirmPasswordSchema } from "../../helpers/validations";
import { Formik, FormikHelpers } from "formik";
import { Grid } from "@material-ui/core";
import SecureInput from "../../shared/SecureInput";
import useActions from "../../hooks/useActions";
import { AuthActions } from "../../store/actions/auth.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import texts from "../../constants/texts";

interface OwnProps {}

type Props = OwnProps;

interface NewPasswordForm {
  password: string;
  passwordConfirm: string;
}

const NewPasswordForm: FunctionComponent<Props> = () => {
  const { changePassword } = useActions<typeof AuthActions>(AuthActions);
  const { isChangePasswordLoading } = useSelector(
    (state: AppState) => state.auth
  );
  const handleNewPasswordSubmit = (
    values: NewPasswordForm,
    actions: FormikHelpers<NewPasswordForm>
  ) => {
    const request = {
      password: values.password,
    };
    changePassword(request);
    actions.setSubmitting(false);
  };
  return (
    <Formik
      initialValues={{ password: "", passwordConfirm: "" }}
      onSubmit={handleNewPasswordSubmit}
      validationSchema={ConfirmPasswordSchema}
    >
      {({
        values,
        handleChange,
        errors,
        touched,
        isValid,
        handleSubmit,
        handleBlur,
      }) => (
        <FormLayout
          buttonColor="secondary"
          buttonText={texts.auth.login.forgotPassword.confirmButton}
          buttonDisabled={!isValid}
          description={texts.auth.login.forgotPassword.stepThreeDescription}
          handleSubmit={handleSubmit}
          loading={isChangePasswordLoading}
        >
          <Grid item xs={6}>
            <SecureInput
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              variant="filled"
              margin="normal"
              fullWidth
              name="password"
              label={texts.auth.login.forgotPassword.newPasswordFieldLabel}
              id="password"
              error={!!errors.password && touched.password}
              helperText={
                errors.password && touched.password && errors.password
              }
            />
          </Grid>
          <Grid item xs={6}>
            <SecureInput
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.passwordConfirm}
              variant="filled"
              margin="normal"
              fullWidth
              name="passwordConfirm"
              label={texts.auth.login.forgotPassword.confirmPasswordFieldLabel}
              id="passwordConfirm"
              error={!!errors.passwordConfirm && touched.passwordConfirm}
              helperText={
                errors.passwordConfirm &&
                touched.passwordConfirm &&
                errors.passwordConfirm
              }
            />
          </Grid>
        </FormLayout>
      )}
    </Formik>
  );
};

export default NewPasswordForm;
