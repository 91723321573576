import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import {
  EquipmentRequest,
  EquipmentRequests,
  UserEquipments,
} from "../../models/responses/equipment.model";
import { Users } from "../../models/responses/user.model";
import { TablePaginationParams } from "../../models/table.models";
import { ID } from "../../models/utils.models";

export interface updateWasherDeliveryAddressPayloadModel {
  deliveryPhoneNumber: string;
  deliveryAddress: string;
}

export enum EquipmentActionTypes {
  GET_LIST_OF_A_WASHER_EQUIPMENT = "[Equipment] Get list of a washer equipment",
  GET_EQUIPMENTS_BY_WASHER_ID = "[Equipment] Get equipments by washer id",
  GET_LIST_OF_A_WASHER_EQUIPMENT_SUCCESS = "[Equipment] Get list of a washer equipment success",
  GET_LIST_OF_A_WASHER_EQUIPMENT_FAILURE = "[Equipment] Get list of a washer equipment failure",

  CREATE_EQUIPMENT_REQUEST = "[Equipment] Create equipment request",
  CREATE_MANY_EQUIPMENTS_REQUEST = "[Equipment] Create many equipments request",
  CREATE_EQUIPMENT_REQUEST_SUCCESS = "[Equipment] Create equipment request success",
  CREATE_EQUIPMENT_REQUEST_FAILURE = "[Equipment] Create equipment request failure",

  GET_WASHERS_LIST_WITH_ACTIVE_REQUESTS = "[Equipment] Get washers list with active requests",
  GET_WASHERS_LIST_WITH_ACTIVE_REQUESTS_SUCCESS = "[Equipment] Get washers list with active requests success",
  GET_WASHERS_LIST_WITH_ACTIVE_REQUESTS_FAILURE = "[Equipment] Get washers list with active requests failure",

  GET_EQUIPMENT_REQUESTS_FOR_WASHER = "[Equipment] Get equipment requests for washer",
  GET_EQUIPMENT_REQUESTS_FOR_WASHER_SUCCESS = "[Equipment] Get equipment requests for washer success",
  GET_EQUIPMENT_REQUESTS_FOR_WASHER_FAILURE = "[Equipment] Get equipment requests for washer failure",

  APPROVE_EQUIPMENT_REQUESTS_FOR_WASHER = "[Equipment] Approve equipment requests for washer",
  APPROVE_EQUIPMENT_REQUESTS_FOR_WASHER_SUCCESS = "[Equipment] Approve equipment requests for washer success",
  APPROVE_EQUIPMENT_REQUESTS_FOR_WASHER_FAILURE = "[Equipment] Approve equipment requests for washer failure",

  UPDATE_WASHER_DELIVERY_ADDRESS = "[Equipment] Update washer delivery address",
  UPDATE_WASHER_DELIVERY_ADDRESS_SUCCESS = "[Equipment] Update washer delivery address success",
  UPDATE_WASHER_DELIVERY_ADDRESS_FAILURE = "[Equipment] Update washer delivery address failure",
}

export const EquipmentActions = {
  getListOfAWasherEquipment: () =>
    createAction(EquipmentActionTypes.GET_LIST_OF_A_WASHER_EQUIPMENT),
  getEquipmentsByWasherId: (payload: ID) =>
    createAction(EquipmentActionTypes.GET_EQUIPMENTS_BY_WASHER_ID, payload),
  getListOfAWasherEquipmentSuccess: (payload: UserEquipments) =>
    createAction(
      EquipmentActionTypes.GET_LIST_OF_A_WASHER_EQUIPMENT_SUCCESS,
      payload
    ),
  getListOfAWasherEquipmentFailure: (payload: string) =>
    createAction(
      EquipmentActionTypes.GET_LIST_OF_A_WASHER_EQUIPMENT_FAILURE,
      payload
    ),

  createEquipmentRequest: (payload: string) =>
    createAction(EquipmentActionTypes.CREATE_EQUIPMENT_REQUEST, payload),
  createManyEquipmentsRequest: (payload: string[]) =>
    createAction(EquipmentActionTypes.CREATE_MANY_EQUIPMENTS_REQUEST, payload),
  createEquipmentRequestSuccess: () =>
    createAction(EquipmentActionTypes.CREATE_EQUIPMENT_REQUEST_SUCCESS),
  createEquipmentRequestFailure: (payload: string) =>
    createAction(
      EquipmentActionTypes.CREATE_EQUIPMENT_REQUEST_FAILURE,
      payload
    ),

  getWashersListWithActiveRequests: (payload: TablePaginationParams) =>
    createAction(
      EquipmentActionTypes.GET_WASHERS_LIST_WITH_ACTIVE_REQUESTS,
      payload
    ),
  getWashersListWithActiveRequestsSuccess: (payload: Users) =>
    createAction(
      EquipmentActionTypes.GET_WASHERS_LIST_WITH_ACTIVE_REQUESTS_SUCCESS,
      payload
    ),
  getWashersListWithActiveRequestsFailure: (payload: string) =>
    createAction(
      EquipmentActionTypes.GET_WASHERS_LIST_WITH_ACTIVE_REQUESTS_FAILURE,
      payload
    ),

  getEquipmentRequestsForWasher: (payload: ID) =>
    createAction(
      EquipmentActionTypes.GET_EQUIPMENT_REQUESTS_FOR_WASHER,
      payload
    ),
  getEquipmentRequestsForWasherSuccess: (payload: EquipmentRequests) =>
    createAction(
      EquipmentActionTypes.GET_EQUIPMENT_REQUESTS_FOR_WASHER_SUCCESS,
      payload
    ),
  getEquipmentRequestsForWasherFailure: (payload: string) =>
    createAction(
      EquipmentActionTypes.GET_EQUIPMENT_REQUESTS_FOR_WASHER_FAILURE,
      payload
    ),

  approveEquipmentRequestsForWasher: (payload: { id: ID; value: number }) =>
    createAction(
      EquipmentActionTypes.APPROVE_EQUIPMENT_REQUESTS_FOR_WASHER,
      payload
    ),
  approveEquipmentRequestsForWasherSuccess: (payload: EquipmentRequest) =>
    createAction(
      EquipmentActionTypes.APPROVE_EQUIPMENT_REQUESTS_FOR_WASHER_SUCCESS,
      payload
    ),
  approveEquipmentRequestsForWasherFailure: (payload: {
    id: ID;
    error: string;
  }) =>
    createAction(
      EquipmentActionTypes.APPROVE_EQUIPMENT_REQUESTS_FOR_WASHER_FAILURE,
      payload
    ),

  updateWasherDeliveryAddress: (
    payload: updateWasherDeliveryAddressPayloadModel
  ) =>
    createAction(EquipmentActionTypes.UPDATE_WASHER_DELIVERY_ADDRESS, payload),
  updateWasherDeliveryAddressSuccess: () =>
    createAction(EquipmentActionTypes.UPDATE_WASHER_DELIVERY_ADDRESS_SUCCESS),
  updateWasherDeliveryAddressFailure: (payload: string) =>
    createAction(
      EquipmentActionTypes.UPDATE_WASHER_DELIVERY_ADDRESS_FAILURE,
      payload
    ),
};

export type EquipmentActions = ActionsUnion<typeof EquipmentActions>;

export type getEquipmentsByWasherIdType = ActionsOfType<
  EquipmentActions,
  EquipmentActionTypes.GET_EQUIPMENTS_BY_WASHER_ID
>;
export type createEquipmentRequestType = ActionsOfType<
  EquipmentActions,
  EquipmentActionTypes.CREATE_EQUIPMENT_REQUEST
>;
export type createManyEquipmentsRequestType = ActionsOfType<
  EquipmentActions,
  EquipmentActionTypes.CREATE_MANY_EQUIPMENTS_REQUEST
>;
export type getWashersListWithActiveRequestsType = ActionsOfType<
  EquipmentActions,
  EquipmentActionTypes.GET_WASHERS_LIST_WITH_ACTIVE_REQUESTS
>;
export type getEquipmentRequestsForWasherType = ActionsOfType<
  EquipmentActions,
  EquipmentActionTypes.GET_EQUIPMENT_REQUESTS_FOR_WASHER
>;
export type approveEquipmentRequestsForWasherType = ActionsOfType<
  EquipmentActions,
  EquipmentActionTypes.APPROVE_EQUIPMENT_REQUESTS_FOR_WASHER
>;
export type updateWasherDeliveryAddressType = ActionsOfType<
  EquipmentActions,
  EquipmentActionTypes.UPDATE_WASHER_DELIVERY_ADDRESS
>;
