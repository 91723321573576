import { ResultModel } from "./result.model";
import { IsDefined, IsNumber, IsString } from "class-validator";
import { Type } from "class-transformer";
import { BasicModel } from "./basic.model";

export class FileModel extends BasicModel {
  @IsString()
  key: string;
  @IsDefined()
  @IsString()
  ownerId: string;
  @IsString()
  type: string;
  @IsString()
  tag: string;
  @IsNumber()
  @Type(() => Number)
  size: number;
  @IsString()
  url: string;
}

export type FileResponse = ResultModel<FileModel>;
