import React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
    },
  })
);

interface OwnProps {
  title: string;
  subtitle?: string;
  topRightElement?: JSX.Element;
}

type Props = OwnProps;

const TitledPaper: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Box mb={1}>
            <Typography variant="h4" component="h3">
              {props.title}
            </Typography>
          </Box>
          {props.subtitle && (
            <Typography variant="body1" color="textSecondary">
              {props.subtitle}
            </Typography>
          )}
        </Box>
        {props.topRightElement}
      </Box>

      {props.children}
    </Paper>
  );
};

export default TitledPaper;
