import { CarTypes } from "../../../models/responses/car.model";
import CoupeHatchbackIcon from "./CoupeHatchbackIcon";
import SuvMinivanIcon from "./SuvMinivanIcon";
import VanIcon from "./VanIcon";
import SedanVagonIcon from "./SedanVagonIcon";
import React from "react";

export default (type: CarTypes) => {
  switch (type) {
    case CarTypes.SEDAN:
      return <SedanVagonIcon />;
    case CarTypes.BIKE:
      return <VanIcon />;
    case CarTypes.MINIVAN:
      return <SuvMinivanIcon />;
    case CarTypes.SUV:
      return <CoupeHatchbackIcon />;
    default:
      return null;
  }
};
