import React, { useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import colors from "../../../constants/colors";
import FormInput from "../../../shared/FormInput";
import SecureInput from "../../../shared/SecureInput";
import { LoginSchema } from "../../../helpers/validations";
import CustomButton from "../../../shared/CustomButton";
import useActions from "../../../hooks/useActions";
import { AuthActions } from "../../../store/actions/auth.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import { AuthData } from "../../../models/requests/Credentials";
import UnauthorizedLayout from "../../../layout/UnauthorizedLayout";
import Container from "@material-ui/core/Container";
import ColoredText from "../../../styled/ColoredText";
import SignInIcon from "../../../shared/icons/SignInIcon";
import { RouteNames } from "../../../constants/routeNames";
import texts from "../../../constants/texts";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const Login: React.FC<Props> = () => {
  useTitle(`Washerly Login`);
  const history = useHistory();
  const authActions = useActions<typeof AuthActions>(AuthActions);
  const { isSignInLoading } = useSelector((state: AppState) => state.auth);
  useEffect(() => {
    authActions.signInFailure("");
  }, [authActions]);

  function handleForgotClick() {
    authActions.openForgotModal();
  }

  function handleSignUpClick() {
    history.push(`/${RouteNames.SIGN_UP}`);
  }

  function handleFormSubmit(
    values: AuthData,
    actions: FormikHelpers<AuthData>
  ) {
    authActions.signIn(values);
    actions.setSubmitting(false);
  }

  return (
    <UnauthorizedLayout>
      <Container maxWidth="xs">
        <Box width={1} pl={1}>
          <Typography align="left" component="h1" variant="h1">
            {texts.auth.login.hello}
            <ColoredText color={colors.blue}>Washerly</ColoredText>
          </Typography>
        </Box>
        <Box width={1} mt={1}>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={handleFormSubmit}
            validationSchema={LoginSchema}
          >
            {({
              values,
              handleChange,
              errors,
              touched,
              isValid,
              handleSubmit,
              handleBlur,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormInput
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  variant="filled"
                  margin="normal"
                  fullWidth
                  id="email"
                  label={texts.auth.login.email}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={!!errors.email && touched.email}
                  helperText={errors.email && touched.email && errors.email}
                />
                <SecureInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  variant="filled"
                  margin="normal"
                  fullWidth
                  name="password"
                  label={texts.auth.login.password}
                  id="password"
                  autoComplete="current-password"
                  visibleIcon
                  error={!!errors.password && touched.password}
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                />
                <Box display="flex" alignItems="center" mt={4}>
                  <CustomButton
                    color="primary"
                    rounded
                    disabled={!isValid || isSignInLoading}
                    type="submit"
                    variant="contained"
                    LeftIcon={SignInIcon}
                    loading={isSignInLoading}
                  >
                    {texts.auth.login.signIn}
                  </CustomButton>
                  <Box ml={2} display="flex">
                    <CustomButton rounded onClick={handleForgotClick}>
                      {texts.auth.login.forgotPasswordButton}?
                    </CustomButton>
                    <Box ml={2}>
                      <CustomButton rounded onClick={handleSignUpClick}>
                        {texts.auth.login.signUn}
                      </CustomButton>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Container>
    </UnauthorizedLayout>
  );
};

export default Login;
