import {
  HeadCell,
  OrderTableColumns,
} from "../../../../../models/table.models";
import texts from "../../../../../constants/texts";

const headCells: HeadCell<OrderTableColumns>[] = [
  {
    id: OrderTableColumns.ID,
    label: texts.orders.tableHeaders.id,
    size: 2,
  },
  {
    id: OrderTableColumns.CAR,
    label: texts.orders.tableHeaders.car,
    size: 3,
  },
  {
    id: OrderTableColumns.DATE,
    label: texts.orders.tableHeaders.date,
    size: 2,
  },
  {
    id: OrderTableColumns.OPTIONS,
    label: texts.orders.tableHeaders.options,
    size: 4,
  },
];

export default headCells;
