import { axiosInstance } from "./base.api";
import { GetWasherLogsParams } from "../models/requests/Logs";
import { LogsResponse } from "../models/responses/log.model";
import { SortParams } from "../models/utils.models";

export const logApi = {
  getUserLogsList(params: GetWasherLogsParams) {
    const config = {
      params: {
        ...params,
        sort: SortParams.DESC,
      },
    };
    return axiosInstance.get<LogsResponse>("/private/logs", config);
  },
};
