import { call, put, takeLatest } from "@redux-saga/core/effects";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import { showError } from "./utils";
import {
  acceptPayoutType,
  getPayoutsForAdminType,
  getPayoutsForWasherType,
  PayoutsActions,
  PayoutsActionTypes,
  rejectPayoutType,
  requestCompanyPayoutType,
  requestPromoterPayoutType,
  requestWasherPayoutType,
} from "../actions/payouts.actions";
import { payoutApi } from "../../api/payout.api";
import { Payouts } from "../../models/responses/payout.model";
import { CompanyOrders, Orders } from "../../models/responses/order.model";
import {
  GetOrdersForCompanyParams,
  GetOrdersForUserParams,
  PAYOUT_STATUS_FILTER,
} from "../../models/requests/Orders";
import { orderApi } from "../../api/order.api";
import { PayoutDispatchTypes } from "../../models/requests/Payouts";
import { referralsApi } from "../../api/referrals.api";
import { Referrals } from "../../models/responses/referrals.model";
import { GetReferralsParams } from "../../models/requests/Referrals";

function* getPayoutsForWasher(action: getPayoutsForWasherType) {
  try {
    const { data } = yield payoutApi.getOwnPayouts(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const payouts = yield transformAndValidate(Payouts, data.data);
      yield put(PayoutsActions.getPayoutsSuccess(payouts));
    }
  } catch (e) {
    yield showError(e);
    yield put(PayoutsActions.getPayoutsFailure(e.message));
  }
}

function* getPayoutsForAdmin(action: getPayoutsForAdminType) {
  try {
    const { data } = yield payoutApi.getAdminPayouts(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const payouts = yield transformAndValidate(Payouts, data.data);
      yield put(PayoutsActions.getPayoutsSuccess(payouts));
    }
  } catch (e) {
    yield showError(e);
    yield put(PayoutsActions.getPayoutsFailure(e.message));
  }
}

function* getPayedOrdersListForWasher() {
  try {
    const params: GetOrdersForUserParams = {
      page: 0,
      size: 0,
      statusFilter: "COMPLETED",
      paymentStatusFilter: "PAID",
      payoutStatusFilter: PAYOUT_STATUS_FILTER.NOT_REQUESTED_OR_REJECT,
    };
    const { data } = yield orderApi.getOrdersByUserId(params);
    if (data.result === ResponseStatus.SUCCESS) {
      const orders = yield transformAndValidate(Orders, data.data);
      yield put(PayoutsActions.getPayedOrdersListSuccess(orders));
    }
  } catch (e) {
    yield showError(e);
    yield put(PayoutsActions.getPayedOrdersListFailure(e.message));
  }
}

function* getUnpaidCompanyOrders() {
  try {
    const params: GetOrdersForCompanyParams = {
      page: 0,
      size: 0,
      payoutStatus: "NOT_REQUESTED_OR_UNPAID",
    };
    const { data } = yield orderApi.getCompanyOrders(params);
    if (data.result === ResponseStatus.SUCCESS) {
      const orders = yield transformAndValidate(CompanyOrders, data.data);
      yield put(PayoutsActions.getUnpaidCompanyOrdersSuccess(orders));
    }
  } catch (e) {
    yield showError(e);
    yield put(PayoutsActions.getUnpaidCompanyOrdersFailure(e.message));
  }
}

function* getPayedReferralsListForPromoter() {
  try {
    const params: GetReferralsParams = {
      page: 0,
      size: 0,
      onlyWithFeeAmount: true,
      payoutStatusFilter: PAYOUT_STATUS_FILTER.NOT_REQUESTED_OR_REJECT,
    };
    const { data } = yield referralsApi.getPromotersReferralsList(params);
    if (data.result === ResponseStatus.SUCCESS) {
      const referrals = yield transformAndValidate(Referrals, data.data);
      yield put(PayoutsActions.getPayedReferralsListSuccess(referrals));
    }
  } catch (e) {
    yield showError(e);
    yield put(PayoutsActions.getPayedReferralsListFailure(e.message));
  }
}

function* requestWasherPayout(action: requestWasherPayoutType) {
  try {
    const { data } = yield payoutApi.requestPayoutForWasher(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      yield put(PayoutsActions.requestPayoutSuccess());
      yield call(getPayedOrdersListForWasher);
    }
  } catch (e) {
    yield showError(e);
    yield put(PayoutsActions.requestPayoutFailure(e.message));
  }
}

function* requestPromoterPayout(action: requestPromoterPayoutType) {
  try {
    const { data } = yield payoutApi.requestPayoutForPromoter(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      yield put(PayoutsActions.requestPayoutSuccess());
      yield call(getPayedReferralsListForPromoter);
    }
  } catch (e) {
    yield showError(e);
    yield put(PayoutsActions.requestPayoutFailure(e.message));
  }
}

function* requestCompanyPayout(action: requestCompanyPayoutType) {
  try {
    const { data } = yield payoutApi.requestPayoutForCompany(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      yield put(PayoutsActions.requestPayoutSuccess());
      yield call(getUnpaidCompanyOrders);
    }
  } catch (e) {
    yield showError(e);
    yield put(PayoutsActions.requestPayoutFailure(e.message));
  }
}

function* acceptPayout(action: acceptPayoutType) {
  try {
    const reqBody = { type: PayoutDispatchTypes.ACCEPT };
    const { data } = yield payoutApi.dispatchPayout(
      action.payload.payoutId,
      reqBody
    );
    if (data.result === ResponseStatus.SUCCESS) {
      yield put(
        PayoutsActions.dispatchWasherPayoutSuccess(action.payload.payoutId)
      );
    }
  } catch (e) {
    yield showError(e);
    yield put(PayoutsActions.dispatchWasherPayoutFailure(e.message));
  }
}

function* rejectPayout(action: rejectPayoutType) {
  try {
    const reqBody = {
      type: PayoutDispatchTypes.DECLINE,
      payload: {
        description: action.payload.message,
      },
    };
    const { data } = yield payoutApi.dispatchPayout(
      action.payload.payoutId,
      reqBody
    );
    if (data.result === ResponseStatus.SUCCESS) {
      yield put(
        PayoutsActions.dispatchWasherPayoutSuccess(action.payload.payoutId)
      );
    }
  } catch (e) {
    yield showError(e);
    yield put(PayoutsActions.dispatchWasherPayoutFailure(e.message));
  }
}

export function* PayoutsSagas() {
  yield takeLatest(
    PayoutsActionTypes.GET_PAYOUTS_FOR_USER,
    getPayoutsForWasher
  );
  yield takeLatest(
    PayoutsActionTypes.REQUEST_PROMOTER_PAYOUT,
    requestPromoterPayout
  );
  yield takeLatest(
    PayoutsActionTypes.GET_PAYOUTS_FOR_ADMIN,
    getPayoutsForAdmin
  );
  yield takeLatest(
    PayoutsActionTypes.GET_PAYED_ORDERS_LIST_FOR_WASHER,
    getPayedOrdersListForWasher
  );
  yield takeLatest(
    PayoutsActionTypes.GET_UNPAID_COMPANY_ORDERS,
    getUnpaidCompanyOrders
  );
  yield takeLatest(
    PayoutsActionTypes.GET_PAYED_REFERRALS_LIST_FOR_PROMOTER,
    getPayedReferralsListForPromoter
  );
  yield takeLatest(
    PayoutsActionTypes.REQUEST_WASHER_PAYOUT,
    requestWasherPayout
  );
  yield takeLatest(
    PayoutsActionTypes.REQUEST_COMPANY_PAYOUT,
    requestCompanyPayout
  );
  yield takeLatest(PayoutsActionTypes.ACCEPT_PAYOUT, acceptPayout);
  yield takeLatest(PayoutsActionTypes.REJECT_PAYOUT, rejectPayout);
}
