import React, { useEffect, useState } from "react";
import TableHeader from "../../../../../shared/sharedTable/TableHeader";
import headCells from "./headCells";
import EquipmentRow from "../EquipmentRow";
import { Box, Button, Grid } from "@material-ui/core";
import texts from "../../../../../constants/texts";
import { UserEquipment } from "../../../../../models/responses/equipment.model";
import useActions from "../../../../../hooks/useActions";
import { AppActions } from "../../../../../store/actions/app.actions";
import { EquipmentActions } from "../../../../../store/actions/equipment.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../store/reducers";

interface OwnProps {
  title: string;
  subtitle: string;
  data: UserEquipment[];
}

type Props = OwnProps;

const EquipmentsList: React.FC<Props> = (props) => {
  const [availableItems, setAvailableItems] = useState<UserEquipment[]>([]);
  const appActions = useActions<typeof AppActions>(AppActions);
  const equipmentActions = useActions<typeof EquipmentActions>(
    EquipmentActions
  );
  const equipmentState = useSelector((state: AppState) => state.equipment);

  useEffect(() => {
    const newNotRequestedItems = props.data.filter(
      (item) =>
        item.userEquipmentRequest === null &&
        item.value < item.equipment.maxValue
    );
    setAvailableItems(newNotRequestedItems);
  }, [props.data]);

  const handleRequestAll = () => {
    const requestEquipmentIds = availableItems.map((item) => item.id);
    equipmentActions.createManyEquipmentsRequest(requestEquipmentIds);
  };
  const headerButton = (
    <Grid item xs={4}>
      <Box display="flex" justifyContent="flex-end" alignItems="center" p={2}>
        <Button
          style={{ width: 200 }}
          variant="outlined"
          color="secondary"
          disabled={!availableItems.length}
          onClick={() =>
            appActions.showConfirmationDialog({
              withReason: false,
              title: `Request all ${props.title}`,
              description: "Are you sure?",
              open: true,
              onSubmit: handleRequestAll,
            })
          }
        >
          {texts.washerEquipments.equipments.orderAll}
        </Button>
      </Box>
    </Grid>
  );
  return (
    <>
      <TableHeader
        title={props.title}
        subtitle={props.subtitle}
        headCells={headCells}
        isLoading={
          equipmentState.washerEquipmentsLoading ||
          equipmentState.createEquipmentRequestsLoading
        }
        headCellsButton={headerButton}
      />
      {props.data.map((item) => (
        <EquipmentRow key={item.id} headCells={headCells} data={item} />
      ))}
    </>
  );
};

export default EquipmentsList;
