import { ResultModel } from "./result.model";
import { User } from "./user.model";
import {
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { Attachment } from "./attachment.model";
import { BasicModel } from "./basic.model";

export class Review extends BasicModel {
  @IsOptional()
  @IsString()
  message: string | null;
  @IsUUID()
  orderId: string;
  @IsDefined()
  @Type(() => User)
  @ValidateNested()
  owner: User;
  @IsString()
  ownerId: string;
  @IsNumber()
  @Type(() => Number)
  communicationRating: number;
  @IsNumber()
  @Type(() => Number)
  qualityRating: number;
  @IsNumber()
  @Type(() => Number)
  washTimeRating: number;
  @IsString()
  userId: string;
  attachment: Attachment[];
}

export class Reviews {
  @IsDefined()
  @Type(() => Review)
  @ValidateNested()
  items: Review[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export type ReviewResponse = ResultModel<Review>;
export type ReviewsResponse = ResultModel<Reviews>;
