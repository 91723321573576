import React, { SVGProps } from "react";

type Props = SVGProps<any>;

const EmptyOrderIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51.798"
      height="20.526"
      {...props}
      viewBox="0 0 51.798 20.526"
    >
      <defs>
        <clipPath id="clip">
          <use xlinkHref="#fill" />
        </clipPath>
      </defs>
      <g
        id="Group_296"
        data-name="Group 296"
        transform="translate(-312.202 -444.203)"
        opacity="0.5"
      >
        <path
          id="Path_617"
          data-name="Path 617"
          d="M317.41,458.811s-4.751.132-4.391-7.223a.59.59,0,0,1,.03-.156h0a.57.57,0,0,1,.437-.381,19.874,19.874,0,0,0,4.974-1.529l.026-.013c.376-.167,8.68-3.873,9.908-3.976a.7.7,0,0,0,.092-.011A23.5,23.5,0,0,1,332.7,445c1.861,0,6.779.8,9.171,2.125,0,0,6.231,2.983,8.045,3.566.041.013.687.271.727.285a12.344,12.344,0,0,1,1.4.522c.332.142.69.265.731.275a9.25,9.25,0,0,0,1.728.265,19.05,19.05,0,0,1,4.785,1.2c.665.4,2.127,1.195,2.127,2.656a2.248,2.248,0,0,1-.931,1.992,5.006,5.006,0,0,1-1.861.8"
          fill="none"
          stroke="#6077a8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.594"
        />
        <g
          id="Path_618"
          data-name="Path 618"
          fill="none"
          strokeLinejoin="round"
        >
          <path
            d="M325.588,450.061s2.985-1.557,4.542-1.557l.259,1.817Z"
            stroke="none"
          />
          <path
            d="M 330.3890075683594 450.3210144042969 L 330.1300048828125 448.5039978027344 C 328.572998046875 448.5039978027344 325.5880126953125 450.0610046386719 325.5880126953125 450.0610046386719 L 330.3890075683594 450.3210144042969 M 330.3890686035156 451.4889526367188 C 330.3680419921875 451.4889526367188 330.3469848632812 451.4884033203125 330.3258361816406 451.4872436523438 L 325.5248413085938 451.2272338867188 C 324.9994506835938 451.1987915039062 324.5579528808594 450.8225402832031 324.4465637207031 450.3082885742188 C 324.3351440429688 449.7940673828125 324.5813598632812 449.2688293457031 325.0478515625 449.0254821777344 C 325.3793640136719 448.8525695800781 328.3384094238281 447.3360595703125 330.1300048828125 447.3360595703125 C 330.7113647460938 447.3360595703125 331.2042236328125 447.7636413574219 331.2862548828125 448.3391723632812 L 331.5452575683594 450.1562194824219 C 331.5945739746094 450.5022583007812 331.486328125 450.8522338867188 331.2501831054688 451.1099853515625 C 331.0283508300781 451.3521118164062 330.7155456542969 451.4889526367188 330.3890686035156 451.4889526367188 Z"
            stroke="none"
            fill="#6077a8"
          />
        </g>
        <g
          id="Path_619"
          data-name="Path 619"
          fill="none"
          strokeLinejoin="round"
        >
          <path
            d="M343.107,451.1s-5.97-2.855-9.214-2.855l.519,2.336Z"
            stroke="none"
          />
          <path
            d="M 343.1069946289062 451.0989990234375 C 343.1069946289062 451.0989990234375 337.1369934082031 448.2439880371094 333.8930053710938 448.2439880371094 L 334.4119873046875 450.5799865722656 L 343.1069946289062 451.0989990234375 M 343.1076354980469 452.2669677734375 C 343.0843200683594 452.2669677734375 343.0609130859375 452.2662658691406 343.0374145507812 452.2648620605469 L 334.3424072265625 451.745849609375 C 333.8218383789062 451.7147827148438 333.3849487304688 451.3423767089844 333.2718505859375 450.8332824707031 L 332.7528686523438 448.4972839355469 C 332.676025390625 448.1514587402344 332.7603149414062 447.7894897460938 332.9819946289062 447.5131530761719 C 333.2036743164062 447.23681640625 333.5387573242188 447.0760498046875 333.8930053710938 447.0760498046875 C 337.3619995117188 447.0760498046875 343.3573608398438 449.9241027832031 343.6108703613281 450.0453491210938 C 344.1147766113281 450.2863159179688 344.3775329589844 450.8503723144531 344.23779296875 451.3911743164062 C 344.1039428710938 451.9091796875 343.6367492675781 452.2669677734375 343.1076354980469 452.2669677734375 Z"
            stroke="none"
            fill="#6077a8"
          />
        </g>
        <g
          id="Ellipse_109"
          data-name="Ellipse 109"
          transform="translate(321.046 456.42)"
          fill="none"
          stroke="#6077a8"
          strokeLinejoin="round"
          strokeWidth="1.427"
        >
          <circle cx="2.985" cy="2.985" r="2.985" stroke="none" />
          <circle cx="2.985" cy="2.985" r="3.698" fill="none" />
        </g>
        <g
          id="Rectangle_282"
          data-name="Rectangle 282"
          transform="translate(329.611 459.664)"
          fill="none"
          stroke="#6077a8"
          strokeLinejoin="round"
          strokeWidth="1.199"
        >
          <rect width="10.252" height="1.687" rx="0.844" stroke="none" />
          <rect
            x="0.599"
            y="0.599"
            width="9.053"
            height="0.488"
            rx="0.244"
            fill="none"
          />
        </g>
        <g
          id="Rectangle_283"
          data-name="Rectangle 283"
          transform="translate(344.271 463.536) rotate(-45)"
          fill="none"
          stroke="#6077a8"
          strokeLinejoin="round"
          strokeWidth="1.199"
        >
          <rect width="10.252" height="1.687" rx="0.844" stroke="none" />
          <rect
            x="0.599"
            y="0.599"
            width="9.053"
            height="0.488"
            rx="0.244"
            fill="none"
          />
        </g>
        <g
          id="Rectangle_284"
          data-name="Rectangle 284"
          transform="translate(351.521 464.729) rotate(-135)"
          fill="none"
          stroke="#6077a8"
          strokeLinejoin="round"
          strokeWidth="1.199"
        >
          <rect width="10.252" height="1.687" rx="0.844" stroke="none" />
          <rect
            x="0.599"
            y="0.599"
            width="9.053"
            height="0.488"
            rx="0.244"
            fill="none"
          />
        </g>
        <g
          id="Rectangle_285"
          data-name="Rectangle 285"
          transform="translate(314.168 462.779)"
          fill="none"
          stroke="#6077a8"
          strokeLinejoin="round"
          strokeWidth="1.883"
        >
          <rect
            id="fill"
            width="25.305"
            height="1.687"
            rx="0.844"
            stroke="none"
          />
          <path
            d="M0,0.7454004883766174h25.305343627929688M24.363721191883087,0v1.6870229244232178M25.305343627929688,0.9416224360466003h-25.305343627929688M0.9416224360466003,1.6870229244232178v-1.6870229244232178"
            fill="none"
            clipPath="url(#clip)"
          />
        </g>
        <g
          id="Rectangle_286"
          data-name="Rectangle 286"
          transform="translate(357.122 462.779)"
          fill="none"
          stroke="#6077a8"
          strokeLinejoin="round"
          strokeWidth="0.982"
        >
          <rect width="6.878" height="1.687" rx="0.844" stroke="none" />
          <rect
            x="0.491"
            y="0.491"
            width="5.896"
            height="0.705"
            rx="0.353"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
};

export default EmptyOrderIcon;
