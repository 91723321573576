import React from "react";
import ContentLoader from "react-content-loader";
import { Grid } from "@material-ui/core";

interface OwnProps {}

type Props = OwnProps;

const TableLoader: React.FC<Props> = () => {
  return (
    <Grid item xs={12}>
      <ContentLoader
        height={400}
        width={1123}
        speed={2}
        primaryColor="#FFFFFF"
        secondaryColor="#F3F3F3"
      >
        <rect x="0" y="0" rx="12" ry="12" width="1123" height="189" />
        <rect x="0" y="196" rx="12" ry="12" width="1123" height="64" />
        <rect x="0" y="267" rx="12" ry="12" width="1123" height="64" />
        <rect x="0" y="339" rx="12" ry="12" width="1123" height="64" />
      </ContentLoader>
    </Grid>
  );
};

export default TableLoader;
