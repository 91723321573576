import React, { useEffect, useState } from "react";
import TitledPaper from "../../../shared/TitledPaper";
import InputWithAdornment from "../../../shared/InputWithAdornment";
import {
  Box,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import CustomButton from "../../../shared/CustomButton";
import useActions from "../../../hooks/useActions";
import { MobileBuildBlacklistActions } from "../../../store/actions/mobileBuildBlacklist.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import Loading from "../../../shared/Loading";
import { AppActions } from "../../../store/actions/app.actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipsContainer: {
      marginTop: theme.spacing(4),
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
    },
    chip: {
      margin: theme.spacing(1),
    },
  })
);

interface OwnProps {}

type Props = OwnProps;

const Blacklist: React.FC<Props> = () => {
  const classes = useStyles();
  const [buildId, setBuildId] = useState("");

  const mobileBuildBlacklistActions = useActions<
    typeof MobileBuildBlacklistActions
  >(MobileBuildBlacklistActions);
  const appActions = useActions<typeof AppActions>(AppActions);

  const mobileBuildBlacklistState = useSelector(
    (state: AppState) => state.mobileBuildBlacklist
  );

  useEffect(() => {
    mobileBuildBlacklistActions.getMobileBuilds();
  }, [mobileBuildBlacklistActions]);

  useEffect(() => {
    if (
      !mobileBuildBlacklistState.addMobileBuildsLoading &&
      !mobileBuildBlacklistState.addMobileBuildsError
    ) {
      setBuildId("");
    }
  }, [
    mobileBuildBlacklistState.addMobileBuildsLoading,
    mobileBuildBlacklistState.addMobileBuildsError,
  ]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    mobileBuildBlacklistActions.addMobileBuild({ buildId });
  }

  function handleDelete(buildId: string) {
    appActions.showConfirmationDialog({
      title: "Remove Mobile Build from Blacklist",
      description:
        "Are you sure that you want to Remove Mobile Build from Blacklist?",
      open: true,
      onSubmit: () => mobileBuildBlacklistActions.removeMobileBuild(buildId),
    });
  }

  if (mobileBuildBlacklistState.mobileBuildsLoading) {
    return (
      <Box mt={12}>
        <Loading />
      </Box>
    );
  }

  return (
    <TitledPaper
      title="Mobile build blacklist"
      subtitle="Mobile build blacklist"
    >
      <Box mt={4}>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={6}>
              <InputWithAdornment
                adornment="Build ID"
                onChange={(event) => setBuildId(event.target.value)}
                value={buildId}
                id="buildId"
                name="buildId"
              />
            </Grid>
            <Grid item xs={2}>
              <CustomButton
                color="secondary"
                variant="contained"
                type="submit"
                disabled={
                  !buildId || mobileBuildBlacklistState.addMobileBuildsLoading
                }
                loading={mobileBuildBlacklistState.addMobileBuildsLoading}
              >
                Add
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </Box>
      <ul className={classes.chipsContainer}>
        {mobileBuildBlacklistState.mobileBuilds.items.map((item) => (
          <li key={item.buildId}>
            <Chip
              className={classes.chip}
              variant="outlined"
              color="primary"
              label={item.buildId}
              onDelete={() => handleDelete(item.buildId)}
            />
          </li>
        ))}
        {mobileBuildBlacklistState.removeMobileBuildsLoading && <Loading />}
      </ul>
    </TitledPaper>
  );
};

export default Blacklist;
