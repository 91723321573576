import React from "react";
import { Box } from "@material-ui/core";
import DashboardStatisticItem from "./DashboardStatisticItem";
import PromoterStatisticsDivider from "../../styled/PromoterStatisticsDivider";
import TitledPaper from "../../shared/TitledPaper";

export type StatisticItem = {
  Icon: React.FC;
  name: string;
  value: string;
};

interface OwnProps {
  title: string;
  subtitle: string;
  items: StatisticItem[];
}

type Props = OwnProps;

const DashboardStatistics: React.FC<Props> = (props) => {
  return (
    <TitledPaper title={props.title} subtitle={props.subtitle}>
      <Box
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {props.items.map((item, index, array) => (
          <>
            <DashboardStatisticItem
              Icon={item.Icon}
              title={item.name}
              value={item.value}
            />
            {index !== array.length - 1 && <PromoterStatisticsDivider />}
          </>
        ))}
      </Box>
    </TitledPaper>
  );
};

export default DashboardStatistics;
