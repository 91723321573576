import React, { SVGProps } from "react";

type Props = SVGProps<any>;

const RejectedListIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18.68"
      viewBox="0 0 17 18.68"
      {...props}
    >
      <g id="list_2_" data-name="list (2)" transform="translate(3 -10.78)">
        <g id="Group_292" data-name="Group 292" transform="translate(5)">
          <rect
            id="Rectangle_276"
            data-name="Rectangle 276"
            width="9"
            height="2"
            rx="1"
            transform="translate(0 12)"
            fill="currentColor"
          />
          <rect
            id="Rectangle_279"
            data-name="Rectangle 279"
            width="9"
            height="2"
            rx="1"
            transform="translate(0 19)"
            fill="currentColor"
          />
          <rect
            id="Rectangle_280"
            data-name="Rectangle 280"
            width="9"
            height="2"
            rx="1"
            transform="translate(0 26)"
            fill="currentColor"
          />
          <path
            id="Path_593"
            data-name="Path 593"
            d="M166.152,177.527a.52.52,0,0,0,.735,0l1.452-1.452,1.452,1.452a.52.52,0,1,0,.735-.735l-1.452-1.452,1.452-1.452a.52.52,0,0,0-.735-.735l-1.452,1.452-1.452-1.452a.52.52,0,0,0-.735.735l1.452,1.452-1.452,1.452A.52.52,0,0,0,166.152,177.527Z"
            transform="translate(-174 -162.219)"
            fill="currentColor"
          />
          <path
            id="Path_594"
            data-name="Path 594"
            d="M166.152,177.527a.52.52,0,0,0,.735,0l1.452-1.452,1.452,1.452a.52.52,0,1,0,.735-.735l-1.452-1.452,1.452-1.452a.52.52,0,0,0-.735-.735l-1.452,1.452-1.452-1.452a.52.52,0,0,0-.735.735l1.452,1.452-1.452,1.452A.52.52,0,0,0,166.152,177.527Z"
            transform="translate(-174 -155.219)"
            fill="currentColor"
          />
          <path
            id="Path_595"
            data-name="Path 595"
            d="M166.152,177.527a.52.52,0,0,0,.735,0l1.452-1.452,1.452,1.452a.52.52,0,1,0,.735-.735l-1.452-1.452,1.452-1.452a.52.52,0,0,0-.735-.735l-1.452,1.452-1.452-1.452a.52.52,0,0,0-.735.735l1.452,1.452-1.452,1.452A.52.52,0,0,0,166.152,177.527Z"
            transform="translate(-174 -148.219)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export default RejectedListIcon;
