import { axiosInstance } from "./base.api";
import { AxiosResponse } from "axios";
import { PendingWashersResponse } from "../models/responses/pendingWasher.model";
import { ID, SortParams } from "../models/utils.models";
import {
  ReviewVerificationFormDto,
  WasherVerificationData,
} from "../models/requests/VerificationForm";
import { ResultModel } from "../models/responses/result.model";
import { ApplicationFormResponse } from "../models/responses/applicationForm.model";
import { PendingWashersRequestParams } from "../models/requests/PendingWashers";
import { PendingWashersTableColumns } from "../models/table.models";
import { UserResponse } from "../models/responses/user.model";

export const pendingWasherApi = {
  sendVerificationForm(form: WasherVerificationData) {
    return axiosInstance.post<ResultModel>(
      "/private/users/washer-verification",
      form
    );
  },

  getPendingWashers(params: PendingWashersRequestParams) {
    const config = {
      params: {
        sortBy: PendingWashersTableColumns.DATE,
        sort: SortParams.DESC,
        ...params,
      },
    };
    return axiosInstance.get<PendingWashersResponse>(
      "/private/users/pending-washers",
      config
    );
  },

  getMyVerificationForm() {
    return axiosInstance.get<ApplicationFormResponse>(
      `/private/users/me/washer-verification`
    );
  },

  getPendingWasherVerificationForm(id: ID) {
    return axiosInstance.get<ApplicationFormResponse>(
      `/private/users/washer-verification/${id}`
    );
  },

  changeVerificationFormStatus(id: ID, body: ReviewVerificationFormDto) {
    return axiosInstance.patch<ApplicationFormResponse>(
      `/private/users/washer-verification/${id}`,
      body
    );
  },
  submitExamByWasher(): Promise<AxiosResponse<UserResponse>> {
    return axiosInstance.post("/private/users/submit-exam");
  },
};
