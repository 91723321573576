import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const ArrowDownIcon: React.FC<Props> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="6.546"
      height="9.092"
      viewBox="0 0 6.546 9.092"
    >
      <path
        id="Union_4"
        data-name="Union 4"
        d="M-9138.009,641.392a.54.54,0,0,1,0-.749l1.923-2.006s0,0,0,0a.542.542,0,0,0,0-.749l-1.923-1.98a.54.54,0,0,1,0-.749.5.5,0,0,1,.727,0l1.925,1.98a1.624,1.624,0,0,1,0,2.246l-1.921,2.006a.508.508,0,0,1-.366.158A.506.506,0,0,1-9138.009,641.392Zm-5.477-2.6a.523.523,0,0,1-.515-.53.522.522,0,0,1,.515-.53h5.479a.523.523,0,0,1,.515.53.524.524,0,0,1-.515.53Z"
        transform="translate(641.546 9144) rotate(90)"
      />
    </svg>
  );
};

export default ArrowDownIcon;
