import styled from "styled-components";
import colors from "../constants/colors";

interface OrderProgressSectionProps {
  isDone: boolean;
  width: number;
}

export default styled.div`
  height: 100%;
  width: ${(props: OrderProgressSectionProps) => props.width}%;
  border-right: 1px solid
    ${(props: OrderProgressSectionProps) =>
      props.isDone ? "rgba(255, 255, 255, .5)" : colors.lightBlueGrey};
  background-color: transparent;
  z-index: 10;
  &:nth-last-child(2) {
    border-right: none;
  }
`;
