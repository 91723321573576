import React from "react";

interface OwnProps {}

type Props = OwnProps;

const CoupeHatchbackIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="40"
      height="16.129"
      viewBox="0 0 40 16.129"
    >
      <path
        id="Path_211"
        data-name="Path 211"
        d="M39.3,121.935l-1.071-.718-.813-2.247a1.048,1.048,0,0,0-.917-.689l-11.29-.74-3.34-2.344a7.227,7.227,0,0,0-5.816-1.115l-2.466.585a28.344,28.344,0,0,0-7.53,2.98c-.033,0-.066-.005-.1-.005H3.142l-.951-.951a1.048,1.048,0,0,0-1.482,1.482l1.258,1.258a1.048,1.048,0,0,0,.741.307h.2q-.918.7-1.779,1.48a1.048,1.048,0,0,0-.345.777v.866l-.677,1.366a1.048,1.048,0,0,0-.109.465,2.361,2.361,0,0,0,2.358,2.358h3.8a4.014,4.014,0,0,0,7.749,0H26.854a4.014,4.014,0,0,0,7.749,0h2.6a2.367,2.367,0,0,0,2.27-1.719l.465-1.652A1.578,1.578,0,0,0,39.3,121.935Zm-1.841,2.827a.263.263,0,0,1-.252.191H34.6a4.014,4.014,0,0,0-7.749,0H13.907a4.014,4.014,0,0,0-7.749,0h-3.8a.262.262,0,0,1-.215-.112l.63-1.271a1.048,1.048,0,0,0,.109-.465v-.641A26.281,26.281,0,0,1,14.07,116.7l2.466-.585a5.13,5.13,0,0,1,4.128.792l3.581,2.513a1.048,1.048,0,0,0,.533.188l10.9.715.7,1.925a1.048,1.048,0,0,0,.4.514l1.046.7ZM32.647,126a1.918,1.918,0,1,1-1.918-1.918A1.92,1.92,0,0,1,32.647,126Zm-20.7,0a1.918,1.918,0,1,1-1.918-1.918A1.92,1.92,0,0,1,11.951,126Z"
        transform="translate(0 -113.886)"
      />
    </svg>
  );
};

export default CoupeHatchbackIcon;
