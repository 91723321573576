import { HeadCell, LogsColumns } from "../../../models/table.models";

const headCells: HeadCell<LogsColumns>[] = [
  {
    id: LogsColumns.ACTIVITY_TYPE,
    label: "Activity Type",
    size: 4,
  },
  {
    id: LogsColumns.DATE,
    label: "Date",
    size: 6,
  },
];

export default headCells;
