import {
  HeadCell,
  OwnPayoutsColumns,
} from "../../../../../models/table.models";
import texts from "../../../../../constants/texts";

const headCells: HeadCell<OwnPayoutsColumns>[] = [
  {
    id: OwnPayoutsColumns.ID,
    label: texts.roles.companyAdmin.payouts.tableHeaders.id,
    size: 2,
  },
  {
    id: OwnPayoutsColumns.DATE,
    label: texts.roles.companyAdmin.payouts.tableHeaders.date,
    size: 2,
  },
  {
    id: OwnPayoutsColumns.TOTAL,
    label: texts.roles.companyAdmin.payouts.tableHeaders.total,
    size: 2,
  },
  {
    id: OwnPayoutsColumns.STATUS,
    label: texts.roles.companyAdmin.payouts.tableHeaders.status,
    size: 2,
  },
];

export default headCells;
