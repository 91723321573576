import React from "react";
import TitledPaper from "../../../shared/TitledPaper";
import texts from "../../../constants/texts";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import InputWithAdornment from "../../../shared/InputWithAdornment";
import CustomButton from "../../../shared/CustomButton";
import { FormikHelpers, useFormik } from "formik";
import { createCompanyData } from "../../../models/requests/Company";
import { createCompanyVerificationSchema } from "../../../helpers/validations";
import useActions from "../../../hooks/useActions";
import { CompanyActions } from "../../../store/actions/company.actions";
import { AppState } from "../../../store/reducers";
import { useSelector } from "react-redux";

interface OwnProps {}

type Props = OwnProps;

const CreateCompany: React.FC<Props> = () => {
  const companyActions = useActions<typeof CompanyActions>(CompanyActions);
  const companyState = useSelector((state: AppState) => state.company);

  const handleFormSubmit = (
    values: createCompanyData,
    actions: FormikHelpers<createCompanyData>
  ) => {
    companyActions.createCompany(values);
    actions.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      country: "",
      postalCode: "",
      address: "",
    },
    onSubmit: handleFormSubmit,
    validationSchema: createCompanyVerificationSchema,
  });

  return (
    <TitledPaper
      title={texts.createCompany.title}
      subtitle={texts.createCompany.subtitle}
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box pt={6} />
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <InputWithAdornment
              adornment={texts.createCompany.companyNameAdornment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              id="name"
              name="name"
              helperText={
                formik.errors.name && formik.touched.name && formik.errors.name
              }
              error={!!formik.errors.name && formik.touched.name}
            />
          </Grid>
          <Grid item xs={4}>
            <InputWithAdornment
              adornment={texts.createCompany.countryAdornment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
              id="country"
              name="country"
              helperText={
                formik.errors.country &&
                formik.touched.country &&
                formik.errors.country
              }
              error={!!formik.errors.country && formik.touched.country}
            />
          </Grid>
          <Grid item xs={4}>
            <InputWithAdornment
              adornment={texts.createCompany.postalCodeAdornment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postalCode}
              id="postalCode"
              name="postalCode"
              helperText={
                formik.errors.postalCode &&
                formik.touched.postalCode &&
                formik.errors.postalCode
              }
              error={!!formik.errors.postalCode && formik.touched.postalCode}
            />
          </Grid>
          <Grid item xs={8}>
            <InputWithAdornment
              adornment={texts.createCompany.addressAdornment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              id="address"
              name="address"
              helperText={
                formik.errors.address &&
                formik.touched.address &&
                formik.errors.address
              }
              error={!!formik.errors.address && formik.touched.address}
            />
          </Grid>
        </Grid>
        <Box flexBasis="100%" display="flex" alignItems="center" mt={4}>
          <CustomButton
            color="secondary"
            disabled={!formik.isValid || companyState.createCompanyLoading}
            type="submit"
            variant="contained"
            loading={companyState.createCompanyLoading}
          >
            {texts.createCompany.createCompanyButton}
          </CustomButton>
        </Box>
      </form>
    </TitledPaper>
  );
};

export default CreateCompany;
