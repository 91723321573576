import React, { useCallback } from "react";
import TableLayout from "../../../../../shared/sharedTable/TableLayout";
import headCells from "./headCells";
import { TablePaginationParams } from "../../../../../models/table.models";
import AdminPayoutRow from "../AdminPayoutRow";
import useActions from "../../../../../hooks/useActions";
import { PayoutsActions } from "../../../../../store/actions/payouts.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../store/reducers";
import { payoutsSelectors } from "../../../../../store/reducers/payouts.reducer";

interface OwnProps {
  title: string;
  subtitle: string;
  isCompleted?: boolean;
}

type Props = OwnProps;

const AdminPayoutsList: React.FC<Props> = (props) => {
  const payoutsActions = useActions<typeof PayoutsActions>(PayoutsActions);
  const payoutsState = useSelector((state: AppState) => state.payouts);
  const data = payoutsSelectors.getPayoutsDataForAdmin(payoutsState);

  const handlePaginationChange = useCallback(
    (values: TablePaginationParams) => {
      const status = props.isCompleted ? "PAID_OR_REJECTED" : "REQUESTED";
      payoutsActions.getPayoutsForAdmin({ ...values, status });
    },
    [props.isCompleted, payoutsActions]
  );

  return (
    <TableLayout
      title={props.title}
      subtitle={props.subtitle}
      isLoading={payoutsState.payoutsLoading}
      total={payoutsState.payouts.total}
      headCells={headCells}
      onPaginationChange={handlePaginationChange}
    >
      {data.map((item) => (
        <AdminPayoutRow columns={headCells} row={item} />
      ))}
    </TableLayout>
  );
};

export default AdminPayoutsList;
