import React from "react";
import texts from "../../../../../constants/texts";
import EmptyData from "../../../../../components/EmptyData";
import EmptyLogsIcon from "../../../../../shared/icons/empty/EmptyLogsIcon";

interface OwnProps {}

type Props = OwnProps;

const Logs: React.FC<Props> = () => {
  return (
    <EmptyData
      title={texts.roles.washer.dashboard.logs.title}
      subtitle={texts.roles.washer.dashboard.logs.subtitle}
      emptyText={texts.roles.washer.dashboard.logs.emptyText}
      Icon={EmptyLogsIcon}
    />
  );
};

export default Logs;
