import React from "react";
import {
  Box,
  Divider,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import UserRating from "../UserProfile/UserInfo/UserRating";
import PhoneIcon from "../../shared/icons/PhoneIcon";
import { formatPhoneNumber, getImageUrl } from "../../helpers/utils/utils";
import MailIcon from "../../shared/icons/MailIcon";
import ContactRow from "./ContactRow";
import MuiRating from "../../shared/MuiRating";
import { User, UserRole } from "../../models/responses/user.model";
import DefaultAvatar from "../../Images/defaultUserAvatar.png";
import WasherNotFoundIcon from "../../shared/icons/WasherNotFoundIcon";
import { unitSize } from "../../constants/sizes";
import { Review } from "../../models/responses/review.model";
import texts from "../../constants/texts";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: theme.spacing(57),
    padding: theme.spacing(4, 3, 4, 3),
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: 10,
    marginRight: theme.spacing(2),
  },
}));

interface OwnProps {
  user: User | null;
  review?: Review;
}

type Props = OwnProps;

const UserContactsCard: React.FC<Props> = ({ user, review }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Typography variant="h4">
        {user && user.role === UserRole.CLIENT
          ? texts.orderDetails.clientContact
          : texts.orderDetails.washerContact}
      </Typography>
      {user ? (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={4}
          >
            <Box display="flex" alignItems="center">
              <img
                src={
                  user.avatarFileId
                    ? getImageUrl(user.avatarFileId, 80)
                    : DefaultAvatar
                }
                alt="Avatar"
                className={classes.avatar}
              />
              <Typography variant="body2" color="textSecondary">
                {user.firstName} {user.secondName}
              </Typography>
            </Box>
            <UserRating value={user.rating} />
          </Box>
          <Box mt={4}>
            <Typography variant="h6">
              {user.role === UserRole.CLIENT
                ? texts.orderDetails.contactClient
                : texts.orderDetails.contactWasher}
            </Typography>
            <ContactRow
              Icon={PhoneIcon}
              title={`${texts.orderDetails.phoneNumber}: `}
              contact={formatPhoneNumber(user.phoneNumber)}
              link={`tel:${user.phoneNumber}`}
            />
            <Box mt={2}>
              <Divider />
            </Box>
            {user.email && (
              <ContactRow
                Icon={MailIcon}
                title={`${texts.orderDetails.emailAddress}: `}
                contact={user.email}
                link={`mailto:${user.email}`}
              />
            )}
          </Box>
          {review && (
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              {user.role === UserRole.WASHER && (
                <>
                  <MuiRating
                    readOnly
                    initValue={review.communicationRating}
                    label={texts.reviews.communicationRating}
                  />
                  <MuiRating
                    readOnly
                    initValue={review.qualityRating}
                    label={texts.reviews.qualityRating}
                  />
                  <MuiRating
                    readOnly
                    initValue={review.washTimeRating}
                    label={texts.reviews.washTimeRating}
                  />
                </>
              )}
              {user.role === UserRole.CLIENT && (
                <MuiRating
                  readOnly
                  initValue={
                    (review.communicationRating +
                      review.washTimeRating +
                      review.qualityRating) /
                    3
                  }
                  label={texts.reviews.reviewForClient}
                />
              )}
            </Box>
          )}
        </>
      ) : (
        <Box mt={7} textAlign="center" color="primary.main">
          <WasherNotFoundIcon width={unitSize * 7} />
          <Box mt={3}>
            <Typography variant="h3" color="primary">
              {texts.orderDetails.washerNotFound}
            </Typography>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default UserContactsCard;
