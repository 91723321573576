import { combineReducers } from "redux";
import { authReducer } from "./auth.reducer";
import { appReducer } from "./app.reducer";
import { usersReducer } from "./users.reducer";
import { ordersReducer } from "./orders.reducer";
import { carsReducer } from "./cars.reducer";
import { reviewsReducer } from "./reviews.reducer";
import { companyReducer } from "./company.reducer";
import { washerVerificationReducer } from "./washerVerification.reducer";
import { pendingWashersReducer } from "./pendingWashers.reducer";
import { statisticReducer } from "./statistic.reducer";
import { paymentReducer } from "./payment.reducer";
import { payoutsReducer } from "./payouts.reducer";
import { equipmentReducer } from "./equipment.reducer";
import { sessionReducer } from "./session.reducer";
import { logReducer } from "./log.reducer";
import { referralsReducer } from "./referrals.reducer";
import { earningsReducer } from "./earnings.reducer";
import { AuthActionTypes } from "../actions/auth.actions";
import { mobileBuildBlacklistReducer } from "./mobileBuildBlacklist.reducer";

const mainReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  users: usersReducer,
  orders: ordersReducer,
  cars: carsReducer,
  reviews: reviewsReducer,
  company: companyReducer,
  washerVerification: washerVerificationReducer,
  pendingWashers: pendingWashersReducer,
  statistic: statisticReducer,
  payment: paymentReducer,
  payouts: payoutsReducer,
  equipment: equipmentReducer,
  session: sessionReducer,
  log: logReducer,
  referrals: referralsReducer,
  earnings: earningsReducer,
  mobileBuildBlacklist: mobileBuildBlacklistReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === AuthActionTypes.LOG_OUT) {
    state = { auth: { ...state.auth } };
  }

  return mainReducer(state, action);
};

export type AppState = ReturnType<typeof mainReducer>;

export default rootReducer;
