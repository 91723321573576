import React from "react";

interface OwnProps {}

type Props = OwnProps;

const InvoicesIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="11.918"
      height="15.034"
      viewBox="0 0 11.918 15.034"
    >
      <g id="invoice" transform="translate(-28.5)">
        <g id="Group_306" data-name="Group 306" transform="translate(28.5)">
          <g id="Group_305" data-name="Group 305">
            <path
              id="Path_628"
              data-name="Path 628"
              d="M36.571,13.859H30.849a1.176,1.176,0,0,1-1.175-1.175V2.349a1.176,1.176,0,0,1,1.175-1.175h7.22a1.176,1.176,0,0,1,1.175,1.175V5.667a.587.587,0,0,0,1.175,0V2.349A2.352,2.352,0,0,0,38.069,0h-7.22A2.352,2.352,0,0,0,28.5,2.349V12.685a2.352,2.352,0,0,0,2.349,2.349h5.722a.587.587,0,1,0,0-1.175Z"
              transform="translate(-28.5)"
            />
            <path
              id="Path_631"
              data-name="Path 631"
              d="M349.911,256.291h-.564a.505.505,0,0,1-.5-.509V255.7a.481.481,0,0,1,.5-.483h.533a.3.3,0,0,1,.319.28.615.615,0,1,0,1.231,0,1.524,1.524,0,0,0-1.262-1.485v-.4a.615.615,0,1,0-1.231,0v.417a1.718,1.718,0,0,0-.81.445,1.694,1.694,0,0,0-.511,1.221v.083a1.737,1.737,0,0,0,1.731,1.74h.564a.5.5,0,0,1,.5.5v.292a.477.477,0,0,1-.5.469h-.916a.29.29,0,0,1-.3-.289.615.615,0,0,0-1.231,0,1.5,1.5,0,0,0,.455,1.083,1.533,1.533,0,0,0,1.023.435v.34a.615.615,0,0,0,1.231,0V260a1.69,1.69,0,0,0,1.477-1.682v-.292A1.735,1.735,0,0,0,349.911,256.291Z"
              transform="translate(-343.094 -249.571)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InvoicesIcon;
