import { axiosInstance } from "./base.api";
import {
  CompaniesResponse,
  CompanyResponse,
} from "../models/responses/company.model";
import { createCompanyData } from "../models/requests/Company";
import { ID } from "../models/utils.models";

export const companyApi = {
  getCompanies() {
    const config = {
      params: {
        size: 0,
      },
    };
    return axiosInstance.get<CompaniesResponse>(
      "/private/companies/all",
      config
    );
  },
  addNewCompany(data: createCompanyData) {
    return axiosInstance.post<CompanyResponse>("/private/companies", data);
  },
  getCompany(companyId?: ID) {
    let config;
    if (companyId) {
      config = {
        params: {
          companyId,
        },
      };
    }
    return axiosInstance.get<CompanyResponse>("/private/companies", config);
  },
};
