import styled from "styled-components";

export default styled.div`
  background-color: #fdfcfd;
  height: 100%;
  border-radius: 0 12px 12px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
