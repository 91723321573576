export default {
  white: "#FFFFFF",
  black: "#000000",
  lightBackground: "#F5F6FA",
  lightBlue2: "#EFF1F7",
  lightBlue: "#90a6da",
  blue: "#6077a8",
  darkBlue: "#314c79",
  lightYellow: "#ffe24b",
  veryLightYellow: "#FFF9E3",
  yellow: "#fdb000",
  darkerYellow: "#E0AC00",
  darkYellow: "#c48100",
  lightRed: "#e57373",
  red: "#f44336",
  darkRed: "#d32f2f",
  textPrimary: "#212121",
  textSecondary: "#8D8D8D",
  textDisabled: "#898989",
  textHint: "#9C9EA3",

  lightGrey: "#F2F3F7",
  blueGrey: "#7C8298",
  lightBlueGrey: "#A9B6C4",
  lightGreen: "#85cc40",
  green: "#67C011",
  darkGreen: "#48860b",
  greenBackground: "rgba(99, 200, 13, .06)",
  redBackground: "rgba(190, 17, 17, .06)",
  orange: "#ED9B00",
};
