import React, { useEffect } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Theme,
} from "@material-ui/core";
import { matchPath } from "react-router";
import colors from "../constants/colors";
import { NavTab } from "../models/router.models";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: theme.spacing(8),
      flexGrow: 1,
    },
    tabs: {
      height: "100%",
    },
    tabsFlexContainer: {
      alignItems: "center",
      height: "100%",
    },
    tabItem: {
      textTransform: "none",
      fontSize: 12,
      position: "relative",
      flexGrow: 1,
      maxWidth: "initial",
      minWidth: "initial",
      "&:not(:last-child):after": {
        position: "absolute",
        display: "block",
        content: "''",
        width: 1,
        right: 0,
        top: "25%",
        height: "50%",
        backgroundColor: colors.lightGrey,
      },
    },
    divider: {
      height: theme.spacing(3),
    },
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > div": {
        maxWidth: theme.spacing(11),
        height: 2,
        width: "100%",
        backgroundColor: theme.palette.secondary.main,
      },
    },
  })
);

interface OwnProps {
  tabs: NavTab[];
}

type Props = OwnProps;

const NavTabs: React.FC<Props> = (props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const getMatchPath = () => {
    const matchTabs = matchPath<{ tabPath: "string" }>(location.pathname, {
      path: `${match.url}/:tabPath`,
    });
    return `/${matchTabs?.params.tabPath || ""}`;
  };

  const classes = useStyles();
  const [value, setValue] = React.useState<string>(getMatchPath());

  useEffect(() => {
    setValue(getMatchPath());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    history.push(`${match.url}${newValue}`);
  };

  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ children: <div /> }}
            classes={{
              root: classes.tabs,
              flexContainer: classes.tabsFlexContainer,
              indicator: classes.indicator,
            }}
          >
            {props.tabs.map(({ title, path }) => (
              <Tab
                className={classes.tabItem}
                key={path}
                label={title}
                value={path}
                disableRipple
              />
            ))}
          </Tabs>
        </Paper>
      </Grid>
      {props.children}
    </>
  );
};

export default NavTabs;
