import React from "react";
import {
  Avatar,
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: theme.spacing(8),
      width: theme.spacing(8),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    name: {
      color: theme.palette.primary.main,
    },
    email: {
      color: theme.palette.text.secondary,
    },
  })
);

interface OwnProps {
  fullName: string;
  avatarSrc: string | null;
  email?: string;
}

type Props = OwnProps;

const UserAvatarWithName: React.FC<Props> = ({
  fullName,
  avatarSrc,
  email,
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      {avatarSrc ? (
        <Avatar alt={fullName} src={avatarSrc} className={classes.avatar} />
      ) : (
        <Avatar className={classes.avatar}>
          {fullName.trim().charAt(0).toUpperCase()}
        </Avatar>
      )}
      <Box>
        <Typography variant="h4" className={classes.name}>
          {fullName}
        </Typography>
        {email && (
          <Typography variant="body1" color="textSecondary">
            {email}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default UserAvatarWithName;
