import React from "react";

interface OwnProps {}

type Props = OwnProps;

const EquipmentIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        id="checked-box"
        d="M8.613,17.8a.7.7,0,0,1-.7.7H3.516A2.816,2.816,0,0,1,.7,15.687v-7.8a.7.7,0,0,1,1.406,0v7.8a1.408,1.408,0,0,0,1.406,1.406H7.91A.7.7,0,0,1,8.613,17.8ZM18,2.609V4.719a.7.7,0,0,1-.7.7V8.269a.7.7,0,1,1-1.406,0V5.422H.7a.7.7,0,0,1-.7-.7V2.609A2.112,2.112,0,0,1,2.109.5H15.891A2.112,2.112,0,0,1,18,2.609ZM1.406,4.016H16.594V2.609a.7.7,0,0,0-.7-.7H2.109a.7.7,0,0,0-.7.7Zm9.14,4.219a.7.7,0,1,0,0-1.406H7.383a.7.7,0,1,0,0,1.406ZM17.424,13.8a.7.7,0,0,0-.819.564,3.337,3.337,0,1,1-2.383-3.828A.7.7,0,0,0,14.6,9.182a4.747,4.747,0,1,0,3.388,5.437A.7.7,0,0,0,17.424,13.8Zm-5.012-.86a.7.7,0,0,0-.994.994l1.035,1.035a1.233,1.233,0,0,0,1.736,0l3.072-3.041a.7.7,0,1,0-.989-1l-2.948,2.918Zm0,0"
        transform="translate(0 -0.5)"
      />
    </svg>
  );
};

export default EquipmentIcon;
