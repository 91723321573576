import {
  getMySessionsHistoryType,
  SessionActions,
  SessionActionTypes,
} from "../actions/session.actions";
import { put, takeLatest } from "@redux-saga/core/effects";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import { showError } from "./utils";
import { sessionApi } from "../../api/session.api";
import { Sessions } from "../../models/responses/session.model";

function* getUserSessions(action: getMySessionsHistoryType) {
  try {
    const { data } = yield sessionApi.getUserSessionsList(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const sessions = yield transformAndValidate(Sessions, data.data);
      yield put(SessionActions.getUserSessionsSuccess(sessions));
    }
  } catch (e) {
    yield showError(e);
    yield put(SessionActions.getUserSessionsFailure(e.message));
  }
}

export function* SessionSagas() {
  yield takeLatest(SessionActionTypes.GET_USER_SESSIONS, getUserSessions);
}
