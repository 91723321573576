import styled from "styled-components";
import { unitSize } from "../constants/sizes";

interface StyledProps {
  open: boolean;
  openWidth: string;
}

export default styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding-right: ${({ open }: StyledProps) => (open ? 0 : `${unitSize * 4}px`)};
  background-color: #ffffff;
  height: 100%;
  width: ${(props: StyledProps) => (props.open ? props.openWidth : "100%")};
  border-radius: 0 12px 12px 0;
  box-shadow: 8px 0 16px -8px rgba(69, 91, 99, 0.08);
  z-index: 1;
  transition: 0.5s;
`;
