import { ResultModel } from "./result.model";
import { IsNumber, IsString, Min } from "class-validator";
import { Type } from "class-transformer";
import { BasicModel } from "./basic.model";

export class GeneralStatistic {
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  beingWashed: number;
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  completeWashed: number;
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  totalClients: number;
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  totalWashers: number;
}

export class WasherStatistic {
  @IsString()
  id: string;
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  acceptedOrdersCount: number;
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  declinedOrdersCount: number;
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  futureOrderCount: number;
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  completedOrderCount: number;
}

export class PromoterStatistic extends BasicModel {
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  companiesReferred: number;
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  washersReferred: number;
  @IsNumber()
  @Min(0)
  @Type(() => Number)
  totalFeeAmount: number;
}

export class CompanyWasherGeneralStatistics {
  top_washer: string;
  total_commission: string;
  total_wash_count: number;
}

export class CompanyWasherStatistics {
  avatar: string;
  email: string;
  id: string;
  name: string;
  our_commission: string;
  rating: string;
  registration_datetime: string;
  total_wash_count: number;
}

export type GeneralStatisticResponse = ResultModel<GeneralStatistic>;
export type PromoterStatisticResponse = ResultModel<PromoterStatistic>;

export type WasherStatisticResponse = ResultModel<WasherStatistic>;
export type CompanyWasherGeneralStatisticsResponse = ResultModel<
  CompanyWasherGeneralStatistics
>;
export type CompanyWasherStatisticsResponse = ResultModel<
  CompanyWasherStatistics
>;
