import React, { useEffect } from "react";
import UsersList from "../../../components/UsersList/UsersList";
import useActions from "../../../hooks/useActions";
import { UsersActions } from "../../../store/actions/users.actions";
import { RouteNames } from "../../../constants/routeNames";
import { ID } from "../../../models/utils.models";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import ListSearch from "../../../components/UsersList/ListSearch";
import userListHeadCells from "../../../components/UsersList/userListHeadCells";
import TableLayout from "../../../shared/sharedTable/TableLayout";
import texts from "../../../constants/texts";
import { useHistory } from "react-router-dom";
import { UserSelectors } from "../../../store/reducers/users.reducer";
import { Grid } from "@material-ui/core";

interface OwnProps {}

type Props = OwnProps;

const ClientsPage: React.FC<Props> = () => {
  const history = useHistory();
  const usersActions = useActions<typeof UsersActions>(UsersActions);
  const usersState = useSelector((state: AppState) => state.users);
  const clients = UserSelectors.getFormattedUsers(usersState, "clients");
  const statisticState = useSelector((state: AppState) => state.statistic);

  const searchValues = [
    texts.users.search.name,
    texts.users.search.email,
    texts.users.search.phoneNumber,
    texts.users.search.orderId,
    texts.users.search.carLicense,
  ];

  useEffect(() => {
    usersActions.setClientsSearchValue("");
  }, [usersActions]);

  const handleSearchChange = (value: string) => {
    usersActions.clientsSearchValue(value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ListSearch
          handleSearchChange={handleSearchChange}
          count={usersState.clients.total}
          type={"client"}
          searchValues={searchValues}
        />
      </Grid>

      <TableLayout
        search={usersState.clientsSearch}
        title={texts.clients.headerTitle}
        subtitle={texts.clients.headerSubtitle}
        isLoading={usersState.clientsLoading}
        onPaginationChange={usersActions.getClients}
        headCells={userListHeadCells}
        total={+usersState.clients.total}
        values={[
          {
            title: texts.clients.total,
            value: statisticState.generalStatistic.totalClients,
          },
        ]}
      >
        <UsersList
          headCells={userListHeadCells}
          data={clients}
          redirectItemTo={(id: ID) =>
            history.push(`/${RouteNames.CLIENTS}/${id}`)
          }
        />
      </TableLayout>
    </Grid>
  );
};

export default ClientsPage;
