import { axiosInstance } from "./base.api";
import { SessionsResponse } from "../models/responses/session.model";
import { GetWasherSessionsParams } from "../models/requests/Sessions";

export const sessionApi = {
  getUserSessionsList(params: GetWasherSessionsParams) {
    const config = {
      params,
    };
    return axiosInstance.get<SessionsResponse>(
      "/private/users/session",
      config
    );
  },
};
