import React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.secondary.main,
    },
    balance: {
      color: theme.palette.primary.main,
    },
  })
);

interface OwnProps {
  IconComponent: React.FC;
  value: string;
  title: string;
}

type Props = OwnProps;

const ValueWithIcon: React.FC<Props> = ({ IconComponent, value, title }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <IconComponent />
      <Box ml={2} textAlign="right">
        <Typography className={classes.balance} variant="h4">
          {value}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default ValueWithIcon;
