import { BasicModel } from "./basic.model";
import {
  IsDefined,
  IsEnum,
  IsNumber,
  IsObject,
  IsString,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { ResultModel } from "./result.model";

export enum LOG_TYPE {
  CARD_ADDED = "CARD_ADDED",
  CARD_REMOVED = "CARD_REMOVED",
  WASH_STARTED = "WASH_STARTED",
  PROFILE_UPDATED = "PROFILE_UPDATED",
  CAR_ADDED = "CAR_ADDED",
  CAR_REMOVED = "CAR_REMOVED",
  WASH_ORDERED = "WASH_ORDERED",
  WASH_CANCELLED = "WASH_CANCELLED",
  WASH_COMPLETED = "WASH_COMPLETED",
  WASH_ACCEPTED = "WASH_ACCEPTED",
  WASH_DECLINED = "WASH_DECLINED",
}

export class Log extends BasicModel {
  @IsEnum(LOG_TYPE)
  type: LOG_TYPE;
  @IsString()
  userId: string;
  @IsObject()
  rawPayload: object;
}

export class Logs {
  @IsDefined()
  @Type(() => Log)
  @ValidateNested()
  items: Log[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export type LogResponse = ResultModel<Log>;
export type LogsResponse = ResultModel<Logs>;
