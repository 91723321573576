import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  createStyles,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import UserAvatarWithName from "./UserAvatarWithName";
import UserRating from "./UserRating";
import ValueWithIcon from "../../../shared/ValueWithIcon";
import { matchPath, useParams } from "react-router";
import VisibleEyeIcon from "../../../shared/icons/VisibleEyeIcon";
import { formatPhoneNumber, getImageUrl } from "../../../helpers/utils/utils";
import { RouteNames } from "../../../constants/routeNames";
import { UsersActions } from "../../../store/actions/users.actions";
import useActions from "../../../hooks/useActions";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import Loading from "../../../shared/Loading";
import WalletIcon from "../../../shared/icons/WalletIcon";
import { unitSize } from "../../../constants/sizes";
import EditIcon from "../../../shared/icons/EditIcon";
import texts from "../../../constants/texts";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ID } from "../../../models/utils.models";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      minHeight: theme.spacing(24),
      display: "flex",
      paddingLeft: theme.spacing(5),
      paddingTop: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(3.5),
    },
    value: {
      color: theme.palette.primary.main,
    },
    divider: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      height: theme.spacing(3),
    },
    buttons: {},
    redButton: {
      marginTop: theme.spacing(1),
      color: "rgba(190, 87, 87, 1)",
      borderColor: "rgba(190, 87, 87, 0.5)",
      "&:hover": {
        borderColor: "#f50057",
        backgroundColor: "rgba(190, 87, 87, 0.08)",
      },
    },
    visibleIcon: {
      padding: 0,
      marginLeft: theme.spacing(3),
    },
    editCreditIcon: {
      padding: theme.spacing(0),
      marginLeft: theme.spacing(2),
      alignSelf: "flex-start",
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
    },
  })
);

interface OwnProps {}

type Props = OwnProps;

const UserInfo: React.FC<Props> = () => {
  const match = useRouteMatch();
  const params = useParams<{ userId: ID }>();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [isPhoneVisible, setIsPhoneVisible] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { activeUser, activeUserLoading } = useSelector(
    (state: AppState) => state.users
  );
  const usersActions = useActions<typeof UsersActions>(UsersActions);

  useEffect(() => {
    const matchTabs = matchPath<{ userLayout: string }>(location.pathname, {
      path: `${match.url}/:userLayout`,
    });
    if (matchTabs !== null) {
      const {
        params: { userLayout },
      } = matchTabs;
      setIsEdit(userLayout === "edit-profile");
    }
  }, [location.pathname, match.url]);

  useEffect(() => {
    usersActions.getActiveUserData(params.userId);
  }, [params.userId, usersActions]);

  const handlePhoneVisibleIconClick = () => {
    setIsPhoneVisible(true);
  };
  const handleEditFinanceClick = () => {
    history.push(`${match.url}/${RouteNames.CREDITS}`);
  };
  const handleEditProfileClick = () => {
    history.push(`${match.url}/${RouteNames.EDIT_PROFILE}`);
  };
  const handleBackClick = () => {
    history.push(match.url);
  };

  return (
    <Grid item xs={12}>
      <Paper className={classes.root}>
        {activeUserLoading && <Loading />}
        {activeUser && (
          <Grid container justify="space-between">
            <Grid item xs={8}>
              <Box
                width={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <UserAvatarWithName
                    avatarSrc={
                      activeUser.avatar &&
                      getImageUrl(activeUser.avatar.id, 128)
                    }
                    fullName={`${activeUser.firstName || ""} ${
                      activeUser.secondName || ""
                    }`}
                    email={activeUser.email || ""}
                  />
                  <UserRating value={activeUser.rating} />
                </Box>
                <Box display="flex">
                  <ValueWithIcon
                    title={texts.userProfile.creditBalance}
                    value={"0"}
                    IconComponent={WalletIcon}
                  />
                  <IconButton
                    className={classes.editCreditIcon}
                    onClick={handleEditFinanceClick}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box ml={11} mt={2} display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.value} variant="h4">
                    {activeUser.completedOrders || 0}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {texts.userProfile.userOrders}
                  </Typography>
                </Box>
                <Divider className={classes.divider} orientation="vertical" />
                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.value} variant="h4">
                      {formatPhoneNumber(
                        activeUser.phoneNumber,
                        isPhoneVisible
                      )}
                      {!isPhoneVisible && (
                        <IconButton
                          className={classes.visibleIcon}
                          edge="end"
                          onClick={handlePhoneVisibleIconClick}
                        >
                          <VisibleEyeIcon />
                        </IconButton>
                      )}
                    </Typography>
                  </Box>
                  <Typography variant="body1" color="textSecondary">
                    {texts.userProfile.phone}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} className={classes.buttonsContainer}>
              <Box width={unitSize * 26}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={isEdit ? handleBackClick : handleEditProfileClick}
                >
                  {isEdit
                    ? texts.userProfile.backToUserInfoButton
                    : texts.userProfile.editProfileButton}
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                  classes={{
                    root: classes.redButton,
                  }}
                >
                  {texts.userProfile.blockUserButton}
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default UserInfo;
