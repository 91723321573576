import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";

interface ChildProps {
  copy: (content: string) => void;
}

interface OwnProps {
  children: (props: ChildProps) => React.ReactElement<any>;
}

type Props = OwnProps;

const CopyToClipboard: React.FC<Props> = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOnTooltipClose = () => {
    setTimeout(() => {
      setShowTooltip(false);
    }, 100);
  };
  const onCopy = async (content: string) => {
    await navigator.clipboard.writeText(content);
    setShowTooltip(true);
  };
  return (
    <Tooltip
      title={showTooltip ? "Copied to clipboard!" : "Click to copy"}
      onClose={handleOnTooltipClose}
    >
      {props.children({ copy: onCopy }) as React.ReactElement<any>}
    </Tooltip>
  );
};

export default CopyToClipboard;
