import { NavTab } from "../../../models/router.models";
import texts from "../../../constants/texts";
import { RouteNames } from "../../../constants/routeNames";

const tabs: NavTab[] = [
  {
    title: texts.roles.admin.payouts.menu.requested,
    path: `/${RouteNames.REQUESTED}`,
  },
  {
    title: texts.roles.admin.payouts.menu.completed,
    path: `/${RouteNames.COMPLETED}`,
  },
];

export default tabs;
