import {
  ReferralsActions,
  ReferralsActionTypes,
} from "../actions/referrals.actions";
import { Referrals } from "../../models/responses/referrals.model";

class StateModel {
  promoterReferralsList: Referrals = { items: [], total: 0 };
  promoterReferralsListLoading: boolean = false;
  promoterReferralsListError: string = "";
}

const initialState = new StateModel();

export function referralsReducer(
  state = initialState,
  action: ReferralsActions
): StateModel {
  switch (action.type) {
    case ReferralsActionTypes.GET_PROMOTERS_REFERRALS_LIST: {
      return {
        ...state,
        promoterReferralsListLoading: true,
        promoterReferralsListError: "",
        promoterReferralsList: initialState.promoterReferralsList,
      };
    }
    case ReferralsActionTypes.GET_PROMOTERS_REFERRALS_LIST_SUCCESS: {
      return {
        ...state,
        promoterReferralsListLoading: false,
        promoterReferralsList: action.payload,
      };
    }
    case ReferralsActionTypes.GET_PROMOTERS_REFERRALS_LIST_FAILURE: {
      return {
        ...state,
        promoterReferralsListLoading: false,
        promoterReferralsListError: action.payload,
      };
    }
    default:
      return state;
  }
}
