import React from "react";

interface OwnProps {}

type Props = OwnProps;

const OrdersIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="18"
      height="17.997"
      viewBox="0 0 18 17.997"
    >
      <path
        id="shopping-list"
        d="M11.424,5.6a.7.7,0,0,1-.7.7H8.3A.7.7,0,1,1,8.3,4.9h2.425A.7.7,0,0,1,11.424,5.6Zm-4.9-1.877a.7.7,0,0,0-.98.17l-1,1.425-.523-.529a.7.7,0,1,0-1,.989l.794.8.011.011A1.093,1.093,0,0,0,4.58,6.9l.072,0a1.1,1.1,0,0,0,.784-.413l.023-.031L6.691,4.7A.7.7,0,0,0,6.521,3.721Zm4.2,5.429H8.3a.7.7,0,1,0,0,1.406h2.425a.7.7,0,0,0,0-1.406Zm-4.2-1.174a.7.7,0,0,0-.98.17l-1,1.425-.523-.529a.7.7,0,1,0-1,.989l.794.8.011.011a1.093,1.093,0,0,0,.759.307l.072,0a1.1,1.1,0,0,0,.784-.413l.023-.031L6.691,8.956A.7.7,0,0,0,6.521,7.977ZM18,3.066v2.25a2.815,2.815,0,0,1-2.812,2.812h-.917v4.007a.7.7,0,1,1-1.406,0V3.066a2.551,2.551,0,0,1,.278-1.16H2.812A1.408,1.408,0,0,0,1.406,3.312V15.685a1.408,1.408,0,0,0,1.406,1.406h8.647a1.408,1.408,0,0,0,1.406-1.406.7.7,0,0,1,1.406,0A2.815,2.815,0,0,1,11.459,18.5H2.812A2.815,2.815,0,0,1,0,15.685V3.312A2.815,2.815,0,0,1,2.812.5H15.466L15.5.5A2.568,2.568,0,0,1,18,3.066Zm-1.406,0a1.161,1.161,0,0,0-1.16-1.16h0a1.161,1.161,0,0,0-1.16,1.16V6.722h.917a1.408,1.408,0,0,0,1.406-1.406Zm-5.873,10.3H8.3a.7.7,0,1,0,0,1.406h2.425a.7.7,0,0,0,0-1.406Zm-4.2-1.174a.7.7,0,0,0-.98.17l-1,1.425-.523-.529a.7.7,0,1,0-1,.989l.794.8.011.011a1.093,1.093,0,0,0,.759.307l.072,0a1.1,1.1,0,0,0,.784-.413l.023-.031,1.232-1.75A.7.7,0,0,0,6.521,12.195Zm0,0"
        transform="translate(0 -0.5)"
      />
    </svg>
  );
};

export default OrdersIcon;
