import { CompanyWasherStatistics } from "../../models/responses/statistic.model";

export const companyWasherStatistics: CompanyWasherStatistics = {
  avatar: "qwerty",
  email: "example@gmail.com",
  id: "2d647e49-ac45-4447-8db1-a6523dec185f",
  name: "John Smith",
  our_commission: "5$",
  rating: "5.0",
  registration_datetime: "2020-04-09T08:51:05.000Z",
  total_wash_count: 10,
};
