import { HeadCell, UserTableColumns } from "../../models/table.models";
import texts from "../../constants/texts";

const userListHeadCells: HeadCell<UserTableColumns>[] = [
  {
    id: UserTableColumns.ID,
    label: texts.users.tableHeaders.id,
    size: 1,
  },
  {
    id: UserTableColumns.EMAIL,
    label: texts.users.tableHeaders.email,
    size: 3,
  },
  {
    id: UserTableColumns.FULL_NAME,
    label: texts.users.tableHeaders.fullName,
    size: 3,
  },
  {
    id: UserTableColumns.PHONE_NUMBER,
    label: texts.users.tableHeaders.phone,
    size: 3,
  },
  {
    id: UserTableColumns.ORDERS,
    label: texts.users.tableHeaders.orders,
    size: 1,
  },
];

export default userListHeadCells;
