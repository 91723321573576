import React, { useState } from "react";
import {
  Box,
  createStyles,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import MenuIcon from "../../shared/icons/MenuIcon";
import DeleteIcon from "../../shared/icons/DeleteIcon";
import InvoicesIcon from "../../shared/icons/InvoicesIcon";
import ArrowRightIcon from "../../shared/icons/ArrowRightIcon";
import clsx from "clsx";
import colors from "../../constants/colors";
import FirstLayer from "../../styled/FirstLayer";
import SecondLayer from "../../styled/SecondLayer";
import texts from "../../constants/texts";
import { useHistory, useLocation } from "react-router-dom";
import { ID } from "../../models/utils.models";

interface StyleProps {
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    menuIcon: {
      padding: theme.spacing(1),
      transform: ({ open }: StyleProps) => (open ? "rotate(90deg)" : "none"),
    },
    icon: {
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "transparent",
        color: colors.green,
      },
    },
    firstLayerArrowIcon: {
      padding: theme.spacing(1),
    },
    secondLayerArrowIcon: {
      padding: theme.spacing(1),
    },
    firstLayer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  })
);

interface OwnProps {
  orderId: ID;
}

type Props = OwnProps;

const OrdersRowActions: React.FC<Props> = ({ orderId }) => {
  const history = useHistory();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const classes = useStyles({ open: menuOpen });
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  const handleOpenDetails = () => {
    history.push(`${location.pathname}/${orderId}`);
  };
  return (
    <Grid item xs={3} className={classes.root}>
      <FirstLayer
        open={menuOpen}
        openWidth="20%"
        className={classes.firstLayer}
      >
        <IconButton
          className={classes.menuIcon}
          onClick={handleMenuToggle}
          disableRipple
        >
          <MenuIcon />
        </IconButton>
        <Fade in={!menuOpen}>
          <IconButton
            className={clsx(classes.firstLayerArrowIcon, classes.icon)}
            onClick={handleOpenDetails}
          >
            <ArrowRightIcon />
          </IconButton>
        </Fade>
      </FirstLayer>
      <SecondLayer>
        <Box pr={4}>
          <IconButton className={classes.icon}>
            <Box>
              <DeleteIcon />
              <Typography color="inherit">{texts.orders.delete}</Typography>
            </Box>
          </IconButton>
          <IconButton className={classes.icon}>
            <Box>
              <InvoicesIcon />
              <Typography color="inherit">{texts.orders.invoice}</Typography>
            </Box>
          </IconButton>
          <IconButton
            className={clsx(classes.secondLayerArrowIcon, classes.icon)}
            onClick={handleOpenDetails}
          >
            <ArrowRightIcon />
          </IconButton>
        </Box>
      </SecondLayer>
    </Grid>
  );
};

export default OrdersRowActions;
