import { createMuiTheme } from "@material-ui/core/styles";
import palette from "./palette";
import typography from "./typography";
import overrides from "./overrides";
import { unitSize } from "../../constants/sizes";

export default createMuiTheme({
  spacing: unitSize,
  palette,
  typography,
  overrides,
});
