import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const InsuranceIcon: React.FC<Props> = (props) => {
  return (
    <svg
      id="document"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="23.343"
      height="26.159"
      viewBox="0 0 23.343 26.159"
      {...props}
    >
      <g id="Group_326" data-name="Group 326" transform="translate(0)">
        <g id="Group_325" data-name="Group 325">
          <path
            id="Path_660"
            data-name="Path 660"
            d="M114.954,201.022A1.022,1.022,0,0,0,113.933,200h-5.467a1.022,1.022,0,1,0,0,2.044h5.467A1.022,1.022,0,0,0,114.954,201.022Z"
            transform="translate(-103.362 -189.782)"
          />
          <path
            id="Path_661"
            data-name="Path 661"
            d="M108.466,280a1.022,1.022,0,1,0,0,2.044h2.2a1.022,1.022,0,1,0,0-2.044Z"
            transform="translate(-103.362 -265.694)"
          />
          <path
            id="Path_662"
            data-name="Path 662"
            d="M37.207,24.115H31.643A2.046,2.046,0,0,1,29.6,22.072V4.087a2.046,2.046,0,0,1,2.044-2.044H44.206A2.046,2.046,0,0,1,46.25,4.087V9.81a1.022,1.022,0,0,0,2.044,0V4.087A4.092,4.092,0,0,0,44.206,0H31.643a4.092,4.092,0,0,0-4.087,4.087V22.072a4.092,4.092,0,0,0,4.087,4.087h5.563a1.022,1.022,0,0,0,0-2.044Z"
            transform="translate(-27.556)"
          />
          <path
            id="Path_663"
            data-name="Path 663"
            d="M256.1,246.324l-5.016-2.241a1.579,1.579,0,0,0-1.021,0l-5.016,2.241a1.022,1.022,0,0,0-.6.933v3.891a6.869,6.869,0,0,0,1.732,4.475,5.77,5.77,0,0,0,4.33,2.063h.138a5.77,5.77,0,0,0,4.33-2.063,6.869,6.869,0,0,0,1.732-4.475v-3.891A1.022,1.022,0,0,0,256.1,246.324Zm-1.439,1.6v3.229c0,2.122-1.718,4.495-4.018,4.495h-.138c-2.3,0-4.018-2.373-4.018-4.495V247.92l4.087-1.826,4.087,1.826Z"
            transform="translate(-233.364 -231.528)"
          />
          <path
            id="Path_664"
            data-name="Path 664"
            d="M120.013,121.022A1.022,1.022,0,0,0,118.991,120H108.466a1.022,1.022,0,1,0,0,2.044h10.525A1.022,1.022,0,0,0,120.013,121.022Z"
            transform="translate(-103.362 -113.869)"
          />
        </g>
      </g>
    </svg>
  );
};

export default InsuranceIcon;
