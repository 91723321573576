import { put, takeLatest } from "@redux-saga/core/effects";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import { showError } from "./utils";
import {
  getUserLogsType,
  LogActions,
  LogActionTypes,
} from "../actions/log.actions";
import { logApi } from "../../api/log.api";
import { Logs } from "../../models/responses/log.model";

function* getUserLogs(action: getUserLogsType) {
  try {
    const { data } = yield logApi.getUserLogsList(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const sessions = yield transformAndValidate(Logs, data.data);
      yield put(LogActions.getUserLogsSuccess(sessions));
    }
  } catch (e) {
    yield showError(e);
    yield put(LogActions.getUserLogsFailure(e.message));
  }
}

export function* LogSagas() {
  yield takeLatest(LogActionTypes.GET_USER_LOGS, getUserLogs);
}
