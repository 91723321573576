import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      width: theme.spacing(10),
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(4),
    },
    formControl: {
      marginBottom: theme.spacing(5),
    },
    formControlLabel: {
      marginTop: theme.spacing(2),
    },
    radioLabel: {
      fontSize: 14,
      fontWeight: 300,
      color: theme.palette.text.primary,
    },
    bold: {
      fontWeight: "bold",
    },
    crossed: {
      textDecoration: "line-through",
    },
  })
);
