import React from "react";

interface OwnProps {}

type Props = OwnProps;

const DeleteIcon: React.FC<Props> = () => {
  return (
    <svg
      id="delete-button"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="12.13"
      height="15"
      viewBox="0 0 12.13 15"
    >
      <g id="Group_78" data-name="Group 78">
        <path
          id="Path_405"
          data-name="Path 405"
          d="M56.571,1.8H54.236a2.03,2.03,0,0,0-4.033,0H47.869a1.716,1.716,0,0,0-1.714,1.714V3.6a1.715,1.715,0,0,0,1.083,1.592v8.1A1.716,1.716,0,0,0,48.952,15h6.537A1.716,1.716,0,0,0,57.2,13.286v-8.1A1.715,1.715,0,0,0,58.285,3.6V3.511A1.716,1.716,0,0,0,56.571,1.8ZM52.22.813a1.22,1.22,0,0,1,1.2.984h-2.39A1.22,1.22,0,0,1,52.22.813Zm4.169,12.474a.9.9,0,0,1-.9.9H48.952a.9.9,0,0,1-.9-.9V5.313h8.338ZM57.472,3.6a.9.9,0,0,1-.9.9h-8.7a.9.9,0,0,1-.9-.9V3.511a.9.9,0,0,1,.9-.9h8.7a.9.9,0,0,1,.9.9V3.6Z"
          transform="translate(-46.155)"
        />
        <path
          id="Path_406"
          data-name="Path 406"
          d="M158.507,255.3a.432.432,0,0,0,.432-.432V250a.432.432,0,1,0-.865,0v4.868A.432.432,0,0,0,158.507,255.3Z"
          transform="translate(-154.611 -242.273)"
        />
        <path
          id="Path_407"
          data-name="Path 407"
          d="M228.577,255.3a.432.432,0,0,0,.432-.432V250a.432.432,0,0,0-.865,0v4.868A.432.432,0,0,0,228.577,255.3Z"
          transform="translate(-222.512 -242.273)"
        />
        <path
          id="Path_408"
          data-name="Path 408"
          d="M298.646,255.3a.432.432,0,0,0,.432-.432V250a.432.432,0,1,0-.865,0v4.868A.432.432,0,0,0,298.646,255.3Z"
          transform="translate(-290.413 -242.273)"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
