import React from "react";
import headCells from "./headCells";
import texts from "../../../../../../constants/texts";
import PayedOrdersRow from "../PayedOrdersRow";
import TableHeader from "../../../../../../shared/sharedTable/TableHeader";
import { Box, Checkbox, Grid, Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../store/reducers";
import { payoutsSelectors } from "../../../../../../store/reducers/payouts.reducer";

interface OwnProps {
  selected: string[];
  onSelectedChange: (values: string[]) => void;
}

type Props = OwnProps;

const PayedOrdersList: React.FC<Props> = (props) => {
  const payoutsState = useSelector((state: AppState) => state.payouts);
  const data = payoutsSelectors.getPayedOrdersData(payoutsState);

  const isSelected = (orderId: string) =>
    props.selected.indexOf(orderId) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, orderId: string) => {
    const selectedIndex = props.selected.indexOf(orderId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, orderId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1)
      );
    }

    props.onSelectedChange(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      props.onSelectedChange(newSelected);
      return;
    }
    props.onSelectedChange([]);
  };

  const headerButton = (
    <Grid item xs={1}>
      <Box
        pr={4}
        height={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {!!data.length && (
          <Tooltip title="Select All" placement="top">
            <Checkbox
              indeterminate={
                props.selected.length > 0 && props.selected.length < data.length
              }
              checked={data.length > 0 && props.selected.length === data.length}
              onChange={handleSelectAllClick}
              color="primary"
            />
          </Tooltip>
        )}
      </Box>
    </Grid>
  );

  return (
    <Grid item container xs={12}>
      <TableHeader
        title={texts.roles.washer.payouts.requestPayout.payedOrdersListTitle}
        subtitle={
          texts.roles.washer.payouts.requestPayout.payedOrdersListSubtitle
        }
        headCells={headCells}
        isLoading={payoutsState.payedOrdersLoading}
        headCellsButton={headerButton}
      />
      {data.map((item) => (
        <PayedOrdersRow
          order={item}
          isItemSelected={isSelected(item.id)}
          key={item.id}
          onClick={handleClick}
        />
      ))}
    </Grid>
  );
};

export default PayedOrdersList;
