export type ID = string;

export enum SnackbarVariant {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  INFO = "info",
}

export enum SortParams {
  ASC = "ASC",
  DESC = "DESC",
}

export enum StatusColors {
  GREEN = "green",
  YELLOW = "yellow",
  LIGHT_BLUE = "lightBlue",
  RED = "red",
  GREY = "grey",
}

export enum PendingUserStatus {
  EXAM = "Exam",
  PENDING = "Pending",
}

export interface MapCoordinate {
  lat: number;
  lng: number;
}

export enum Currency {
  USD = "USD",
  EUR = "EUR",
  BTC = "BTC",
  JPY = "JPY",
}
