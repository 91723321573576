import React, { useState } from "react";
import {
  Box,
  createStyles,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { unitSize } from "../../../../../constants/sizes";
import { ID } from "../../../../../models/utils.models";
import { PackagesColumns } from "../../../../../models/table.models";
import headCells from "./headCells";
import EditIcon from "../../../../../shared/icons/EditIcon";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingLeft: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    textContainer: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  })
);

export interface PackageRow {
  id: ID;
  title: string;
  price: number;
  washerPayoutPrice: number;
}

export type UpdatePackageDataType = Partial<PackageRow> &
  Pick<PackageRow, "id">;

interface OwnProps {
  row: PackageRow;
  onChange: (pack: UpdatePackageDataType) => void;
}

type Props = OwnProps;

const PackageListRow: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [data, setData] = useState<UpdatePackageDataType>({ id: props.row.id });

  const handleSaveClick = () => {
    props.onChange(data);
    setIsEditMode(false);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
    setData({ id: props.row.id });
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const { name } = e.target;
    let value: string | number = e.target.value;
    if (name === "washerPayoutPrice" || name === "price") {
      value = Number(value) * 100;
    }
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const renderCell = (id: PackagesColumns) => {
    switch (id) {
      case PackagesColumns.ACTIONS:
        return !isEditMode ? (
          <Box>
            <Tooltip title={"Edit"}>
              <IconButton onClick={() => setIsEditMode(true)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Box>
            <Tooltip title={"Save"}>
              <IconButton onClick={handleSaveClick}>
                <CheckIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Cancel"}>
              <IconButton onClick={handleCancelClick}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      case PackagesColumns.NAME:
        return !isEditMode ? (
          <Typography>{props.row.title}</Typography>
        ) : (
          <TextField
            fullWidth
            defaultValue={props.row.title}
            name="title"
            onChange={handleFieldChange}
          />
        );
      case PackagesColumns.WASHER_PAYOUT_PRICE:
        return !isEditMode ? (
          <Typography>{(props.row.price / 100).toFixed(2)}</Typography>
        ) : (
          <TextField
            defaultValue={(props.row.price / 100).toFixed(2)}
            type="number"
            name="price"
            onChange={handleFieldChange}
          />
        );
      case PackagesColumns.PRICE:
        return !isEditMode ? (
          <Typography>
            {(props.row.washerPayoutPrice / 100).toFixed(2)}
          </Typography>
        ) : (
          <TextField
            name="washerPayoutPrice"
            defaultValue={(props.row.washerPayoutPrice / 100).toFixed(2)}
            type="number"
            onChange={handleFieldChange}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container>
          {headCells.map(({ id, size }, index) => (
            <Grid key={id} item xs={size}>
              <Box
                pl={index > 0 ? 4 : 0}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={1}
                height={unitSize * 9}
              >
                {renderCell(id)}
                <Box pl={2} height={unitSize * 3}>
                  <Divider orientation="vertical" />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PackageListRow;
