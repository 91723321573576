import React from "react";
import SimpleTableRow from "../../shared/sharedTable/SimpleTableRow";
import { Grid } from "@material-ui/core";
import { ID } from "../../models/utils.models";
import { HeadCell } from "../../models/table.models";

interface OwnProps {
  redirectItemTo: (id: ID) => void;
  data: { [key: string]: string }[];
  headCells: HeadCell[];
}

type Props = OwnProps;

const UsersList: React.FC<Props> = (props) => {
  return (
    <Grid container>
      {props.data.map((row) => (
        <SimpleTableRow
          key={row.id}
          row={row}
          columns={props.headCells}
          arrowAction={props.redirectItemTo}
        />
      ))}
    </Grid>
  );
};

export default UsersList;
