import React, { SVGProps } from "react";

type Props = SVGProps<any>;

const CopyIcon: React.FC<Props> = (props) => {
  return (
    <svg
      id="Group_344"
      data-name="Group 344"
      xmlns="http://www.w3.org/2000/svg"
      width="9.836"
      height="9.89"
      viewBox="0 0 9.836 9.89"
      {...props}
    >
      <g id="Group_342" data-name="Group 342" transform="translate(0 1.726)">
        <path
          id="Path_727"
          data-name="Path 727"
          d="M7.933,18.491H.551A.391.391,0,0,1,.16,18.1V10.719a.391.391,0,0,1,.391-.391H7.933a.391.391,0,0,1,.391.391V18.1A.391.391,0,0,1,7.933,18.491ZM.941,17.71h6.6v-6.6H.941Z"
          transform="translate(-0.16 -10.328)"
          fill="currentColor"
        />
      </g>
      <g id="Group_343" data-name="Group 343" transform="translate(1.673)">
        <path
          id="Path_728"
          data-name="Path 728"
          d="M17.94,8.163h-.793a.391.391,0,0,1,0-.781h.4V.781h-6.6v.435a.391.391,0,1,1-.781,0V.391A.391.391,0,0,1,10.558,0H17.94a.391.391,0,0,1,.391.391V7.773A.391.391,0,0,1,17.94,8.163Z"
          transform="translate(-10.167)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default CopyIcon;
