import { axiosInstance } from "./base.api";
import {
  AuthResponse,
  PromoCodeResponse,
} from "../models/responses/auth.model";
import {
  AuthData,
  RequestSignUpEmailVerificationCodeData,
  RequestSignUpPhoneVerificationCodeData,
  SendSignUpCodeFromEmailData,
  SendVerifyCodeType,
  SignUpData,
} from "../models/requests/Credentials";
import { ResultModel } from "../models/responses/result.model";
import { UserResponse } from "../models/responses/user.model";
import { ModifyUserInfoDto } from "../models/requests/Users";

export const authApi = {
  authorize(data: AuthData) {
    return axiosInstance.post<AuthResponse>("/auth/admin/authorization", data);
  },

  resetPasswordFromEmail(data: RequestSignUpEmailVerificationCodeData) {
    return axiosInstance.post<ResultModel>("/auth/resetPassword", data);
  },

  checkCodeFromEmail(data: SendSignUpCodeFromEmailData) {
    return axiosInstance.post<AuthResponse>(
      "/auth/authorization/resetCode",
      data
    );
  },

  updateMyUserData(data: Partial<ModifyUserInfoDto>, token?: string) {
    let config;
    if (token) {
      config = {
        headers: { Authorization: `bearer ${token}` },
      };
    }
    return axiosInstance.patch<UserResponse>("/private/users/me", data, config);
  },

  sendPhoneAuthorizationCode(
    data: RequestSignUpPhoneVerificationCodeData,
    purpose: string
  ) {
    const config = {
      params: {
        purpose,
      },
    };
    return axiosInstance.post<ResultModel>(
      "/auth/sendAuthorizationCode",
      data,
      config
    );
  },

  sendEmailAuthorizationCode(data: RequestSignUpEmailVerificationCodeData) {
    return axiosInstance.post<ResultModel>(
      "/auth/sendAuthEmailVerificationCode",
      data
    );
  },

  signUp(data: SignUpData) {
    return axiosInstance.post<AuthResponse>("/auth/signUp", data);
  },

  getPromoCodeStats(code: string) {
    return axiosInstance.get<PromoCodeResponse>(`auth/coupon/code/${code}`);
  },
  sendVerifyCode(data: SendVerifyCodeType) {
    return axiosInstance.post<ResultModel>("/private/users/verify", data);
  },
  confirmVerifyCode(code: string) {
    return axiosInstance.put<ResultModel>("/private/users/verify/check", {
      code,
    });
  },
};
