import React, { useState } from "react";
import { Color, Container, Cover, Popover, Swatch } from "./style";
import { ChromePicker, ColorResult } from "react-color";

interface OwnProps {
  onColorChange: (color: string) => void;
  color: string;
}

type Props = OwnProps;

const ColorPicker: React.FC<Props> = (props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color: ColorResult) => {
    props.onColorChange(color.hex);
  };

  return (
    <Container>
      <Swatch onClick={handleClick}>
        <Color color={props.color} />
      </Swatch>
      {displayColorPicker ? (
        <Popover>
          <Cover onClick={handleClose} />
          <ChromePicker
            disableAlpha
            color={props.color}
            onChange={handleChange}
          />
        </Popover>
      ) : null}
    </Container>
  );
};

export default ColorPicker;
