import React from "react";
import {
  Box,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { unitSize } from "../../constants/sizes";
import { HeadCell, OrderTableColumns } from "../../models/table.models";
import IdCell from "../../shared/sharedTable/IdCell";
import { Order, OrderStatus } from "../../models/responses/order.model";
import moment from "moment";
import OrdersRowActions from "./OrdersRowActions";
import { sortOrderPackages } from "../../helpers/utils/utils";
import OrderProgress from "./OrderProgress";
import { formatDate } from "../../helpers/utils/date.utils";
import CarWithModel from "../../shared/CarWithModel";
import OrderOptions from "../../shared/OrderOptions";
import OrderStatusCell from "./OrderStatusCell";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(1),
    },
    rowContainer: {
      paddingLeft: theme.spacing(4),
      boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.03)",
    },
    divider: {
      marginRight: theme.spacing(2.5),
      height: theme.spacing(3),
    },
    textContainer: {
      overflow: "hidden",
      color: theme.palette.primary.main,
    },
    inProgressContainer: {
      width: "100%",
      minHeight: theme.spacing(7),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  })
);

interface OwnProps {
  order: Order;
  headCells: HeadCell<OrderTableColumns>[];
}

type Props = OwnProps;

const OrdersListRow: React.FC<Props> = ({ headCells, order }) => {
  const classes = useStyles();
  const renderCell = (id: OrderTableColumns) => {
    switch (id) {
      case OrderTableColumns.ID: {
        return <IdCell id={order.id} />;
      }
      case OrderTableColumns.CAR: {
        return (
          <CarWithModel type={order.car.carType.name} model={order.car.model} />
        );
      }
      case OrderTableColumns.STATUS: {
        return <OrderStatusCell status={order.status} />;
      }
      case OrderTableColumns.DATE: {
        const [date, time] = formatDate(order.scheduledTime);
        return (
          <Box display="flex" flexDirection="column">
            <Typography variant="body1" color="textPrimary">
              {date}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {time}
            </Typography>
          </Box>
        );
      }
      case OrderTableColumns.TIME: {
        const calculatedTime = order.packages.reduce((acc, pack) => {
          return acc + moment.duration(pack.duration).asHours();
        }, 0);
        return (
          calculatedTime > 0 && (
            <Typography variant="body1" color="textPrimary">
              {calculatedTime.toFixed(1)} hours
            </Typography>
          )
        );
      }
      case OrderTableColumns.OPTIONS: {
        return <OrderOptions packages={order.packages} />;
      }

      default:
        return null;
    }
  };
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container className={classes.rowContainer}>
          {headCells.map(({ id, size }, index) => (
            <Grid key={id} item xs={size}>
              <Box
                minHeight={unitSize * 11}
                pr={1}
                display="flex"
                alignItems="center"
              >
                {index > 0 && (
                  <Divider className={classes.divider} orientation="vertical" />
                )}
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  className={classes.textContainer}
                >
                  {renderCell(id)}
                </Box>
              </Box>
            </Grid>
          ))}
          <OrdersRowActions orderId={order.id} />
        </Grid>
        {order.status === OrderStatus.IN_PROGRESS && order.startTime && (
          <Grid container className={classes.inProgressContainer}>
            <OrderProgress
              packages={sortOrderPackages(order.packages)}
              startTime={order.startTime}
            />
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default OrdersListRow;
