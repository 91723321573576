import styled from "styled-components";
import { unitSize } from "../constants/sizes";

export default styled.div`
  height: ${unitSize * 5}px;
  width: ${unitSize * 1.5}px;
  background: radial-gradient(
    ellipse farthest-side at left center,
    rgba(0, 0, 0, 0.08),
    transparent
  );
`;
