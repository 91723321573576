import { put } from "@redux-saga/core/effects";
import { AppActions } from "../actions/app.actions";
import { SnackbarVariant } from "../../models/utils.models";
import { attachmentApi } from "../../api/attachment.api";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import { FileModel } from "../../models/responses/file.model";
import { ErrorMessages } from "../../constants/errorMessages";

export function* showError(e: any) {
  let message;
  console.error(e);
  if (Array.isArray(e)) {
    message = ErrorMessages.DATA_ERROR;
  } else if (typeof e.message === "string" && e.message in ErrorMessages) {
    message = ErrorMessages[e.message as keyof typeof ErrorMessages];
  } else {
    message = ErrorMessages.OTHER_ERROR;
  }
  yield put(
    AppActions.showSnackbar({
      variant: SnackbarVariant.ERROR,
      message: message,
    })
  );
}

export function* sendFile(file: File, tag: string) {
  const bodyFormData = new FormData();
  bodyFormData.set("tag", tag);
  bodyFormData.set("file", file);
  const { data } = yield attachmentApi.sendFile(bodyFormData);
  if (data.result === ResponseStatus.SUCCESS) {
    const respFile = yield transformAndValidate(FileModel, data.data);
    return respFile.id;
  }
}
