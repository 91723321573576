import React from "react";
import {
  Box,
  createStyles,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import colors from "../../constants/colors";
import { HeadCell } from "../../models/table.models";
import { ID, StatusColors } from "../../models/utils.models";
import IdCell from "./IdCell";

interface StyleProps {
  color?: StatusColors;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingLeft: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    divider: {
      height: theme.spacing(3),
      marginRight: theme.spacing(2.5),
    },
    icon: {
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "transparent",
        color: colors.green,
      },
    },
    textContainer: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    text: {
      color: (props: StyleProps) => props.color || theme.palette.text.primary,
    },
  })
);

interface OwnProps {
  columns: HeadCell[];
  row: any;
  arrowAction?: (id: ID) => void;
  color?: StatusColors;
  specificRender?: { [key: string]: JSX.Element };
}

type Props = OwnProps;

const SimpleTableRow: React.FC<Props> = ({ arrowAction, ...props }) => {
  const classes = useStyles({ color: props.color });
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container alignItems="center">
          {props.columns.map(({ id, size }, index) => (
            <Grid key={id} item xs={size}>
              <Box
                py={2}
                pr={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.textContainer}
                >
                  {index > 0 && (
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                  )}
                  {(() => {
                    if (id === "id") return <IdCell id={props.row.id} />;
                    if (props.specificRender && props.specificRender[id])
                      return props.specificRender[id];
                    else
                      return (
                        <Typography
                          className={classes.text}
                          variant="body1"
                          noWrap
                        >
                          {props.row[id]}
                        </Typography>
                      );
                  })()}
                </Box>
              </Box>
            </Grid>
          ))}
          {arrowAction && (
            <Grid item xs={1}>
              <Box
                pr={4}
                height={1}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <IconButton
                  className={classes.icon}
                  onClick={() => arrowAction(props.row.id)}
                >
                  <ArrowRightIcon />
                </IconButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default SimpleTableRow;
