import {
  EquipmentRequests,
  UserEquipments,
} from "../../models/responses/equipment.model";
import {
  EquipmentActions,
  EquipmentActionTypes,
} from "../actions/equipment.actions";
import { Users } from "../../models/responses/user.model";

class StateModel {
  washerEquipments: UserEquipments = { items: [], total: 0 };
  washerEquipmentsLoading = false;
  washerEquipmentsError = "";
  createEquipmentRequestsLoading = false;
  createEquipmentRequestsError = "";
  washersWithRequests: Users = { items: [], total: 0 };
  washersWithRequestsLoading = false;
  washersWithRequestsError = "";
  equipmentRequests: EquipmentRequests = { items: [], total: 0 };
  equipmentRequestsLoading = false;
  equipmentRequestsError = "";
  approvingEquipmentRequests: string[] = [];
  approveEquipmentRequestsError = "";
  updateDeliveryAddressLoading = false;
  updateDeliveryAddressError = "";
}

const initialState = new StateModel();

export function equipmentReducer(
  state = initialState,
  action: EquipmentActions
): StateModel {
  switch (action.type) {
    case EquipmentActionTypes.GET_LIST_OF_A_WASHER_EQUIPMENT:
      return {
        ...state,
        washerEquipmentsLoading: true,
        washerEquipmentsError: "",
      };
    case EquipmentActionTypes.GET_EQUIPMENTS_BY_WASHER_ID:
      return {
        ...state,
        washerEquipmentsLoading: true,
        washerEquipmentsError: "",
      };
    case EquipmentActionTypes.GET_LIST_OF_A_WASHER_EQUIPMENT_SUCCESS:
      return {
        ...state,
        washerEquipments: action.payload,
        washerEquipmentsLoading: false,
      };
    case EquipmentActionTypes.GET_LIST_OF_A_WASHER_EQUIPMENT_FAILURE:
      return {
        ...state,
        washerEquipmentsLoading: false,
        washerEquipmentsError: action.payload,
      };
    case EquipmentActionTypes.CREATE_EQUIPMENT_REQUEST:
      return {
        ...state,
        createEquipmentRequestsError: "",
        createEquipmentRequestsLoading: true,
      };
    case EquipmentActionTypes.CREATE_EQUIPMENT_REQUEST_SUCCESS:
      return {
        ...state,
        createEquipmentRequestsLoading: false,
      };
    case EquipmentActionTypes.CREATE_EQUIPMENT_REQUEST_FAILURE:
      return {
        ...state,
        createEquipmentRequestsLoading: false,
        createEquipmentRequestsError: action.payload,
      };
    case EquipmentActionTypes.GET_WASHERS_LIST_WITH_ACTIVE_REQUESTS:
      return {
        ...state,
        washersWithRequestsLoading: true,
        washersWithRequestsError: "",
      };
    case EquipmentActionTypes.GET_WASHERS_LIST_WITH_ACTIVE_REQUESTS_SUCCESS:
      return {
        ...state,
        washersWithRequestsLoading: false,
        washersWithRequests: action.payload,
      };
    case EquipmentActionTypes.GET_WASHERS_LIST_WITH_ACTIVE_REQUESTS_FAILURE:
      return {
        ...state,
        washersWithRequestsLoading: false,
        washersWithRequestsError: "",
      };
    case EquipmentActionTypes.GET_EQUIPMENT_REQUESTS_FOR_WASHER:
      return {
        ...state,
        equipmentRequestsError: "",
        equipmentRequestsLoading: true,
        approvingEquipmentRequests: [],
      };
    case EquipmentActionTypes.GET_EQUIPMENT_REQUESTS_FOR_WASHER_SUCCESS:
      return {
        ...state,
        equipmentRequests: action.payload,
        equipmentRequestsLoading: false,
      };
    case EquipmentActionTypes.GET_EQUIPMENT_REQUESTS_FOR_WASHER_FAILURE:
      return {
        ...state,
        equipmentRequestsLoading: false,
        equipmentRequestsError: action.payload,
      };
    case EquipmentActionTypes.APPROVE_EQUIPMENT_REQUESTS_FOR_WASHER:
      return {
        ...state,
        approvingEquipmentRequests: [
          ...state.approvingEquipmentRequests,
          action.payload.id,
        ],
      };
    case EquipmentActionTypes.APPROVE_EQUIPMENT_REQUESTS_FOR_WASHER_SUCCESS:
      return {
        ...state,
        approvingEquipmentRequests: state.approvingEquipmentRequests.filter(
          (reqId) => reqId !== action.payload.id
        ),
        equipmentRequests: {
          ...state.equipmentRequests,
          items: state.equipmentRequests.items.map((item) => {
            if (item.id !== action.payload.id) {
              return item;
            }
            return action.payload;
          }),
        },
      };
    case EquipmentActionTypes.APPROVE_EQUIPMENT_REQUESTS_FOR_WASHER_FAILURE:
      return {
        ...state,
        approvingEquipmentRequests: state.approvingEquipmentRequests.filter(
          (reqId) => reqId !== action.payload.id
        ),
        approveEquipmentRequestsError: action.payload.error,
      };
    case EquipmentActionTypes.UPDATE_WASHER_DELIVERY_ADDRESS:
      return {
        ...state,
        updateDeliveryAddressLoading: true,
        updateDeliveryAddressError: "",
      };
    case EquipmentActionTypes.UPDATE_WASHER_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        updateDeliveryAddressLoading: false,
      };
    case EquipmentActionTypes.UPDATE_WASHER_DELIVERY_ADDRESS_FAILURE:
      return {
        ...state,
        updateDeliveryAddressError: action.payload,
      };
    default:
      return state;
  }
}

function getFormattedWashers(state: StateModel) {
  return state.washersWithRequests.items.map(
    ({ id, email, firstName, secondName, phoneNumber }) => ({
      id,
      email: email || "",
      fullName: `${firstName} ${secondName}`,
      phoneNumber,
    })
  );
}

export const EquipmentSelectors = {
  getFormattedWashers,
};
