import colors from "../../constants/colors";

export default {
  common: {
    black: colors.black,
    white: colors.white,
  },
  background: {
    paper: colors.white,
    default: colors.lightBackground,
  },
  primary: {
    light: colors.lightBlue,
    main: colors.blue,
    dark: colors.darkBlue,
    contrastText: colors.white,
  },
  secondary: {
    light: colors.lightYellow,
    main: colors.yellow,
    dark: colors.darkYellow,
    contrastText: colors.white,
  },
  error: {
    light: colors.lightRed,
    main: colors.red,
    dark: colors.darkRed,
    contrastText: colors.white,
  },
  text: {
    primary: colors.textPrimary,
    secondary: colors.textSecondary,
    disabled: colors.textDisabled,
    hint: colors.textHint,
  },
};
