import {
  IsDefined,
  IsEnum,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { ResultModel } from "./result.model";

export enum CardBrands {
  AMEX = "amex",
  DINERS = "diners",
  DISCOVER = "discover",
  JCB = "jcb",
  MASTERCARD = "mastercard",
  UNIONPAY = "unionpay",
  VISA = "visa",
  UNKNOWN = "unknown",
}

class BankingCard {
  @IsDefined()
  @IsEnum(CardBrands)
  brand: CardBrands;
  @IsString()
  country: string;
  @IsDefined()
  @Type(() => Number)
  expMonth: number;
  @IsDefined()
  @Type(() => Number)
  expYear: number;
  @IsString()
  funding: "credit" | "debit" | "prepaid" | "unknown";
  @IsDefined()
  @IsString()
  last4: string;
  address: any;
  @IsOptional()
  @IsString()
  email: string | null;
  @IsOptional()
  @IsString()
  name: string | null;
  @IsOptional()
  @IsString()
  phone: string | null;
}

export class PaymentMethod {
  @IsDefined()
  @IsString()
  id: string;
  @IsDefined()
  @Type(() => BankingCard)
  @ValidateNested()
  card: BankingCard;
}

export class PaymentMethods {
  @IsDefined()
  @Type(() => PaymentMethod)
  @ValidateNested()
  items: PaymentMethod[];
}

export type PaymentMethodsResponse = ResultModel<PaymentMethods>;
