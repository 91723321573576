export enum ResponseStatus {
  FAILURE = "failure",
  SUCCESS = "success",
}

export interface ResultModel<D = any> {
  result: ResponseStatus;
  error?: string;
  data?: D;
}

//TODO: Rewrite as class validator
