import React, { useEffect } from "react";
import CreateCompany from "../CreateCompany";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import useActions from "../../../hooks/useActions";
import { CompanyActions } from "../../../store/actions/company.actions";
import Loading from "../../../shared/Loading";
import CompanyCoupon from "./components/CompanyCoupon";
import CompanyStatistics from "./components/CompanyStatistics";
import { Grid } from "@material-ui/core";

interface OwnProps {}

type Props = OwnProps;

const CompanyDashboard: React.FC<Props> = () => {
  const companyState = useSelector((state: AppState) => state.company);
  const companyActions = useActions<typeof CompanyActions>(CompanyActions);

  useEffect(() => {
    companyActions.getMyActiveCompany();
  }, [companyActions]);

  if (companyState.activeCompanyLoading) {
    return <Loading />;
  }

  if (companyState.activeCompany) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CompanyCoupon />
        </Grid>
        <Grid item xs={12}>
          <CompanyStatistics />
        </Grid>
      </Grid>
    );
  }

  return <CreateCompany />;
};

export default CompanyDashboard;
