import React from "react";

interface OwnProps {}

type Props = OwnProps;

const ClockIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="9.954"
      height="9.954"
      viewBox="0 0 9.954 9.954"
    >
      <path
        id="wall-clock"
        d="M9.954,4.977A4.957,4.957,0,0,1,9.111,7.74a.389.389,0,0,1-.646-.432A4.171,4.171,0,1,0,7.149,8.571a.389.389,0,0,1,.4.665,4.978,4.978,0,1,1,2.4-4.26ZM5,1.555a.389.389,0,0,0-.389.389V4.3A.778.778,0,0,0,5.2,5.728l1.03,1.03a.389.389,0,0,0,.55-.55l-1.03-1.03A.778.778,0,0,0,5.385,4.3V1.944A.389.389,0,0,0,5,1.555Zm0,0"
      />
    </svg>
  );
};

export default ClockIcon;
