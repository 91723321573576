import React from "react";
import useActions from "../../../../hooks/useActions";
import { AuthActions } from "../../../../store/actions/auth.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import { Formik, FormikHelpers } from "formik";
import { PhoneSchema } from "../../../../helpers/validations";
import FormLayout from "../../../ForgotPasswordModal/FormLayout";
import { Grid } from "@material-ui/core";
import FormInput from "../../../../shared/FormInput";

interface OwnProps {}

type Props = OwnProps;

interface PhoneForm {
  phoneNumber: string;
}

const ChangePhoneForm: React.FC<Props> = () => {
  const authActions = useActions<typeof AuthActions>(AuthActions);
  const { isSendVerifyCodeLoading } = useSelector(
    (state: AppState) => state.auth
  );
  const handlePhoneSubmit = (
    values: PhoneForm,
    actions: FormikHelpers<PhoneForm>
  ) => {
    const request = {
      phoneNumber: values.phoneNumber,
      template: "Your Washerly code for confirm Phone Number is $otp",
    };
    authActions.verifyUserPhoneNumber(request);
    actions.setSubmitting(false);
  };
  return (
    <Formik
      initialValues={{ phoneNumber: "" }}
      onSubmit={handlePhoneSubmit}
      validationSchema={PhoneSchema}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        handleSubmit,
        handleBlur,
        setFieldValue,
      }) => (
        <FormLayout
          buttonColor="primary"
          buttonText="Send code"
          buttonDisabled={!isValid}
          description="Please enter new phone number"
          handleSubmit={handleSubmit}
          loading={isSendVerifyCodeLoading}
        >
          <Grid item xs={12}>
            <FormInput
              onChange={(event) =>
                setFieldValue(
                  "phoneNumber",
                  event.target.value.replace(/[^+0-9]/g, "")
                )
              }
              onBlur={handleBlur}
              value={values.phoneNumber}
              variant="filled"
              margin="normal"
              fullWidth
              id="phoneNumber"
              label="New Phone Number"
              name="phoneNumber"
              autoComplete="phoneNumber"
              autoFocus
              helperText={
                errors.phoneNumber && touched.phoneNumber && errors.phoneNumber
              }
              error={!!errors.phoneNumber && touched.phoneNumber}
            />
          </Grid>
        </FormLayout>
      )}
    </Formik>
  );
};

export default ChangePhoneForm;
