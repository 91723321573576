const texts = {
  auth: {
    login: {
      hello: "Welcome to ",
      email: "E-mail",
      password: "Password",
      signIn: "Sign In",
      signUn: "Sign Up",
      forgotPasswordButton: "Forgot password",
      forgotPassword: {
        modalTitle: "Forgot Password",
        stepTwoDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        recoveryEmailLabel: "Recovery E-mail",
        verificationCodeLabel: "Code",
        stepOneDescription:
          "This page will help you to reset your lost password, please enter the email and follow instructions.",
        confirmButton: "Confirm",
        restore:
          "After you entered your email, click on the resend code button.",
        stepThreeDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        newPasswordFieldLabel: "New password",
        confirmPasswordFieldLabel: "Repeat password",
      },
    },
    signUp: {
      userTypes: {
        company: "Company",
        washer: "Washer",
        promoter: "Promoter",
      },
      welcome: "Welcome",
      toSignUp: "to Washerly Registration page",
      description:
        "If you are a Company, Washer or Promoter, please use this form to signup and access the dashboard",
      userTypeLabel: "You are",
      emailLabel: "E-mail",
      phoneLabel: "Phone Number",
      passwordLabel: "Password",
      passwordConfirmLabel: "Confirm password",
      consentLabel: "I agree to terms",
      signUp: "Sign Up",
      smsVerificationCodeLabel: "Code from SMS",
      emailVerificationCodeLabel: "Code from Email",
      referralCodeLabel: "Coupon",
    },
  },
  orders: {
    tableHeaders: {
      id: "ID",
      car: "Car",
      date: "Date",
      time: "Time",
      options: "Options",
      status: "Status",
    },
    timeLeft: "Time left",
    inProcess: "In process",
    delete: "Delete",
    invoice: "Invoice",
  },
  orderDetails: {
    washPhotoButton: "See wash photo",
    orderNumber: "Order #",
    washPrice: "Wash Price",
    expectedWashTime: "Expected Wash Time",
    washTime: "Wash Time",
    extraDirty: "Extra dirty",
    clientContact: "Client contact",
    washerContact: "Washer contact",
    contactClient: "Contact client",
    contactWasher: "Contact washer",
    phoneNumber: "Phone number",
    emailAddress: "Email address",
    washerNotFound: "Washer not found",
    washProcess: "Wash Process",
  },
  PackageStatus: {
    created: "Created",
    inProcess: "In Process",
    done: "Done",
  },
  reviews: {
    communicationRating: "Communication",
    qualityRating: "Quality",
    washTimeRating: "Wash Time",
    reviewForClient: "Review for client",
    showMore: "show more",
    headerTitle: "Review",
    headerDescription:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    reviewWasher: "Review washer",
    reviewClient: "Review client",
  },
  tutorials: {
    hotHeader: "Hot tutorials",
    hotDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    commonHeader: "All tutorials",
    commonDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  },
  adminComments: {
    title: "Washer comments",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    writeComment: "Write comment",
    commentFieldLabel: "Comment",
    sendCommentButton: "Send comment",
    commentsTitle: "Comments",
    reply: "Reply",
  },
  cars: {
    tableHeaders: {
      type: "Type",
      name: "Name",
      licencePlate: "Licence plate",
      totalWash: "Total Wash",
      lastWash: "Last Wash",
    },
    headerTitle: "Client Cars",
    headerSubtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    totalCars: "Total cars",
  },
  bankingCards: {
    tableHeaders: {
      type: "Type",
      cardHolder: "Card holder",
      cardNumber: "Number",
      expirationDate: "Expiration Date",
    },
    headerTitle: "Banking Cards",
    headerSubtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    totalCards: "Total Cards",
  },
  credits: {
    tableHeaders: {
      value: "Value",
      description: "Description",
    },
    headerTitle: "Credits",
    headerSubtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    inputPLaceHolder: "Value",
    decreaseButton: "Decrease",
    increaseButton: "Increase",
  },
  editProfile: {
    menu: {
      title: "Menu",
      personal: "Personal",
      location: "Location",
      finance: "Finance",
    },
    personal: {
      title: "Personal",
      subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      firstNameAdornment: "First Name",
      lastNameAdornment: "Last Name",
      emailAdornment: "E-mail",
      phoneNumberAdornment: "Phone",
      saveButton: "Save change",
    },
  },
  equipment: {
    lastRefilled: "Last refilled",
    rightSideTitle: "Date refilled",
  },
  futureOrders: {
    headerTitle: "Future Orders",
    headerSubtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  },
  pastOrders: {
    headerTitle: "Orders",
    headerSubtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  },
  userProfile: {
    creditBalance: "Credit Balance",
    userOrders: "User Orders",
    phone: "Phone",
    backToUserInfoButton: "Back to User info",
    editProfileButton: "Edit profile",
    blockUserButton: "Block User",
    menu: {
      logs: "Logs",
      sessions: "Sessions",
      orders: "Orders",
      cars: "Cars",
      futureWashes: "Future Washes",
      credits: "Credits",
      bankingCards: "Banking Cards",
      reviews: "Reviews",
      equipment: "Equipment",
      adminComments: "Admin comments",
    },
  },
  users: {
    headerTitle: "Users",
    headerSubtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    total: "Total users",
    tableHeaders: {
      id: "ID",
      email: "E-mail",
      fullName: "Full Name",
      phone: "Phone",
      orders: "Orders",
      applicationDate: "Application date",
      status: "Status",
      company: "Company",
      user: "User",
      dateRegistration: "Date registration",
      totalWash: "Total Wash",
      referrals: "Referrals",
    },
    search: {
      name: "Name",
      email: "E-mail",
      phoneNumber: "Phone number",
      orderId: "Order ID",
      carLicense: "Car license plate",
      company: "Company",
    },
  },
  washerEquipments: {
    equipments: {
      tableHeaders: {
        equipment: "Equipment",
        usage: "Usage",
      },
      title: "Equipments",
      orderAll: "Order all",
    },
    liquids: {
      tableHeaders: {
        liquid: "Liquid",
        usage: "Usage",
      },
      title: "Liquids",
      subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      orderAll: "Order all liquids",
    },

    contact: {
      address: "Delivery Address",
      addressDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      phoneAdornment: "Phone",
      addressAdornment: "Address",
      saveButton: "Save",
    },
  },
  validations: {
    email: {
      notValid: "Not a valid e-mail",
      isRequired: "E-mail is required",
    },
    password: {
      short: "Password is too short",
      required: "Password is required",
    },
    authorizationCode: {
      matches: "Must be exactly 5 digits",
      required: "Code is required",
    },
    phoneNumber: {
      matches: "Phone number is not valid",
      required: "Phone Number shouldn't be empty ",
    },
    passwordConfirm: {
      matches: "Passwords are not match",
      required: "Password confirm is required",
    },
    consent: {
      test: "You have to agree with our Terms and Conditions",
      required: "You have to agree with our Terms and Conditions",
    },
    address: {
      required: "Address shouldn't be empty ",
    },
    userType: {
      required: "Please choose your user type",
    },
    country: {
      required: "Country is required",
      short: "Country name is too short",
    },
    postalCode: {
      required: "Postal Code is required",
    },
  },
  header: {
    appName: "Washerly",
    washerDashboard: "Washer Dashboard",
    promoterDashboard: "Promoter Dashboard",
    logged: "You logged in as",
    adminDashboard: "Admin Dashboard",
  },
  statistics: {
    currentlyWashers: "Currently Washers",
    currentlyClients: "Currently clients",
    beingWashed: "Being Washed",
    completeWashed: "Complete Washed",
    registeredSince: "Registered Since",
    washersReferred: "Washers Referred",
    activeWashers: "Active Washers",
    usersReferred: "Clients Referred",
    totalEarned: "Total Earned",
    companiesReferred: "Companies Referred",
    currentBalance: "Current Balance",
  },
  promoters: {
    headerTitle: "Promoters List",
    headerSubtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    total: "Total promoters",
  },
  clients: {
    headerTitle: "Clients List",
    headerSubtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    total: "Total clients",
  },
  washers: {
    headerTitle: "Washers List",
    headerSubtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    total: "Total washers",
  },
  washerVerification: {
    title: "Personal",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    firstNameAdornment: "First Name",
    secondNameAdornment: "Last Name",
    emailAdornment: "E-mail",
    phoneNumberAdornment: "Phone",
    countryAdornment: "Country",
    postalCodeAdornment: "Postal code",
    addressAdornment: "Address",
    questions: "Questions",
    questionsDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    submitTitle: "Submit",
    submitDescription:
      "Please, review all entered data for correctness. If everything is properly entered, click the button below to apply",
    submitButton: "Send application",
    successNotification: "Your verification form was sent successfully",
    formSent1: "Verification form was sent",
    formSent2: "Please wait, your request will be reviewed by admin",
  },
  menu: {
    company: {
      dashboard: "Dashboard",
      washers: "Washers",
      earnings: "Earnings",
      clients: "Users",
      commissionHistory: "Commission History",
      settings: "Settings",
      payouts: "Payouts",
    },
  },
  createCompany: {
    title: "Create company",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    companyNameAdornment: "Company name",
    countryAdornment: "Country",
    postalCodeAdornment: "Postal code",
    addressAdornment: "Address",
    createCompanyButton: "Create",
    snackbar: "Your company is created successfully",
  },
  HtmlInformation: {
    title: "HTML Information",
    subTitle:
      "Promote using your coupon codes and by referring washers or companies to start using Washerly",
  },
  roles: {
    admin: {
      menu: {
        dashboard: "Dashboard",
        promoters: "Promoters",
        clients: "Clients",
        washers: "Washers",
        pendingWashers: "Pending Washers",
        statistics: "Statistics",
        payouts: "Payouts",
        equipments: "Equipments",
        blacklist: "Blacklist",
        pricingMap: "Pricing map",
        orders: "Orders",
      },
      pendingWashers: {
        menu: {
          individual: "Individual",
          company: "Company",
        },
        individual: {
          headerTitle: "Applied individual washers",
          headerSubtitle:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        },
        company: {
          headerTitle: "Applied company washers",
          headerSubtitle:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        },
        status: {
          label: "Status:",
          rejected: "Rejected",
          accepted: "Accepted",
          pending: "Pending",
        },
        application: {
          country: "Country",
          address: "Address",
          postalCode: "Postal code",
          phoneNumber: "Phone number",
          buttons: {
            approve: "Approve",
            reject: "Reject",
          },
        },
      },
      payouts: {
        menu: {
          requested: "Requested",
          completed: "Completed",
        },
        requested: {
          title: "Requested Payouts",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        },
        completed: {
          title: "Completed Payouts",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        },
        tableHeaders: {
          id: "ID",
          email: "E-mail",
          fullName: "Full Name",
          phone: "Phone",
          amount: "Amount",
          date: "Date",
        },
      },
      equipments: {
        requestList: {
          title: "Equipment requests list",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          tableHeaders: {
            id: "ID",
            date: "Date",
            email: "E-mail",
            fullName: "Full Name",
            phone: "Phone",
          },
        },
      },
    },
    washer: {
      menu: {
        dashboard: "Dashboard",
        finance: "Finance",
        orders: "Orders",
        verification: "Verification",
        tutorials: "Tutorials",
        startTheExam: "Start the Exam",
        equipment: "Equipment",
        payouts: "Payouts",
        history: "History",
        logs: "Logs",
      },
      dashboard: {
        lastOrder: {
          title: "Last Order",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          emptyText: "You haven’t order",
        },
        ordersList: {
          title: "My Orders",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          emptyText: "You haven’t orders",
        },
        logs: {
          title: "Logs",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          emptyText: "You haven’t logs",
        },
      },
      payouts: {
        menu: {
          requested: "Requested",
          completed: "Completed",
        },
        requested: {
          title: "Requested Payouts",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        },
        completed: {
          title: "Completed Payouts",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        },
        tableHeaders: {
          id: "Payout ID",
          date: "Date",
          total: "Total",
          status: "Status",
        },
        viewInvoiceButton: "View Invoice",
        requestPayoutButton: "Request Payout",
        requestPayout: {
          title: "Request Payout",
          payedOrdersListTitle: "List of Paid Orders",
          payedOrdersListSubtitle:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          emptyPayedOrdersText: "You don't have payed orders",
          tableHeaders: {
            id: "Order ID",
            date: "Date",
            clientName: "Client Name",
            price: "Price",
          },
        },
      },
    },
    promoter: {
      menu: {
        dashboard: "Dashboard",
        referrals: "Referrals",
        earnings: "Earnings",
        payouts: "Payouts",
        settings: "Settings",
      },
      dashboard: {
        couponTitle: "Your Unique Coupon code",
        couponSubtitle:
          "Your Unique Coupon code for referring Clients, Companies and Washers",
        copyCoupon: "Copy my coupon code",
        statisticsTitle: "Promoter Statistics",
        statisticsSubtitle:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      },
      earnings: {
        title: "Earnings",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        tableHeaders: {
          id: "Earning ID",
          type: "Type",
          name: "Name",
          description: "Earning Description",
        },
      },
      referrals: {
        title: "Referrals list",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        tableHeaders: {
          info: "User Info",
          type: "Type",
          date: "Date registration",
          commission: "Total Commission",
        },
      },
      settings: {
        title: "Settings",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      },
    },
    companyAdmin: {
      dashboard: {
        couponTitle: "Your Unique Coupon code",
        couponSubtitle: "Account earns 50% of the profit from washers",
        statisticsTitle: "Company Statistics",
        statisticsSubtitle:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      },
      earnings: {
        title: "Earnings",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        tableHeaders: {
          transactionType: "Transaction type",
          dateAndTime: "Date and time",
          amount: "Amount",
          description: "Description",
        },
      },
      washers: {
        title: "Washers List",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        tableHeaders: {
          washer: "Washer",
          dateRegistration: "Date registration",
          totalWash: "Total Wash",
          commission: "Commission",
        },
      },
      washersProfile: {
        title: "Wash Complete",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        tableHeaders: {
          id: "ID",
          car: "Car",
          date: "Date",
          time: "Time",
          options: "Options",
          washPrice: "Wash Price",
        },
      },
      pdfStatistics: {
        title: "Download PDF Statistics",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        button: "Generate pdf",
      },
      earningsGraph: {
        title: "Earnings",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      },
      payouts: {
        menu: {
          requested: "Requested",
          completed: "Completed",
        },
        requested: {
          title: "Requested Payouts",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        },
        completed: {
          title: "Completed Payouts",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        },
        tableHeaders: {
          id: "Payout ID",
          date: "Date",
          total: "Total",
          status: "Status",
        },
        requestPayout: {
          title: "Request Payout",
          payedOrdersListTitle: "List of Paid Orders",
          payedOrdersListSubtitle:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          emptyPayedOrdersText: "You don't have payed orders",
          tableHeaders: {
            id: "Order ID",
            date: "Date",
            washerName: "Washer Name",
            commission: "Commission",
          },
        },
        viewInvoiceButton: "View Invoice",
        requestPayoutButton: "Request Payout",
      },
    },
  },
};
//TODO: sort by role
export default texts;
