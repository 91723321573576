import * as React from "react";
import { Typography } from "@material-ui/core";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Copyright {new Date().getFullYear()} © Washerly
    </Typography>
  );
}

export default Copyright;
