import {
  IsBoolean,
  IsDate,
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { ResultModel } from "./result.model";
import { BasicModel } from "./basic.model";

export enum EquipmentUnit {
  PCS = "PCS",
  LITRE = "LITRE",
  CONTAINERITEM = "CONTAINERITEM",
  ML = "ML",
}

export function getUnitType(unit: EquipmentUnit) {
  switch (unit) {
    case EquipmentUnit.CONTAINERITEM:
    case EquipmentUnit.PCS:
      return "item";
    case EquipmentUnit.LITRE:
    case EquipmentUnit.ML:
      return "liquid";
  }
}

export class Equipment extends BasicModel {
  @IsDefined()
  @IsString()
  nameCode: string;
  @IsDefined()
  @IsString()
  title: string;
  @IsOptional()
  @IsString()
  description: null | string;
  @IsEnum(EquipmentUnit)
  valueUnit: EquipmentUnit;
  @IsNumber()
  @Type(() => Number)
  maxValue: number;
}

export class UserEquipment {
  @IsDefined()
  @IsString()
  id: string;
  @IsDate()
  @Type(() => Date)
  createdTime: Date;
  @IsDefined()
  @IsString()
  userId: string;
  @IsDefined()
  @IsString()
  equipmentId: string;
  @IsNumber()
  @Type(() => Number)
  value: number;
  @IsDefined()
  @Type(() => Equipment)
  @ValidateNested()
  equipment: Equipment;
  @IsOptional()
  userEquipmentRequest: null | object;
  @IsOptional()
  @IsDate()
  @Type(() => Date)
  lastRefilledDate: null | Date;
}

export class EquipmentRequest {
  @IsDefined()
  @IsString()
  id: string;
  @IsDate()
  @Type(() => Date)
  createdTime: Date;
  @IsDefined()
  @IsString()
  userId: string;
  @IsDefined()
  @IsString()
  equipmentId: string;
  @IsDefined()
  @IsString()
  userEquipmentId: string;
  @IsNumber()
  @Type(() => Number)
  value: number;
  @IsBoolean()
  @Type(() => Boolean)
  completed: boolean;
  @IsOptional()
  @IsDate()
  @Type(() => Date)
  completedAt: Date | null;
  @IsDefined()
  @Type(() => UserEquipment)
  @ValidateNested()
  userEquipment: UserEquipment;
}

export class UserEquipments {
  @IsDefined()
  @Type(() => UserEquipment)
  @ValidateNested()
  items: UserEquipment[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export class EquipmentRequests {
  @IsDefined()
  @Type(() => EquipmentRequest)
  @ValidateNested()
  items: EquipmentRequest[];
  @IsNumber()
  @Type(() => Number)
  total: number;
}

export type WasherEquipmentsResponse = ResultModel<UserEquipments>;
export type WasherEquipmentRequestResponse = ResultModel<EquipmentRequest>;
export type WasherEquipmentRequestsResponse = ResultModel<EquipmentRequests>;
