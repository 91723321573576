import React from "react";
import Box from "@material-ui/core/Box";
import Copyright from "../../shared/Copyright";
import ForgotPassword from "../../components/ForgotPasswordModal/ForgotPassword";
import CustomizedSnackbar from "../../shared/CustomizedSnackbar";
import Container from "@material-ui/core/Container";
import LogoImage from "../../Images/NewAppLogo.svg";
import { makeStyles, Theme } from "@material-ui/core";
import { RouteNames } from "../../constants/routeNames";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "../../components/ConfirmationDialog";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
  },
  logo: {
    height: theme.spacing(8),
    cursor: "pointer",
  },
}));

interface OwnProps {}

type Props = OwnProps;

const UnauthorizedLayout: React.FC<Props> = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const handleLogoClick = () => history.push(`/${RouteNames.ROOT}`);
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Box mt={4}>
        <img
          onClick={handleLogoClick}
          className={classes.logo}
          src={LogoImage}
          alt="logo"
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
      >
        {props.children}
      </Box>
      <Box mb={4}>
        <Copyright />
      </Box>
      <ForgotPassword />
      <CustomizedSnackbar />
      <ConfirmationDialog />
    </Container>
  );
};

export default UnauthorizedLayout;
