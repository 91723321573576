import { Page } from "../../models/router.models";
import texts from "../../constants/texts";
import { RouteNames } from "../../constants/routeNames";
import UserIcon from "../../shared/icons/UserIcon";
import LocationIcon from "../../shared/icons/LocationIcon";
import CreditCardIcon from "../../shared/icons/CreditCardIcon";

export const editDataPages: Page[] = [
  {
    title: texts.editProfile.menu.personal,
    path: `/${RouteNames.PERSONAL}`,
    Icon: UserIcon,
  },
  {
    title: texts.editProfile.menu.location,
    path: `/${RouteNames.LOCATION}`,
    Icon: LocationIcon,
  },
  {
    title: texts.editProfile.menu.finance,
    path: `/${RouteNames.FINANCE}`,
    Icon: CreditCardIcon,
  },
];
