import { ID } from "../utils.models";
import { TablePaginationParams } from "../table.models";

export interface ModifyUserInfoDto {
  firstName: string;
  secondName: string;
  email: string;
  password: string;
  locationLat: string;
  locationLong: string;
  avatarFileId: ID;
  thumbnailFileId: ID;
  pushToken: string;
  isOnline: string;
  deliveryPhoneNumber: string;
  deliveryAddress: string;
}

export enum EquipmentRequestWasherStatus {
  ALL = "ALL",
  HAVE_REQUEST = "HAVE_REQUEST",
}

export interface washersRequestParams extends TablePaginationParams {
  equipmentRequestFilter?: EquipmentRequestWasherStatus;
}
