import React from "react";
import {
  Question,
  QuestionName,
} from "../../../../models/requests/VerificationForm";
import { Field, FieldProps } from "formik";
import MuiSwitch from "../../../../shared/MuiSwitch";

interface OwnProps {
  name: string;
  value: QuestionName;
}

type Props = OwnProps;

const SwitchField: React.FC<Props> = ({ name, value }) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<Question[]>) => {
        const index = field.value.findIndex(
          (question) => question.name === value
        );
        const handleChange = () => {
          const nextValue = [...field.value];
          nextValue[index].value = !nextValue[index].value;
          form.setFieldValue(name, nextValue);
        };
        return (
          <MuiSwitch
            checked={field.value[index].value}
            onChange={handleChange}
            disableRipple
          />
        );
      }}
    </Field>
  );
};

export default SwitchField;
