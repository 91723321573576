import React from "react";

interface OwnProps {}

type Props = OwnProps;

const WalletIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="23.68"
      height="23.68"
      viewBox="0 0 23.68 23.68"
    >
      <g id="wallet_1_" data-name="wallet (1)" transform="translate(0 0)">
        <g id="Group_124" data-name="Group 124" transform="translate(0 0)">
          <path
            id="Path_437"
            data-name="Path 437"
            d="M18.731,11.886a2.775,2.775,0,1,0,0,5.55h3.931l.046,0,.046,0a.925.925,0,0,0,.925-.925V9.25a3.7,3.7,0,0,0-1.533-3L19,1.091a.925.925,0,0,0-1.277-.3L15.4,2.226,14.355.455a.925.925,0,0,0-1.277-.32L4.167,5.55H2.775a.926.926,0,0,1-.925-.9.926.926,0,0,1,.925-.9H4.486a.925.925,0,1,0,0-1.85H2.775a2.78,2.78,0,0,0-2.7,2.132A.921.921,0,0,0,0,4.394V19.98a3.7,3.7,0,0,0,3.7,3.7H19.98a3.7,3.7,0,0,0,3.51-2.528.925.925,0,1,0-1.755-.585A1.848,1.848,0,0,1,19.98,21.83H3.7a1.852,1.852,0,0,1-1.85-1.85V7.242a2.762,2.762,0,0,0,.925.159h17.2a1.852,1.852,0,0,1,1.85,1.85v2.636Zm3.1,3.7h-3.1a.925.925,0,0,1,0-1.85h3.1ZM7.73,5.55,13.239,2.2l.985,1.667A.927.927,0,0,0,14.291,4a.938.938,0,0,0,.066.092l.86,1.456Zm9.636,0L16.343,3.819,17.9,2.85l1.646,2.7Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </svg>
  );
};

export default WalletIcon;
