import { RouteProps } from "react-router";
import { RouteNames, RouteParams } from "../../../../constants/routeNames";
import asAsync from "../../../../helpers/asAsync";

export const routes: RouteProps[] = [
  {
    path: `/${RouteNames.WASHERS}/:${RouteParams.USER_ID}/${RouteNames.SESSIONS}`,
    component: asAsync(
      import("../../../../components/UserProfile/UserSessions")
    ),
    exact: true,
  },
  {
    path: `/${RouteNames.WASHERS}/:${RouteParams.USER_ID}/${RouteNames.LOGS}`,
    component: asAsync(import("../../../../components/UserProfile/UserLogs")),
    exact: true,
  },
  {
    path: `/${RouteNames.WASHERS}/:${RouteParams.USER_ID}/${RouteNames.ORDERS}`,
    component: asAsync(import("../../../../components/UserProfile/PastOrders")),
    exact: true,
  },
  {
    path: `/${RouteNames.WASHERS}/:${RouteParams.USER_ID}/${RouteNames.ORDERS}/:${RouteParams.ORDER_ID}`,
    component: asAsync(import("../../../../components/OrderDetails")),
    exact: true,
  },
  {
    path: `/${RouteNames.WASHERS}/:${RouteParams.USER_ID}/${RouteNames.FUTURE_WASH}`,
    component: asAsync(
      import("../../../../components/UserProfile/FutureOrders")
    ),
    exact: true,
  },
  {
    path: `/${RouteNames.WASHERS}/:${RouteParams.USER_ID}/${RouteNames.FUTURE_WASH}/:${RouteParams.ORDER_ID}`,
    component: asAsync(import("../../../../components/OrderDetails")),
    exact: true,
  },
  {
    path: `/${RouteNames.WASHERS}/:${RouteParams.USER_ID}/${RouteNames.REVIEWS}`,
    component: asAsync(import("../../../../components/UserProfile/Reviews")),
    exact: true,
  },
  {
    path: `/${RouteNames.WASHERS}/:${RouteParams.USER_ID}/${RouteNames.EQUIPMENT}`,
    component: asAsync(import("../../../../components/UserProfile/Equipment")),
    exact: true,
  },
  {
    path: `/${RouteNames.WASHERS}/:${RouteParams.USER_ID}/${RouteNames.ADMIN_COMMENTS}`,
    component: asAsync(
      import("../../../../components/UserProfile/AdminComments")
    ),
    exact: true,
  },
];
