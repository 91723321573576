import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import { Reviews } from "../../models/responses/review.model";
import { ReviewsForActiveUserParams } from "../../models/requests/Reviews";

export enum ReviewsActionTypes {
  GET_REVIEWS_FOR_ACTIVE_USER = "[Reviews] Get reviews for active user",
  GET_REVIEWS_FOR_ACTIVE_USER_SUCCESS = "[Reviews] Get reviews for active user success",
  GET_REVIEWS_FOR_ACTIVE_USER_FAILURE = "[Reviews] Get reviews for active user failure",
}

export const ReviewsActions = {
  getReviewsForActiveUser: (payload: ReviewsForActiveUserParams) =>
    createAction(ReviewsActionTypes.GET_REVIEWS_FOR_ACTIVE_USER, payload),
  getReviewsForActiveUserSuccess: (payload: Reviews) =>
    createAction(
      ReviewsActionTypes.GET_REVIEWS_FOR_ACTIVE_USER_SUCCESS,
      payload
    ),
  getReviewsForActiveUserFailure: (payload: string) =>
    createAction(
      ReviewsActionTypes.GET_REVIEWS_FOR_ACTIVE_USER_FAILURE,
      payload
    ),
};

export type ReviewsActions = ActionsUnion<typeof ReviewsActions>;

export type getReviewsForActiveUserType = ActionsOfType<
  ReviewsActions,
  ReviewsActionTypes.GET_REVIEWS_FOR_ACTIVE_USER
>;
