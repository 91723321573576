import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";
import Map from "./components/Map";
import { Currency, MapCoordinate } from "../../../models/utils.models";
import { v4 as uuidv4 } from "uuid";
import RegionInfo from "./components/RegionInfo";
import { packages } from "../../../mock/packages";
import PackagesList from "./components/PackagesList";

export interface PriceRegion {
  id: string;
  createdDate: Date;
  coords: MapCoordinate[];
  name: string;
  currency: Currency;
  color: string;
  blueprintPackages: any;
  zIndex: number;
}

interface OwnProps {}

type Props = OwnProps;

const PricingMap: React.FC<Props> = (props) => {
  const [priceRegions, setPriceRegions] = useState<PriceRegion[]>([]);
  const [selectedRegionId, setSelectedRegionId] = useState<
    string | undefined
  >();
  const getRegionById = useCallback(
    (id: string) => {
      return priceRegions.find((region) => region.id === id);
    },
    [priceRegions]
  );

  const handleRegionClick = useCallback(
    (id: string) => {
      const newSelectedId = id !== selectedRegionId ? id : undefined;
      setSelectedRegionId(newSelectedId);
    },
    [selectedRegionId]
  );

  const handleRegionCreate = useCallback(
    (coords: MapCoordinate[]) => {
      const newRegion: PriceRegion = {
        id: uuidv4(),
        createdDate: new Date(),
        coords,
        blueprintPackages: [...packages],
        name: `Region ${priceRegions.length + 1}`,
        color: "#FF0000",
        currency: Currency.EUR,
        zIndex: priceRegions.length,
      };
      setPriceRegions((prevRegions) => [...prevRegions, newRegion]);
      setSelectedRegionId(newRegion.id);
    },
    [priceRegions]
  );

  const handleRegionChange = useCallback(
    ({ id, ...data }: Partial<PriceRegion> & Pick<PriceRegion, "id">) => {
      const newPriceRegions = priceRegions.map((region) => {
        if (id === region.id) {
          return { ...region, ...data };
        }
        return region;
      });
      setPriceRegions(newPriceRegions);
    },
    [priceRegions]
  );

  const handlePackagesChange = useCallback(
    (newPackages: any) => {
      if (selectedRegionId) {
        handleRegionChange({
          id: selectedRegionId,
          blueprintPackages: [...newPackages],
        });
      }
    },
    [selectedRegionId, handleRegionChange]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Map
          priceRegions={priceRegions}
          onRegionCreate={handleRegionCreate}
          onRegionClick={handleRegionClick}
          selectedRegionId={selectedRegionId}
          onPolygonChanged={handleRegionChange}
        />
      </Grid>
      {selectedRegionId && (
        <Grid item xs={12}>
          <RegionInfo
            priceRegion={getRegionById(selectedRegionId)!}
            onSubmit={handleRegionChange}
          />
        </Grid>
      )}
      {selectedRegionId && (
        <PackagesList
          packages={getRegionById(selectedRegionId)!.blueprintPackages}
          onChange={handlePackagesChange}
        />
      )}
    </Grid>
  );
};

export default PricingMap;
