import React, { useMemo } from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Formik, FormikHelpers } from "formik";
import {
  EditPersonalUserData,
  VERIFY_TYPE,
} from "../../models/requests/Credentials";
import TitledPaper from "../../shared/TitledPaper";
import texts from "../../constants/texts";
import { editPersonalUserDataSchema } from "../../helpers/validations";
import InputWithAdornment from "../../shared/InputWithAdornment";
import CustomButton from "../../shared/CustomButton";
import { User } from "../../models/responses/user.model";
import useActions from "../../hooks/useActions";
import { UsersActions } from "../../store/actions/users.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import { AuthActions } from "../../store/actions/auth.actions";
import ChangeCredentialsModal from "./ChangeCredentialsModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      marginTop: theme.spacing(4),
      display: "flex",
      flexFlow: "row wrap",
    },
    textField: {
      flexBasis: theme.spacing(30),
      marginRight: theme.spacing(6),
    },
    input: {
      fontSize: "14px",
    },
    inputAdornment: {
      whiteSpace: "nowrap",
      fontSize: "12px",
      marginRight: theme.spacing(3),
    },
    underline: {
      "&:before": {
        borderBottom: "1px solid #E9E9E9",
      },
    },
  })
);

interface OwnProps {
  user?: User;
}

type Props = OwnProps;

const EditPersonal: React.FC<Props> = (props) => {
  const classes = useStyles();

  const authSelector = useSelector((state: AppState) => state.auth);
  const usersSelector = useSelector((state: AppState) => state.users);
  const usersActions = useActions<typeof UsersActions>(UsersActions);
  const authActions = useActions<typeof AuthActions>(AuthActions);
  const isLoading = useMemo(() => {
    return props.user
      ? usersSelector.updateUserLoading
      : authSelector.isUpdateUserLoading;
  }, [
    props.user,
    usersSelector.updateUserLoading,
    authSelector.isUpdateUserLoading,
  ]);
  const user = useMemo(() => {
    return props.user || authSelector.authorizedUser;
  }, [props.user, authSelector.authorizedUser]);

  function handleFormSubmit(
    values: EditPersonalUserData,
    actions: FormikHelpers<EditPersonalUserData>
  ) {
    const { firstName, secondName } = values;
    if (props.user) {
      usersActions.updateUserData({
        firstName,
        secondName,
        userId: props.user.id,
      });
    } else {
      authActions.updateCurrentUserData({ firstName, secondName });
    }
    actions.setSubmitting(false);
  }
  if (!user) {
    return null;
  }
  const handleChangeEmailClick = () => {
    authActions.openChangeCredentialModal(VERIFY_TYPE.EMAIL);
  };
  const handleChangePhoneClick = () => {
    authActions.openChangeCredentialModal(VERIFY_TYPE.PHONE);
  };
  return (
    <TitledPaper
      title={texts.editProfile.personal.title}
      subtitle={texts.editProfile.personal.subtitle}
    >
      <Formik
        initialValues={{
          firstName: user.firstName || "",
          secondName: user.secondName || "",
        }}
        onSubmit={handleFormSubmit}
        validationSchema={editPersonalUserDataSchema}
        enableReinitialize
        initialErrors={{ firstName: "init" }}
      >
        {({
          values,
          handleChange,
          errors,
          touched,
          isValid,
          handleSubmit,
          handleBlur,
        }) => (
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <InputWithAdornment
              className={classes.textField}
              fullWidth={false}
              adornment={texts.editProfile.personal.firstNameAdornment}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              id="firstName"
              name="firstName"
              autoFocus
              helperText={
                errors.firstName && touched.firstName && errors.firstName
              }
              error={!!errors.firstName && touched.firstName}
            />
            <InputWithAdornment
              fullWidth={false}
              adornment={texts.editProfile.personal.lastNameAdornment}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.secondName}
              margin="normal"
              id="secondName"
              name="secondName"
              helperText={
                errors.secondName && touched.secondName && errors.secondName
              }
              error={!!errors.secondName && touched.secondName}
              className={classes.textField}
            />
            <Box flexBasis="100%" display="flex" alignItems="center" mt={4}>
              <CustomButton
                color="secondary"
                disabled={!isValid || isLoading}
                type="submit"
                variant="contained"
                large
                loading={isLoading}
              >
                {texts.editProfile.personal.saveButton}
              </CustomButton>
            </Box>
          </form>
        )}
      </Formik>
      {!props.user && (
        <Box mt={8} display="flex" alignItems="center">
          <Typography variant="h5">Email:</Typography>
          <Box ml={2}>
            <Typography variant="h5" color="primary">
              {user?.email || ""}
            </Typography>
          </Box>
          <Box ml={2}>
            <CustomButton
              color="primary"
              onClick={handleChangeEmailClick}
              variant="outlined"
            >
              Change
            </CustomButton>
          </Box>
        </Box>
      )}
      {!props.user && (
        <Box mt={4} display="flex" alignItems="center">
          <Typography variant="h5">Phone:</Typography>
          <Box ml={2}>
            <Typography variant="h5" color="primary">
              {user?.phoneNumber || ""}
            </Typography>
          </Box>
          <Box ml={2}>
            <CustomButton
              color="primary"
              onClick={handleChangePhoneClick}
              variant="outlined"
            >
              Change
            </CustomButton>
          </Box>
        </Box>
      )}
      <ChangeCredentialsModal />
    </TitledPaper>
  );
};

export default EditPersonal;
