import React, { useState } from "react";
import styled from "styled-components";
import { getImageUrl } from "../helpers/utils/utils";
import PhotosLightbox from "./PhotosLightbox";
import colors from "../constants/colors";

const Container = styled.div`
  display: flex;
`;

const ImageBox = styled.div`
  height: 35px;
  width: 35px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  &::after {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: black;
    transition: 0.5s;
  }
  &:hover {
    &::after {
      opacity: 0.2;
    }
  }
`;
const OverflowMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: ${colors.textPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface OwnProps {
  attachment: string[];
  size: number;
  limit?: number;
}

type Props = OwnProps;

const ImageThumbnails: React.FC<Props> = ({ attachment, size, limit = 6 }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [carouselOpen, setCarouselOpen] = useState(false);
  const items =
    attachment.length > limit ? attachment.slice(0, limit) : attachment;
  const handleCarouselOpen = (index = 0) => {
    setPhotoIndex(index);
    setCarouselOpen(true);
  };
  return (
    <Container>
      {items.map((item, index, arr) => (
        <ImageBox onClick={() => handleCarouselOpen(index)}>
          <img src={getImageUrl(item, size)} alt={`thumbnail ${index}`} />
          {attachment.length > limit && index === arr.length - 1 && (
            <OverflowMask>+{attachment.length - index}</OverflowMask>
          )}
        </ImageBox>
      ))}
      {carouselOpen && (
        <PhotosLightbox
          images={attachment.map((item) => getImageUrl(item))}
          onClose={() => setCarouselOpen(false)}
          startIndex={photoIndex}
        />
      )}
    </Container>
  );
};

export default ImageThumbnails;
