import { v } from "overshom-valid";

export enum NODE_ENV {
  development = "development",
  production = "production",
}

const fromProcessEnv = {
  NODE_ENV: v.Enum(NODE_ENV),
  REACT_APP_BASE_URL: v.String(),
};

export const typedEnv = v.Object(fromProcessEnv).validate(process.env);

export const isDevMode = typedEnv.NODE_ENV === "development";
