import React from "react";
import "./style.css";

interface OwnProps {
  percent: number;
}

type Props = OwnProps;

const CircleChart: React.FC<Props> = (props) => {
  return (
    <div className="single-chart">
      <svg viewBox="0 0 60 60" className="circular-chart orange">
        <path
          className="circle-bg"
          d="M30 14.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={`${props.percent}, 100`}
          d="M30 14.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
    </div>
  );
};

export default CircleChart;
