import { makeStyles, Switch, Theme, Typography } from "@material-ui/core";
import React from "react";
import Box from "@material-ui/core/Box";
import { SwitchProps } from "@material-ui/core/Switch";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 48,
    height: 28,
    padding: 0,
    margin: theme.spacing(0, 3.5),
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
    },
    "&:hover": {
      backgroundColor: "initial",
    },
  },
  thumb: {
    width: 24,
    height: 24,
    borderRadius: 5,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.11)",
  },
  track: {
    position: "relative",
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
    opacity: 1,
    transition: theme.transitions.create(["background-color"]),
  },
  checked: {},
  focusVisible: {},
}));

interface OwnProps {
  leftText: string;
  rightText: string;
}

type Props = OwnProps & SwitchProps;

const MuiToggleSwitch: React.FC<Props> = ({
  leftText,
  rightText,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" color="textPrimary">
        {leftText}
      </Typography>
      <Switch
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
      <Typography variant="body1" color="textPrimary">
        {rightText}
      </Typography>
    </Box>
  );
};

export default MuiToggleSwitch;
