import React, { useCallback, useEffect, useState } from "react";
import useActions from "../../../hooks/useActions";
import { AppState } from "../../../store/reducers";
import texts from "../../../constants/texts";
import { TablePaginationParams } from "../../../models/table.models";
import ListSearch from "../../../components/UsersList/ListSearch";
import FormInput from "../../../shared/FormInput";
import ArrowDropDownIcon from "../../../styled/ArrowDropDownIcon";
import TableLayout from "../../../shared/sharedTable/TableLayout";
import { useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { OrdersListStatusFilter } from "../../../models/requests/Orders";
import { OrdersActions } from "../../../store/actions/orders.actions";
import OrdersList from "../../../components/OrdersList";
import orderListHeadCells from "../../../components/OrdersList/orderListHeadCells";

const orderStatuses = [
  {
    value: OrdersListStatusFilter.ALL,
    label: "All",
  },
  {
    value: OrdersListStatusFilter.COMPLETED,
    label: "Completed",
  },
  {
    value: OrdersListStatusFilter.IN_PROGRESS,
    label: "In Progress",
  },
  {
    value: OrdersListStatusFilter.CANCELED,
    label: "Canceled",
  },
];

interface OwnProps {}

type Props = OwnProps;

const Orders: React.FC<Props> = (props) => {
  const [statusFilter, setStatusFilter] = useState<OrdersListStatusFilter>(
    OrdersListStatusFilter.ALL
  );
  const ordersActions = useActions<typeof OrdersActions>(OrdersActions);
  const ordersState = useSelector((state: AppState) => state.orders);
  const headCells = orderListHeadCells;

  useEffect(() => {
    ordersActions.changeSearchValue("");
  }, [ordersActions]);

  const handlePaginationChange = useCallback(
    (values: TablePaginationParams) => {
      ordersActions.getOrdersList({
        ...values,
        statusFilter,
      });
    },
    [statusFilter, ordersActions]
  );

  const handleSearchChange = (value: string) => {
    ordersActions.changeSearchValue(value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <ListSearch
          handleSearchChange={handleSearchChange}
          count={ordersState.ordersList.total}
          type={"order"}
          searchValues={["Washer Name", "Customer Name", "Order Id"]}
        />
      </Grid>
      <Grid item xs={3} style={{ alignSelf: "center" }}>
        <FormInput
          select
          label={texts.roles.admin.pendingWashers.status.label}
          value={statusFilter}
          onChange={(event) =>
            setStatusFilter(event.target.value as OrdersListStatusFilter)
          }
          variant="filled"
          fullWidth
          SelectProps={{
            IconComponent: ArrowDropDownIcon,
          }}
        >
          {orderStatuses.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{ fontSize: 14 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </FormInput>
      </Grid>

      <TableLayout
        search={ordersState.searchValue}
        title={"Orders"}
        subtitle={"List of all orders"}
        isLoading={ordersState.ordersListLoading}
        onPaginationChange={handlePaginationChange}
        headCells={headCells}
        total={+ordersState.ordersList.total}
        withoutSorting
      >
        <OrdersList data={ordersState.ordersList} />
      </TableLayout>
    </Grid>
  );
};

export default Orders;
