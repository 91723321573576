import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

interface OwnProps {}

type Props = OwnProps;

const Loading: React.FC<Props> = () => {
  return (
    <Box
      width={1}
      height={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignSelf="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default Loading;
