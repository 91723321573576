import React, { SVGProps } from "react";

type Props = SVGProps<any>;

const CompanyIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.848"
      height="18.982"
      viewBox="0 0 19.848 18.982"
      {...props}
    >
      <g id="commercial-buldings" transform="translate(0.004 -8.861)">
        <g
          id="Group_341"
          data-name="Group 341"
          transform="translate(-0.004 8.861)"
        >
          <path
            id="Path_687"
            data-name="Path 687"
            d="M19.233,26.969h-.591l.015-15.945a2.076,2.076,0,0,0-1.969-2.164H11.9a2.016,2.016,0,0,0-1.892,2.164V13.9c-.158-.037-1.028-.063-1.2-.063H3.4a1.882,1.882,0,0,0-1.969,1.775l.019,11.361H.59c-.326,0-.594.1-.594.422s.267.451.594.451H19.248c.326,0,.6-.128.6-.454S19.559,26.969,19.233,26.969ZM2.3,15.608c0-.6.844-.906,1.1-.906H8.811c.256,0,1.1.047,1.1.906l.02,11.361h-7.6Zm8.583-4.584A1.192,1.192,0,0,1,11.9,9.73h4.786c.186,0,1.1.06,1.1,1.295L17.8,26.969H10.934Z"
            transform="translate(0.004 -8.861)"
            fill="currentColor"
          />
          <path
            id="Path_702"
            data-name="Path 702"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-67.838 -274.394)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
          <path
            id="Path_705"
            data-name="Path 705"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-67.838 -271.569)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
          <path
            id="Path_707"
            data-name="Path 707"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-67.838 -268.745)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
          <path
            id="Path_709"
            data-name="Path 709"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-59.364 -268.745)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
          <path
            id="Path_711"
            data-name="Path 711"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-59.364 -271.569)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
          <path
            id="Path_713"
            data-name="Path 713"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-59.364 -274.394)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
          <path
            id="Path_715"
            data-name="Path 715"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-59.364 -277.219)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
          <path
            id="Path_703"
            data-name="Path 703"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-70.332 -274.394)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
          <path
            id="Path_704"
            data-name="Path 704"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-70.332 -271.569)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
          <path
            id="Path_706"
            data-name="Path 706"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-70.332 -268.745)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
          <path
            id="Path_708"
            data-name="Path 708"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-61.858 -268.745)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
          <path
            id="Path_710"
            data-name="Path 710"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-61.858 -271.569)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
          <path
            id="Path_712"
            data-name="Path 712"
            d="M75.455,282.72H74.77a.4.4,0,0,0-.4.4v.684a.4.4,0,0,0,.4.4h.685a.4.4,0,0,0,.4-.4v-.684A.4.4,0,0,0,75.455,282.72Z"
            transform="translate(-61.858 -274.394)"
            fill="none"
            stroke="currentColor"
            strokeWidth="0.8"
          />
        </g>
      </g>
    </svg>
  );
};

export default CompanyIcon;
