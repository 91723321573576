import { makeStyles, Theme } from "@material-ui/core";
import { drawerWidth } from "../../../constants/sizes";
import colors from "../../../constants/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    minHeight: theme.spacing(13),
    // ...constants.mixins.toolbar,
  },
  drawerPaper: {
    height: "auto",
    position: "relative",
    border: 0,
    borderRadius: theme.spacing(0, 2, 2, 0),
    boxShadow: "5px 0px 20px rgba(0, 0, 0, 0.06)",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8),
    },
  },
  list: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  item: {
    display: "flex",
    padding: theme.spacing(1.5, 0, 2, 0),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
    "& $title": {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },
  title: {
    color: theme.palette.text.secondary,
  },
  button: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    letterSpacing: 0,
    minHeight: theme.spacing(4.5),
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.2,
  },
  icon: {
    color: colors.blueGrey,
    minWidth: theme.spacing(5),
  },
  listTitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },
  logo: {
    width: theme.spacing(13),
    cursor: "pointer",
  },
}));
