import React from "react";
import { getImageUrl, sortOrderPackages } from "../helpers/utils/utils";
import { getPackageStatusProps } from "../constants/utils";
import { createStyles, makeStyles, Theme, Tooltip } from "@material-ui/core";
import PackageContainerWithBadge from "../styled/PackageContainerWithBadge";
import { Package } from "../models/responses/package.model";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    packageIcon: {
      height: theme.spacing(2.5),
    },
    packageIconContainer: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
  })
);

interface OwnProps {
  packages: Package[];
}

type Props = OwnProps;

const OrderOptions: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <>
      {sortOrderPackages(props.packages).map(
        ({ iconFileId, id, status, title }) => {
          const { statusColor, statusText } = getPackageStatusProps(status);
          return (
            <Tooltip
              key={id}
              placement="top"
              title={`${title} (${statusText})`}
              className={classes.packageIconContainer}
            >
              <PackageContainerWithBadge color={statusColor}>
                <img
                  className={classes.packageIcon}
                  src={getImageUrl(iconFileId)}
                  alt={`${title} icon`}
                />
              </PackageContainerWithBadge>
            </Tooltip>
          );
        }
      )}
    </>
  );
};

export default OrderOptions;
