import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import {
  Experience,
  VerificationFormStatus,
} from "../requests/VerificationForm";
import { User } from "./user.model";
import { ResultModel } from "./result.model";
import { BasicModel } from "./basic.model";

class Question {
  @IsDate()
  @Type(() => Date)
  createdTime: Date;
  @IsString()
  id: string;
  @IsString()
  washerVerificationFormId: string;
  @IsString()
  name: string;
  @IsBoolean()
  value: boolean;
}

export class ApplicationForm extends BasicModel {
  @IsString()
  userId: string;
  @IsString()
  country: string;
  @IsString()
  address: string;
  @IsString()
  postalCode: string;
  @IsEnum(Experience)
  experience: Experience;
  @IsEnum(VerificationFormStatus)
  status: VerificationFormStatus;
  @IsOptional()
  feedback: null;
  @Type(() => User)
  @ValidateNested()
  user: User;
  @Type(() => Question)
  @ValidateNested()
  questions: Question[];
  photos: any[];
}

export type ApplicationFormResponse = ResultModel<ApplicationForm>;
