import {
  EarningsActions,
  EarningsActionTypes,
} from "../actions/earnings.actions";
import {
  EarningsGraphData,
  EarningsListForCompanyAdmin,
} from "../../models/responses/earnings.model";
import { GetEarningsParams } from "../../models/requests/Earnings";

class StateModel {
  earningsGraphForCompanyAdmin: EarningsGraphData[] = [];
  earningsGraphForCompanyAdminLoading: boolean = false;
  earningsGraphForCompanyAdminError: string = "";
  earningsGraphForCompanyAdminParams: GetEarningsParams = {
    page: 0,
    size: 5,
  };

  earningsHistory: EarningsListForCompanyAdmin = { items: [], total: 0 };
  earningsHistoryLoading: boolean = false;
  earningsHistoryError: string = "";
}

const initialState = new StateModel();

export function earningsReducer(
  state = initialState,
  action: EarningsActions
): StateModel {
  switch (action.type) {
    case EarningsActionTypes.GET_EARNINGS_GRAPH_DATA_FOR_COMPANY_ADMIN: {
      return {
        ...state,
        earningsGraphForCompanyAdminLoading: true,
        earningsGraphForCompanyAdminError: "",
        earningsGraphForCompanyAdmin: initialState.earningsGraphForCompanyAdmin,
      };
    }
    case EarningsActionTypes.GET_EARNINGS_GRAPH_DATA_FOR_COMPANY_ADMIN_SUCCESS: {
      return {
        ...state,
        earningsGraphForCompanyAdminLoading: false,
        earningsGraphForCompanyAdmin: action.payload,
      };
    }
    case EarningsActionTypes.GET_EARNINGS_GRAPH_DATA_FOR_COMPANY_ADMIN_FAILURE: {
      return {
        ...state,
        earningsGraphForCompanyAdminLoading: false,
        earningsGraphForCompanyAdminError: action.payload,
      };
    }
    case EarningsActionTypes.GET_EARNINGS_GRAPH_DATA_PARAMS: {
      return {
        ...state,
        earningsGraphForCompanyAdminParams: {
          ...state.earningsGraphForCompanyAdminParams,
          ...action.payload,
        },
      };
    }
    case EarningsActionTypes.GET_EARNINGS_HISTORY: {
      return {
        ...state,
        earningsHistoryLoading: true,
        earningsHistoryError: "",
        earningsHistory: initialState.earningsHistory,
      };
    }
    case EarningsActionTypes.GET_EARNINGS_HISTORY_SUCCESS: {
      return {
        ...state,
        earningsHistoryLoading: false,
        earningsHistory: action.payload,
      };
    }
    case EarningsActionTypes.GET_EARNINGS_HISTORY_FAILURE: {
      return {
        ...state,
        earningsHistoryLoading: false,
        earningsHistoryError: action.payload,
      };
    }
    default:
      return state;
  }
}
