import React from "react";

interface OwnProps {}

type Props = OwnProps;

const StatisticsIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        id="pie-chart_1_"
        data-name="pie-chart (1)"
        d="M4.76,9a.7.7,0,1,1,.7.7A.7.7,0,0,1,4.76,9ZM3.705,7.944a.7.7,0,1,0-.7-.7A.7.7,0,0,0,3.705,7.944ZM5.463,6.187a.7.7,0,1,0-.7-.7A.7.7,0,0,0,5.463,6.187ZM3.705,11.459a.7.7,0,1,0-.7-.7A.7.7,0,0,0,3.705,11.459ZM7.221,7.944a.7.7,0,1,0-.7-.7A.7.7,0,0,0,7.221,7.944Zm0-3.549a.7.7,0,1,0-.7-.7A.7.7,0,0,0,7.221,4.4ZM17.43,12.131a.7.7,0,0,1-1.318-.49A7.665,7.665,0,0,0,16.559,9.7H10.68l4.641,4.719a.7.7,0,0,1-.008.994A8.991,8.991,0,0,1,4.282,16.658a9.078,9.078,0,0,1-1.55-1.2.706.706,0,0,1-.221-.222A9,9,0,0,1,6.923.246,8.985,8.985,0,0,1,17.43,12.131ZM9.706,1.44V8.3h6.856a7.51,7.51,0,0,0-.172-1.051A7.607,7.607,0,0,0,9.706,1.44ZM2.512,5.067a.7.7,0,0,1-.559,1.12,7.566,7.566,0,0,0-.529,2.344.7.7,0,1,1,0,.936A7.6,7.6,0,0,0,1.95,11.81a.7.7,0,0,1,.56,1.124,7.534,7.534,0,0,0,.592.841q.169-.177.388-.4c.488-.5,1.172-1.176,2.033-2.016C6.678,10.23,7.849,9.106,8.3,8.675V1.44a7.652,7.652,0,0,0-2.135.518s0,.009,0,.013a.7.7,0,0,1-1.131.557,7.547,7.547,0,0,0-.876.628.7.7,0,1,1-.99.99A7.663,7.663,0,0,0,2.512,5.067ZM13.8,14.882,8.976,9.974c-1.986,1.9-4.046,3.917-4.891,4.8a7.594,7.594,0,0,0,9.718.1Zm0,0"
        transform="translate(0 0.001)"
      />
    </svg>
  );
};

export default StatisticsIcon;
