import { Order, Orders } from "../../models/responses/order.model";
import { OrdersActions, OrdersActionTypes } from "../actions/orders.actions";

class StateModel {
  ordersList: Orders = { items: [], total: 0 };
  ordersListLoading: boolean = false;
  ordersListError: string = "";
  searchValue: string = "";
  pastOrdersForActiveUser: Orders = { items: [], total: 0 };
  pastOrdersForActiveUserLoading: boolean = false;
  pastOrdersForActiveUserError: string = "";
  futureOrdersForActiveUser: Orders = { items: [], total: 0 };
  futureOrdersForActiveUserLoading: boolean = false;
  futureOrdersForActiveUserError: string = "";
  activeOrder: Order | null = null;
  activeOrderLoading: boolean = false;
  activeOrderError: string = "";
  lastCompleteOrder: Order | null = null;
  lastCompleteOrderLoading: boolean = false;
  lastCompleteOrderError: string = "";
}

const initialState = new StateModel();

export function ordersReducer(
  state = initialState,
  action: OrdersActions
): StateModel {
  switch (action.type) {
    case OrdersActionTypes.GET_ORDERS_LIST: {
      return {
        ...state,
        ordersListLoading: true,
        ordersListError: "",
        ordersList: initialState.ordersList,
      };
    }
    case OrdersActionTypes.GET_ORDERS_LIST_SUCCESS: {
      return {
        ...state,
        ordersListLoading: false,
        ordersList: action.payload,
      };
    }
    case OrdersActionTypes.GET_ORDERS_LIST_FAILURE: {
      return {
        ...state,
        ordersListLoading: false,
        ordersListError: action.payload,
      };
    }
    case OrdersActionTypes.SET_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: action.payload,
      };
    }
    case OrdersActionTypes.GET_PAST_ORDERS_FOR_ACTIVE_USER: {
      return {
        ...state,
        pastOrdersForActiveUserLoading: true,
        pastOrdersForActiveUserError: "",
        pastOrdersForActiveUser: initialState.pastOrdersForActiveUser,
      };
    }
    case OrdersActionTypes.GET_PAST_ORDERS_FOR_ACTIVE_USER_SUCCESS: {
      return {
        ...state,
        pastOrdersForActiveUserLoading: false,
        pastOrdersForActiveUser: action.payload,
      };
    }
    case OrdersActionTypes.GET_PAST_ORDERS_FOR_ACTIVE_USER_FAILURE: {
      return {
        ...state,
        pastOrdersForActiveUserLoading: false,
        pastOrdersForActiveUserError: action.payload,
      };
    }
    case OrdersActionTypes.GET_FUTURE_ORDERS_FOR_ACTIVE_USER: {
      return {
        ...state,
        futureOrdersForActiveUserLoading: true,
        futureOrdersForActiveUserError: "",
        futureOrdersForActiveUser: initialState.pastOrdersForActiveUser,
      };
    }
    case OrdersActionTypes.GET_FUTURE_ORDERS_FOR_ACTIVE_USER_SUCCESS: {
      return {
        ...state,
        futureOrdersForActiveUserLoading: false,
        futureOrdersForActiveUser: action.payload,
      };
    }
    case OrdersActionTypes.GET_FUTURE_ORDERS_FOR_ACTIVE_USER_FAILURE: {
      return {
        ...state,
        futureOrdersForActiveUserLoading: false,
        futureOrdersForActiveUserError: action.payload,
      };
    }
    case OrdersActionTypes.GET_ACTIVE_ORDER_DATA: {
      return {
        ...state,
        activeOrderLoading: true,
        activeOrderError: "",
        activeOrder: initialState.activeOrder,
      };
    }
    case OrdersActionTypes.GET_ACTIVE_ORDER_SUCCESS: {
      return {
        ...state,
        activeOrderLoading: false,
        activeOrder: action.payload,
      };
    }
    case OrdersActionTypes.GET_ACTIVE_ORDER_FAILURE: {
      return {
        ...state,
        activeOrderLoading: false,
        activeOrderError: action.payload,
      };
    }
    case OrdersActionTypes.GET_LAST_COMPLETE_ORDER: {
      return {
        ...state,
        lastCompleteOrderError: "",
        lastCompleteOrderLoading: true,
      };
    }
    case OrdersActionTypes.GET_LAST_COMPLETE_ORDER_SUCCESS: {
      return {
        ...state,
        lastCompleteOrderLoading: false,
        lastCompleteOrder: action.payload,
      };
    }
    case OrdersActionTypes.GET_LAST_COMPLETE_ORDER_FAILURE: {
      return {
        ...state,
        lastCompleteOrderLoading: false,
        lastCompleteOrderError: action.payload,
      };
    }

    default:
      return state;
  }
}
