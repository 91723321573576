import React from "react";
import { Box, List, Paper, Typography } from "@material-ui/core";
import texts from "../../../../../../constants/texts";
import FirstLayer from "../../../../../../styled/FirstLayer";
import SecondLayer from "../../../../../../styled/SecondLayer";
import DateRow from "../DateRow";
import { useStyles } from "./style";
import RequestedEquipment from "../RequestedEquipment";
import { EquipmentRequest } from "../../../../../../models/responses/equipment.model";

interface OwnProps {
  title: string;
  data: EquipmentRequest[];
}

type Props = OwnProps;

const RequestedEquipments: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Box flexGrow={2} className={classes.leftSide}>
        <Typography variant="h4" component="h3">
          {props.title}
        </Typography>
        <List className={classes.list}>
          {props.data.map((item) => (
            <RequestedEquipment key={item.id} data={item} />
          ))}
        </List>
      </Box>
      <Box className={classes.rightSide}>
        <FirstLayer open openWidth="5%" />
        <SecondLayer>
          <Box className={classes.rightSideContent}>
            <Typography variant="h4" component="h3">
              {texts.equipment.rightSideTitle}
            </Typography>
            <List className={classes.list}>
              {props.data.map((item) => (
                <DateRow
                  key={`date-${item.id}`}
                  date={item.userEquipment.lastRefilledDate}
                />
              ))}
            </List>
          </Box>
        </SecondLayer>
      </Box>
    </Paper>
  );
};

export default RequestedEquipments;
