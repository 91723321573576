import { axiosInstance } from "./base.api";
import { UserResponse, UsersResponse } from "../models/responses/user.model";
import {
  ModifyUserInfoDto,
  washersRequestParams,
} from "../models/requests/Users";
import { TablePaginationParams } from "../models/table.models";
import { ID } from "../models/utils.models";

export const userApi = {
  getPromoters(params: TablePaginationParams) {
    const config = {
      params: {
        ...params,
      },
    };
    return axiosInstance.get<UsersResponse>("/private/users/promoters", config);
  },

  getClients(params: TablePaginationParams) {
    const config = {
      params: {
        ...params,
        radius: 0,
      },
    };
    return axiosInstance.get<UsersResponse>("/private/users/clients", config);
  },

  getWashers(params: washersRequestParams) {
    const config = {
      params: {
        ...params,
        radius: 0,
      },
    };
    return axiosInstance.get<UsersResponse>("/private/users/washers", config);
  },

  getCompanyWashers(params: washersRequestParams) {
    const config = {
      params,
    };
    return axiosInstance.get<UsersResponse>(
      "/private/users/company-washers",
      config
    );
  },

  getMyUser() {
    return axiosInstance.get<UserResponse>("/private/users/me");
  },

  getUserById(id: ID) {
    return axiosInstance.get<UserResponse>(`/private/users/${id}`);
  },

  updateUserPersonalData(data: Partial<ModifyUserInfoDto>, id?: ID) {
    return axiosInstance.patch<UserResponse>(
      `/private/users/${id || "me"}`,
      data
    );
  },
};
