import React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    volumeValue: {
      color: colors.green,
      fontSize: 16,
      fontWeight: 300,
      whiteSpace: "nowrap",
    },
  })
);

interface OwnProps {
  title: string;
  value: string;
}

type Props = OwnProps;

const StatisticComponent: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Box mr={2}>
      <Typography variant="subtitle1" color="textSecondary" noWrap>
        {props.title}
      </Typography>
      <span className={classes.volumeValue}>
        {props.value.split(":").join(" : ")}
      </span>
    </Box>
  );
};

export default StatisticComponent;
