import React from "react";
import getCarIconByType from "./icons/CarTypes/getCarIconByType";
import { Box, Typography } from "@material-ui/core";
import { CarTypes } from "../models/responses/car.model";

interface OwnProps {
  type: CarTypes;
  model: string;
}

type Props = OwnProps;

const CarWithModel: React.FC<Props> = (props) => {
  return (
    <Box display="flex" flexDirection="column" color="primary.main">
      {getCarIconByType(props.type)}
      <Typography variant="h6" color={"primary"} style={{ marginTop: 5 }}>
        {props.model}
      </Typography>
    </Box>
  );
};

export default CarWithModel;
