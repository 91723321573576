import React from "react";
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import colors from "../../constants/colors";
import PackageItem from "./PackageItem";
import { Package } from "../../models/responses/package.model";
import OrderProgress from "../OrdersList/OrderProgress";
import texts from "../../constants/texts";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: theme.spacing(44),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  packageItem: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(4),
    paddingRight: theme.spacing(3),
    color: colors.lightBlueGrey,
  },
  packageIcon: {
    maxWidth: theme.spacing(5),
    maxHeight: theme.spacing(3),
  },
  packageTitle: {
    color: theme.palette.primary.main,
    fontSize: "12px",
    fontWeight: 300,
    marginTop: theme.spacing(2),
  },
  inProgressContainer: {
    minHeight: theme.spacing(7),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.03)",
  },
}));

interface OwnProps {
  packages: Package[];
  startTime: Date | null;
  isInProgress: boolean;
}

type Props = OwnProps;

const WashProcess: React.FC<Props> = ({
  packages,
  startTime,
  isInProgress,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Box ml={3} mb={1}>
        <Typography variant="h4">{texts.orderDetails.washProcess}</Typography>
      </Box>
      <Grid container>
        {isInProgress && startTime && (
          <Grid item xs={12} container className={classes.inProgressContainer}>
            <OrderProgress packages={packages} startTime={startTime} />
          </Grid>
        )}
        {packages.map((pack, index, arr) => (
          <PackageItem
            key={pack.id}
            servicePack={pack}
            bb={arr.length > 4 && arr.length - index > 3}
          />
        ))}
      </Grid>
    </Paper>
  );
};

export default WashProcess;
