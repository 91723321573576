import { createStyles, makeStyles, Theme } from "@material-ui/core";
import colors from "../../../../../constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.03)",
    },
    label: {
      paddingLeft: theme.spacing(2),
      position: "relative",
      "&::before": {
        content: '""',
        display: "block",
        position: "absolute",
        left: 0,
        top: `calc(50% - ${theme.spacing(1) / 2}px)`,
        height: theme.spacing(1),
        width: theme.spacing(1),
        borderRadius: 50,
      },
    },
    redLabel: {
      "&::before": {
        backgroundColor: colors.darkRed,
      },
    },
    greenLabel: {
      "&::before": {
        backgroundColor: colors.green,
      },
    },
    divider: {
      height: theme.spacing(5),
    },
  })
);
