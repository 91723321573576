import { Package } from "../../models/responses/package.model";
import { typedEnv } from "../../typed-env";
import { Currency, ID } from "../../models/utils.models";

export function numberWithSpaces(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatCurrency(n: number, currency?: Currency) {
  const options = currency
    ? {
        style: "currency",
        currency: currency,
        maximumFractionDigits: 2,
      }
    : undefined;
  return new Intl.NumberFormat("en", options).format(n / 100);
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatPhoneNumber(tel: string, isVisible: boolean = true) {
  const len = tel.length;
  if (!isVisible) {
    tel = tel.slice(0, len - 9) + "•••••••";
  }
  return `${tel.slice(0, len - 9)} ${tel.slice(len - 9, len)}`;
}

export function sortOrderPackages(packages: Package[]) {
  const statusPriorities = {
    CREATED: 3,
    START: 2,
    COMPLETE: 1,
  };
  return packages.sort((a, b) => {
    return statusPriorities[a.status] - statusPriorities[b.status];
  });
}

export const parseRating = (value: number) => {
  return value.toFixed(1);
};

export function formatBytes(bytes: number, decimals = 1) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getImageUrl(imageFileId: ID, size?: number) {
  return `${typedEnv.REACT_APP_BASE_URL}/files/image/${imageFileId}${
    size ? `?size=${size}` : ""
  }`;
}

export function getRandomElementsFromArray<T>(arr: T[], n: number): T[] {
  let result = new Array(n);
  let len = arr.length;
  let taken = new Array(len);
  if (n > len) {
    throw new RangeError("getRandom: more elements taken than available");
  }
  while (n--) {
    const x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}
