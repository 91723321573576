import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import CustomButton from "../shared/CustomButton";
import { useSelector } from "react-redux";
import { AppState } from "../store/reducers";
import useActions from "../hooks/useActions";
import { AppActions } from "../store/actions/app.actions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const ConfirmationDialog: React.FC = () => {
  const classes = useStyles();
  const [message, setMessage] = useState();
  const [error, setError] = useState("");
  const appState = useSelector((state: AppState) => state.app);
  const appActions = useActions<typeof AppActions>(AppActions);
  const handleSubmit = () => {
    if (!message && appState.confirmationDialog.withReason) {
      setError("Rejection reason shouldn't be empty");
    } else {
      appState.confirmationDialog.onSubmit(message);
      appActions.closeConfirmationDialog();
    }
  };
  return (
    <Dialog
      open={appState.confirmationDialog.open}
      onClose={appActions.closeConfirmationDialog}
      classes={{ paper: classes.root }}
    >
      <DialogTitle disableTypography>
        {appState.confirmationDialog.title}
      </DialogTitle>
      <DialogContent>
        <Typography>{appState.confirmationDialog.description}</Typography>
        {appState.confirmationDialog.withReason && (
          <TextField
            onChange={(event) => {
              setMessage(event.target.value);
              setError("");
            }}
            value={message}
            autoFocus
            margin="dense"
            id="message"
            label="Rejection reason"
            multiline
            fullWidth
            error={!!error}
            helperText={error}
          />
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={appActions.closeConfirmationDialog}
          color="primary"
        >
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSubmit} color="primary" disabled={!!error}>
          Submit
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
