import { withStyles } from "@material-ui/core/styles";
import { Radio, RadioProps } from "@material-ui/core";
import colors from "../../constants/colors";
import React from "react";

export const GreenRadio = withStyles({
  root: {
    color: colors.lightGreen,
    "&$checked": {
      color: colors.green,
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);
