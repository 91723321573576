import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingLeft: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    divider: {
      marginRight: theme.spacing(2.5),
    },
    textContainer: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    actionLayer: {
      position: "relative",
    },
  })
);
