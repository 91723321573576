import React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import deliveryImage from "../../Images/delivery.png";
import { unitSize } from "../../constants/sizes";
import texts from "../../constants/texts";
import { FormikHelpers, useFormik } from "formik";
import { deliveryAddressSchema } from "../../helpers/validations";
import InputWithAdornment from "../../shared/InputWithAdornment";
import CustomButton from "../../shared/CustomButton";
import { updateWasherDeliveryAddressPayloadModel } from "../../store/actions/equipment.actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerPaper: {
      minHeight: theme.spacing(13),
      padding: theme.spacing(4, 3, 4, 4),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    deliveryIcon: {
      height: theme.spacing(4),
    },
    headerInput: {
      width: theme.spacing(23),
      marginRight: theme.spacing(1),
    },
  })
);

interface OwnProps {
  phoneNumber: string;
  address: string;
  onSubmit?: (data: updateWasherDeliveryAddressPayloadModel) => void;
  loading?: boolean;
}

type Props = OwnProps;

const DeliveryAddress: React.FC<Props> = (props) => {
  const classes = useStyles();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      deliveryPhoneNumber: props.phoneNumber,
      deliveryAddress: props.address,
    },
    onSubmit: handleFormSubmit,
    validationSchema: deliveryAddressSchema,
    initialErrors: {
      deliveryPhoneNumber: "init",
      deliveryAddress: "init",
    },
  });

  function handleFormSubmit(
    values: updateWasherDeliveryAddressPayloadModel,
    actions: FormikHelpers<any>
  ) {
    if (props.onSubmit !== undefined) {
      props.onSubmit(values);
    }
    actions.setSubmitting(false);
  }

  return (
    <Paper className={classes.headerPaper}>
      <Box display="flex" alignItems="center">
        <img
          src={deliveryImage}
          alt="delivery"
          className={classes.deliveryIcon}
        />
        <Box ml={2} maxWidth={unitSize * 39} mr={1}>
          <Typography variant="h4" component="h3" gutterBottom>
            {texts.washerEquipments.contact.address}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {texts.washerEquipments.contact.addressDescription}
          </Typography>
        </Box>
      </Box>
      <InputWithAdornment
        className={classes.headerInput}
        adornment={texts.washerEquipments.contact.phoneAdornment}
        type="tel"
        onChange={(event) =>
          formik.setFieldValue(
            "deliveryPhoneNumber",
            event.target.value.replace(/[^+0-9]/g, "")
          )
        }
        onBlur={formik.handleBlur}
        helperText={
          formik.errors.deliveryPhoneNumber &&
          formik.touched.deliveryPhoneNumber &&
          formik.errors.deliveryPhoneNumber
        }
        error={
          !!formik.errors.deliveryPhoneNumber &&
          formik.touched.deliveryPhoneNumber
        }
        value={formik.values.deliveryPhoneNumber}
        disabled={!props.onSubmit}
        id="deliveryPhoneNumber"
        name="deliveryPhoneNumber"
      />
      <InputWithAdornment
        className={classes.headerInput}
        adornment={texts.washerEquipments.contact.addressAdornment}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        helperText={
          formik.errors.deliveryAddress &&
          formik.touched.deliveryAddress &&
          formik.errors.deliveryAddress
        }
        error={
          !!formik.errors.deliveryAddress && formik.touched.deliveryAddress
        }
        value={formik.values.deliveryAddress}
        disabled={!props.onSubmit}
        id="deliveryAddress"
        name="deliveryAddress"
      />
      {!!props.onSubmit && (
        <Box>
          <CustomButton
            color="secondary"
            disabled={!formik.isValid || props.loading}
            variant="contained"
            type="submit"
            onClick={() => formik.handleSubmit()}
            loading={props.loading}
          >
            {texts.washerEquipments.contact.saveButton}
          </CustomButton>
        </Box>
      )}
    </Paper>
  );
};

export default DeliveryAddress;
