export const examQuestions = [
  {
    question:
      "If you are cleaning the interior with liquid, where should you spray it on?",
    variants: {
      a: "Dirty details",
      b: "Microfibre cloth",
      c: "Doors",
    },
    correctAnswer: "b",
  },
  {
    question: "If you find something in the car, where should you place it?",
    variants: {
      a: "Put it in our washerly paper bags and leave it on the front seat",
      b: "Throw them out",
      c: "Keep it",
    },
    correctAnswer: "a",
  },
  {
    question: "What should you do before turning on the app?",
    variants: {
      a:
        "Make sure you have all equipment ready and that all batteries are charged",
      b: "Just turn on the app and start",
    },
    correctAnswer: "a",
  },
  // {
  //   question: "What is the brush used for?",
  //   variants: {
  //     a: "Windows",
  //     b: "For small details that you cant reach.",
  //     c: "Interior",
  //   },
  //   correctAnswer: "b",
  // },
  // {
  //   question:
  //     "How many microfibre cloths should you use to wash the interior and exterior of one car?",
  //   variants: {
  //     a: "2-3 pieces",
  //     b: "6-7 pieces",
  //   },
  //   correctAnswer: "b",
  // },
  // {
  //   question: "How much purple nano should be used in exterior cleaning?",
  //   variants: {
  //     a: "300-600 ml",
  //     b: "1 liter",
  //   },
  //   correctAnswer: "a",
  // },
  // {
  //   question: "How long should it take you to clean the exterior?",
  //   variants: {
  //     a: "2 hours",
  //     b: "20 minutes",
  //     c: "1 hour",
  //   },
  //   correctAnswer: "c",
  // },
  // {
  //   question: "How long should it take you to clean the interior?",
  //   variants: {
  //     a: "15 minutes",
  //     b: "40 minutes",
  //     c: "1 hour and 20 minutes",
  //   },
  //   correctAnswer: "b",
  // },
  // {
  //   question: "What do you do with a dirty microfibre cloth?",
  //   variants: {
  //     a:
  //       "Wash it at 30C degrees with no soap added to the washing machine or hand wash it",
  //     b: "Regular wash in a washing machine",
  //     c: "You don’t need to wash them",
  //   },
  //   correctAnswer: "a",
  // },
  // {
  //   question: "What to do if you run out of some kind of equipment?",
  //   variants: {
  //     a: "Contact the support centre",
  //     b: "Continue working",
  //   },
  //   correctAnswer: "a",
  // },
  // {
  //   question:
  //     "What to do if u cant find the client’s car or it’s in a bad location?",
  //   variants: {
  //     a: "Call support/call client",
  //     b: "Cancel order",
  //   },
  //   correctAnswer: "a",
  // },
  // {
  //   question: "What do you do after completing a wash?",
  //   variants: {
  //     a: "Upload car photos",
  //     b: "Turn off the app",
  //   },
  //   correctAnswer: "a",
  // },
  // {
  //   question: "What should you do if it starts raining?",
  //   variants: {
  //     a: "Cancel order",
  //     b: "Call support",
  //   },
  //   correctAnswer: "b",
  // },
];
