import { put, takeLatest } from "@redux-saga/core/effects";
import {
  getReviewsForActiveUserType,
  ReviewsActions,
  ReviewsActionTypes,
} from "../actions/reviews.actions";
import { reviewApi } from "../../api/review.api";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import { Reviews } from "../../models/responses/review.model";
import { showError } from "./utils";

function* getReviewsForUser(action: getReviewsForActiveUserType) {
  try {
    const { data } = yield reviewApi.getReviewsByUserId(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const reviews = yield transformAndValidate(Reviews, data.data);
      yield put(ReviewsActions.getReviewsForActiveUserSuccess(reviews));
    }
  } catch (e) {
    yield showError(e);
    yield put(ReviewsActions.getReviewsForActiveUserFailure(e.message));
  }
}

export function* ReviewsSagas() {
  yield takeLatest(
    ReviewsActionTypes.GET_REVIEWS_FOR_ACTIVE_USER,
    getReviewsForUser
  );
}
