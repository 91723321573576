import { Logs } from "../../models/responses/log.model";
import { LogActions, LogActionTypes } from "../actions/log.actions";

class StateModel {
  logs: Logs = { items: [], total: 0 };
  logsLoading = false;
  logsError = "";
}

const initialState = new StateModel();

export function logReducer(
  state = initialState,
  action: LogActions
): StateModel {
  switch (action.type) {
    case LogActionTypes.GET_USER_LOGS:
      return {
        ...state,
        logsError: "",
        logsLoading: true,
      };
    case LogActionTypes.GET_USER_LOGS_SUCCESS:
      return {
        ...state,
        logsLoading: false,
        logs: action.payload,
      };
    case LogActionTypes.GET_USER_LOGS_FAILURE:
      return {
        ...state,
        logsLoading: false,
        logsError: action.payload,
      };
    default:
      return state;
  }
}
