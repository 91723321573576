import { RouteNames } from "../../constants/routeNames";
import { UserRoutes } from "../../models/router.models";
import WasherEquipment from "../../pages/washer/WasherEquipment";
import WasherDashboard from "../../pages/washer/WasherDashboard";
import WasherOrders from "../../pages/washer/WasherOrders";
import WasherPayouts from "../../pages/washer/WasherPayouts";
import WasherHistory from "../../pages/washer/WasherHistory";
import WasherLogs from "../../pages/washer/WasherLogs/WasherLogs";
import Settings from "../../pages/Settings";

const washerRoutes: UserRoutes = {
  routes: [
    {
      path: `/${RouteNames.DASHBOARD}`,
      component: WasherDashboard,
      exact: true,
    },
    {
      path: `/${RouteNames.EQUIPMENT}`,
      component: WasherEquipment,
      exact: true,
    },
    {
      path: `/${RouteNames.ORDERS}`,
      component: WasherOrders,
    },
    {
      path: `/${RouteNames.PAYOUTS}`,
      component: WasherPayouts,
    },
    {
      path: `/${RouteNames.HISTORY}`,
      component: WasherHistory,
    },
    {
      path: `/${RouteNames.LOGS}`,
      component: WasherLogs,
    },
    {
      path: `/${RouteNames.SETTINGS}`,
      component: Settings,
    },
  ],
  defaultRoute: `/${RouteNames.DASHBOARD}`,
};

export default washerRoutes;
