import { createStyles, makeStyles, Theme } from "@material-ui/core";
import colors from "../../../../../constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingLeft: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    divider: {
      marginRight: theme.spacing(2.5),
    },
    textContainer: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    actionLayer: {
      position: "relative",
    },
    icon: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "transparent",
        color: colors.green,
      },
    },
  })
);
