import { RefObject, useEffect, useRef, useState } from "react";

export default function useVisible(): [RefObject<HTMLElement>, boolean] {
  const [isVisible, setIsVisible] = useState(false);
  const element = useRef<HTMLElement>(null);

  const checkProgressVisible = () => {
    if (element !== null && element.current) {
      const elementPosition = element.current.getBoundingClientRect();
      if (window.innerHeight > elementPosition.top + elementPosition.height) {
        window.removeEventListener("scroll", checkProgressVisible);
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkProgressVisible, true);
    checkProgressVisible();
    return () => window.removeEventListener("scroll", checkProgressVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [element, isVisible];
}
