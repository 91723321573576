import React, { useCallback } from "react";
import TableLayout from "../../../../../shared/sharedTable/TableLayout";
import { TablePaginationParams } from "../../../../../models/table.models";
import headCells from "./headCells";
import PromoterPayoutRow from "../PromoterPayoutRow";
import RequestPayoutButton from "../RequestPayoutButton";
import useActions from "../../../../../hooks/useActions";
import { PayoutsActions } from "../../../../../store/actions/payouts.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../store/reducers";
import { payoutsSelectors } from "../../../../../store/reducers/payouts.reducer";

interface OwnProps {
  title: string;
  subtitle: string;
  isCompleted?: boolean;
}

type Props = OwnProps;

const PromoterPayoutsList: React.FC<Props> = (props) => {
  const payoutsActions = useActions<typeof PayoutsActions>(PayoutsActions);
  const payoutState = useSelector((state: AppState) => state.payouts);

  const formattedData = payoutsSelectors.getPayoutsDataForWasher(payoutState);
  const status = props.isCompleted ? "PAID_OR_REJECTED" : "REQUESTED";
  const handlePaginationChange = useCallback(
    (values: TablePaginationParams) => {
      payoutsActions.getPayoutsForUser({ ...values, status });
    },
    [status, payoutsActions]
  );

  return (
    <TableLayout
      title={props.title}
      subtitle={props.subtitle}
      isLoading={payoutState.payoutsLoading}
      total={payoutState.payouts.total}
      headCells={headCells}
      onPaginationChange={handlePaginationChange}
      headCellsButton={!props.isCompleted && <RequestPayoutButton />}
    >
      {formattedData.map((item) => (
        <PromoterPayoutRow columns={headCells} row={item} />
      ))}
    </TableLayout>
  );
};

export default PromoterPayoutsList;
