import React, { SVGProps } from "react";

type Props = SVGProps<any>;

const CheckedListIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.013"
      height="19.032"
      viewBox="0 0 18.013 19.032"
      {...props}
    >
      <g id="list_2_" data-name="list (2)" transform="translate(3.013 -10.983)">
        <g id="Group_292" data-name="Group 292" transform="translate(5)">
          <path
            id="shopping-list"
            d="M85.635,88.679a1.07,1.07,0,0,0-1.492.259l-1.528,2.17-.8-.8A1.071,1.071,0,1,0,80.3,91.809l1.21,1.222.016.016a1.665,1.665,0,0,0,1.156.468c.036,0,.073,0,.109,0a1.667,1.667,0,0,0,1.194-.629c.012-.016.024-.031.035-.048l1.876-2.664A1.071,1.071,0,0,0,85.635,88.679Z"
            transform="translate(-88 -77.5)"
            fill="currentColor"
          />
          <path
            id="shopping-list-2"
            data-name="shopping-list"
            d="M85.635,88.679a1.07,1.07,0,0,0-1.492.259l-1.528,2.17-.8-.8A1.071,1.071,0,1,0,80.3,91.809l1.21,1.222.016.016a1.665,1.665,0,0,0,1.156.468c.036,0,.073,0,.109,0a1.667,1.667,0,0,0,1.194-.629c.012-.016.024-.031.035-.048l1.876-2.664A1.071,1.071,0,0,0,85.635,88.679Z"
            transform="translate(-88 -70.5)"
            fill="currentColor"
          />
          <path
            id="shopping-list-3"
            data-name="shopping-list"
            d="M85.635,88.679a1.07,1.07,0,0,0-1.492.259l-1.528,2.17-.8-.8A1.071,1.071,0,1,0,80.3,91.809l1.21,1.222.016.016a1.665,1.665,0,0,0,1.156.468c.036,0,.073,0,.109,0a1.667,1.667,0,0,0,1.194-.629c.012-.016.024-.031.035-.048l1.876-2.664A1.071,1.071,0,0,0,85.635,88.679Z"
            transform="translate(-88 -63.5)"
            fill="currentColor"
          />
          <rect
            id="Rectangle_276"
            data-name="Rectangle 276"
            width="9"
            height="2"
            rx="1"
            transform="translate(1 13)"
            fill="currentColor"
          />
          <rect
            id="Rectangle_277"
            data-name="Rectangle 277"
            width="9"
            height="2"
            rx="1"
            transform="translate(1 20)"
            fill="currentColor"
          />
          <rect
            id="Rectangle_278"
            data-name="Rectangle 278"
            width="9"
            height="2"
            rx="1"
            transform="translate(1 27)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export default CheckedListIcon;
