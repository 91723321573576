import styled from "styled-components";
import { unitSize } from "../constants/sizes";
import colors from "../constants/colors";

export default styled.div`
  padding: ${unitSize * 0.5}px ${unitSize}px;
  border: 1px solid ${colors.lightGrey};
  border-radius: 4px;
  color: ${colors.textHint};
  font-size: 8px;
  margin-right: ${unitSize}px;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
`;
