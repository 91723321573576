import { Page } from "../../../../models/router.models";
import { RouteNames } from "../../../../constants/routeNames";
import DashboardIcon from "../../../../shared/icons/DashboardIcon";
import texts from "../../../../constants/texts";
import ReferralsIcon from "../../../../shared/icons/ReferralsIcon";
import FinanceIcon from "../../../../shared/icons/FinanceIcon";

const companyAdminMenu: Page[] = [
  {
    title: texts.menu.company.dashboard,
    path: `/${RouteNames.DASHBOARD}`,
    Icon: DashboardIcon,
  },
  {
    title: texts.menu.company.washers,
    path: `/${RouteNames.WASHERS}`,
    Icon: ReferralsIcon,
  },
  {
    title: texts.menu.company.payouts,
    path: `/${RouteNames.PAYOUTS}`,
    Icon: FinanceIcon,
  },
];

export default companyAdminMenu;
