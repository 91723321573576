import React from "react";
import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import SearchIcon from "../../shared/icons/SearchIcon";
import { unitSize } from "../../constants/sizes";
import { capitalizeFirstLetter } from "../../helpers/utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      height: theme.spacing(9),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      alignItems: "center",
    },
    textField: {
      paddingLeft: theme.spacing(3),
      flexGrow: 1,
      fontSize: 12,
    },
    input: {
      "& input": {
        "&::placeholder": {
          fontWeight: 300,
          fontSize: 12,
          color: theme.palette.text.secondary,
        },
      },
    },
  })
);

interface OwnProps {
  handleSearchChange: (value: string) => void;
  count: number;
  type: string;
  searchValues: string[];
}

type Props = OwnProps;

const ListSearch: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
        maxWidth={unitSize * 14.5}
        height={unitSize * 3}
      >
        <SearchIcon />
        <Typography variant={"body1"} color={"textPrimary"}>
          Find {capitalizeFirstLetter(props.type)}
        </Typography>
        <Divider orientation="vertical" />
      </Box>
      <TextField
        onChange={(event) => props.handleSearchChange(event.target.value)}
        className={classes.textField}
        type="text"
        id="outlined-full-width"
        style={{ margin: 8 }}
        placeholder={props.searchValues.join(", ")}
        margin="normal"
        InputProps={{
          className: classes.input,
          disableUnderline: true,
        }}
      />
      <Typography variant={"caption"} color={"textPrimary"}>
        Found: {props.count} {capitalizeFirstLetter(props.type)}s
      </Typography>
    </Paper>
  );
};

export default ListSearch;
