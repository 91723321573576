import { Page } from "../../../../models/router.models";
import texts from "../../../../constants/texts";
import { RouteNames } from "../../../../constants/routeNames";
import DashboardIcon from "../../../../shared/icons/DashboardIcon";
import ReferralsIcon from "../../../../shared/icons/ReferralsIcon";
import FinanceIcon from "../../../../shared/icons/FinanceIcon";

const promoterMenu: Page[] = [
  {
    title: texts.roles.promoter.menu.dashboard,
    path: `/${RouteNames.DASHBOARD}`,
    Icon: DashboardIcon,
  },
  {
    title: texts.roles.promoter.menu.referrals,
    path: `/${RouteNames.REFERRALS}`,
    Icon: ReferralsIcon,
  },
  {
    title: texts.roles.promoter.menu.payouts,
    path: `/${RouteNames.PAYOUTS}`,
    Icon: FinanceIcon,
  },
];

export default promoterMenu;
