import React from "react";

interface OwnProps {}

type Props = OwnProps;

const SearchIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="15.32"
      height="15.324"
      viewBox="0 0 15.32 15.324"
    >
      <g id="magnifying-glass" transform="translate(-183.05 -121)">
        <path
          id="Path_404"
          data-name="Path 404"
          d="M15.238,14.542l-3.724-3.724a6.539,6.539,0,1,0-.646.646l3.724,3.724a.462.462,0,0,0,.323.136.448.448,0,0,0,.323-.136A.459.459,0,0,0,15.238,14.542ZM.964,6.534a5.616,5.616,0,1,1,5.616,5.62A5.623,5.623,0,0,1,.964,6.534Z"
          transform="translate(183 121)"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
