import React from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  Order,
  OrderStatus,
} from "../../../../../models/responses/order.model";
import {
  HeadCell,
  OrderTableColumns,
} from "../../../../../models/table.models";
import { formatDate } from "../../../../../helpers/utils/date.utils";
import { unitSize } from "../../../../../constants/sizes";
import OrderProgress from "../../../../../components/OrdersList/OrderProgress";
import CarWithModel from "../../../../../shared/CarWithModel";
import { sortOrderPackages } from "../../../../../helpers/utils/utils";
import OrderOptions from "../../../../../shared/OrderOptions";
import IdCell from "../../../../../shared/sharedTable/IdCell";
import ArrowRightIcon from "../../../../../shared/icons/ArrowRightIcon";
import { useHistory } from "react-router-dom";
import { RouteNames } from "../../../../../constants/routeNames";
import { useStyles } from "./style";

interface OwnProps {
  order: Order;
  headCells: HeadCell<OrderTableColumns>[];
}

type Props = OwnProps;

const MyOrdersListRow: React.FC<Props> = ({ headCells, order }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleOpenDetails = () => {
    history.push(
      `${RouteNames.ORDERS}/${RouteNames.NON_FUTURE_WASH}${order.id}`
    );
  };
  const renderCell = (id: OrderTableColumns) => {
    switch (id) {
      case OrderTableColumns.ID: {
        return <IdCell id={order.id} />;
      }
      case OrderTableColumns.CAR: {
        return (
          <CarWithModel type={order.car.carType.name} model={order.car.model} />
        );
      }
      case OrderTableColumns.DATE: {
        const [date, time] = formatDate(order.scheduledTime);
        return (
          <Box display="flex" flexDirection="column">
            <Typography variant="body1" color="textPrimary">
              {date}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {time}
            </Typography>
          </Box>
        );
      }
      case OrderTableColumns.OPTIONS: {
        return <OrderOptions packages={order.packages} />;
      }

      default:
        return null;
    }
  };
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container className={classes.rowContainer}>
          {headCells.map(({ id, size }, index) => (
            <Grid key={id} item xs={size}>
              <Box
                minHeight={unitSize * 11}
                pr={2}
                display="flex"
                alignItems="center"
              >
                {index > 0 && (
                  <Divider className={classes.divider} orientation="vertical" />
                )}
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  className={classes.textContainer}
                >
                  {renderCell(id)}
                </Box>
              </Box>
            </Grid>
          ))}
          <Grid item xs={1}>
            <Box
              pr={4}
              height={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                className={classes.arrowIcon}
                onClick={handleOpenDetails}
              >
                <ArrowRightIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        {order.status === OrderStatus.IN_PROGRESS && order.startTime && (
          <Grid container className={classes.inProgressContainer}>
            <OrderProgress
              packages={sortOrderPackages(order.packages)}
              startTime={order.startTime}
            />
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default MyOrdersListRow;
