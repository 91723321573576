export default {
  hot: [
    "CFXfSBR5Q9w",
    "PugdSwF9d9w",
    "donC_3mKyZQ",
    "rZu09GXvJto",
    "NRTGnf3uJ0M",
  ],
  common: [
    "yponMLIyFcI",
    "fedki5R_-vo",
    "fN9k81hpmMY",
    "EB_m9PV6sn0",
    "yponMLIyFcI",
    "fedki5R_-vo",
    "zA4TbroWsFQ",
  ],
};
