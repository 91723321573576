import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(4),
    display: "flex",
  },
  avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
  divider: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    height: theme.spacing(3),
  },
}));
