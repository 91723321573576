import React from "react";
import CircleChart from "../../../../../shared/CircleChart/CircleChart";
import { Box, Divider, Typography } from "@material-ui/core";
import colors from "../../../../../constants/colors";
import clsx from "clsx";
import CheckedListIcon from "../../../../../shared/icons/CheckedListIcon";
import RejectedListIcon from "../../../../../shared/icons/RejectedListIcon";
import { getPercent } from "../../../../../helpers/utils/arithmetic.utils";
import { useStyles } from "./style";

interface OwnProps {
  accepted: number;
  declined: number;
}

type Props = OwnProps;

const MyOrdersHeader: React.FC<Props> = (props) => {
  const classes = useStyles();
  const percent = getPercent(props.accepted, props.declined)[0];
  return (
    <Box
      pl={2}
      pr={5}
      pt={3}
      pb={3}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classes.root}
    >
      <Box display="flex" alignItems="center">
        <CircleChart percent={percent} />
        <Box ml={2} display="flex" flexDirection="column">
          <Typography
            variant="body1"
            color="textPrimary"
            className={clsx(classes.label, classes.greenLabel)}
            gutterBottom
          >
            Accepted orders
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            className={clsx(classes.label, classes.redLabel)}
          >
            Declined orders
          </Typography>
        </Box>
      </Box>
      <Divider orientation="vertical" className={classes.divider} />
      <Box display="flex" alignItems="center">
        <CheckedListIcon color={colors.green} />
        <Box ml={3}>
          <Typography color="primary" variant="h4">
            {props.accepted}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Accepted orders
          </Typography>
        </Box>
      </Box>
      <Divider orientation="vertical" className={classes.divider} />
      <Box display="flex" alignItems="center">
        <RejectedListIcon color={colors.darkRed} />
        <Box ml={3}>
          <Typography color="primary" variant="h4">
            {props.declined}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Rejected orders
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MyOrdersHeader;
