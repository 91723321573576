import React from "react";
import CopyToClipboard from "../CopyToClipboard";
import { Typography } from "@material-ui/core";
import { ID } from "../../models/utils.models";

interface OwnProps {
  id: ID;
  prefix?: string;
}

type Props = OwnProps;

const IdCell: React.FC<Props> = (props) => {
  return (
    <CopyToClipboard>
      {({ copy }) => (
        <Typography
          variant="body1"
          color="textPrimary"
          noWrap
          style={{ cursor: "pointer" }}
          onClick={() => copy(props.id)}
        >
          {props.prefix} {props.id}
        </Typography>
      )}
    </CopyToClipboard>
  );
};

export default IdCell;
