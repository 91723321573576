import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import EquipmentsList from "./components/WasherEquipmentsList";
import DeliveryAddress from "../../../components/equipments/DeliveryAddress";
import useActions from "../../../hooks/useActions";
import { EquipmentActions } from "../../../store/actions/equipment.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import { getUnitType } from "../../../models/responses/equipment.model";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const WasherEquipment: React.FC<Props> = () => {
  useTitle(`Washerly Equipment`);
  const equipmentActions = useActions<typeof EquipmentActions>(
    EquipmentActions
  );
  const equipmentState = useSelector((state: AppState) => state.equipment);
  const authState = useSelector((state: AppState) => state.auth);
  useEffect(() => {
    equipmentActions.getListOfAWasherEquipment();
  }, [equipmentActions]);
  const items = equipmentState.washerEquipments.items.filter(
    (item) => getUnitType(item.equipment.valueUnit) === "item"
  );
  const liquids = equipmentState.washerEquipments.items.filter(
    (item) => getUnitType(item.equipment.valueUnit) === "liquid"
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DeliveryAddress
          phoneNumber={
            authState.authorizedUser?.deliveryPhoneNumber ||
            authState.authorizedUser?.phoneNumber ||
            ""
          }
          address={authState.authorizedUser?.deliveryAddress || ""}
          loading={equipmentState.updateDeliveryAddressLoading}
          onSubmit={equipmentActions.updateWasherDeliveryAddress}
        />
      </Grid>
      <Grid item xs={12} container>
        <EquipmentsList
          title="Items"
          subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
          data={items}
        />
      </Grid>
      <Grid item xs={12} container>
        <EquipmentsList
          title="Liquids"
          subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
          data={liquids}
        />
      </Grid>
    </Grid>
  );
};

export default WasherEquipment;
