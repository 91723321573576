import React, { useEffect } from "react";
import TitledPaper from "../../../../../shared/TitledPaper";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import useActions from "../../../../../hooks/useActions";
import { OrdersActions } from "../../../../../store/actions/orders.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../store/reducers";
import CarWithModel from "../../../../../shared/CarWithModel";
import {
  formatDate,
  getFormattedTimeDuration,
  getTimeDifference,
} from "../../../../../helpers/utils/date.utils";
import OrderOptions from "../../../../../shared/OrderOptions";
import moment from "moment";
import { useStyles } from "./style";
import texts from "../../../../../constants/texts";
import EmptyData from "../../../../../components/EmptyData";
import EmptyOrderIcon from "../../../../../shared/icons/empty/EmptyOrderIcon";
import { useRoleOrderPrice } from "../../../../../hooks/useRoleOrderPrice";

interface OwnProps {}

type Props = OwnProps;

const LastOrder: React.FC<Props> = () => {
  const classes = useStyles();
  const ordersActions = useActions<typeof OrdersActions>(OrdersActions);
  const { lastCompleteOrder, lastCompleteOrderLoading } = useSelector(
    (state: AppState) => state.orders
  );
  const orderPrice = useRoleOrderPrice(lastCompleteOrder);
  useEffect(() => {
    ordersActions.getLastCompleteOrder();
  }, [ordersActions]);
  if (!lastCompleteOrder) {
    return (
      <EmptyData
        loading={lastCompleteOrderLoading}
        title={texts.roles.washer.dashboard.lastOrder.title}
        subtitle={texts.roles.washer.dashboard.lastOrder.subtitle}
        emptyText={texts.roles.washer.dashboard.lastOrder.emptyText}
        Icon={EmptyOrderIcon}
      />
    );
  }
  const [date, time] = formatDate(lastCompleteOrder.scheduledTime);
  const calculatedTime = lastCompleteOrder.packages.reduce((acc, pack) => {
    return acc + moment.duration(pack.duration).asMilliseconds();
  }, 0);
  const washTime =
    lastCompleteOrder.endTime && lastCompleteOrder.startTime
      ? getFormattedTimeDuration(
          getTimeDifference(
            lastCompleteOrder.startTime,
            lastCompleteOrder.endTime
          )
        )
      : getFormattedTimeDuration(calculatedTime);
  return (
    <Grid item xs={12}>
      <TitledPaper
        title={texts.roles.washer.dashboard.lastOrder.title}
        subtitle={texts.roles.washer.dashboard.lastOrder.subtitle}
      >
        <Grid container className={classes.container}>
          <Grid item xs={3}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Box display="flex" alignItems="flex-end">
                  <CarWithModel
                    type={lastCompleteOrder.car.carType.name}
                    model={lastCompleteOrder.car.model}
                  />
                  <Box ml={2}>
                    <Typography color="textPrimary">
                      ({lastCompleteOrder.car.licensePlate})
                    </Typography>
                  </Box>
                </Box>
                <Box mt={1}>
                  <Typography variant="body1" color="textSecondary">
                    {date} {time}
                  </Typography>
                </Box>
              </Box>
              <Divider orientation="vertical" className={classes.divider} />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              pl={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h6" color={"primary"}>
                  Options
                </Typography>
                <Box display="flex" flexWrap="wrap" alignItems="center">
                  <OrderOptions packages={lastCompleteOrder.packages} />
                </Box>
              </Box>
              <Divider orientation="vertical" className={classes.divider} />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              pl={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography color="primary" variant="h4" gutterBottom>
                  {washTime}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Total Time
                </Typography>
              </Box>
              <Divider orientation="vertical" className={classes.divider} />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box pl={3} display="flex" alignItems="center">
              <Box>
                <Typography color="primary" variant="h4" gutterBottom>
                  {orderPrice}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Price
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </TitledPaper>
    </Grid>
  );
};

export default LastOrder;
