import { BasicModel } from "./basic.model";
import { IsEnum, IsOptional, IsString, ValidateNested } from "class-validator";
import { ID } from "../utils.models";
import { Type } from "class-transformer";
import { User } from "./user.model";

export enum COUPON_STATUS {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
}

export class Coupon extends BasicModel {
  @IsString()
  code: string;
  @IsString()
  ownerId: ID;
  @IsEnum(COUPON_STATUS)
  status: COUPON_STATUS;
  @IsOptional()
  @IsString()
  expiredTime?: string;
  @IsOptional()
  @ValidateNested()
  @Type(() => User)
  owner?: User;
}
