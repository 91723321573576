import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import { GetWasherLogsParams } from "../../models/requests/Logs";
import { Logs } from "../../models/responses/log.model";

export enum LogActionTypes {
  GET_USER_LOGS = "[Log] Get user logs",
  GET_USER_LOGS_SUCCESS = "[Log] Get user logs success",
  GET_USER_LOGS_FAILURE = "[Log] Get user logs failure",
}

export const LogActions = {
  getUserLogs: (payload: GetWasherLogsParams) =>
    createAction(LogActionTypes.GET_USER_LOGS, payload),
  getUserLogsSuccess: (payload: Logs) =>
    createAction(LogActionTypes.GET_USER_LOGS_SUCCESS, payload),
  getUserLogsFailure: (payload: string) =>
    createAction(LogActionTypes.GET_USER_LOGS_FAILURE, payload),
};

export type LogActions = ActionsUnion<typeof LogActions>;

export type getUserLogsType = ActionsOfType<
  LogActions,
  LogActionTypes.GET_USER_LOGS
>;
