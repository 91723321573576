import { RouteNames } from "../../constants/routeNames";
import { UserRoutes } from "../../models/router.models";
import CompanyDashboard from "../../pages/companyAdmin/Dashboard";
import Washers from "../../pages/companyAdmin/Washers";
import Settings from "../../pages/Settings";
import CompanyPayouts from "../../pages/companyAdmin/CompanyPayouts";

const companyAdminRoutes: UserRoutes = {
  routes: [
    {
      path: `/${RouteNames.DASHBOARD}`,
      component: CompanyDashboard,
      exact: true,
    },
    {
      path: `/${RouteNames.WASHERS}`,
      component: Washers,
    },
    {
      path: `/${RouteNames.PAYOUTS}`,
      component: CompanyPayouts,
    },
    {
      path: `/${RouteNames.SETTINGS}`,
      component: Settings,
    },
  ],
  defaultRoute: `/${RouteNames.DASHBOARD}`,
};

export default companyAdminRoutes;
