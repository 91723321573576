import { HeadCell, OrderTableColumns } from "../../models/table.models";
import texts from "../../constants/texts";

const orderListHeadCells: HeadCell<OrderTableColumns>[] = [
  {
    id: OrderTableColumns.ID,
    label: texts.orders.tableHeaders.id,
    size: 1,
  },
  {
    id: OrderTableColumns.CAR,
    label: texts.orders.tableHeaders.car,
    size: 1,
  },
  {
    id: OrderTableColumns.STATUS,
    label: texts.orders.tableHeaders.status,
    size: 1,
  },
  {
    id: OrderTableColumns.DATE,
    label: texts.orders.tableHeaders.date,
    size: 2,
  },
  {
    id: OrderTableColumns.TIME,
    label: texts.orders.tableHeaders.time,
    size: 1,
  },
  {
    id: OrderTableColumns.OPTIONS,
    label: texts.orders.tableHeaders.options,
    size: 3,
  },
];

export default orderListHeadCells;
