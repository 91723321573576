import DashboardIcon from "../../../../shared/icons/DashboardIcon";
import ClientsIcon from "../../../../shared/icons/ClientsIcon";
import WashersIcon from "../../../../shared/icons/WashersIcon";
import StatisticsIcon from "../../../../shared/icons/StatisticsIcon";
import { RouteNames } from "../../../../constants/routeNames";
import { Page } from "../../../../models/router.models";
import texts from "../../../../constants/texts";
import FinanceIcon from "../../../../shared/icons/FinanceIcon";
import EquipmentIcon from "../../../../shared/icons/EquipmentIcon";
import PhoneLinkSetupRoundedIcon from "@material-ui/icons/PhonelinkSetupRounded";
import MapIcon from "@material-ui/icons/Map";
import OrdersIcon from "../../../../shared/icons/OrdersIcon";

const adminMenu: Page[] = [
  {
    title: texts.roles.admin.menu.dashboard,
    path: `/${RouteNames.DASHBOARD}`,
    Icon: DashboardIcon,
    disabled: true,
  },
  {
    title: texts.roles.admin.menu.promoters,
    path: `/${RouteNames.PROMOTERS}`,
    Icon: ClientsIcon,
  },
  {
    title: texts.roles.admin.menu.clients,
    path: `/${RouteNames.CLIENTS}`,
    Icon: ClientsIcon,
  },
  {
    title: texts.roles.admin.menu.washers,
    path: `/${RouteNames.WASHERS}`,
    Icon: WashersIcon,
  },
  {
    title: texts.roles.admin.menu.pendingWashers,
    path: `/${RouteNames.PENDING_WASHERS}`,
    Icon: WashersIcon,
  },
  {
    title: texts.roles.admin.menu.orders,
    path: `/${RouteNames.ORDERS}`,
    Icon: OrdersIcon,
  },
  {
    title: texts.roles.admin.menu.statistics,
    path: "/statistics",
    Icon: StatisticsIcon,
    disabled: true,
  },
  {
    title: texts.roles.admin.menu.payouts,
    path: `/${RouteNames.PAYOUTS}`,
    Icon: FinanceIcon,
  },
  {
    title: texts.roles.admin.menu.equipments,
    path: `/${RouteNames.EQUIPMENT}`,
    Icon: EquipmentIcon,
  },
  {
    title: texts.roles.admin.menu.blacklist,
    path: `/${RouteNames.BLACKLIST}`,
    Icon: PhoneLinkSetupRoundedIcon,
  },
  {
    title: texts.roles.admin.menu.pricingMap,
    path: `/${RouteNames.PRICING_MAP}`,
    Icon: MapIcon,
  },
];

export default adminMenu;
