import React from "react";
import NavTabs from "../../../components/NavTabs";
import tabs from "./tabs";
import { RouteNames } from "../../../constants/routeNames";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Grid } from "@material-ui/core";
import PromoterPayoutsList from "./components/PromoterPayoutsList";
import RequestPayout from "./RequestPayout";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const PromoterPayouts: React.FC<Props> = () => {
  useTitle(`Washerly Payouts`);
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/${RouteNames.REQUEST_PAYOUT}`}>
        <RequestPayout />
      </Route>
      <Grid container spacing={2}>
        <NavTabs tabs={tabs}>
          <Switch>
            <Route exact path={`${match.path}/${RouteNames.REQUESTED}`}>
              <PromoterPayoutsList
                title="Requested Payouts"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
              />
            </Route>
            <Route exact path={`${match.path}/${RouteNames.COMPLETED}`}>
              <PromoterPayoutsList
                title="Completed Payouts"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                isCompleted
              />
            </Route>
            <Redirect to={`${match.path}/${RouteNames.REQUESTED}`} />
          </Switch>
        </NavTabs>
      </Grid>
    </Switch>
  );
};

export default PromoterPayouts;
