import { axiosInstance } from "./base.api";
import {
  PayoutResponse,
  PayoutsResponse,
} from "../models/responses/payout.model";
import {
  DispatchWasherPayoutData,
  getAdminPayoutsParams,
  getUserPayoutsParams,
  requestCompanyPayoutsData,
  requestPromoterPayoutData,
  requestWasherPayoutData,
} from "../models/requests/Payouts";
import { ID } from "../models/utils.models";

export const payoutApi = {
  getOwnPayouts(params: getUserPayoutsParams) {
    const config = {
      params,
    };
    return axiosInstance.get<PayoutsResponse>("/private/payouts", config);
  },

  getAdminPayouts(params: getAdminPayoutsParams) {
    const config = {
      params,
    };
    return axiosInstance.get<PayoutsResponse>("/private/payouts/admin", config);
  },

  requestPayoutForWasher(data: requestWasherPayoutData) {
    return axiosInstance.post<PayoutResponse>("/private/payouts/washer", data);
  },

  requestPayoutForPromoter(data: requestPromoterPayoutData) {
    return axiosInstance.post<PayoutResponse>(
      "/private/payouts/promoter",
      data
    );
  },

  requestPayoutForCompany(data: requestCompanyPayoutsData) {
    return axiosInstance.post<PayoutResponse>("/private/payouts/company", data);
  },

  dispatchPayout(id: ID, data: DispatchWasherPayoutData) {
    return axiosInstance.put<PayoutResponse>(`/private/payouts/${id}`, data);
  },
};
