import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const WasherNotFoundIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62.999"
      height="65.268"
      viewBox="0 0 62.999 65.268"
      {...props}
    >
      <g id="_1" data-name="1" transform="translate(-48.343 -41.007)">
        <path
          id="Path_575"
          data-name="Path 575"
          d="M76.442,44.139a50.12,50.12,0,0,0,7.233-1.085c.074-.025.149-.048.226-.067.75-.183,4.51-.954,6.112,1.564,1.774,2.787,4.429,8.472,4.084,13.422q-.006.085-.006.171v.7a2.666,2.666,0,0,0,1.308,2.3c.066.039.133.077.2.113a2.706,2.706,0,0,1,1.418,2.135,9.5,9.5,0,0,1-.252,3.969,6.346,6.346,0,0,1-1.459,2.283,2.653,2.653,0,0,1-.422.316A3.915,3.915,0,0,0,93.581,72.2c-.637,2.039-1.657,6.244-2.8,8.028s-2.676,4.97-5.1,6.626a11.115,11.115,0,0,1-5.861,2.167,14.254,14.254,0,0,1-5.48-.765,15.385,15.385,0,0,1-4.46-3.186c-1.111-1.358-2.94-3.433-3.516-5.068a2.481,2.481,0,0,0-.124-.3,29.054,29.054,0,0,1-2.349-5.849,8.767,8.767,0,0,0-.645-2.314,2.682,2.682,0,0,0-.589-.844,3.006,3.006,0,0,0-1.187-.792,1.8,1.8,0,0,1-1.275-1.529c-.115-.69-.126-3.666-.127-4.237,0-.058,0-.115-.006-.173-.02-.3-.067-1.378.261-1.707a2.364,2.364,0,0,1,1.529-.892c.637,0,.892-.637.892-1.274s.765-7.646.892-8.793a7.7,7.7,0,0,1,3.556-5.613,2.608,2.608,0,0,1,.979-.293,59.788,59.788,0,0,0,7.7-1.14A2.7,2.7,0,0,1,76.442,44.139Z"
          fill="none"
          stroke="currentColor"
          strokeLinejoin="bevel"
          strokeWidth="3.313"
        />
        <path
          id="Path_576"
          data-name="Path 576"
          d="M70.644,86.985l-2.4,3.71a3.076,3.076,0,0,1-2.571,1.387H59.1a9.1,9.1,0,0,0-9.1,9.1v3.134"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3.313"
        />
        <circle
          id="Ellipse_34"
          data-name="Ellipse 34"
          cx="7.009"
          cy="7.009"
          r="7.009"
          transform="translate(91.797 86.985)"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="3.313"
        />
        <line
          id="Line_67"
          data-name="Line 67"
          x2="5.225"
          y2="5.097"
          transform="translate(103.775 98.836)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3.313"
        />
      </g>
    </svg>
  );
};

export default WasherNotFoundIcon;
