import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

interface OwnProps {
  images: string[];
  onClose: () => void;
  startIndex?: number;
}

type Props = OwnProps;

const PhotosLightbox: React.FC<Props> = ({
  images,
  onClose,
  startIndex = 0,
}) => {
  const [photoIndex, setPhotoIndex] = useState(startIndex);
  return (
    <Lightbox
      reactModalStyle={{
        overlay: {
          zIndex: 1300,
        },
      }}
      mainSrc={images[photoIndex]}
      nextSrc={images[photoIndex + 1]}
      prevSrc={photoIndex > 0 ? images[photoIndex - 1] : undefined}
      onCloseRequest={onClose}
      onMovePrevRequest={() =>
        setPhotoIndex((photoIndex + images.length - 1) % images.length)
      }
      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
    />
  );
};

export default PhotosLightbox;
