import MuiButton from "./MuiButton";
import MuiFormHelperText from "./MuiFormHelperText";
import MuiDialog from "./MuiDialog";
import MuiPaper from "./MuiPaper";
import MuiSelect from "./MuiSelect";
import MuiAvatar from "./MuiAvatar";

export default {
  MuiButton,
  MuiFormHelperText,
  MuiDialog,
  MuiPaper,
  MuiSelect,
  MuiAvatar,
} as any;
