import React from "react";
import SimpleTableRow from "../../../shared/sharedTable/SimpleTableRow";
import { Grid } from "@material-ui/core";
import { UserSelectors } from "../../../store/reducers/users.reducer";
import headCells from "./headCells";

interface OwnProps {
  data: ReturnType<typeof UserSelectors.getFormattedPromoters>;
}

type Props = OwnProps;

const PromotersList: React.FC<Props> = (props) => {
  return (
    <Grid container>
      {props.data.map((row) => (
        <SimpleTableRow key={row.id} row={row} columns={headCells} />
      ))}
    </Grid>
  );
};

export default PromotersList;
