import React from "react";
import { Box, Typography } from "@material-ui/core";
import { unitSize } from "../constants/sizes";

interface OwnProps {
  icon: any;
  title: string;
  value: string;
}

type Props = OwnProps;

const AdminStatisticItem: React.FC<Props> = (props) => {
  const { icon, title, value } = props;
  return (
    <Box display="flex" alignItems="center" flexGrow={1}>
      <img src={icon} alt={"icon"} height={unitSize * 4} />
      <Box ml={3}>
        <Typography variant="h2" component="p" color="primary">
          {value}
        </Typography>
        <Typography color="textSecondary" variant="subtitle1" component="p">
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default AdminStatisticItem;
