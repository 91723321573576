import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import { showError } from "./utils";
import { equipmentApi } from "../../api/equipment.api";
import {
  approveEquipmentRequestsForWasherType,
  createEquipmentRequestType,
  createManyEquipmentsRequestType,
  EquipmentActions,
  EquipmentActionTypes,
  getEquipmentRequestsForWasherType,
  getEquipmentsByWasherIdType,
  getWashersListWithActiveRequestsType,
  updateWasherDeliveryAddressType,
} from "../actions/equipment.actions";
import {
  EquipmentRequest,
  EquipmentRequests,
  UserEquipments,
} from "../../models/responses/equipment.model";
import { takeEvery } from "redux-saga/effects";
import { userApi } from "../../api/user.api";
import { EquipmentRequestWasherStatus } from "../../models/requests/Users";
import { User, Users } from "../../models/responses/user.model";
import { AuthActions } from "../actions/auth.actions";

function* getListOfAWasherEquipment() {
  try {
    const { data } = yield equipmentApi.getWasherEquipment();
    if (data.result === ResponseStatus.SUCCESS) {
      const equipments = yield transformAndValidate(UserEquipments, data.data);
      yield put(EquipmentActions.getListOfAWasherEquipmentSuccess(equipments));
    }
  } catch (e) {
    yield showError(e);
    yield put(EquipmentActions.getListOfAWasherEquipmentFailure(e.message));
  }
}

function* getEquipmentsByWasherId(action: getEquipmentsByWasherIdType) {
  try {
    const { data } = yield equipmentApi.getWasherEquipment(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const equipments = yield transformAndValidate(UserEquipments, data.data);
      yield put(EquipmentActions.getListOfAWasherEquipmentSuccess(equipments));
    }
  } catch (e) {
    yield showError(e);
    yield put(EquipmentActions.getListOfAWasherEquipmentFailure(e.message));
  }
}

function* createEquipmentRequest(action: createEquipmentRequestType) {
  try {
    const { data } = yield equipmentApi.createEquipmentRequest(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      // const resp = yield transformAndValidate(WasherEquipments, data.data);
      yield put(EquipmentActions.createEquipmentRequestSuccess());
      yield call(getListOfAWasherEquipment);
    }
  } catch (e) {
    yield showError(e);
    yield put(EquipmentActions.createEquipmentRequestFailure(e.message));
  }
}

function* createManyEquipmentsRequest(action: createManyEquipmentsRequestType) {
  try {
    yield all(
      action.payload.map((washerEquipmentId) =>
        put(EquipmentActions.createEquipmentRequest(washerEquipmentId))
      )
    );
  } catch (e) {
    yield showError(e);
    yield put(EquipmentActions.getListOfAWasherEquipmentFailure(e.message));
  }
}

function* getWashersListWithActiveRequests(
  action: getWashersListWithActiveRequestsType
) {
  try {
    const requestParams = {
      ...action.payload,
      equipmentRequestFilter: EquipmentRequestWasherStatus.HAVE_REQUEST,
    };
    const { data } = yield userApi.getWashers(requestParams);
    if (data.result === ResponseStatus.SUCCESS) {
      const washers = yield transformAndValidate(Users, data.data);
      yield put(
        EquipmentActions.getWashersListWithActiveRequestsSuccess(washers)
      );
    }
  } catch (e) {
    yield showError(e);
    yield put(
      EquipmentActions.getWashersListWithActiveRequestsFailure(e.message)
    );
  }
}

function* getEquipmentRequestsForWasher(
  action: getEquipmentRequestsForWasherType
) {
  try {
    const { data } = yield equipmentApi.getEquipmentRequestsForWasher(
      action.payload
    );
    if (data.result === ResponseStatus.SUCCESS) {
      const equipmentRequests = yield transformAndValidate(
        EquipmentRequests,
        data.data
      );
      yield put(
        EquipmentActions.getEquipmentRequestsForWasherSuccess(equipmentRequests)
      );
    }
  } catch (e) {
    yield showError(e);
    yield put(EquipmentActions.getEquipmentRequestsForWasherFailure(e.message));
  }
}

function* approveEquipmentRequestsForWasher(
  action: approveEquipmentRequestsForWasherType
) {
  try {
    const { data } = yield equipmentApi.approveEquipmentRequest(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const equipmentRequest = yield transformAndValidate(
        EquipmentRequest,
        data.data
      );
      yield put(
        EquipmentActions.approveEquipmentRequestsForWasherSuccess(
          equipmentRequest
        )
      );
    }
  } catch (e) {
    yield showError(e);
    yield put(
      EquipmentActions.approveEquipmentRequestsForWasherFailure({
        error: e.message,
        id: action.payload.id,
      })
    );
  }
}

function* updateWasherDeliveryAddress(action: updateWasherDeliveryAddressType) {
  try {
    const { data } = yield userApi.updateUserPersonalData(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      yield put(EquipmentActions.updateWasherDeliveryAddressSuccess());
      const authorizedUser = yield transformAndValidate(User, data.data);
      yield put(AuthActions.setMyUserData(authorizedUser));
    }
  } catch (e) {
    yield showError(e);
    yield put(EquipmentActions.updateWasherDeliveryAddressFailure(e.message));
  }
}

export function* EquipmentSagas() {
  yield takeLatest(
    EquipmentActionTypes.GET_LIST_OF_A_WASHER_EQUIPMENT,
    getListOfAWasherEquipment
  );
  yield takeLatest(
    EquipmentActionTypes.GET_EQUIPMENTS_BY_WASHER_ID,
    getEquipmentsByWasherId
  );
  yield takeEvery(
    EquipmentActionTypes.CREATE_EQUIPMENT_REQUEST,
    createEquipmentRequest
  );
  yield takeLatest(
    EquipmentActionTypes.CREATE_MANY_EQUIPMENTS_REQUEST,
    createManyEquipmentsRequest
  );
  yield takeLatest(
    EquipmentActionTypes.GET_WASHERS_LIST_WITH_ACTIVE_REQUESTS,
    getWashersListWithActiveRequests
  );
  yield takeLatest(
    EquipmentActionTypes.GET_EQUIPMENT_REQUESTS_FOR_WASHER,
    getEquipmentRequestsForWasher
  );
  yield takeEvery(
    EquipmentActionTypes.APPROVE_EQUIPMENT_REQUESTS_FOR_WASHER,
    approveEquipmentRequestsForWasher
  );
  yield takeLatest(
    EquipmentActionTypes.UPDATE_WASHER_DELIVERY_ADDRESS,
    updateWasherDeliveryAddress
  );
}
