import React, { useEffect } from "react";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "./icons/StarIcon";
import { Box, Typography } from "@material-ui/core";

interface OwnProps {
  initValue: number;
  readOnly?: boolean;
  label?: string;
}

type Props = OwnProps;

const MuiRating: React.FC<Props> = ({ initValue, readOnly, label }) => {
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    setValue(initValue);
  }, [initValue]);
  return (
    <Box display="flex" flexDirection="column" flexBasis="50%" mt={2}>
      {label && <Typography variant="h6">{label}</Typography>}
      <Box mt={2}>
        <Rating
          readOnly={readOnly}
          name="simple-controlled"
          value={value}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setValue(newValue);
            }
          }}
          icon={<StarIcon size="small" />}
        />
      </Box>
    </Box>
  );
};

export default MuiRating;
