import { ID, SortParams } from "../utils.models";

export interface getUserPayoutsParams {
  size: number;
  page: number;
  status?: "PAID" | "ALL" | "REJECT" | "PAID_OR_REJECTED" | "REQUESTED";
  sort?: SortParams;
}

export interface getAdminPayoutsParams extends getUserPayoutsParams {
  washerId?: ID;
}

interface requestPayoutData {
  bankAccount: string;
}

export interface requestWasherPayoutData extends requestPayoutData {
  orderIds: string[];
}

export interface requestPromoterPayoutData extends requestPayoutData {
  referralUserIds: string[];
}

export interface requestCompanyPayoutsData extends requestPayoutData {
  companyOrderIds: string[];
}

export enum PayoutDispatchTypes {
  DECLINE = "DECLINE",
  ACCEPT = "ACCEPT",
}

export interface DispatchWasherPayoutData {
  type: PayoutDispatchTypes;
  payload?: {
    description: string;
  };
}
