import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const RejectIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g id="Group_342" data-name="Group 342" transform="translate(-602 -1000)">
        <g
          id="Ellipse_120"
          data-name="Ellipse 120"
          transform="translate(602 1000)"
          fill="#fff"
          stroke="#bd1212"
          strokeWidth="0.5"
          opacity="0.532"
        >
          <circle cx="8" cy="8" r="8" stroke="none" />
          <circle cx="8" cy="8" r="7.75" fill="none" />
        </g>
        <path
          id="Path_674"
          data-name="Path 674"
          d="M2.287,2.06,4.072.275A.161.161,0,0,0,3.844.048L2.059,1.833.274.048A.161.161,0,0,0,.047.275L1.832,2.06.047,3.845a.161.161,0,0,0,.228.228L2.059,2.288,3.844,4.073a.161.161,0,0,0,.228-.228Z"
          transform="translate(608 1005.999)"
          fill="#be1212"
          opacity="0.636"
        />
      </g>
    </svg>
  );
};

export default RejectIcon;
