import { Page } from "../../../../models/router.models";
import { RouteNames } from "../../../../constants/routeNames";
import DashboardIcon from "../../../../shared/icons/DashboardIcon";
import FinanceIcon from "../../../../shared/icons/FinanceIcon";
import EquipmentIcon from "../../../../shared/icons/EquipmentIcon";
import texts from "../../../../constants/texts";
import OrdersIcon from "../../../../shared/icons/OrdersIcon";
import HistoryIcon from "../../../../shared/icons/HistoryIcon";

const washerMenu: Page[] = [
  {
    title: texts.roles.washer.menu.dashboard,
    path: `/${RouteNames.DASHBOARD}`,
    Icon: DashboardIcon,
  },
  {
    title: texts.roles.washer.menu.finance,
    path: `/${RouteNames.FINANCE}`,
    Icon: FinanceIcon,
    disabled: true,
  },
  {
    title: texts.roles.washer.menu.orders,
    path: `/${RouteNames.ORDERS}`,
    Icon: OrdersIcon,
  },
  {
    title: texts.roles.washer.menu.equipment,
    path: `/${RouteNames.EQUIPMENT}`,
    Icon: EquipmentIcon,
  },
  {
    title: texts.roles.washer.menu.payouts,
    path: `/${RouteNames.PAYOUTS}`,
    Icon: FinanceIcon,
  },
  {
    title: texts.roles.washer.menu.history,
    path: `/${RouteNames.HISTORY}`,
    Icon: HistoryIcon,
  },
  {
    title: texts.roles.washer.menu.logs,
    path: `/${RouteNames.LOGS}`,
    Icon: HistoryIcon,
  },
];

export default washerMenu;
