import { delay, put, takeLatest } from "@redux-saga/core/effects";
import { showError } from "./utils";
import {
  CompanyActions,
  CompanyActionTypes,
  createCompanyType,
} from "../actions/company.actions";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import { Companies, Company } from "../../models/responses/company.model";
import { companyApi } from "../../api/company.api";
import { AppActions } from "../actions/app.actions";
import { SnackbarVariant } from "../../models/utils.models";
import texts from "../../constants/texts";

function* getAllCompanies() {
  yield delay(100);
  try {
    const { data } = yield companyApi.getCompanies();
    if (data.result === ResponseStatus.SUCCESS) {
      const companies = yield transformAndValidate(Companies, data.data);
      yield put(CompanyActions.getAllCompaniesSuccess(companies));
    }
  } catch (e) {
    yield put(CompanyActions.getAllCompaniesFailure(e.message));
    yield showError(e);
  }
}

function* createCompany(action: createCompanyType) {
  try {
    const { data } = yield companyApi.addNewCompany(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const company = yield transformAndValidate(Company, data.data);
      yield put(CompanyActions.createCompanySuccess(company));
      yield put(
        AppActions.showSnackbar({
          variant: SnackbarVariant.SUCCESS,
          message: texts.createCompany.snackbar,
        })
      );
    }
  } catch (e) {
    yield put(CompanyActions.createCompanyFailure(e.message));
    yield showError(e);
  }
}

function* getMyActiveCompany() {
  try {
    const { data } = yield companyApi.getCompany();
    if (data.result === ResponseStatus.SUCCESS) {
      const company = data.data
        ? yield transformAndValidate(Company, data.data)
        : null;
      yield put(CompanyActions.getMyActiveCompanySuccess(company));
    }
  } catch (e) {
    yield put(CompanyActions.getMyActiveCompanyFailure(e.message));
    yield showError(e);
  }
}

export function* CompanySagas() {
  yield takeLatest(CompanyActionTypes.GET_ALL_COMPANIES, getAllCompanies);
  yield takeLatest(CompanyActionTypes.CREATE_COMPANY, createCompany);
  yield takeLatest(
    CompanyActionTypes.GET_MY_ACTIVE_COMPANY,
    getMyActiveCompany
  );
}
