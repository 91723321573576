import { makeStyles, Theme } from "@material-ui/core/styles";
import colors from "../../../constants/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  couponStatus: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: "14px",
    fontWeight: 300,
    color: colors.blueGrey,
    "& span": {
      display: "block",
      fontWeight: 700,
      "&.valid": {
        color: colors.darkGreen,
      },
      "&.invalid": {
        color: colors.lightRed,
      },
    },
  },
  couponOwner: {
    display: "inline-flex",
    flexDirection: "column",
    borderRadius: 10,
    fontFamily: "'Roboto', sans-serif",
    fontSize: "13px",
    fontWeight: 400,
    color: colors.blueGrey,
    border: "1px dashed",
    borderColor: colors.blueGrey,
    "& span": {
      display: "block",
      color: colors.orange,
      fontWeight: 500,
    },
  },
}));
