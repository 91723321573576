import React from "react";
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { unitSize } from "../../../../../../constants/sizes";
import IdCell from "../../../../../../shared/sharedTable/IdCell";
import { WasherPayedOrdersColumns } from "../../../../../../models/table.models";
import headCells from "../PayedOrdersList/headCells";
import { useStyles } from "./style";

interface OwnProps {
  order: { [key in WasherPayedOrdersColumns]: string };
  isItemSelected: boolean;
  onClick: (event: React.MouseEvent<unknown>, orderId: string) => void;
}

type Props = OwnProps;

const PayedOrdersRow: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container>
          {headCells.map(({ id, size }, index) => (
            <Grid key={id} item xs={size}>
              <Box
                pb={3}
                pt={2}
                pr={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  height={unitSize * 3}
                  className={classes.textContainer}
                >
                  {index > 0 && (
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                  )}
                  {id === "id" ? (
                    <IdCell id={props.order.id} />
                  ) : (
                    <Typography variant="body1" color="textPrimary" noWrap>
                      {props.order[id]}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
          <Grid item xs={1}>
            <Box
              pr={4}
              height={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Checkbox
                color="primary"
                onClick={(event) => props.onClick(event, props.order.id)}
                checked={props.isItemSelected}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PayedOrdersRow;
