import React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import StarIcon from "../../../shared/icons/StarIcon";
import { parseRating } from "../../../helpers/utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.secondary.main,
    },
    ratingValue: {
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
    },
  })
);

interface OwnProps {
  value: number | null;
}

type Props = OwnProps;

const UserRating: React.FC<Props> = ({ value }) => {
  const classes = useStyles();
  const rating = !value ? "-.-" : parseRating(value);
  return (
    <Box display="flex" alignItems="flex-end" ml={3} className={classes.root}>
      <StarIcon />
      <Typography variant="h4" className={classes.ratingValue}>
        {rating}
      </Typography>
    </Box>
  );
};

export default UserRating;
