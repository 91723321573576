import React from "react";

interface OwnProps {}

type Props = OwnProps;

const PhoneIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="9.299"
      height="13.45"
      viewBox="0 0 9.299 13.45"
    >
      <path
        id="mobile"
        d="M8.248,8.724V2.1A1.052,1.052,0,0,0,7.2,1.051H2.1A1.052,1.052,0,0,0,1.051,2.1v9.247A1.052,1.052,0,0,0,2.1,12.4H7.2a1.052,1.052,0,0,0,1.051-1.051.525.525,0,1,1,1.051,0,2.1,2.1,0,0,1-2.1,2.1H2.1a2.1,2.1,0,0,1-2.1-2.1V2.1A2.1,2.1,0,0,1,2.1,0H7.2A2.1,2.1,0,0,1,9.3,2.1V8.724a.525.525,0,1,1-1.051,0ZM4.124,11.062a.525.525,0,1,0,.525-.525A.525.525,0,0,0,4.124,11.062ZM5.175,2.919a.525.525,0,0,0,0-1.051H4.124a.525.525,0,1,0,0,1.051Z"
        transform="translate(0 0)"
      />
    </svg>
  );
};

export default PhoneIcon;
