import { PaymentMethod } from "../../models/responses/payment.model";
import { PaymentActions, PaymentActionTypes } from "../actions/payment.actions";
import {
  formatTwoDigitDate,
  getLastTwoDigitsOfYear,
} from "../../helpers/utils/date.utils";
import { addMissingSpaces } from "../../helpers/utils/string.utils";

class StateModel {
  paymentMethodsForActiveUser: PaymentMethod[] = [];
  paymentMethodsForActiveUserLoading: boolean = false;
  paymentMethodsForActiveUserError: string = "";
}

const initialState = new StateModel();

export function paymentReducer(
  state = initialState,
  action: PaymentActions
): StateModel {
  switch (action.type) {
    case PaymentActionTypes.GET_PAYMENT_METHODS_FOR_ACTIVE_USER: {
      return {
        ...state,
        paymentMethodsForActiveUserLoading: true,
        paymentMethodsForActiveUserError: "",
      };
    }
    case PaymentActionTypes.GET_PAYMENT_METHODS_FOR_ACTIVE_USER_SUCCESS: {
      return {
        ...state,
        paymentMethodsForActiveUserLoading: false,
        paymentMethodsForActiveUser: action.payload,
      };
    }
    case PaymentActionTypes.GET_PAYMENT_METHODS_FOR_ACTIVE_USER_FAILURE: {
      return {
        ...state,
        paymentMethodsForActiveUserLoading: false,
        paymentMethodsForActiveUserError: action.payload,
      };
    }
    default:
      return state;
  }
}

export const PaymentSelectors = {
  getCreditCardsData: (state: StateModel) =>
    state.paymentMethodsForActiveUser.map((item) => ({
      id: item.id,
      type: item.card.brand,
      cardHolder: item.card.name || "unknown",
      cardNumber: addMissingSpaces(
        `• • • •     • • • •     • • • •     ${item.card.last4}`
      ),
      expirationDate: `${formatTwoDigitDate(
        item.card.expMonth
      )}/${getLastTwoDigitsOfYear(item.card.expYear)}`,
    })),
};
