import React from "react";

interface OwnProps {}

type Props = OwnProps;

const TutorialsIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
    >
      <path
        id="search"
        d="M8.719,12.656a.7.7,0,1,1,0,1.406H7.734a.7.7,0,1,1,0-1.406Zm5.766-3.762a.7.7,0,0,0-.7-.7H7.734a.7.7,0,0,0,0,1.406h6.047A.7.7,0,0,0,14.484,8.895ZM7.734,5.379h6.047a.7.7,0,0,0,0-1.406H7.734a.7.7,0,0,0,0,1.406ZM4.922,12.656h-.7a.7.7,0,1,0,0,1.406h.7a.7.7,0,1,0,0-1.406Zm0-4.465h-.7a.7.7,0,0,0,0,1.406h.7a.7.7,0,0,0,0-1.406Zm0-4.219h-.7a.7.7,0,1,0,0,1.406h.7a.7.7,0,0,0,0-1.406ZM17.794,16.8a.7.7,0,0,1-.995.995L15.61,16.6a3.168,3.168,0,1,1,1.018-.971Zm-3.8-1.155a1.758,1.758,0,1,0-1.758-1.758A1.76,1.76,0,0,0,13.992,15.645ZM15.187,0H2.812A2.816,2.816,0,0,0,0,2.812V15.187A2.816,2.816,0,0,0,2.812,18h7.242a.7.7,0,0,0,0-1.406H2.812a1.408,1.408,0,0,1-1.406-1.406V2.812A1.408,1.408,0,0,1,2.812,1.406H15.187a1.408,1.408,0,0,1,1.406,1.406V9.773a.7.7,0,0,0,1.406,0V2.812A2.816,2.816,0,0,0,15.187,0Zm0,0"
      />
    </svg>
  );
};

export default TutorialsIcon;
