import { RouteProps } from "react-router";
import { RouteNames, RouteParams } from "../../../../constants/routeNames";
import asAsync from "../../../../helpers/asAsync";

export const routes: RouteProps[] = [
  {
    path: `/${RouteNames.CLIENTS}/:${RouteParams.USER_ID}/${RouteNames.LOGS}`,
    component: asAsync(import("../../../../components/UserProfile/UserLogs")),
    exact: true,
  },
  {
    path: `/${RouteNames.CLIENTS}/:${RouteParams.USER_ID}/${RouteNames.ORDERS}`,
    component: asAsync(import("../../../../components/UserProfile/PastOrders")),
    exact: true,
  },
  {
    path: `/${RouteNames.CLIENTS}/:${RouteParams.USER_ID}/${RouteNames.ORDERS}/:${RouteParams.ORDER_ID}`,
    component: asAsync(import("../../../../components/OrderDetails")),
    exact: true,
  },
  {
    path: `/${RouteNames.CLIENTS}/:${RouteParams.USER_ID}/${RouteNames.CARS}`,
    component: asAsync(import("../../../../components/UserProfile/Cars")),
    exact: true,
  },
  {
    path: `/${RouteNames.CLIENTS}/:${RouteParams.USER_ID}/${RouteNames.FUTURE_WASH}`,
    component: asAsync(
      import("../../../../components/UserProfile/FutureOrders")
    ),
    exact: true,
  },
  {
    path: `/${RouteNames.CLIENTS}/:${RouteParams.USER_ID}/${RouteNames.FUTURE_WASH}/:${RouteParams.ORDER_ID}`,
    component: asAsync(import("../../../../components/OrderDetails")),
    exact: true,
  },
  {
    path: `/${RouteNames.CLIENTS}/:${RouteParams.USER_ID}/${RouteNames.BANKING_CARDS}`,
    component: asAsync(
      import("../../../../components/UserProfile/BankingCards")
    ),
    exact: true,
  },
  {
    path: `/${RouteNames.CLIENTS}/:${RouteParams.USER_ID}/${RouteNames.REVIEWS}`,
    component: asAsync(import("../../../../components/UserProfile/Reviews")),
    exact: true,
  },
  {
    path: `/${RouteNames.CLIENTS}/:${RouteParams.USER_ID}/${RouteNames.CREDITS}`,
    component: asAsync(import("../../../../components/UserProfile/Credits")),
    exact: true,
  },
];
