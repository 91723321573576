import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import NewAppLogo from "../../../Images/NewAppLogo.svg";
import CustomRouterLink from "../../../shared/CustomRouterLink";
import { User, UserRole } from "../../../models/responses/user.model";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import { useHistory } from "react-router-dom";
import { RouteNames } from "../../../constants/routeNames";
import { useStyles } from "./style";
import adminMenu from "./menus/admin.menu";
import washerMenu from "./menus/washer.menu";
import promoterMenu from "./menus/promoter.menu";
import pendingWasherMenu from "./menus/pendingWasher.menu";
import companyAdminMenu from "./menus/companyAdmin.menu";
import pendingVerifiedWasherMenu from "./menus/pendingVerifiedWasher.menu";

function getUserMenuItems(user: User | null) {
  switch (user?.role) {
    case UserRole.ADMIN:
      return adminMenu;
    case UserRole.PENDING_WASHER:
      return user?.isVerified ? pendingVerifiedWasherMenu : pendingWasherMenu;
    case UserRole.WASHER:
      return washerMenu;
    case UserRole.COMPANY_ADMIN:
      return companyAdminMenu;
    case UserRole.PROMOTER:
      return promoterMenu;
    default:
      return [];
  }
}

interface OwnProps {
  onToggleDriver: () => void;
  isOpen: boolean;
}

type Props = OwnProps;

const NavBar: React.FC<Props> = (props) => {
  const authState = useSelector((state: AppState) => state.auth);
  const history = useHistory();
  const classes = useStyles();
  const pages = getUserMenuItems(authState.authorizedUser);

  const handleLogoClick = () => {
    history.push(RouteNames.ROOT);
  };

  const { onToggleDriver, isOpen } = props;
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !isOpen && classes.drawerPaperClose),
      }}
      open={isOpen}
    >
      <div className={classes.toolbarIcon}>
        <Box>
          {isOpen && (
            <img
              className={classes.logo}
              src={NewAppLogo}
              alt="logo"
              onClick={handleLogoClick}
            />
          )}
        </Box>
        <IconButton onClick={onToggleDriver}>
          {isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </div>
      <List className={classes.list}>
        {pages.map(({ title, path, Icon, disabled }, index, arr) => (
          <React.Fragment key={path}>
            <ListItem className={classes.item} key={title}>
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                disabled={disabled}
                to={path}
              >
                <div className={classes.icon}>
                  <Icon />
                </div>
                <Typography variant="body1" className={classes.title}>
                  {title}
                </Typography>
              </Button>
            </ListItem>
            {index !== arr.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};

export default NavBar;
