import { delay, put, select, takeLatest } from "@redux-saga/core/effects";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import { showError } from "./utils";
import {
  getActiveWasherCVType,
  getPendingWashersType,
  PendingWashersActions,
  PendingWashersActionsTypes,
  pendingWashersSearchValueType,
  rejectActiveWasherCvType,
} from "../actions/pendingWashers.actions";
import { pendingWasherApi } from "../../api/pendingWasher.api";
import { PendingWashers } from "../../models/responses/pendingWasher.model";
import { ApplicationForm } from "../../models/responses/applicationForm.model";
import { AppState } from "../reducers";
import { AppActions } from "../actions/app.actions";
import { SnackbarVariant } from "../../models/utils.models";
import { VerificationFormStatus } from "../../models/requests/VerificationForm";

function* getPendingWashers(action: getPendingWashersType) {
  yield delay(100);
  try {
    const { data } = yield pendingWasherApi.getPendingWashers(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const pendingWashers = yield transformAndValidate(
        PendingWashers,
        data.data
      );
      yield put(PendingWashersActions.getPendingWashersSuccess(pendingWashers));
    }
  } catch (e) {
    yield put(PendingWashersActions.getPendingWashersFailure(e.message));
    yield showError(e);
  }
}

function* getActiveWasherCVData(action: getActiveWasherCVType) {
  try {
    const { data } = yield pendingWasherApi.getPendingWasherVerificationForm(
      action.payload
    );
    if (data.result === ResponseStatus.SUCCESS) {
      const activeWasherCV = yield transformAndValidate(
        ApplicationForm,
        data.data
      );
      yield put(PendingWashersActions.getActiveWasherCVSuccess(activeWasherCV));
    }
  } catch (e) {
    yield put(PendingWashersActions.getActiveWasherCVFailure(e.message));
    yield showError(e);
  }
}

function* approveActiveWasherCv() {
  try {
    const activeWasherCV = yield select(
      (state: AppState) => state.pendingWashers.activeWasherCV
    );
    if (activeWasherCV !== null) {
      const body = {
        status: VerificationFormStatus.APPROVE,
      };
      const { data } = yield pendingWasherApi.changeVerificationFormStatus(
        activeWasherCV.id,
        body
      );
      if (data.result === ResponseStatus.SUCCESS) {
        const approvedCv = yield transformAndValidate(
          ApplicationForm,
          data.data
        );
        yield put(
          PendingWashersActions.changeWasherCvStatusSuccess(approvedCv)
        );
        yield put(
          AppActions.showSnackbar({
            variant: SnackbarVariant.SUCCESS,
            message: "Washer approved!",
          })
        );
      }
    } else {
      yield put(
        PendingWashersActions.changeWasherCvStatusFailure("No active washer CV")
      );
    }
  } catch (e) {
    yield put(PendingWashersActions.changeWasherCvStatusFailure(e.message));
    yield showError(e);
  }
}

function* rejectActiveWasherCv(action: rejectActiveWasherCvType) {
  try {
    const activeWasherCV = yield select(
      (state: AppState) => state.pendingWashers.activeWasherCV
    );
    if (activeWasherCV !== null) {
      const body = {
        status: VerificationFormStatus.REJECT,
        message: action.payload,
      };
      const { data } = yield pendingWasherApi.changeVerificationFormStatus(
        activeWasherCV.id,
        body
      );
      if (data.result === ResponseStatus.SUCCESS) {
        const rejectedCv = yield transformAndValidate(
          ApplicationForm,
          data.data
        );
        yield put(
          PendingWashersActions.changeWasherCvStatusSuccess(rejectedCv)
        );
        yield put(
          AppActions.showSnackbar({
            variant: SnackbarVariant.SUCCESS,
            message: "Washer rejected!",
          })
        );
      }
    } else {
      yield put(
        PendingWashersActions.changeWasherCvStatusFailure("No active washer CV")
      );
    }
  } catch (e) {
    yield put(PendingWashersActions.changeWasherCvStatusFailure(e.message));
    yield showError(e);
  }
}

function* setPendingWashersSearchValue(action: pendingWashersSearchValueType) {
  yield delay(400);
  yield put(PendingWashersActions.setPendingWashersSearchValue(action.payload));
}

export function* PendingWashersSagas() {
  yield takeLatest(
    PendingWashersActionsTypes.GET_PENDING_WASHERS,
    getPendingWashers
  );
  yield takeLatest(
    PendingWashersActionsTypes.GET_ACTIVE_WASHER_CV,
    getActiveWasherCVData
  );
  yield takeLatest(
    PendingWashersActionsTypes.PENDING_WASHERS_SEARCH_VALUE,
    setPendingWashersSearchValue
  );
  yield takeLatest(
    PendingWashersActionsTypes.APPROVE_ACTIVE_WASHER_CV,
    approveActiveWasherCv
  );
  yield takeLatest(
    PendingWashersActionsTypes.REJECT_ACTIVE_WASHER_CV,
    rejectActiveWasherCv
  );
}
