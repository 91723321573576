import React from "react";

interface OwnProps {}

type Props = OwnProps;

const MailIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14.211"
      viewBox="0 0 20.319 14.211"
    >
      <g
        id="Group_26"
        data-name="Group 26"
        transform="translate(-89.377 -384.264)"
      >
        <path
          id="Path_36"
          data-name="Path 36"
          d="M90,392.366V397.6a.251.251,0,0,0,.249.25h18.574a.251.251,0,0,0,.249-.25V385.136a.25.25,0,0,0-.249-.249H90.249a.25.25,0,0,0-.249.249v3.49"
          fill="none"
          stroke="#fcc200"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.247"
        />
        <path
          id="Path_37"
          data-name="Path 37"
          d="M90.623,385.51l8.85,7.729,7.23-6.108"
          fill="none"
          stroke="#fcc200"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.247"
        />
        <line
          id="Line_16"
          data-name="Line 16"
          y1="5.859"
          x2="5.734"
          transform="translate(90.623 391.369)"
          fill="none"
          stroke="#fcc200"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.247"
        />
        <line
          id="Line_17"
          data-name="Line 17"
          x2="5.734"
          y2="5.859"
          transform="translate(102.715 391.369)"
          fill="none"
          stroke="#fcc200"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.247"
        />
      </g>
    </svg>
  );
};

export default MailIcon;
