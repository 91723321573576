import { put, takeLatest } from "@redux-saga/core/effects";
import { ResponseStatus } from "../../models/responses/result.model";
import { transformAndValidate } from "class-transformer-validator";
import { showError } from "./utils";
import {
  getPromotersReferralsListType,
  ReferralsActions,
  ReferralsActionTypes,
} from "../actions/referrals.actions";
import { referralsApi } from "../../api/referrals.api";
import { Referrals } from "../../models/responses/referrals.model";

export function* getPromotersReferralsList(
  action: getPromotersReferralsListType
) {
  try {
    const { data } = yield referralsApi.getPromotersReferralsList(
      action.payload
    );
    if (data.result === ResponseStatus.SUCCESS) {
      const referrals = yield transformAndValidate(Referrals, data.data);
      yield put(ReferralsActions.getPromotersReferralsListSuccess(referrals));
    }
  } catch (e) {
    yield showError(e);
    yield put(ReferralsActions.getPromotersReferralsListFailure(e.message));
  }
}

export function* ReferralsSagas() {
  yield takeLatest(
    ReferralsActionTypes.GET_PROMOTERS_REFERRALS_LIST,
    getPromotersReferralsList
  );
}
