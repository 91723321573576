import React, { useEffect } from "react";
import {
  AppBar,
  Box,
  Divider,
  Grow,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import useActions from "../../../hooks/useActions";
import { AuthActions } from "../../../store/actions/auth.actions";
import AdminStatisticItem from "../../../components/AdminStatisticItem";
import RadialDivider from "../../../styled/RadialDivider";
import CurrentlyWashersIcon from "../../../Images/CurrentlyWashers.png";
import CurrentlyUsersIcon from "../../../Images/CurrentlyUsers.png";
import BeingWashedIcon from "../../../Images/BeingWashed.png";
import CompleteWashedIcon from "../../../Images/CompleteWashed.png";
import SettingsHeaderIcon from "../../../shared/icons/SettingsHeaderIcon";
import { unitSize } from "../../../constants/sizes";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import NewAppLogo from "../../../Images/NewAppLogo.svg";
import { AuthSelectors } from "../../../store/reducers/auth.reducer";
import texts from "../../../constants/texts";
import { useHistory } from "react-router-dom";
import { RouteNames } from "../../../constants/routeNames";
import { UserRole } from "../../../models/responses/user.model";
import { StatisticActions } from "../../../store/actions/statistic.actions";
import CustomRouterLink from "../../../shared/CustomRouterLink";

interface StyleProps {
  isOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0, 6), // keep right padding when drawer closed
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(11.5),
    boxShadow: "5px 0px 20px rgba(0, 0, 0, 0.03)",
    position: "relative",
  },
  appBar: {
    paddingLeft: theme.spacing(9),
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(["padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "5px 0px 20px rgba(0, 0, 0, 0.06)",
  },
  appBarShift: {
    paddingLeft: theme.spacing(29),
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(["padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.text.secondary,
    paddingLeft: ({ isOpen }: StyleProps) =>
      isOpen ? theme.spacing(0) : theme.spacing(9),
    transition: ".5s",
  },
  closeButton: {
    padding: 0,
    color: theme.palette.error.dark,
  },
  settingsButton: {
    padding: 0,
  },
  logo: {
    width: theme.spacing(9),
    position: "absolute",
    cursor: "pointer",
  },
}));

interface OwnProps {
  isOpen: boolean;
}

type Props = OwnProps;

const Header: React.FC<Props> = ({ isOpen }) => {
  const { logOut } = useActions<typeof AuthActions>(AuthActions);
  const { getGeneralStatistic } = useActions<typeof StatisticActions>(
    StatisticActions
  );

  const history = useHistory();
  const { generalStatistic } = useSelector(
    (state: AppState) => state.statistic
  );
  const authState = useSelector((state: AppState) => state.auth);
  const isAdmin = AuthSelectors.isAdmin(authState);
  const fullName = AuthSelectors.getAuthorizedUserFullName(authState);
  const companyName = useSelector(
    (state: AppState) => state.company.activeCompany?.name
  );

  const classes = useStyles({ isOpen });

  const handleLogout = () => {
    logOut();
  };
  const handleLogoClick = () => {
    history.push(RouteNames.ROOT);
  };

  const getTitleByUserRole = (role: UserRole) => {
    switch (role) {
      case UserRole.COMPANY_ADMIN: {
        return `${companyName || "Company"} Dashboard`;
      }
      case UserRole.WASHER: {
        return texts.header.washerDashboard;
      }
      case UserRole.PROMOTER: {
        return texts.header.promoterDashboard;
      }
      case UserRole.ADMIN: {
        return texts.header.adminDashboard;
      }
      default: {
        return texts.header.appName;
      }
    }
  };

  useEffect(() => {
    if (isAdmin) {
      getGeneralStatistic();
    }
  }, [isAdmin, getGeneralStatistic]);

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, isOpen && classes.appBarShift)}
    >
      <Box display="flex" flexDirection="column">
        <Toolbar className={classes.toolbar}>
          <Grow in={!isOpen} timeout={{ enter: 1000 }}>
            <img
              className={classes.logo}
              src={NewAppLogo}
              alt="logo"
              onClick={handleLogoClick}
            />
          </Grow>
          <Typography
            component="h1"
            variant="h3"
            noWrap
            className={classes.title}
          >
            {getTitleByUserRole(authState.authorizedUser!.role)}
          </Typography>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Box mr={2.5} textAlign="right" maxWidth={unitSize * 8}>
              <Typography variant="body1" color="textSecondary">
                {texts.header.logged}
              </Typography>
            </Box>
            <Box mr={2.5}>
              <Typography variant="h3" color="primary" noWrap>
                {fullName}
              </Typography>
            </Box>
            <Box mr={2.5} color="textSecondary">
              <IconButton
                className={classes.settingsButton}
                component={CustomRouterLink}
                to={`/${RouteNames.SETTINGS}`}
              >
                <SettingsHeaderIcon />
              </IconButton>
            </Box>
            <IconButton className={classes.closeButton} onClick={handleLogout}>
              <CloseIcon style={{ fontSize: unitSize * 2 }} />
            </IconButton>
          </Box>
        </Toolbar>
      </Box>
      {isAdmin && (
        <>
          <Box px={4}>
            <Divider variant="middle" />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={6}
            py={4}
            bgcolor="white"
          >
            <AdminStatisticItem
              icon={CurrentlyWashersIcon}
              title={texts.statistics.currentlyWashers}
              value={generalStatistic.totalWashers.toLocaleString()}
            />
            <Box flexGrow={1}>
              <RadialDivider />
            </Box>
            <AdminStatisticItem
              icon={CurrentlyUsersIcon}
              title={texts.statistics.currentlyClients}
              value={generalStatistic.totalClients.toLocaleString()}
            />
            <Box flexGrow={1}>
              <RadialDivider />
            </Box>
            <AdminStatisticItem
              icon={BeingWashedIcon}
              title={texts.statistics.beingWashed}
              value={generalStatistic.beingWashed.toLocaleString()}
            />
            <Box flexGrow={1}>
              <RadialDivider />
            </Box>
            <AdminStatisticItem
              icon={CompleteWashedIcon}
              title={texts.statistics.completeWashed}
              value={generalStatistic.completeWashed.toLocaleString()}
            />
          </Box>
        </>
      )}
    </AppBar>
  );
};

export default Header;
