import React, { useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { washerVerificationSchema } from "../../../helpers/validations";
import {
  Button,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CustomButton from "../../../shared/CustomButton";
import TitledPaper from "../../../shared/TitledPaper";
import InputWithAdornment from "../../../shared/InputWithAdornment";
import PrimaryIconWithText from "../../../shared/PrimaryIconWithText";
import PhotoIcon from "../../../shared/icons/PhotoIcon";
import InsuranceIcon from "../../../shared/icons/InsuranceIcon";
import DriverLicenseIcon from "../../../shared/icons/DriverLicenseIcon";
import UploadFilesModal from "../../../components/UploadFilesModal";
import texts from "../../../constants/texts";
import useActions from "../../../hooks/useActions";
import { WasherVerificationActions } from "../../../store/actions/washerVerification.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import {
  Experience,
  experienceTexts,
  QuestionName,
  WasherVerificationFormData,
} from "../../../models/requests/VerificationForm";
import SwitchField from "./components/SwitchField";
import VerificationSent from "./components/VerificationSent";
import Loading from "../../../shared/Loading";
import AvatarField from "./components/AvatarField";
import { useTitle } from "react-use";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      width: theme.spacing(10),
      marginTop: theme.spacing(1),
    },
    uploadButton: {
      padding: theme.spacing(0.5, 3),
    },
    formControl: {
      margin: theme.spacing(3),
    },
    radioLabel: {
      fontSize: 12,
      fontWeight: 300,
      color: theme.palette.primary.main,
    },
    photosUploadPaper: {
      padding: theme.spacing(5, 4, 5, 3),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%",
    },
  })
);

interface OwnProps {}

type Props = OwnProps;

const WasherVerification: React.FC<Props> = () => {
  useTitle(`Washerly Verification`);
  const classes = useStyles();

  const [
    isCleanPhotoUploadModalOpen,
    setIsCleanPhotoUploadModalOpen,
  ] = useState<boolean>(false);
  const [
    isInsurancePhotoUploadModalOpen,
    setIsAddressPhotoUploadModalOpen,
  ] = useState<boolean>(false);
  const [
    isDriverLicensePhotoUploadModalOpen,
    setIsDriverLicensePhotoUploadModalOpen,
  ] = useState<boolean>(false);

  const washerVerificationActions = useActions<
    typeof WasherVerificationActions
  >(WasherVerificationActions);
  const washerVerificationState = useSelector(
    (state: AppState) => state.washerVerification
  );
  const authState = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    washerVerificationActions.getMyVerificationForm();
  }, [washerVerificationActions]);

  function handleFormSubmit(
    values: WasherVerificationFormData,
    actions: FormikHelpers<any>
  ) {
    washerVerificationActions.sendVerificationForm(values);
    actions.setSubmitting(false);
  }

  if (washerVerificationState.myVerificationFormLoading) {
    return <Loading />;
  }
  if (washerVerificationState.myVerificationForm !== null) {
    return <VerificationSent />;
  }

  return (
    <Formik
      initialValues={{
        avatar: undefined,
        firstName: authState.authorizedUser!.firstName,
        secondName: authState.authorizedUser!.secondName,
        email: authState.authorizedUser!.email || "",
        phoneNumber: authState.authorizedUser!.phoneNumber,
        country: "",
        postalCode: "",
        address: "",
        questions: [
          {
            name: QuestionName.ADULT,
            value: false,
          },
          {
            name: QuestionName.DRIVER_LICENSE,
            value: false,
          },
        ],
        experience: Experience.NONE,
        cleanPhoto: [],
        insurancePhoto: [],
        driverLicensePhoto: [],
      }}
      onSubmit={handleFormSubmit}
      validationSchema={washerVerificationSchema}
      isInitialValid={false}
    >
      {({
        values,
        handleChange,
        errors,
        touched,
        isValid,
        handleSubmit,
        handleBlur,
        setFieldValue,
      }) => (
        <>
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TitledPaper
                  title={texts.washerVerification.title}
                  subtitle={texts.washerVerification.subtitle}
                >
                  <Box pt={6} />
                  <Grid container spacing={6} alignItems="center">
                    <AvatarField
                      onChange={(file) => setFieldValue("avatar", file)}
                      required
                    />
                    <Grid item xs={4}>
                      <InputWithAdornment
                        adornment={texts.washerVerification.firstNameAdornment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        id="firstName"
                        name="firstName"
                        helperText={
                          errors.firstName &&
                          touched.firstName &&
                          errors.firstName
                        }
                        error={!!errors.firstName && touched.firstName}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputWithAdornment
                        adornment={texts.washerVerification.secondNameAdornment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.secondName}
                        id="secondName"
                        name="secondName"
                        helperText={
                          errors.secondName &&
                          touched.secondName &&
                          errors.secondName
                        }
                        error={!!errors.secondName && touched.secondName}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputWithAdornment
                        adornment={texts.washerVerification.emailAdornment}
                        value={values.email}
                        id="email"
                        name="email"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputWithAdornment
                        adornment={
                          texts.washerVerification.phoneNumberAdornment
                        }
                        value={values.phoneNumber}
                        id="phoneNumber"
                        name="phoneNumber"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputWithAdornment
                        adornment={texts.washerVerification.countryAdornment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.country}
                        id="country"
                        name="country"
                        helperText={
                          errors.country && touched.country && errors.country
                        }
                        error={!!errors.country && touched.country}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputWithAdornment
                        adornment={texts.washerVerification.postalCodeAdornment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.postalCode}
                        id="postalCode"
                        name="postalCode"
                        helperText={
                          errors.postalCode &&
                          touched.postalCode &&
                          errors.postalCode
                        }
                        error={!!errors.postalCode && touched.postalCode}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <InputWithAdornment
                        adornment={texts.washerVerification.addressAdornment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                        id="address"
                        name="address"
                        helperText={
                          errors.address && touched.address && errors.address
                        }
                        error={!!errors.address && touched.address}
                      />
                    </Grid>
                  </Grid>
                </TitledPaper>
              </Grid>
              <Grid item xs={12}>
                <TitledPaper
                  title={texts.washerVerification.questions}
                  subtitle={texts.washerVerification.questionsDescription}
                >
                  <Box
                    display="flex"
                    width={0.5}
                    justifyContent="space-between"
                    mt={6}
                  >
                    <Typography variant="subtitle1" color="primary">
                      {QuestionName.ADULT}
                    </Typography>
                    <SwitchField name="questions" value={QuestionName.ADULT} />
                  </Box>
                  <Divider className={classes.divider} />
                  <Box
                    display="flex"
                    width={0.5}
                    justifyContent="space-between"
                    mt={2}
                  >
                    <Typography variant="subtitle1" color="primary">
                      {QuestionName.DRIVER_LICENSE}
                    </Typography>
                    <SwitchField
                      name="questions"
                      value={QuestionName.DRIVER_LICENSE}
                    />
                  </Box>
                </TitledPaper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.photosUploadPaper}>
                  <PrimaryIconWithText
                    Icon={PhotoIcon}
                    text="Clean photo of your vehicle"
                    maxWidth={0.6}
                  />
                  <Box>
                    <Button
                      className={classes.uploadButton}
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsCleanPhotoUploadModalOpen(true)}
                    >
                      {values.cleanPhoto.length ? "Edit" : "Upload"}
                    </Button>
                    {!values.cleanPhoto.length && (
                      <Typography variant="body2" color="error" align="right">
                        *required
                      </Typography>
                    )}
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.photosUploadPaper}>
                  <PrimaryIconWithText
                    Icon={InsuranceIcon}
                    text="Proof of Address"
                    maxWidth={0.6}
                  />
                  <Box>
                    <Button
                      className={classes.uploadButton}
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsAddressPhotoUploadModalOpen(true)}
                    >
                      {values.insurancePhoto.length ? "Edit" : "Upload"}
                    </Button>
                    {!values.insurancePhoto.length && (
                      <Typography variant="body2" color="error" align="right">
                        *required
                      </Typography>
                    )}
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.photosUploadPaper}>
                  <PrimaryIconWithText
                    Icon={DriverLicenseIcon}
                    text="Drivers license photo & expiration date"
                    maxWidth={0.6}
                  />
                  <Box>
                    <Button
                      className={classes.uploadButton}
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        setIsDriverLicensePhotoUploadModalOpen(true)
                      }
                    >
                      {values.driverLicensePhoto.length ? "Edit" : "Upload"}
                    </Button>
                    {!values.driverLicensePhoto.length && (
                      <Typography variant="body2" color="error" align="right">
                        *required
                      </Typography>
                    )}
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <TitledPaper
                  title="Experience"
                  subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                >
                  <Box mt={5} display="flex" justifyContent="space-between">
                    <Typography variant="body1" color="textPrimary">
                      Experience with car washing and mobile detailing
                    </Typography>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <RadioGroup
                        name="experience"
                        value={values.experience}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          className={classes.radioLabel}
                          value={Experience.PROFESSIONAL}
                          control={<Radio color="primary" />}
                          label={experienceTexts[Experience.PROFESSIONAL]}
                        />
                        <FormControlLabel
                          className={classes.radioLabel}
                          value={Experience.AMATEUR}
                          control={<Radio color="primary" />}
                          label={experienceTexts[Experience.AMATEUR]}
                        />
                        <FormControlLabel
                          className={classes.radioLabel}
                          value={Experience.NONE}
                          control={<Radio color="primary" />}
                          label={experienceTexts[Experience.NONE]}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </TitledPaper>
              </Grid>
              <Grid item xs={12}>
                <TitledPaper
                  title={texts.washerVerification.submitTitle}
                  subtitle={texts.washerVerification.submitDescription}
                >
                  <Box
                    flexBasis="100%"
                    display="flex"
                    alignItems="center"
                    mt={4}
                  >
                    <CustomButton
                      color="secondary"
                      disabled={
                        !isValid ||
                        washerVerificationState.sendingVerificationForm
                      }
                      type="submit"
                      variant="contained"
                      loading={washerVerificationState.sendingVerificationForm}
                    >
                      {texts.washerVerification.submitButton}
                    </CustomButton>
                  </Box>
                </TitledPaper>
              </Grid>
            </Grid>
          </form>
          <UploadFilesModal
            open={isCleanPhotoUploadModalOpen}
            onClose={() => setIsCleanPhotoUploadModalOpen(false)}
            title={"Clean photo of your vehicle"}
            files={values.cleanPhoto}
            onChange={(files) => setFieldValue("cleanPhoto", files)}
          />
          <UploadFilesModal
            open={isInsurancePhotoUploadModalOpen}
            onClose={() => setIsAddressPhotoUploadModalOpen(false)}
            title={"Auto insurance photo & expiration date"}
            files={values.insurancePhoto}
            onChange={(files) => setFieldValue("insurancePhoto", files)}
          />
          <UploadFilesModal
            open={isDriverLicensePhotoUploadModalOpen}
            onClose={() => setIsDriverLicensePhotoUploadModalOpen(false)}
            title={"Drivers license photo & expiration date"}
            files={values.driverLicensePhoto}
            onChange={(files) => setFieldValue("driverLicensePhoto", files)}
          />
        </>
      )}
    </Formik>
  );
};

export default WasherVerification;
