import {
  HeadCell,
  WasherPayedOrdersColumns,
} from "../../../../../../models/table.models";
import texts from "../../../../../../constants/texts";

const headCells: HeadCell<WasherPayedOrdersColumns>[] = [
  {
    id: WasherPayedOrdersColumns.ID,
    label: texts.roles.washer.payouts.requestPayout.tableHeaders.id,
    size: 3,
  },
  {
    id: WasherPayedOrdersColumns.DATE,
    label: texts.roles.washer.payouts.requestPayout.tableHeaders.date,
    size: 3,
  },
  {
    id: WasherPayedOrdersColumns.CLIENT_NAME,
    label: texts.roles.washer.payouts.requestPayout.tableHeaders.clientName,
    size: 3,
  },
  {
    id: WasherPayedOrdersColumns.PRICE,
    label: texts.roles.washer.payouts.requestPayout.tableHeaders.price,
    size: 2,
  },
];

export default headCells;
