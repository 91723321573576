import React, { useEffect } from "react";
import useActions from "../../../hooks/useActions";
import { UsersActions } from "../../../store/actions/users.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import ListSearch from "../../../components/UsersList/ListSearch";
import TableLayout from "../../../shared/sharedTable/TableLayout";
import texts from "../../../constants/texts";
import { UserSelectors } from "../../../store/reducers/users.reducer";
import { Grid } from "@material-ui/core";
import PromotersList from "./PromotersList";
import headCells from "./headCells";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const PromotersPage: React.FC<Props> = () => {
  useTitle(`Washerly Promoters`);
  const usersActions = useActions<typeof UsersActions>(UsersActions);
  const usersState = useSelector((state: AppState) => state.users);
  const promoters = UserSelectors.getFormattedPromoters(usersState);

  const searchValues = [
    texts.users.search.name,
    texts.users.search.email,
    texts.users.search.phoneNumber,
  ];

  useEffect(() => {
    usersActions.setPromotersSearchValue("");
  }, [usersActions]);

  const handleSearchChange = (value: string) => {
    usersActions.promotersSearchValue(value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ListSearch
          handleSearchChange={handleSearchChange}
          count={usersState.promoters.total}
          type={"promoter"}
          searchValues={searchValues}
        />
      </Grid>

      <TableLayout
        search={usersState.promotersSearch}
        title={texts.promoters.headerTitle}
        subtitle={texts.promoters.headerSubtitle}
        isLoading={usersState.promotersLoading}
        onPaginationChange={usersActions.getPromoters}
        headCells={headCells}
        total={+usersState.promoters.total}
      >
        <PromotersList data={promoters} />
      </TableLayout>
    </Grid>
  );
};

export default PromotersPage;
