import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const FireIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.727"
      height="28"
      viewBox="0 0 18.727 28"
      {...props}
    >
      <g id="surface1" transform="translate(-0.031)">
        <path
          id="Path_678"
          data-name="Path 678"
          d="M4.9,28C1.788,17.744,8.365,14,8.365,14a15.86,15.86,0,0,0,2.65,9.737,16.736,16.736,0,0,0,3.327-1.958A35.534,35.534,0,0,1,13.19,28a16.453,16.453,0,0,0,5.3-8.3c1.268-5.179-2.415-10.379-2.415-10.379A13.888,13.888,0,0,1,12.63,19.347a1.987,1.987,0,0,0,.3-.465c.436-.873,1.137-3.142.727-8.4C13.081,3.11,6.4,0,6.4,0c.576,4.494-1.151,5.529-5.2,14.059S4.9,28,4.9,28Zm0,0"
          transform="translate(0)"
          fill="#fd9800"
        />
      </g>
    </svg>
  );
};

export default FireIcon;
