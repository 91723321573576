import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const ImageIcon: React.FC<Props> = (props) => {
  return (
    <svg
      id="upload"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="19.259"
      height="17.905"
      viewBox="0 0 19.259 17.905"
      {...props}
    >
      <path
        id="Path_675"
        data-name="Path 675"
        d="M16.25,0H3.009A3.013,3.013,0,0,0,0,3.009V13.542a3.013,3.013,0,0,0,3.009,3.009H13.278a.752.752,0,1,0,0-1.5H10.507L8.778,12.8,14.259,5.83l3.69,4.078a.752.752,0,0,0,1.31-.5V3.009A3.013,3.013,0,0,0,16.25,0Zm1.5,7.451L14.777,4.16a.752.752,0,0,0-1.149.04l-5.8,7.374L5.862,9.02a.752.752,0,0,0-.6-.293h0a.752.752,0,0,0-.6.294L3.014,11.173a.752.752,0,0,0,1.193.917l1.06-1.378,3.341,4.334h-5.6a1.506,1.506,0,0,1-1.5-1.5V3.009a1.506,1.506,0,0,1,1.5-1.5H16.25a1.506,1.506,0,0,1,1.5,1.5Zm0,0"
      />
      <path
        id="Path_676"
        data-name="Path 676"
        d="M82.257,72a2.257,2.257,0,1,0,2.257,2.257A2.259,2.259,0,0,0,82.257,72Zm0,3.009a.752.752,0,1,1,.752-.752A.753.753,0,0,1,82.257,75.009Zm0,0"
        transform="translate(-76.991 -69.292)"
      />
      <path
        id="Path_677"
        data-name="Path 677"
        d="M357.343,288.562l-.007-.007a1.875,1.875,0,0,0-2.661,0l-1.452,1.433a.752.752,0,0,0,1.057,1.071l.972-.959v4.223a.752.752,0,0,0,1.5,0v-4.2l.931.961a.752.752,0,1,0,1.081-1.046Zm0,0"
        transform="translate(-339.721 -277.167)"
      />
    </svg>
  );
};

export default ImageIcon;
