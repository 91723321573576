import { axiosInstance } from "./base.api";
import { ReviewsForActiveUserParams } from "../models/requests/Reviews";
import { ReviewsResponse } from "../models/responses/review.model";

export const reviewApi = {
  getReviewsByUserId(params: ReviewsForActiveUserParams) {
    const config = {
      params: {
        ...params,
      },
    };
    return axiosInstance.get<ReviewsResponse>(`/private/reviews/user`, config);
  },
};
