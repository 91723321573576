import { put, takeLatest } from "@redux-saga/core/effects";
import { ResponseStatus } from "../../models/responses/result.model";
import { showError } from "./utils";
import {
  EarningsActions,
  EarningsActionTypes,
  getEarningsGraphDataForCompanyAdminType,
  getEarningsHistoryType,
  getEarningsPDFType,
} from "../actions/earnings.actions";
import { earningsApi } from "../../api/earnings.api";

function* getEarningsGraphDataForCompanyAdmin(
  action: getEarningsGraphDataForCompanyAdminType
) {
  try {
    const { data } = yield earningsApi.getEarningsGraphDataForCompanyAdmin(
      action.payload
    );
    if (data.result === ResponseStatus.SUCCESS) {
      const graphData = data.data;
      yield put(
        EarningsActions.getEarningsGraphDataForCompanyAdminSuccess(graphData)
      );
    }
  } catch (e) {
    yield showError(e);
    yield put(
      EarningsActions.getEarningsGraphDataForCompanyAdminFailure(e.message)
    );
  }
}

function* getEarningsHistory(action: getEarningsHistoryType) {
  try {
    const { data } = yield earningsApi.getEarningsHistory(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const listData = data.data;
      yield put(EarningsActions.getEarningsHistorySuccess(listData));
    }
  } catch (e) {
    yield showError(e);
    yield put(EarningsActions.getEarningsHistoryFailure(e.message));
  }
}

function* getEarningsPDF(action: getEarningsPDFType) {
  try {
    const pdf = yield earningsApi.getEarningsPDF(action.payload);
    const file = new Blob([pdf.data], {
      type: "application/pdf",
    });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  } catch (e) {
    yield showError(e);
  }
}

export function* EarningsSaga() {
  yield takeLatest(
    EarningsActionTypes.GET_EARNINGS_GRAPH_DATA_FOR_COMPANY_ADMIN,
    getEarningsGraphDataForCompanyAdmin
  );
  yield takeLatest(
    EarningsActionTypes.GET_EARNINGS_HISTORY,
    getEarningsHistory
  );
  yield takeLatest(EarningsActionTypes.GET_EARNINGS_PDF, getEarningsPDF);
}
