import React, { ReactNode, ReactNodeArray } from "react";
import {
  Box,
  createStyles,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { unitSize } from "../../constants/sizes";
import { numberWithSpaces } from "../../helpers/utils/utils";
import SortIcon from "../icons/SortIcon";
import RadialDivider from "../../styled/RadialDivider";
import { HeadCell, HeaderValue, TableHeaders } from "../../models/table.models";
import { SortParams } from "../../models/utils.models";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(4),
      marginBottom: theme.spacing(1.5),
      position: "relative",
    },
    volumeName: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
      whiteSpace: "nowrap",
    },
    volumeValue: {
      marginRight: theme.spacing(3),
      color: theme.palette.secondary.main,
      fontSize: 16,
      fontWeight: 300,
    },
    volumesDivider: {
      marginRight: theme.spacing(3),
    },
    radialDivider: {
      marginRight: theme.spacing(1),
    },
    progress: {
      position: "absolute",
      bottom: 3,
      left: 0,
      width: "100%",
    },
  })
);

interface OwnProps<T = TableHeaders> {
  title: string;
  subtitle: string;
  values?: HeaderValue[];
  valuesComponents?: ReactNodeArray;
  headCells: HeadCell<T>[];
  onSortChange?: (property: T) => void;
  order?: SortParams;
  orderBy?: string;
  isLoading?: boolean;
  headerLayout?: React.ReactNode;
  headCellsButton?: ReactNode;
  headerRightSide?: React.ReactNode;
}

type Props = OwnProps;

const TableHeader: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    title,
    subtitle,
    values,
    headCells,
    order,
    orderBy,
    onSortChange,
    isLoading,
  } = props;
  return (
    <Grid item xs={12}>
      <Paper className={classes.root}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={1}
          mb={2}
          pl={4}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box>
              <Box mb={1}>
                <Typography variant="h4" component="h3">
                  {title}
                </Typography>
              </Box>
              <Typography variant="body1" color="textSecondary">
                {subtitle}
              </Typography>
            </Box>
            {props.headerRightSide}
          </Box>
          {values && !!values.length && (
            <Box display="flex" pr={2}>
              {values.map(({ title, value, unit }, index) => (
                <Box
                  key={index}
                  height={unitSize * 3}
                  display="flex"
                  alignItems="center"
                >
                  {index > 0 && (
                    <Divider
                      className={classes.volumesDivider}
                      orientation="vertical"
                    />
                  )}
                  <Typography
                    variant="subtitle1"
                    className={classes.volumeName}
                  >
                    {title}
                  </Typography>
                  <span className={classes.volumeValue}>
                    {numberWithSpaces(value)} {!!unit && unit}
                  </span>
                </Box>
              ))}
            </Box>
          )}
          {!!props.valuesComponents && (
            <Box pr={2} display="flex">
              {props.valuesComponents.map((item, index) => (
                <Box
                  key={index}
                  height={unitSize * 3}
                  display="flex"
                  alignItems="center"
                >
                  {index > 0 && (
                    <Divider
                      className={classes.volumesDivider}
                      orientation="vertical"
                    />
                  )}
                  {item}
                </Box>
              ))}
            </Box>
          )}
        </Box>
        {props.headerLayout}
        <Box pl={4}>
          <Grid container alignItems="center">
            {headCells.map(({ id, label, size }, index) => (
              <Grid key={id} item xs={size}>
                <Box
                  pb={3}
                  pt={2}
                  pr={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    {index > 0 && (
                      <RadialDivider className={classes.radialDivider} />
                    )}
                    <Typography variant="body1" color="textPrimary">
                      {label}
                    </Typography>
                  </Box>
                  {onSortChange && (
                    <IconButton onClick={() => onSortChange(id)}>
                      <SortIcon order={orderBy === id ? order : undefined} />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            ))}
            {props.headCellsButton}
          </Grid>
        </Box>
        {isLoading && (
          <LinearProgress className={classes.progress} variant="query" />
        )}
      </Paper>
    </Grid>
  );
};

export default TableHeader;
