import React, { useCallback } from "react";
import texts from "../../../constants/texts";
import TableLayout from "../../../shared/sharedTable/TableLayout";
import headCells from "./headCells";
import { TablePaginationParams } from "../../../models/table.models";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import useActions from "../../../hooks/useActions";
import { ReferralsActions } from "../../../store/actions/referrals.actions";
import ReferralsListRow from "./components/ReferralsListRow";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const Referrals: React.FC<Props> = () => {
  useTitle(`Washerly Referrals`);
  const referralsState = useSelector((state: AppState) => state.referrals);
  const referralsActions = useActions<typeof ReferralsActions>(
    ReferralsActions
  );
  const handlePaginationChange = useCallback(
    (values: TablePaginationParams) => {
      referralsActions.getPromotersReferralsList({ ...values });
    },
    [referralsActions]
  );

  return (
    <TableLayout
      title={texts.roles.promoter.referrals.title}
      subtitle={texts.roles.promoter.referrals.subtitle}
      isLoading={false}
      total={referralsState.promoterReferralsList.total}
      headCells={headCells}
      onPaginationChange={handlePaginationChange}
      withoutSorting
    >
      {referralsState.promoterReferralsList.items.map((referral) => (
        <ReferralsListRow referral={referral} />
      ))}
    </TableLayout>
  );
};

export default Referrals;
