import React, { useEffect, useState } from "react";
import UnauthorizedLayout from "../../../layout/UnauthorizedLayout";
import Typography from "@material-ui/core/Typography";
import colors from "../../../constants/colors";
import Container from "@material-ui/core/Container";
import ColoredText from "../../../styled/ColoredText";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Grow,
  MenuItem,
} from "@material-ui/core";
import FormInput from "../../../shared/FormInput";
import CustomButton from "../../../shared/CustomButton";
import { Formik, FormikHelpers } from "formik";
import {
  SignUpDataSchema,
  SignUpShouldVerifySchema,
} from "../../../helpers/validations";
import ArrowDropDownIcon from "../../../styled/ArrowDropDownIcon";
import CompanyIcon from "../../../shared/icons/CompanyIcon";
import WasherIcon from "../../../shared/icons/WasherIcon";
import SecureInput from "../../../shared/SecureInput";
import SignUpIcon from "../../../shared/icons/SignUpIcon";
import { unitSize } from "../../../constants/sizes";
import texts from "../../../constants/texts";
import useActions from "../../../hooks/useActions";
import { AuthActions } from "../../../store/actions/auth.actions";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import { UserRole } from "../../../models/responses/user.model";
import { useHistory } from "react-router-dom";
import { AuthSelectors } from "../../../store/reducers/auth.reducer";
import { RouteNames } from "../../../constants/routeNames";
import { authApi } from "../../../api/auth.api";
import { PromoCode } from "../../../models/responses/auth.model";
import { useStyles } from "./style";
import { useTitle } from "react-use";

const userTypes = [
  {
    value: UserRole.COMPANY_ADMIN,
    label: texts.auth.signUp.userTypes.company,
    icon: CompanyIcon,
  },
  {
    value: UserRole.PENDING_WASHER,
    label: texts.auth.signUp.userTypes.washer,
    icon: WasherIcon,
  },
  {
    value: UserRole.PROMOTER,
    label: texts.auth.signUp.userTypes.promoter,
    icon: WasherIcon, // TODO: get icon for promoter
  },
];

interface SignUpFormData {
  userType: UserRole;
  firstName: string;
  secondName: string;
  email: string;
  phoneNumber: string;
  password: string;
  passwordConfirm: string;
  consent: boolean;
  authorizationCode: string;
  referralCode: string;
  emailVerificationCode: string;
}

function getIconForUserType(selectValue: string) {
  const userType = userTypes.find(({ value }) => selectValue === value);
  return userType ? userType.icon : undefined;
}

interface OwnProps {}

type Props = OwnProps;

const SignUp: React.FC<Props> = () => {
  useTitle(`Washerly SignUp`);
  const history = useHistory();
  const classes = useStyles();
  const authActions = useActions<typeof AuthActions>(AuthActions);
  const authState = useSelector((state: AppState) => state.auth);
  const isAuthorized = AuthSelectors.isAuthorized(authState);
  const [couponDetails, setCouponDetails] = useState<PromoCode | undefined>();

  useEffect(() => {
    if (isAuthorized) {
      history.push(`/${RouteNames.ROOT}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  function handleFormSubmit(
    values: SignUpFormData,
    actions: FormikHelpers<SignUpFormData>
  ) {
    if (!authState.isSignUpCodesSent) {
      const data = {
        phoneNumber: values.phoneNumber,
        email: values.email,
      };
      authActions.signUpSendVerificationCodes(data);
      actions.resetForm({ values: { ...values } });
    } else {
      const signUpData = {
        role: values.userType,
        firstName: values.firstName,
        secondName: values.secondName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        password: values.password,
        authorizationCode: values.authorizationCode,
        emailVerificationCode: values.emailVerificationCode,
        couponCode: referralCodePayloadLogic(values),
      };
      authActions.signUpSendUserData(signUpData);
    }
  }

  function referralCodePayloadLogic(
    values: SignUpFormData
  ): string | undefined {
    return values.userType !== UserRole.PROMOTER
      ? couponDetails?.isValid
        ? values.referralCode
        : undefined
      : undefined;
  }

  function getCouponDetails(value: string) {
    if (value.length) {
      authApi
        .getPromoCodeStats(value)
        .then((res) => {
          const newDetails = {
            ...res.data.data,
            isValid: true,
          };
          setCouponDetails(newDetails);
        })
        .catch(() => setCouponDetails({ isValid: false }));
    } else {
      setCouponDetails(undefined);
    }
  }

  return (
    <UnauthorizedLayout>
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={8}>
            <Typography gutterBottom align="left" component="h1" variant="h1">
              {texts.auth.signUp.welcome},{" "}
              <ColoredText color={colors.blue}>
                {texts.auth.signUp.toSignUp}
              </ColoredText>
            </Typography>
            <Typography gutterBottom variant="subtitle1" color="textSecondary">
              {texts.auth.signUp.description}
            </Typography>
          </Grid>

          <Formik
            initialValues={{
              userType: UserRole.WASHER,
              firstName: "",
              secondName: "",
              email: "",
              phoneNumber: "+",
              password: "",
              passwordConfirm: "",
              emailVerificationCode: "",
              authorizationCode: "",
              consent: false,
              referralCode: "",
            }}
            onSubmit={handleFormSubmit}
            validationSchema={
              !authState.isSignUpCodesSent
                ? SignUpShouldVerifySchema
                : SignUpDataSchema
            }
          >
            {({
              values,
              handleChange,
              errors,
              touched,
              isValid,
              handleSubmit,
              handleBlur,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={4}>
                      <FormInput
                        id="userType"
                        select
                        label={texts.auth.signUp.userTypeLabel}
                        value={values.userType}
                        onChange={handleChange}
                        helperText={
                          errors.userType && touched.userType && errors.userType
                        }
                        margin="normal"
                        variant="filled"
                        onBlur={handleBlur}
                        fullWidth
                        name="userType"
                        error={!!errors.userType && touched.userType}
                        StartAdornment={getIconForUserType(values.userType)}
                        SelectProps={{
                          IconComponent: ArrowDropDownIcon,
                        }}
                      >
                        {userTypes.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            style={{ fontSize: 14 }}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </FormInput>
                    </Grid>
                    <Grid item xs={4}>
                      <FormInput
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        error={!!errors.firstName && touched.firstName}
                        helperText={
                          errors.firstName &&
                          touched.firstName &&
                          errors.firstName
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormInput
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.secondName}
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="secondName"
                        label="Last Name"
                        name="secondName"
                        error={!!errors.secondName && touched.secondName}
                        helperText={
                          errors.secondName &&
                          touched.secondName &&
                          errors.secondName
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={4}>
                      <FormInput
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="email"
                        label={texts.auth.signUp.emailLabel}
                        name="email"
                        error={!!errors.email && touched.email}
                        disabled={authState.isSignUpCodesSent}
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                      />
                    </Grid>
                    {authState.isSignUpCodesSent && (
                      <Grid item xs={2}>
                        <Grow in={authState.isSignUpCodesSent}>
                          <FormInput
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.emailVerificationCode}
                            variant="filled"
                            name="emailVerificationCode"
                            label={texts.auth.signUp.emailVerificationCodeLabel}
                            id="emailVerificationCode"
                            autoFocus
                            margin="normal"
                            fullWidth
                            error={
                              !!errors.emailVerificationCode &&
                              touched.emailVerificationCode
                            }
                          />
                        </Grow>
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <FormInput
                        type="tel"
                        onChange={(event) =>
                          setFieldValue(
                            "phoneNumber",
                            event.target.value.replace(/[^+0-9]/g, "")
                          )
                        }
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="phoneNumber"
                        label={texts.auth.signUp.phoneLabel}
                        name="phoneNumber"
                        error={!!errors.phoneNumber && touched.phoneNumber}
                        helperText={
                          errors.phoneNumber &&
                          touched.phoneNumber &&
                          errors.phoneNumber
                        }
                        disabled={authState.isSignUpCodesSent}
                      />
                    </Grid>
                    {authState.isSignUpCodesSent && (
                      <Grid item xs={2}>
                        <Grow in={authState.isSignUpCodesSent}>
                          <FormInput
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.authorizationCode}
                            variant="filled"
                            name="authorizationCode"
                            label={texts.auth.signUp.smsVerificationCodeLabel}
                            id="verification_code"
                            margin="normal"
                            fullWidth
                            error={
                              !!errors.authorizationCode &&
                              touched.authorizationCode
                            }
                          />
                        </Grow>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={4}>
                      <SecureInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        variant="filled"
                        margin="normal"
                        fullWidth
                        name="password"
                        label={texts.auth.signUp.passwordLabel}
                        id="password"
                        error={!!errors.password && touched.password}
                        helperText={
                          errors.password && touched.password && errors.password
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SecureInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.passwordConfirm}
                        variant="filled"
                        margin="normal"
                        fullWidth
                        name="passwordConfirm"
                        label={texts.auth.signUp.passwordConfirmLabel}
                        id="passwordConfirm"
                        error={
                          !!errors.passwordConfirm && touched.passwordConfirm
                        }
                        helperText={
                          errors.passwordConfirm &&
                          touched.passwordConfirm &&
                          errors.passwordConfirm
                        }
                      />
                    </Grid>
                  </Grid>
                  {values.userType !== UserRole.PROMOTER && (
                    <Grid
                      container
                      alignItems="center"
                      xs={12}
                      spacing={3}
                      style={{
                        marginTop: "25px",
                        marginBottom: "25px",
                      }}
                    >
                      <Grid item xs={6}>
                        <FormInput
                          onChange={handleChange}
                          onBlur={(e) => getCouponDetails(e.target.value)}
                          value={values.referralCode}
                          variant="filled"
                          fullWidth
                          name="referralCode"
                          label={texts.auth.signUp.referralCodeLabel}
                          id="referralCode"
                        />
                      </Grid>
                      {couponDetails?.isValid !== undefined && (
                        <Grid item xs={3} className={classes.couponStatus}>
                          Introduced coupon
                          <span
                            className={
                              couponDetails?.isValid ? "valid" : "invalid"
                            }
                          >
                            {couponDetails?.isValid ? "Active" : "Invalid"}
                          </span>
                        </Grid>
                      )}
                      {couponDetails?.name && (
                        <Grid item xs={3} className={classes.couponOwner}>
                          You were referred by
                          <span>{couponDetails?.name}</span>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  <Box mt={3} display="flex" alignItems="center">
                    <CustomButton
                      color="primary"
                      rounded
                      disabled={!isValid || authState.isSignUpLoading}
                      type="submit"
                      variant="contained"
                      LeftIcon={SignUpIcon}
                      loading={authState.isSignUpLoading}
                    >
                      {texts.auth.signUp.signUp}
                    </CustomButton>
                    <FormControlLabel
                      style={{ marginLeft: unitSize * 4 }}
                      control={
                        <Checkbox
                          name="consent"
                          id="consent"
                          onChange={handleChange}
                          color="primary"
                        />
                      }
                      label={texts.auth.signUp.consentLabel}
                    />
                  </Box>
                </Grid>
                <Typography variant="subtitle1" color="error">
                  {errors.consent && touched.consent && errors.consent}
                </Typography>
              </form>
            )}
          </Formik>
        </Grid>
      </Container>
    </UnauthorizedLayout>
  );
};

export default SignUp;
