import palette from "../palette";

export default {
  paper: {
    width: 510,
    height: 370,
    borderRadius: 20,
    backgroundColor: palette.background.default,
  },
};
