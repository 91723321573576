import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const ImageFileIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="21.732"
      height="24.295"
      viewBox="0 0 21.732 24.295"
      {...props}
    >
      <path
        id="image"
        d="M13.571,18.458a.949.949,0,1,1,.949.949A.949.949,0,0,1,13.571,18.458ZM21.732,16.7v5.22a2.375,2.375,0,0,1-2.373,2.373H11.768A2.375,2.375,0,0,1,9.4,21.922V16.7a2.375,2.375,0,0,1,2.373-2.373H19.36A2.375,2.375,0,0,1,21.732,16.7Zm-10.439,0v5.21l.807-.807a1.424,1.424,0,0,1,2.013,0l.556.556,1.615-1.583a1.423,1.423,0,0,1,2,.01l1.547,1.548V16.7a.475.475,0,0,0-.475-.475H11.768A.475.475,0,0,0,11.293,16.7ZM4.7,8.019A3.325,3.325,0,0,0,8.019,4.7a.949.949,0,0,0-1.9,0A1.425,1.425,0,0,1,4.7,6.121H2.061A1.81,1.81,0,0,1,2.424,5.6L5.557,2.435A1.825,1.825,0,0,1,6.846,1.9h8.618a1.9,1.9,0,0,1,1.9,1.9v7.687a.949.949,0,1,0,1.9,0V3.8a3.8,3.8,0,0,0-3.8-3.8H6.846A3.736,3.736,0,0,0,4.209,1.1L1.076,4.26A3.69,3.69,0,0,0,0,6.873V20.5a3.8,3.8,0,0,0,3.8,3.8H6.548a.949.949,0,1,0,0-1.9H3.8a1.9,1.9,0,0,1-1.9-1.9V8.019Zm0,0"
      />
    </svg>
  );
};

export default ImageFileIcon;
