import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import useActions from "../../../../../hooks/useActions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../store/reducers";
import { TablePaginationParams } from "../../../../../models/table.models";
import ListSearch from "../../../../../components/UsersList/ListSearch";
import TableLayout from "../../../../../shared/sharedTable/TableLayout";
import texts from "../../../../../constants/texts";
import { ID } from "../../../../../models/utils.models";
import { PendingWashersActions } from "../../../../../store/actions/pendingWashers.actions";
import { Grid, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "../../../../../styled/ArrowDropDownIcon";
import FormInput from "../../../../../shared/FormInput";
import { PendingWashersSelectors } from "../../../../../store/reducers/pendingWashers.reducer";
import {
  pendingCompanyWashersListHeadCells,
  pendingIndividualWashersListHeadCells,
} from "./headCells";
import { VerificationFormStatusFilter } from "../../../../../models/requests/PendingWashers";
import SimpleTableRow from "../../../../../shared/sharedTable/SimpleTableRow";
import PendingStatusCell from "../PendingStatusCell";

const statusTypes = [
  {
    value: VerificationFormStatusFilter.REQUESTED_OR_APPROVE,
    label: texts.roles.admin.pendingWashers.status.pending,
  },
];

interface OwnProps {
  headerTitle: string;
  headerSubtitle: string;
  withCompany?: boolean;
}

type Props = OwnProps;

const PendingWashers: React.FC<Props> = (props) => {
  const [searchValues, setSearchValues] = useState<string[]>([]);
  const [status, setStatus] = useState<VerificationFormStatusFilter>(
    VerificationFormStatusFilter.REQUESTED_OR_APPROVE
  );
  const match = useRouteMatch();
  const history = useHistory();
  const pendingWashersActions = useActions<typeof PendingWashersActions>(
    PendingWashersActions
  );
  const pendingWashersState = useSelector(
    (state: AppState) => state.pendingWashers
  );
  const users = PendingWashersSelectors.getFormattedUsers(pendingWashersState);
  const headCells = props.withCompany
    ? pendingCompanyWashersListHeadCells
    : pendingIndividualWashersListHeadCells;

  useEffect(() => {
    const baseSearch = [
      texts.users.search.email,
      texts.users.search.name,
      texts.users.search.phoneNumber,
    ];
    if (props.withCompany) {
      setSearchValues([...baseSearch, texts.users.search.company]);
    } else setSearchValues(baseSearch);
  }, [props.withCompany]);

  useEffect(() => {
    pendingWashersActions.setPendingWashersSearchValue("");
  }, [pendingWashersActions]);

  const handlePaginationChange = useCallback(
    (values: TablePaginationParams) => {
      pendingWashersActions.getPendingWashers({
        ...values,
        isCompanyEmployee: props.withCompany,
        status,
      });
    },
    [props.withCompany, status, pendingWashersActions]
  );

  const handleSearchChange = (value: string) => {
    pendingWashersActions.pendingWashersSearchValue(value);
  };

  return (
    <>
      <Grid item xs={9}>
        <ListSearch
          handleSearchChange={handleSearchChange}
          count={pendingWashersState.pendingWashers.total}
          type={"washer"}
          searchValues={searchValues}
        />
      </Grid>
      <Grid item xs={3} style={{ alignSelf: "center" }}>
        <FormInput
          select
          label={texts.roles.admin.pendingWashers.status.label}
          value={status}
          onChange={(event) =>
            setStatus(event.target.value as VerificationFormStatusFilter)
          }
          variant="filled"
          fullWidth
          SelectProps={{
            IconComponent: ArrowDropDownIcon,
          }}
        >
          {statusTypes.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{ fontSize: 14 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </FormInput>
      </Grid>

      <TableLayout
        search={pendingWashersState.pendingWashersSearch}
        title={props.headerTitle}
        subtitle={props.headerSubtitle}
        isLoading={pendingWashersState.pendingWashersLoading}
        onPaginationChange={handlePaginationChange}
        headCells={headCells}
        total={+pendingWashersState.pendingWashers.total}
      >
        {users.map((row) => (
          <SimpleTableRow
            key={row.id}
            row={row}
            columns={headCells}
            arrowAction={(id: ID) => history.push(`${match.url}/${id}`)}
            specificRender={{
              status: <PendingStatusCell status={row.status} />,
            }}
          />
        ))}
      </TableLayout>
    </>
  );
};

export default PendingWashers;
