import { HeadCell, SessionsColumns } from "../../../models/table.models";

const headCells: HeadCell<SessionsColumns>[] = [
  {
    id: SessionsColumns.STATUS,
    label: "Status",
    size: 2,
  },
  {
    id: SessionsColumns.START_TIME,
    label: "From",
    size: 3,
  },
  {
    id: SessionsColumns.END_TIME,
    label: "To",
    size: 3,
  },
  {
    id: SessionsColumns.DURATION,
    label: "Duration",
    size: 2,
  },
  {
    id: SessionsColumns.COMPLETED_ORDERS,
    label: "Orders",
    size: 2,
  },
];

export default headCells;
