import React from "react";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import TitledPaper from "../shared/TitledPaper";
import Loading from "../shared/Loading";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      minHeight: 228,
    },
    emptyText: {
      fontWeight: 300,
      marginTop: theme.spacing(2),
    },
  })
);

interface OwnProps {
  title: string;
  subtitle: string;
  emptyText: string;
  Icon: React.FC;
  loading?: boolean;
}

type Props = OwnProps;

const EmptyData: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <TitledPaper title={props.title} subtitle={props.subtitle}>
        <Box
          pt={4}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {props.loading ? (
            <Loading />
          ) : (
            <>
              <props.Icon />
              <Typography
                color="primary"
                variant="h4"
                className={classes.emptyText}
              >
                {props.emptyText}
              </Typography>
            </>
          )}
        </Box>
      </TitledPaper>
    </Grid>
  );
};

export default EmptyData;
