export const getPolygonOptions = (
  color: string,
  zIndex: number,
  isHover: boolean,
  isSelected: boolean,
  clickable: boolean
) => {
  let strokeOpacity = 0.6;
  let strokeWeight = 1;
  let fillOpacity = 0.25;
  if (isHover) {
    strokeOpacity = 0.8;
    strokeWeight = 2;
    fillOpacity = 0.35;
  }
  if (isSelected) {
    strokeOpacity = 1;
    strokeWeight = 3;
    fillOpacity = 0.45;
  }
  return {
    strokeColor: color,
    strokeOpacity,
    strokeWeight,
    fillColor: color,
    fillOpacity,
    clickable,
    editable: isSelected && !clickable,
    zIndex,
  };
};
