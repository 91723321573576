import { put, takeLatest } from "@redux-saga/core/effects";
import {
  CarsActions,
  CarsActionTypes,
  getCarsForActiveUserType,
} from "../actions/cars.actions";
import { carApi } from "../../api/car.api";
import { ResponseStatus } from "../../models/responses/result.model";
import { showError } from "./utils";
import { transformAndValidate } from "class-transformer-validator";
import { Cars } from "../../models/responses/car.model";

function* getCarsForUser(action: getCarsForActiveUserType) {
  try {
    const { data } = yield carApi.getCarsByUserId(action.payload);
    if (data.result === ResponseStatus.SUCCESS) {
      const userCars = yield transformAndValidate(Cars, data.data);
      yield put(CarsActions.getCarsForActiveUserSuccess(userCars));
    }
  } catch (e) {
    yield showError(e);
    yield put(CarsActions.getCarsForActiveUserFailure(e.message));
  }
}

export function* CarsSagas() {
  yield takeLatest(CarsActionTypes.GET_CARS_FOR_ACTIVE_USER, getCarsForUser);
}
