import React from "react";
import { StatusColors } from "../../../../models/utils.models";
import { HeadCell, SessionsColumns } from "../../../../models/table.models";
import { capitalizeFirstLetter } from "../../../../helpers/utils/utils";
import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { useStyles } from "../../../../pages/washer/WasherPayouts/components/WasherPayoutRow/style";
import { unitSize } from "../../../../constants/sizes";
import { SESSION_STATUS } from "../../../../models/responses/session.model";
import BorderedValue from "../../../../styled/BorderedValue";

interface OwnProps {
  row: { [key: string]: string };
  columns: HeadCell<SessionsColumns>[];
}

type Props = OwnProps;

const SessionRow: React.FC<Props> = (props) => {
  const getColorByStatus = (status: SESSION_STATUS) => {
    switch (status) {
      case SESSION_STATUS.ONLINE:
        return StatusColors.GREEN;
      case SESSION_STATUS.OFFLINE:
        return StatusColors.RED;
    }
  };
  const renderCell = (id: SessionsColumns) => {
    switch (id) {
      case SessionsColumns.STATUS:
        return (
          <BorderedValue
            filled
            color={getColorByStatus(props.row[id] as SESSION_STATUS)}
          >
            {capitalizeFirstLetter(props.row[id].toLowerCase())}
          </BorderedValue>
        );
      case SessionsColumns.START_TIME:
      case SessionsColumns.END_TIME:
      case SessionsColumns.DURATION:
      case SessionsColumns.COMPLETED_ORDERS:
        return (
          <Typography color="textPrimary" variant="body1" noWrap>
            {props.row[id]}
          </Typography>
        );
    }
  };
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container>
          {props.columns.map(({ id, size }, index) => (
            <Grid key={id} item xs={size}>
              <Box
                pb={2}
                pt={2}
                pr={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  height={unitSize * 4}
                  className={classes.textContainer}
                >
                  {index > 0 && (
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                  )}
                  {renderCell(id)}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default SessionRow;
