import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import { OutlinedInputProps } from "@material-ui/core/OutlinedInput";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import roundedTextInput from "../mui/styles/roundedTextInput";

const handleMouseDownPassword = (
  event: React.MouseEvent<HTMLButtonElement>
) => {
  event.preventDefault();
};

interface SecureInputProps {
  visibleIcon?: boolean;
}

const SecureInput: React.FC<TextFieldProps & SecureInputProps> = ({
  visibleIcon,
  ...props
}) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  const classes = roundedTextInput();
  return (
    <TextField
      type={isShowPassword ? "text" : "password"}
      InputProps={
        {
          classes,
          disableUnderline: true,
          endAdornment: visibleIcon && (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {isShowPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        } as Partial<OutlinedInputProps>
      }
      {...props}
    />
  );
};

export default SecureInput;
