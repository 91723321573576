import { Payouts } from "../../models/responses/payout.model";
import { PayoutsActions, PayoutsActionTypes } from "../actions/payouts.actions";
import { formatDate } from "../../helpers/utils/date.utils";
import { formatCurrency } from "../../helpers/utils/utils";
import { CompanyOrders, Orders } from "../../models/responses/order.model";
import { Referrals } from "../../models/responses/referrals.model";
import { Currency } from "../../models/utils.models";

class StateModel {
  payouts: Payouts = { items: [], total: 0 };
  payoutsLoading: boolean = false;
  payoutsError: string = "";
  payedOrders: Orders = { items: [], total: 0 };
  payedOrdersLoading: boolean = false;
  payedOrdersError: string = "";
  unpaidCompanyOrders: CompanyOrders = { items: [], total: 0 };
  unpaidCompanyOrdersLoading: boolean = false;
  unpaidCompanyOrdersError: string = "";
  payedReferrals: Referrals = { items: [], total: 0 };
  payedReferralsLoading: boolean = false;
  payedReferralsError: string = "";
  requestPayoutLoading: boolean = false;
  requestPayoutError: string = "";
  dispatchPayoutLoading: boolean = false;
  dispatchPayoutError: string = "";
}

const initialState = new StateModel();

export function payoutsReducer(
  state = initialState,
  action: PayoutsActions
): StateModel {
  switch (action.type) {
    case PayoutsActionTypes.GET_PAYOUTS_FOR_USER: {
      return {
        ...state,
        payoutsError: "",
        payoutsLoading: true,
      };
    }
    case PayoutsActionTypes.GET_PAYOUTS_FOR_ADMIN: {
      return {
        ...state,
        payoutsError: "",
        payoutsLoading: true,
      };
    }
    case PayoutsActionTypes.GET_PAYOUTS_SUCCESS: {
      return {
        ...state,
        payoutsLoading: false,
        payouts: action.payload,
      };
    }
    case PayoutsActionTypes.GET_PAYOUTS_FAILURE: {
      return {
        ...state,
        payoutsLoading: false,
        payoutsError: action.payload,
      };
    }
    case PayoutsActionTypes.GET_PAYED_ORDERS_LIST_FOR_WASHER: {
      return {
        ...state,
        payedOrdersLoading: true,
        payedOrdersError: "",
      };
    }
    case PayoutsActionTypes.GET_PAYED_ORDERS_LIST_SUCCESS: {
      return {
        ...state,
        payedOrdersLoading: false,
        payedOrders: action.payload,
      };
    }
    case PayoutsActionTypes.GET_PAYED_ORDERS_LIST_FAILURE: {
      return {
        ...state,
        payedOrdersLoading: false,
        payedOrdersError: action.payload,
      };
    }
    case PayoutsActionTypes.GET_UNPAID_COMPANY_ORDERS:
      return {
        ...state,
        unpaidCompanyOrdersLoading: true,
        unpaidCompanyOrdersError: "",
      };
    case PayoutsActionTypes.GET_UNPAID_COMPANY_ORDERS_SUCCESS:
      return {
        ...state,
        unpaidCompanyOrdersLoading: false,
        unpaidCompanyOrders: action.payload,
      };
    case PayoutsActionTypes.GET_UNPAID_COMPANY_ORDERS_FAILURE:
      return {
        ...state,
        unpaidCompanyOrdersLoading: false,
        unpaidCompanyOrdersError: action.payload,
      };
    case PayoutsActionTypes.GET_PAYED_REFERRALS_LIST_FOR_PROMOTER: {
      return {
        ...state,
        payedReferralsLoading: true,
        payedReferralsError: "",
      };
    }
    case PayoutsActionTypes.GET_PAYED_REFERRALS_LIST_SUCCESS: {
      return {
        ...state,
        payedReferralsLoading: false,
        payedReferrals: action.payload,
      };
    }
    case PayoutsActionTypes.GET_PAYED_REFERRALS_LIST_FAILURE: {
      return {
        ...state,
        payedReferralsLoading: true,
        payedReferralsError: action.payload,
      };
    }
    case PayoutsActionTypes.REQUEST_WASHER_PAYOUT: {
      return {
        ...state,
        requestPayoutLoading: true,
        requestPayoutError: "",
      };
    }
    case PayoutsActionTypes.REQUEST_PROMOTER_PAYOUT: {
      return {
        ...state,
        requestPayoutLoading: true,
        requestPayoutError: "",
      };
    }
    case PayoutsActionTypes.REQUEST_COMPANY_PAYOUT: {
      return {
        ...state,
        requestPayoutLoading: true,
        requestPayoutError: "",
      };
    }
    case PayoutsActionTypes.REQUEST_PAYOUT_SUCCESS: {
      return {
        ...state,
        requestPayoutLoading: false,
      };
    }
    case PayoutsActionTypes.REQUEST_PAYOUT_FAILURE: {
      return {
        ...state,
        requestPayoutLoading: false,
        requestPayoutError: action.payload,
      };
    }
    case PayoutsActionTypes.ACCEPT_PAYOUT: {
      return {
        ...state,
        dispatchPayoutLoading: true,
        dispatchPayoutError: "",
      };
    }
    case PayoutsActionTypes.REJECT_PAYOUT: {
      return {
        ...state,
        dispatchPayoutLoading: true,
        dispatchPayoutError: "",
      };
    }
    case PayoutsActionTypes.DISPATCH_WASHER_PAYOUT_SUCCESS: {
      return {
        ...state,
        payouts: {
          items: state.payouts.items.filter(
            (payout) => payout.id !== action.payload
          ),
          total: state.payouts.total - 1,
        },
        dispatchPayoutLoading: false,
      };
    }
    case PayoutsActionTypes.DISPATCH_WASHER_PAYOUT_FAILURE: {
      return {
        ...state,
        dispatchPayoutLoading: false,
        dispatchPayoutError: action.payload,
      };
    }
    default:
      return state;
  }
}

export const payoutsSelectors = {
  getPayoutsDataForWasher: (state: StateModel) =>
    state.payouts.items.map((item) => ({
      id: item.id,
      date: formatDate(item.createdTime)[0],
      total: formatCurrency(item.amount, item.currency),
      status: item.status,
      invoiceFileId: item.invoiceFileId,
    })),
  getPayoutsDataForAdmin: (state: StateModel) =>
    state.payouts.items.map((item) => ({
      id: item.id,
      date: formatDate(item.createdTime)[0],
      fullName: `${item.user.firstName} ${item.user.secondName}`,
      amount: formatCurrency(item.amount, item.currency),
      status: item.status,
      invoiceFileId: item.invoiceFileId,
    })),
  getPayedOrdersData: (state: StateModel) =>
    state.payedOrders.items.map((item) => ({
      id: item.id,
      date: formatDate(item.createdTime)[0],
      clientName: `${item.customer.firstName} ${item.customer.secondName}`,
      price: formatCurrency(
        item.washerPayoutPrice +
          (item.tipPayoutPrice ? item.tipPayoutPrice : 0),
        item.currency
      ),
    })),
  getUnpaidCompanyOrdersData: (state: StateModel) =>
    state.unpaidCompanyOrders.items.map((item) => ({
      id: item.id,
      date: formatDate(item.createdTime)[0],
      washerName: `${item.washer.firstName} ${item.washer.secondName}`,
      commission: formatCurrency(item.feeAmount, item.currency),
    })),
  getPayedReferralsData: (state: StateModel) =>
    state.payedReferrals.items.map((item) => ({
      id: item.id,
      date: formatDate(item.createdTime)[0],
      referralName: `${item.referralUser.firstName || ""} ${
        item.referralUser.secondName || ""
      }`,
      price: formatCurrency(item.feeAmount, Currency.EUR),
    })),
};
