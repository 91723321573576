import React from "react";

interface OwnProps {}

type Props = OwnProps;

const EditIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.765"
      fill="currentColor"
      height="15.791"
      viewBox="0 0 15.765 15.791"
    >
      <path
        id="pencil_1_"
        data-name="pencil (1)"
        d="M15.765,2.465A2.469,2.469,0,0,0,11.549.723L1.611,10.7A2.958,2.958,0,0,0,.9,11.857l-.009.028L0,15.791l3.917-.877.029-.01A2.962,2.962,0,0,0,5.1,14.19l9.939-9.979A2.453,2.453,0,0,0,15.765,2.465ZM2.172,14.041l-.415-.416.3-1.319,1.438,1.439Zm12-10.7L4.521,13.029,2.773,11.28l6.61-6.636,1.006,1.006.872-.872L10.253,3.771l.938-.941L12.2,3.84l.872-.872L12.06,1.956l.361-.363A1.236,1.236,0,1,1,14.17,3.341Zm-7.685,11.2h9.28v1.233H5.256Zm0,0"
        transform="translate(0 0.001)"
      />
    </svg>
  );
};

export default EditIcon;
