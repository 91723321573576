import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import { VERIFY_TYPE } from "../../../models/requests/Credentials";
import ChangeEmail from "./ChangeEmail/ChangeEmail";
import ChangePhone from "./ChangePhone/ChangePhone";
import useActions from "../../../hooks/useActions";
import { AuthActions } from "../../../store/actions/auth.actions";
import Popup from "../../../shared/Popup";

interface OwnProps {}

type Props = OwnProps;

const ChangeCredentialsModal: React.FC<Props> = () => {
  const authActions = useActions<typeof AuthActions>(AuthActions);
  const { changeCredentialModalState } = useSelector(
    (state: AppState) => state.auth
  );
  const handleModalClose = () => {
    authActions.closeChangeCredentialModal();
  };

  switch (changeCredentialModalState.type) {
    case VERIFY_TYPE.EMAIL:
      return (
        <Popup
          open={changeCredentialModalState.isOpen}
          handleClose={handleModalClose}
          title={"Change Email"}
        >
          <ChangeEmail step={changeCredentialModalState.step} />
        </Popup>
      );
    case VERIFY_TYPE.PHONE:
      return (
        <Popup
          open={changeCredentialModalState.isOpen}
          handleClose={handleModalClose}
          title={"Change Phone Number"}
        >
          <ChangePhone step={changeCredentialModalState.step} />
        </Popup>
      );
    default:
      return null;
  }
};

export default ChangeCredentialsModal;
