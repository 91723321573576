import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(3),
      alignItems: "center",
    },
    divider: {
      height: theme.spacing(6),
      marginLeft: theme.spacing(1),
    },
  })
);
