import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(6),
    height: theme.spacing(13),
    display: "flex",
    alignItems: "center",
  },
}));
