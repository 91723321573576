import React from "react";
import { Grid } from "@material-ui/core";
import OptionsBlock from "./OptionsBlock";
import SessionsList from "./SessionsList";
import { ID } from "../../models/utils.models";

interface OwnProps {
  userId?: ID;
}

type Props = OwnProps;

const Sessions: React.FC<Props> = (props) => {
  return (
    <Grid item container spacing={2}>
      <Grid item xs={4}>
        <OptionsBlock />
      </Grid>
      <Grid container item xs={8}>
        <SessionsList userId={props.userId} />
      </Grid>
    </Grid>
  );
};

export default Sessions;
