export default {
  htmlFontSize: 16,
  fontSize: 12,
  h1: {
    fontWeight: 400,
    fontSize: "1.56rem", //25px
  },
  h2: {
    fontWeight: 400,
    fontSize: "1.25rem", //20px
  },
  h3: {
    fontWeight: 400,
    fontSize: "1.125rem", //18px
  },
  h4: {
    fontWeight: 400,
    fontSize: "1rem", //16px
  },
  h5: {
    fontSize: ".875rem", //14px
    fontWeight: 400,
  },
  h6: {
    fontWeight: 400,
    fontSize: ".75rem", //12px
  },
  subtitle1: {
    fontSize: ".875rem", //14px
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: ".625rem", //10px
    fontWeight: 300,
  },
  body1: {
    fontSize: ".75rem", //12px
    fontWeight: 300,
  },
  body2: {
    fontSize: ".75rem", //12px
    fontWeight: 400,
  },
  button: {},
  overline: {
    fontSize: ".625rem", //10px
    fontWeight: 400,
  },
};
