import React, { useCallback } from "react";
import headCells from "./headCells";
import { formatDate } from "../../../helpers/utils/date.utils";
import useQuery from "../../../hooks/useQuery";
import { QueryParams } from "../../../constants/routeNames";
import { TablePaginationParams } from "../../../models/table.models";
import TableLayout from "../../../shared/sharedTable/TableLayout";
import { GetWasherSessionsParams } from "../../../models/requests/Sessions";
import useActions from "../../../hooks/useActions";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import { LogActions } from "../../../store/actions/log.actions";
import LogRow from "./LogRow";

interface OwnProps {
  userId?: string;
}

type Props = OwnProps;

const LogsList: React.FC<Props> = (props) => {
  const logTypeTexts = {
    CARD_ADDED: "Card Added",
    CARD_REMOVED: "Card Removed",
    WASH_STARTED: "Wash Started",
    PROFILE_UPDATED: "Profile Updated",
    CAR_ADDED: "Car Added",
    CAR_REMOVED: "Car Removed",
    WASH_ORDERED: "Wash Ordered",
    WASH_CANCELLED: "Wash Cancelled",
    WASH_COMPLETED: "Wash Completed",
    WASH_ACCEPTED: "Wash Accepted",
    WASH_DECLINED: "Wash Declined",
  };

  const logActions = useActions<typeof LogActions>(LogActions);
  const logState = useSelector((state: AppState) => state.log);

  const data = logState.logs.items.map((log) => ({
    id: log.id,
    activityType: logTypeTexts[log.type],
    date: formatDate(log.createdTime).join(" "),
  }));

  let query = useQuery();
  const queryFrom = query.get(QueryParams.FROM);
  const queryTo = query.get(QueryParams.TO);

  const handlePaginationChange = useCallback(
    (values: TablePaginationParams) => {
      const params: GetWasherSessionsParams = { ...values };
      if (queryTo) {
        params.dateTimeTo = queryTo;
      }
      if (queryFrom) {
        params.dateTimeFrom = queryFrom;
      }
      if (props.userId) {
        params.userId = props.userId;
      }
      logActions.getUserLogs(params);
    },
    [queryFrom, queryTo, logActions, props.userId]
  );

  return (
    <TableLayout
      title="Logs"
      subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      isLoading={logState.logsLoading}
      total={logState.logs.total}
      headCells={headCells}
      onPaginationChange={handlePaginationChange}
      withoutSorting
    >
      {data.map((row) => (
        <LogRow key={row.id} row={row} columns={headCells} />
      ))}
    </TableLayout>
  );
};

export default LogsList;
