import localforage from "localforage";

export const getToken = async (): Promise<string> => {
  return await localforage.getItem("access_token");
};

export const setToken = async (token: string) => {
  await localforage.setItem("access_token", token);
};

export const removeToken = async () => {
  await localforage.removeItem("access_token");
};
