import styled from "styled-components";
import React, { useState } from "react";

interface FirstLayerProps {
  active: boolean;
  rightText: string;
  leftText: string;
}

interface SecondLayerProps {
  active: boolean;
}

const FirstLayer = styled.div`
  &{
    position: relative;
    width: 125px;
    height: 32px;
    border-radius: 800px;
    background-color: #F0F0F0;
    display: flex;
    align-items: center;
    justify-content: ${(props: FirstLayerProps) =>
      props.active ? "flex-start" : "flex-end"};
    transition: 1s ease-in;
  }
  &:before{
    display: block;
    z-index: 1;
    position: absolute;
    left: 23px;
    top: auto;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: 300;
    color: ${(props: FirstLayerProps) => (props.active ? "#8D8D8D" : "#6176A8")}
    content: "${(props: FirstLayerProps) => props.leftText}";
  }
  &:after {
    display: block;
    z-index: 1;
    position: absolute;
    right: 23px;
    top: auto;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: 300;
    color: ${(props: FirstLayerProps) => (props.active ? "#6176A8" : "#8D8D8D")}
    content: "${(props: FirstLayerProps) => props.rightText}";
  }
`;

const SecondLayer = styled.div`
  position: absolute;
  height: 32px;
  width: 64px;
  left: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
  border-radius: 800px;
  transform: translateX(
    ${(props: SecondLayerProps) => (props.active ? 61 : 0)}px
  );
  transition: 0.5s;
  background-color: #ffffff;
`;

interface OwnProps {}

type Props = OwnProps;

const ToggleButton: React.FC<Props> = (props) => {
  const [active, setActive] = useState(false);
  return (
    <FirstLayer
      active={active}
      rightText="Views"
      leftText="Date"
      onClick={() => setActive(!active)}
    >
      <SecondLayer active={active} />
    </FirstLayer>
  );
};

export default ToggleButton;
