import {
  ActionsOfType,
  ActionsUnion,
  createAction,
} from "@martin_hotell/rex-tils";
import {
  AuthData,
  RequestSignUpEmailVerificationCodeData,
  SendSignUpCodeFromEmailData,
  SignUpData,
  VERIFY_TYPE,
} from "../../models/requests/Credentials";
import { User } from "../../models/responses/user.model";
import { ModifyUserInfoDto } from "../../models/requests/Users";

export interface SendVerificationCodesPayload {
  phoneNumber: string;
  email: string;
}

export enum AuthActionTypes {
  SIGN_IN = "[Auth] Sign in",
  SIGN_IN_FAILURE = "[Auth] Sign in failure",

  SIGN_UP_SEND_VERIFICATION_CODES = "[Auth] Sign up send verification codes",
  SIGN_UP_SEND_VERIFICATION_CODES_SUCCESS = "[Auth] Sign up send verification codes success",
  SIGN_UP_SEND_USER_DATA = "[Auth] Sign up send user data",
  SIGN_UP_FAILURE = "[Auth] Sign up failure",
  SIGN_UP_SUCCESS = "[Auth] Sign up success",

  UNAUTHORIZE = "[Auth] Unauthorize user",
  LOG_OUT = "[Auth] Logout user",

  OPEN_FORGOT_MODAL = "[Auth] Open forgot modal",
  CLOSE_FORGOT_MODAL = "[Auth] Close forgot modal",
  SEND_RESET_CODE_TO_EMAIL = "[Auth] Send reset code to email",
  SEND_RESET_CODE_SUCCESS = "[Auth] Send reset code to email success ",
  SEND_RESET_CODE_FAILURE = "[Auth] Send reset code to email failure",
  CONFIRM_RESET_CODE = "[Auth] Confirm reset code",
  CONFIRM_RESET_CODE_FAILURE = "[Auth] Confirm reset code to email failure",
  CONFIRM_RESET_CODE_SUCCESS = "[Auth] Confirm reset code to email success",
  CHANGE_PASSWORD = "[Auth] Change password",
  CHANGE_PASSWORD_FAILURE = "[Auth] Change password failure",

  GET_MY_USER_DATA = "[Auth] Get my user data",
  SET_MY_USER_DATA = "[Auth] Set my user data",

  UPDATE_CURRENT_USER_DATA = "[Auth] Update current user data",
  UPDATE_CURRENT_USER_SUCCESS = "[Auth] Update current user success",
  UPDATE_CURRENT_USER_FAILURE = "[Auth] Update current user failure",

  VERIFY_USER_EMAIL = "[Auth] Verify user email",
  VERIFY_USER_PHONE_NUMBER = "[Auth] Verify user phone number",
  SEND_VERIFY_CODE_SUCCESS = "[Auth] Send verify code success",
  SEND_VERIFY_CODE_FAILURE = "[Auth] Send verify code failure",

  CONFIRM_VERIFY_CODE = "[Auth] Confirm verify code",
  CONFIRM_VERIFY_CODE_SUCCESS = "[Auth] Confirm verify code success",
  CONFIRM_VERIFY_CODE_FAILURE = "[Auth] Confirm verify code failure",

  OPEN_CHANGE_CREDENTIAL_MODAL = "[Auth] Open change credential modal",
  CLOSE_CHANGE_CREDENTIAL_MODAL = "[Auth] Close change credential modal",
}

export const AuthActions = {
  signIn: (payload: AuthData) => createAction(AuthActionTypes.SIGN_IN, payload),
  signInFailure: (payload: string) =>
    createAction(AuthActionTypes.SIGN_IN_FAILURE, payload),
  unauthorize: () => createAction(AuthActionTypes.UNAUTHORIZE),
  logOut: () => createAction(AuthActionTypes.LOG_OUT),

  signUpSendVerificationCodes: (payload: SendVerificationCodesPayload) =>
    createAction(AuthActionTypes.SIGN_UP_SEND_VERIFICATION_CODES, payload),
  signUpSendVerificationCodesSuccess: () =>
    createAction(AuthActionTypes.SIGN_UP_SEND_VERIFICATION_CODES_SUCCESS),
  signUpSendUserData: (payload: SignUpData) =>
    createAction(AuthActionTypes.SIGN_UP_SEND_USER_DATA, payload),
  signUpFailure: (payload: string) =>
    createAction(AuthActionTypes.SIGN_UP_FAILURE, payload),
  signUpSuccess: () => createAction(AuthActionTypes.SIGN_UP_SUCCESS),

  openForgotModal: () => createAction(AuthActionTypes.OPEN_FORGOT_MODAL),
  closeForgotModal: () => createAction(AuthActionTypes.CLOSE_FORGOT_MODAL),

  sendResetCodeToEmail: (payload: RequestSignUpEmailVerificationCodeData) =>
    createAction(AuthActionTypes.SEND_RESET_CODE_TO_EMAIL, payload),
  sendResetCodeSuccess: (payload: string) =>
    createAction(AuthActionTypes.SEND_RESET_CODE_SUCCESS, payload),
  sendResetCodeFailure: (payload: string) =>
    createAction(AuthActionTypes.SEND_RESET_CODE_FAILURE, payload),

  confirmResetCode: (payload: SendSignUpCodeFromEmailData) =>
    createAction(AuthActionTypes.CONFIRM_RESET_CODE, payload),
  confirmResetCodeSuccess: (payload: string) =>
    createAction(AuthActionTypes.CONFIRM_RESET_CODE_SUCCESS, payload),
  confirmResetCodeFailure: (payload: string) =>
    createAction(AuthActionTypes.CONFIRM_RESET_CODE_FAILURE, payload),

  changePassword: (payload: { password: string }) =>
    createAction(AuthActionTypes.CHANGE_PASSWORD, payload),
  changePasswordFailure: (payload: string) =>
    createAction(AuthActionTypes.CHANGE_PASSWORD_FAILURE, payload),

  getMyUserData: () => createAction(AuthActionTypes.GET_MY_USER_DATA),
  setMyUserData: (payload: User) =>
    createAction(AuthActionTypes.SET_MY_USER_DATA, payload),

  updateCurrentUserData: (payload: Partial<ModifyUserInfoDto>) =>
    createAction(AuthActionTypes.UPDATE_CURRENT_USER_DATA, payload),
  updateCurrentUserSuccess: (payload: User) =>
    createAction(AuthActionTypes.UPDATE_CURRENT_USER_SUCCESS, payload),
  updateCurrentUserFailure: (payload: string) =>
    createAction(AuthActionTypes.UPDATE_CURRENT_USER_FAILURE, payload),

  verifyUserEmail: (payload: { email: string; template: string }) =>
    createAction(AuthActionTypes.VERIFY_USER_EMAIL, payload),
  verifyUserPhoneNumber: (payload: { phoneNumber: string; template: string }) =>
    createAction(AuthActionTypes.VERIFY_USER_PHONE_NUMBER, payload),
  sendVerifyCodeSuccess: () =>
    createAction(AuthActionTypes.SEND_VERIFY_CODE_SUCCESS),
  sendVerifyCodeFailure: (payload: string) =>
    createAction(AuthActionTypes.SEND_VERIFY_CODE_FAILURE, payload),

  confirmVerifyCode: (payload: string) =>
    createAction(AuthActionTypes.CONFIRM_VERIFY_CODE, payload),
  confirmVerifyCodeSuccess: () =>
    createAction(AuthActionTypes.CONFIRM_VERIFY_CODE_SUCCESS),
  confirmVerifyCodeFailure: (payload: string) =>
    createAction(AuthActionTypes.CONFIRM_VERIFY_CODE_FAILURE, payload),

  openChangeCredentialModal: (payload: VERIFY_TYPE) =>
    createAction(AuthActionTypes.OPEN_CHANGE_CREDENTIAL_MODAL, payload),
  closeChangeCredentialModal: () =>
    createAction(AuthActionTypes.CLOSE_CHANGE_CREDENTIAL_MODAL),
};

export type AuthActions = ActionsUnion<typeof AuthActions>;

export type signInType = ActionsOfType<AuthActions, AuthActionTypes.SIGN_IN>;
export type sendResetCodeToEmailType = ActionsOfType<
  AuthActions,
  AuthActionTypes.SEND_RESET_CODE_TO_EMAIL
>;
export type confirmResetCodeType = ActionsOfType<
  AuthActions,
  AuthActionTypes.CONFIRM_RESET_CODE
>;
export type changePasswordType = ActionsOfType<
  AuthActions,
  AuthActionTypes.CHANGE_PASSWORD
>;
export type signUpSendVerificationCodeType = ActionsOfType<
  AuthActions,
  AuthActionTypes.SIGN_UP_SEND_VERIFICATION_CODES
>;
export type signUpSendUserDataType = ActionsOfType<
  AuthActions,
  AuthActionTypes.SIGN_UP_SEND_USER_DATA
>;

export type updateCurrentUserDataType = ActionsOfType<
  AuthActions,
  AuthActionTypes.UPDATE_CURRENT_USER_DATA
>;

export type verifyUserEmailType = ActionsOfType<
  AuthActions,
  AuthActionTypes.VERIFY_USER_EMAIL
>;

export type verifyUserPhoneNumberType = ActionsOfType<
  AuthActions,
  AuthActionTypes.VERIFY_USER_PHONE_NUMBER
>;

export type confirmVerifyCodeType = ActionsOfType<
  AuthActions,
  AuthActionTypes.CONFIRM_VERIFY_CODE
>;
