import React from "react";

interface OwnProps {}

type Props = OwnProps;

const SuvMinivanIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="30.556"
      viewBox="0 0 50 30.556"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_234"
            data-name="Rectangle 234"
            width="50"
            height="30.556"
            transform="translate(9)"
            fill="currentColor"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_17"
        data-name="Mask Group 17"
        transform="translate(-9)"
        clipPath="url(#clip-path)"
      >
        <g id="car_5_" data-name="car (5)" transform="translate(9 -9.722)">
          <path
            id="Path_876"
            data-name="Path 876"
            d="M46.963,23.291l-8.081-6.1a35.686,35.686,0,0,0-21.376-7.164H8.836a5.934,5.934,0,0,0-5.523,3.722L1.16,19.064A15.788,15.788,0,0,0,0,25.018v4.8a6.023,6.023,0,0,0,5.133,5.949,5.591,5.591,0,0,0,10.818.065H34.06a5.591,5.591,0,0,0,10.8,0h3.691A1.449,1.449,0,0,0,50,34.383V29.391A7.681,7.681,0,0,0,46.963,23.291ZM13.244,34.383a2.693,2.693,0,1,1-2.693-2.693A2.7,2.7,0,0,1,13.244,34.383Zm28.909,0A2.693,2.693,0,1,1,39.46,31.69,2.7,2.7,0,0,1,42.153,34.383ZM47.1,32.934H44.86a5.591,5.591,0,0,0-10.8,0H15.951a5.591,5.591,0,0,0-10.767-.116,3.119,3.119,0,0,1-2.285-3v-4.8a12.9,12.9,0,0,1,.948-4.866L6,14.835a3.048,3.048,0,0,1,2.837-1.912h8.669A32.77,32.77,0,0,1,37.135,19.5l8.081,6.1A4.768,4.768,0,0,1,47.1,29.39v3.544Z"
            fill="currentColor"
          />
          <path
            id="Path_877"
            data-name="Path 877"
            d="M35.138,21.473,32.48,19.466A21.739,21.739,0,0,0,19.458,15.1a1.449,1.449,0,0,0-1.449,1.449v6.42a1.449,1.449,0,0,0,1.449,1.449H34.264a1.449,1.449,0,0,0,1.449-1.449v-.341A1.45,1.45,0,0,0,35.138,21.473ZM20.907,18.057a18.851,18.851,0,0,1,9.477,3.464H20.907Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export default SuvMinivanIcon;
