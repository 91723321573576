import { CompanyActions, CompanyActionTypes } from "../actions/company.actions";
import { Companies, Company } from "../../models/responses/company.model";

class StateModel {
  allCompanies: Companies = { items: [], total: 0 };
  companiesLoading: boolean = false;
  companiesError: string = "";
  createCompanyLoading: boolean = false;
  createCompanyError: string = "";
  activeCompany: Company | null = null;
  activeCompanyLoading: boolean = false;
  activeCompanyError: string = "";
}

const initialState = new StateModel();

export function companyReducer(
  state = initialState,
  action: CompanyActions
): StateModel {
  switch (action.type) {
    case CompanyActionTypes.GET_ALL_COMPANIES: {
      return {
        ...state,
        companiesError: "",
        companiesLoading: true,
      };
    }
    case CompanyActionTypes.GET_ALL_COMPANIES_SUCCESS: {
      return {
        ...state,
        companiesLoading: false,
        allCompanies: action.payload,
      };
    }
    case CompanyActionTypes.GET_ALL_COMPANIES_FAILURE: {
      return {
        ...state,
        companiesLoading: false,
        companiesError: action.payload,
      };
    }
    case CompanyActionTypes.CREATE_COMPANY: {
      return {
        ...state,
        createCompanyLoading: true,
        createCompanyError: "",
      };
    }
    case CompanyActionTypes.CREATE_COMPANY_SUCCESS: {
      return {
        ...state,
        createCompanyLoading: false,
        activeCompany: action.payload,
      };
    }
    case CompanyActionTypes.CREATE_COMPANY_FAILURE: {
      return {
        ...state,
        createCompanyLoading: false,
        createCompanyError: action.payload,
      };
    }
    case CompanyActionTypes.GET_MY_ACTIVE_COMPANY:
      return {
        ...state,
        activeCompanyLoading: true,
        activeCompanyError: "",
      };
    case CompanyActionTypes.GET_MY_ACTIVE_COMPANY_SUCCESS:
      return {
        ...state,
        activeCompanyLoading: false,
        activeCompany: action.payload,
      };
    case CompanyActionTypes.GET_MY_ACTIVE_COMPANY_FAILURE:
      return {
        ...state,
        activeCompanyLoading: false,
        activeCompanyError: action.payload,
      };
    default:
      return state;
  }
}
