import React from "react";

interface OwnProps {}

type Props = OwnProps;

const ArrowLeftIcon: React.FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.092"
      height="10.546"
      viewBox="0 0 15.092 10.546"
    >
      <path
        id="Union_4"
        data-name="Union 4"
        d="M9.945.248a.851.851,0,0,0-.007,1.207l3.192,3.231s0,0,0,0a.856.856,0,0,1,0,1.207L9.941,9.088a.853.853,0,0,0,1.207,1.207L14.343,7.1a2.561,2.561,0,0,0,0-3.619L11.153.255A.852.852,0,0,0,9.945.248ZM.854,4.44a.854.854,0,0,0,0,1.707H9.948a.854.854,0,0,0,0-1.707Z"
        transform="translate(15.092 10.546) rotate(180)"
        opacity="0.4"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
