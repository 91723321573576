import { MobileBuilds } from "../../models/responses/MobileBuildBlacklist.model";
import {
  MobileBuildBlacklistActions,
  MobileBuildBlacklistActionTypes,
} from "../actions/mobileBuildBlacklist.actions";

class StateModel {
  mobileBuilds: MobileBuilds = { items: [], total: 0 };
  mobileBuildsLoading = false;
  mobileBuildsError = "";
  addMobileBuildsLoading = false;
  addMobileBuildsError = "";
  removeMobileBuildsLoading = false;
  removeMobileBuildsError = "";
}

const initialState = new StateModel();

export function mobileBuildBlacklistReducer(
  state = initialState,
  action: MobileBuildBlacklistActions
): StateModel {
  switch (action.type) {
    case MobileBuildBlacklistActionTypes.ADD_MOBILE_BUILD:
      return {
        ...state,
        addMobileBuildsLoading: true,
        addMobileBuildsError: "",
      };
    case MobileBuildBlacklistActionTypes.ADD_MOBILE_BUILD_SUCCESS:
      return {
        ...state,
        addMobileBuildsLoading: false,
        mobileBuilds: action.payload,
      };
    case MobileBuildBlacklistActionTypes.ADD_MOBILE_BUILD_FAILURE:
      return {
        ...state,
        addMobileBuildsLoading: false,
        addMobileBuildsError: action.payload,
      };
    case MobileBuildBlacklistActionTypes.GET_MOBILE_BUILDS:
      return {
        ...state,
        mobileBuildsLoading: true,
        mobileBuildsError: "",
      };
    case MobileBuildBlacklistActionTypes.GET_MOBILE_BUILDS_SUCCESS:
      return {
        ...state,
        mobileBuildsLoading: false,
        mobileBuilds: action.payload,
      };
    case MobileBuildBlacklistActionTypes.GET_MOBILE_BUILDS_FAILURE:
      return {
        ...state,
        mobileBuildsLoading: false,
        mobileBuildsError: action.payload,
      };
    case MobileBuildBlacklistActionTypes.REMOVE_MOBILE_BUILD:
      return {
        ...state,
        removeMobileBuildsLoading: true,
        removeMobileBuildsError: "",
      };
    case MobileBuildBlacklistActionTypes.REMOVE_MOBILE_BUILD_SUCCESS:
      return {
        ...state,
        removeMobileBuildsLoading: false,
        mobileBuilds: action.payload,
      };
    case MobileBuildBlacklistActionTypes.REMOVE_MOBILE_BUILD_FAILURE:
      return {
        ...state,
        removeMobileBuildsLoading: false,
        removeMobileBuildsError: action.payload,
      };
    default:
      return state;
  }
}
