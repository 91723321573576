import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = SvgIconProps;

const DriverLicenseIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="23.498"
      height="26.219"
      viewBox="0 0 23.498 26.219"
      {...props}
    >
      <path
        id="done"
        d="M9.98,24.171H4.1a2.056,2.056,0,0,1-2.054-2.048V6.657A2.056,2.056,0,0,1,4.1,4.609H7.317v.512a3.073,3.073,0,0,0,6.145,0V4.609h3.226a2.051,2.051,0,0,1,2.048,2.048v7.886a1.024,1.024,0,0,0,2.048,0V6.657a4.1,4.1,0,0,0-4.1-4.1H13.462V1.024A1.024,1.024,0,0,0,12.438,0h-4.1A1.024,1.024,0,0,0,7.317,1.024V2.56H4.1A4.1,4.1,0,0,0,0,6.657V22.122a4.1,4.1,0,0,0,4.1,4.1H9.98a1.024,1.024,0,0,0,0-2.048ZM9.366,2.048h2.048V5.121a1.024,1.024,0,0,1-2.048,0ZM23.306,18.11l-5.069,7.08-.034.045a2.64,2.64,0,0,1-1.878.978q-.087.006-.174.006a2.64,2.64,0,0,1-1.817-.726l-3.272-3.141a1.024,1.024,0,0,1,1.419-1.477l3.268,3.138a.585.585,0,0,0,.842-.044l5.048-7.051a1.024,1.024,0,1,1,1.666,1.192ZM7.061,12.239a1.024,1.024,0,0,1-1.024,1.024H5.525a1.024,1.024,0,1,1,0-2.048h.512A1.024,1.024,0,0,1,7.061,12.239Zm9.32,0a1.024,1.024,0,0,1-1.024,1.024H9.622a1.024,1.024,0,1,1,0-2.048h5.735A1.024,1.024,0,0,1,16.381,12.239Zm-9.32,4.1A1.024,1.024,0,0,1,6.037,17.36H5.525a1.024,1.024,0,0,1,0-2.048h.512A1.024,1.024,0,0,1,7.061,16.336Zm9.32,0a1.024,1.024,0,0,1-1.024,1.024H9.622a1.024,1.024,0,1,1,0-2.048h5.735A1.024,1.024,0,0,1,16.381,16.336Zm0,0"
      />
    </svg>
  );
};

export default DriverLicenseIcon;
