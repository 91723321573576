import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "../pages/auth/Login";
import { useSelector } from "react-redux";
import { AppState } from "../store/reducers";
import MainLayout from "../layout/MainLayout";
import { RouteNames } from "../constants/routeNames";
import useActions from "../hooks/useActions";
import { AuthActions } from "../store/actions/auth.actions";
import Loading from "../shared/Loading";
import { AuthSelectors } from "../store/reducers/auth.reducer";
import { User, UserRole } from "../models/responses/user.model";
import { UserRoutes } from "../models/router.models";
import washerRoutes from "./routes/washer.routes";
import promoterRoutes from "./routes/promoter.routes";
import pendingVerifiedWasherRoutes from "./routes/pandingVerifiedWasher.routes";
import companyAdminRoutes from "./routes/companyAdmin.routes";
import adminRoutes from "./routes/admin.routes";
import pendingWasherRoutes from "./routes/pandingWasher.routes";

function getUserRoutes(user: User | null): UserRoutes {
  switch (user?.role) {
    case UserRole.ADMIN:
      return adminRoutes;
    case UserRole.PENDING_WASHER:
      return user?.isVerified
        ? pendingVerifiedWasherRoutes
        : pendingWasherRoutes;
    case UserRole.WASHER:
      return washerRoutes;
    case UserRole.COMPANY_ADMIN:
      return companyAdminRoutes;
    case UserRole.PROMOTER:
      return promoterRoutes;
    default:
      return {
        routes: [],
        defaultRoute: `/${RouteNames.ROOT}`,
      };
  }
}

const AppRouter: React.FC = () => {
  const authActions = useActions<typeof AuthActions>(AuthActions);
  const authState = useSelector((state: AppState) => state.auth);
  const isAuthorized = AuthSelectors.isAuthorized(authState);
  useEffect(() => {
    if (!authState.isAuthChecked) {
      authActions.getMyUserData();
    }
  }, [authState.isAuthChecked, authActions]);
  const { routes, defaultRoute } = getUserRoutes(authState.authorizedUser);

  if (!authState.isAuthChecked) {
    return <Loading />;
  }
  if (!isAuthorized) {
    return <Login />;
  }
  return (
    <MainLayout>
      <Switch>
        {routes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        {<Redirect to={defaultRoute} />}
      </Switch>
    </MainLayout>
  );
};

export default AppRouter;
