import React, { useState } from "react";
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { unitSize } from "../../constants/sizes";
import getCarIconByType from "../../shared/icons/CarTypes/getCarIconByType";
import { Order, OrderStatus } from "../../models/responses/order.model";
import PhotosLightbox from "../../shared/PhotosLightbox";
import { getImageUrl } from "../../helpers/utils/utils";
import texts from "../../constants/texts";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: theme.spacing(12),
    padding: theme.spacing(0, 4),
    color: theme.palette.primary.main,
  },
}));

interface OwnProps {
  order: Order;
}

type Props = OwnProps;

const CarInfo: React.FC<Props> = ({ order }) => {
  const [carouselOpen, setCarouselOpen] = useState(false);
  const { car } = order;
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Box display="flex" alignItems="center">
        {getCarIconByType(car.carType.name)}
        <Box ml={3}>
          <Typography variant="h5" color="primary">
            {car.model}
          </Typography>
          <Typography variant="body1">{car.licensePlate}</Typography>
        </Box>
      </Box>
      <Box width={unitSize * 26}>
        <Button
          disabled={order.status !== OrderStatus.COMPLETED}
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => {
            setCarouselOpen(true);
          }}
        >
          {texts.orderDetails.washPhotoButton}
        </Button>
      </Box>
      {carouselOpen && (
        <PhotosLightbox
          images={order.attachment.map((item) => getImageUrl(item.fileId))}
          onClose={() => setCarouselOpen(false)}
        />
      )}
    </Paper>
  );
};

export default CarInfo;
