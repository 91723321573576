export const packages = [
  {
    id: "2cff1ec6-204b-42b8-8c71-b204a797743f",
    nameCode: "interior_perfume_strawberry",
    title: "Interior Perfume strawberry",
    price: 0,
    washerPayoutPrice: 0,
    duration: "00:01:00",
    extraTime: null,
    index: 3,
    iconFileId: "71e27d74-4854-4c25-b2d4-7ce921f414d6",
    extraDirtyAllow: false,
    createdTime: "2020-04-15T09:59:54.000Z",
    icon: {
      createdTime: "2020-04-15T09:59:53.000Z",
      id: "71e27d74-4854-4c25-b2d4-7ce921f414d6",
      key:
        "car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/907a19bb-1a87-4694-8add-71183368c117/perfume.png",
      ownerId: "1f741180-9400-48e0-b5a4-ac97bc2f2d8d",
      type: "image/png",
      tag: "standard-package",
      url:
        "https://washerly-dev-bucket.cellar-c2.services.clever-cloud.com/car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/907a19bb-1a87-4694-8add-71183368c117/perfume.png",
      size: "5253",
    },
    equipments: [
      {
        id: "171f2ace-6468-4866-acad-05966f63b1c4",
        blueprintPackageId: "2cff1ec6-204b-42b8-8c71-b204a797743f",
        equipmentId: "97d3e6fb-58df-45c0-b433-89f8917125be",
        equipmentValue: 5,
      },
    ],
  },
  {
    id: "5ffb8e48-b8bf-46af-8aaf-087263669090",
    nameCode: "interior_perfume_apple",
    title: "Interior Perfume apple",
    price: 0,
    washerPayoutPrice: 0,
    duration: "00:01:00",
    extraTime: null,
    index: 2,
    iconFileId: "71e27d74-4854-4c25-b2d4-7ce921f414d6",
    extraDirtyAllow: false,
    createdTime: "2020-04-15T09:59:54.000Z",
    icon: {
      createdTime: "2020-04-15T09:59:53.000Z",
      id: "71e27d74-4854-4c25-b2d4-7ce921f414d6",
      key:
        "car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/907a19bb-1a87-4694-8add-71183368c117/perfume.png",
      ownerId: "1f741180-9400-48e0-b5a4-ac97bc2f2d8d",
      type: "image/png",
      tag: "standard-package",
      url:
        "https://washerly-dev-bucket.cellar-c2.services.clever-cloud.com/car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/907a19bb-1a87-4694-8add-71183368c117/perfume.png",
      size: "5253",
    },
    equipments: [
      {
        id: "8f47775b-be84-49e0-82ef-8a0f53f5de26",
        blueprintPackageId: "5ffb8e48-b8bf-46af-8aaf-087263669090",
        equipmentId: "97d3e6fb-58df-45c0-b433-89f8917125be",
        equipmentValue: 5,
      },
    ],
  },
  {
    id: "8587068a-2a46-4607-a270-939cf0c02355",
    nameCode: "interior_perfume_mango_peach",
    title: "Interior Perfume mango and peach",
    price: 0,
    washerPayoutPrice: 0,
    duration: "00:01:00",
    extraTime: null,
    index: 4,
    iconFileId: "71e27d74-4854-4c25-b2d4-7ce921f414d6",
    extraDirtyAllow: false,
    createdTime: "2020-04-15T09:59:54.000Z",
    icon: {
      createdTime: "2020-04-15T09:59:53.000Z",
      id: "71e27d74-4854-4c25-b2d4-7ce921f414d6",
      key:
        "car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/907a19bb-1a87-4694-8add-71183368c117/perfume.png",
      ownerId: "1f741180-9400-48e0-b5a4-ac97bc2f2d8d",
      type: "image/png",
      tag: "standard-package",
      url:
        "https://washerly-dev-bucket.cellar-c2.services.clever-cloud.com/car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/907a19bb-1a87-4694-8add-71183368c117/perfume.png",
      size: "5253",
    },
    equipments: [
      {
        id: "87073516-4afe-4fa5-aab6-2c00b5b2a0c0",
        blueprintPackageId: "8587068a-2a46-4607-a270-939cf0c02355",
        equipmentId: "97d3e6fb-58df-45c0-b433-89f8917125be",
        equipmentValue: 5,
      },
    ],
  },
  {
    id: "e95d9625-1239-4cae-bbc7-6602d9686918",
    nameCode: "interior_cleaning",
    title: "Interior Cleaning",
    price: 1500,
    washerPayoutPrice: 750,
    duration: "00:35:00",
    extraTime: "00:10:00",
    index: 0,
    iconFileId: "5acebf94-7699-4fa6-af58-f37e2beef516",
    extraDirtyAllow: true,
    createdTime: "2020-04-15T09:59:54.000Z",
    icon: {
      createdTime: "2020-04-15T09:59:53.000Z",
      id: "5acebf94-7699-4fa6-af58-f37e2beef516",
      key:
        "car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/4d2ba4ca-db88-477b-b67f-f9bea4820e81/interior_cleaning.png",
      ownerId: "1f741180-9400-48e0-b5a4-ac97bc2f2d8d",
      type: "image/png",
      tag: "standard-package",
      url:
        "https://washerly-dev-bucket.cellar-c2.services.clever-cloud.com/car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/4d2ba4ca-db88-477b-b67f-f9bea4820e81/interior_cleaning.png",
      size: "7783",
    },
    equipments: [
      {
        id: "88db23ed-d527-4647-af70-b38b23616de8",
        blueprintPackageId: "e95d9625-1239-4cae-bbc7-6602d9686918",
        equipmentId: "2f02c956-f260-4d62-ad0d-8252fb618d4c",
        equipmentValue: 5,
      },
    ],
  },
  {
    id: "f612882e-a92a-412b-b538-260c834e2c9d",
    nameCode: "exterior_wash",
    title: "Exterior wash",
    price: 1500,
    washerPayoutPrice: 750,
    duration: "00:55:00",
    extraTime: "00:10:00",
    index: 1,
    iconFileId: "63adca9e-c57d-42d4-a54d-eefd0cf5542d",
    extraDirtyAllow: true,
    createdTime: "2020-04-15T09:59:54.000Z",
    icon: {
      createdTime: "2020-04-15T09:59:53.000Z",
      id: "63adca9e-c57d-42d4-a54d-eefd0cf5542d",
      key:
        "car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/a5f85815-21fb-495b-80b2-4f0c4bf938a3/exterior_wash.png",
      ownerId: "1f741180-9400-48e0-b5a4-ac97bc2f2d8d",
      type: "image/png",
      tag: "standard-package",
      url:
        "https://washerly-dev-bucket.cellar-c2.services.clever-cloud.com/car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/a5f85815-21fb-495b-80b2-4f0c4bf938a3/exterior_wash.png",
      size: "5382",
    },
    equipments: [
      {
        id: "f8c1dd7a-2515-4152-bd58-7dde06615cd4",
        blueprintPackageId: "f612882e-a92a-412b-b538-260c834e2c9d",
        equipmentId: "513940e6-7a03-4ace-87ab-aab637e17c2c",
        equipmentValue: 5,
      },
    ],
  },
  {
    id: "fc00b4c1-a954-4770-9cc6-df23f7eab24d",
    nameCode: "nano_windshield_protection",
    title: "Nano windshield protection",
    price: 700,
    washerPayoutPrice: 100,
    duration: "00:15:00",
    extraTime: null,
    index: 5,
    iconFileId: "3134330e-770f-4adf-bf96-93410219c980",
    extraDirtyAllow: false,
    createdTime: "2020-04-15T09:59:54.000Z",
    icon: {
      createdTime: "2020-04-15T09:59:53.000Z",
      id: "3134330e-770f-4adf-bf96-93410219c980",
      key:
        "car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/b239b73f-ec74-423e-aa7b-516f806bdd6f/nano_liquid.png",
      ownerId: "1f741180-9400-48e0-b5a4-ac97bc2f2d8d",
      type: "image/png",
      tag: "standard-package",
      url:
        "https://washerly-dev-bucket.cellar-c2.services.clever-cloud.com/car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/b239b73f-ec74-423e-aa7b-516f806bdd6f/nano_liquid.png",
      size: "8686",
    },
    equipments: [
      {
        id: "95b3f0e2-f388-4281-8fbb-a09e5b1fc267",
        blueprintPackageId: "fc00b4c1-a954-4770-9cc6-df23f7eab24d",
        equipmentId: "97d3e6fb-58df-45c0-b433-89f8917125be",
        equipmentValue: 5,
      },
    ],
  },
  {
    id: "31f71f72-1289-40a6-8458-86c674b09299",
    nameCode: "windshield_fluid",
    title: "Windshield fluid",
    price: 400,
    washerPayoutPrice: 0,
    duration: "00:02:00",
    extraTime: null,
    index: 6,
    iconFileId: "cc2b0715-de05-428a-991c-736d62e78396",
    extraDirtyAllow: false,
    createdTime: "2020-04-15T09:59:55.000Z",
    icon: {
      createdTime: "2020-04-15T09:59:53.000Z",
      id: "cc2b0715-de05-428a-991c-736d62e78396",
      key:
        "car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/8796e999-a085-473c-85dd-7501e76bc5c0/wax.png",
      ownerId: "1f741180-9400-48e0-b5a4-ac97bc2f2d8d",
      type: "image/png",
      tag: "standard-package",
      url:
        "https://washerly-dev-bucket.cellar-c2.services.clever-cloud.com/car-shine-files/1f741180-9400-48e0-b5a4-ac97bc2f2d8d/8796e999-a085-473c-85dd-7501e76bc5c0/wax.png",
      size: "8686",
    },
    equipments: [
      {
        id: "0f46442f-5a69-432f-b850-8c93bc2e7db6",
        blueprintPackageId: "31f71f72-1289-40a6-8458-86c674b09299",
        equipmentId: "2a2d02c4-7a11-4b41-aaf6-4d74fe66132f",
        equipmentValue: 5,
      },
    ],
  },
];
