import React, { useEffect, useState } from "react";
import TutorialsHeader from "../../../components/Tutorials/TutorialsHeader";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import VideoModal from "./components/VideoModal";
import mockedTutorials from "../../../mock/mockedTutorials";
import { tutorialsApi } from "../../../api/tutorials.api";
import texts from "../../../constants/texts";
import { useTitle } from "react-use";

interface OwnProps {}

type Props = OwnProps;

const WasherTutorials: React.FC<Props> = () => {
  useTitle(`Washerly Tutorials`);
  const [videoId, setVideoId] = useState("");
  const [hotData, setHotData] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    const hotReqs = mockedTutorials.hot.map((id) =>
      tutorialsApi.getTutorialInfo(id).then((resp) => ({
        ...resp.data.data,
        id,
      }))
    );
    Promise.all<any>(hotReqs).then((items) => {
      setHotData(items);
    });
    const reqs = mockedTutorials.common.map((id) =>
      tutorialsApi.getTutorialInfo(id).then((resp) => ({
        ...resp.data.data,
        id,
      }))
    );
    Promise.all<any>(reqs).then((items) => {
      setData(items);
    });
  }, []);
  const handleClickOnVideo = (id: string) => {
    setVideoId(id);
  };

  const handleClose = () => {
    setVideoId("");
  };
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12}>
        <TutorialsHeader
          title={texts.tutorials.hotHeader}
          description={texts.tutorials.hotDescription}
          hot
        />
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {hotData.map((item) => (
          <Grid key={item.id} item xs={4}>
            <Card>
              <CardActionArea onClick={() => handleClickOnVideo(item.id)}>
                <CardMedia
                  component="img"
                  height="203"
                  image={item.thumbnail_url}
                  title={item.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    {item.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <TutorialsHeader
          title={texts.tutorials.commonHeader}
          description={texts.tutorials.commonDescription}
        />
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {data.map((item) => (
          <Grid key={item.id} item xs={3}>
            <Card>
              <CardActionArea onClick={() => handleClickOnVideo(item.id)}>
                <CardMedia
                  component="img"
                  height="203"
                  image={item.thumbnail_url}
                  title={item.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    {item.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <VideoModal open={!!videoId} onClose={handleClose} videoId={videoId} />
    </Grid>
  );
};

export default WasherTutorials;
