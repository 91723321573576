import * as React from "react";
import { useEffect } from "react";
import NavBar from "./NavBar";
import { Container, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import Header from "./Header";
import CustomizedSnackbar from "../../shared/CustomizedSnackbar";
import AppBarSpacer from "../../styled/AppBarSpacer";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import { AuthSelectors } from "../../store/reducers/auth.reducer";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useTitle } from "react-use";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const MainLayout: React.FC = (props) => {
  useTitle(`Washerly`);
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(true);
  const authState = useSelector((state: AppState) => state.auth);
  const isAdmin = AuthSelectors.isAdmin(authState);
  const matches = useMediaQuery("(min-width:1200px)");

  useEffect(() => {
    setIsOpen(matches);
  }, [matches]);

  const handleToggleClose = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes.root}>
      <Header isOpen={isOpen} />
      <NavBar onToggleDriver={handleToggleClose} isOpen={isOpen} />
      <main className={classes.content}>
        <AppBarSpacer withStatistic={!!isAdmin} />
        <Container maxWidth="lg" className={classes.container}>
          {props.children}
        </Container>
      </main>
      <CustomizedSnackbar />
      <ConfirmationDialog />
    </div>
  );
};

export default MainLayout;
