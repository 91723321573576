import React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: theme.spacing(3),
      marginRight: theme.spacing(2),
      flexShrink: 0,
    },
  })
);

interface OwnProps {
  Icon: React.FC<SvgIconProps>;
  text: string;
  maxWidth?: number;
}

type Props = OwnProps;

const PrimaryIconWithText: React.FC<Props> = ({ Icon, text, maxWidth }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      color="primary.main"
      maxWidth={maxWidth}
    >
      <Icon className={classes.icon} />
      <Box>
        <Typography variant="h6" color="primary">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default PrimaryIconWithText;
